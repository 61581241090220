export const bist100 = {
	indices: [{ name: "BIST 100", yahoo: "^XU100" }],
	companies: [
		{ name: "Anadolu Efes Malt", indices: ["BIST 100"], symbol: "AEFES.IS" },
		{ name: "Akbank TAS", indices: ["BIST 100"], symbol: "AKBNK.IS" },
		{ name: "Akfen GYO", indices: ["BIST 100"], symbol: "AKFGY.IS" },
		{ name: "Aksa Akrilik", indices: ["BIST 100"], symbol: "AKSA.IS" },
		{ name: "Aksa Enerji Uretim", indices: ["BIST 100"], symbol: "AKSEN.IS" },
		{ name: "Albaraka Turk", indices: ["BIST 100"], symbol: "ALBRK.IS" },
		{ name: "Arcelik AS", indices: ["BIST 100"], symbol: "ARCLK.IS" },
		{ name: "Aselsan", indices: ["BIST 100"], symbol: "ASELS.IS" },
		{ name: "Bosch Fren", indices: ["BIST 100"], symbol: "BFREN.IS" },
		{ name: "BIM Magazalar", indices: ["BIST 100"], symbol: "BIMAS.IS" },
		{ name: "Borusan Birlesik", indices: ["BIST 100"], symbol: "BRSAN.IS" },
		{ name: "Borusan Yatirim", indices: ["BIST 100"], symbol: "BRYAT.IS" },
		{ name: "Coca Cola Icecek", indices: ["BIST 100"], symbol: "CCOLA.IS" },
		{ name: "Cimsa", indices: ["BIST 100"], symbol: "CIMSA.IS" },
		{ name: "Dogus Otomotiv", indices: ["BIST 100"], symbol: "DOGUB.IS" },
		{ name: "Dogan Holding", indices: ["BIST 100"], symbol: "DOHOL.IS" },
		{ name: "Eczacibasi Yatirim", indices: ["BIST 100"], symbol: "ECZYT.IS" },
		{ name: "Ege Endustri", indices: ["BIST 100"], symbol: "EGEEN.IS" },
		{ name: "Emlak Konut GYO", indices: ["BIST 100"], symbol: "EKGYO.IS" },
		{ name: "Enerjisa Enerji", indices: ["BIST 100"], symbol: "ENJSA.IS" },
		{ name: "ENKA", indices: ["BIST 100"], symbol: "ENKAI.IS" },
		{ name: "Erdemir", indices: ["BIST 100"], symbol: "EREGL.IS" },
		{ name: "Ford Otosan", indices: ["BIST 100"], symbol: "FROTO.IS" },
		{ name: "Garanti Bank", indices: ["BIST 100"], symbol: "GARAN.IS" },
		{ name: "Goltas Cimento", indices: ["BIST 100"], symbol: "GOLTS.IS" },
		{ name: "Gubretas", indices: ["BIST 100"], symbol: "GUBRF.IS" },
		{ name: "Turkiye Sigorta", indices: ["BIST 100"], symbol: "TSKB.IS" },
		{ name: "Turkiye Halk Bk", indices: ["BIST 100"], symbol: "HALKB.IS" },
		{ name: "Hektas", indices: ["BIST 100"], symbol: "HEKTS.IS" },
		{ name: "Turkiye Is Bankasi C", indices: ["BIST 100"], symbol: "ISCTR.IS" },
		{ name: "Is GYO", indices: ["BIST 100"], symbol: "ISGYO.IS" },
		{
			name: "Is Yatirim Menkul Degerler",
			indices: ["BIST 100"],
			symbol: "ISMEN.IS",
		},
		{ name: "Koc Holding", indices: ["BIST 100"], symbol: "KCHOL.IS" },
		{ name: "Konya Cimento", indices: ["BIST 100"], symbol: "KONYA.IS" },
		{ name: "Koza Anadolu", indices: ["BIST 100"], symbol: "KOZAA.IS" },
		{ name: "Koza Altin", indices: ["BIST 100"], symbol: "KOZAL.IS" },
		{ name: "Kardemir D", indices: ["BIST 100"], symbol: "KRDMD.IS" },
		{ name: "Mavi Giyim", indices: ["BIST 100"], symbol: "MAVI.IS" },
		{ name: "Migros", indices: ["BIST 100"], symbol: "MGROS.IS" },
		{ name: "Oyak Cimento", indices: ["BIST 100"], symbol: "OYAKC.IS" },
		{ name: "Otokar", indices: ["BIST 100"], symbol: "OTKAR.IS" },
		{ name: "Petkim", indices: ["BIST 100"], symbol: "PETKM.IS" },
		{ name: "Sabanci Holding", indices: ["BIST 100"], symbol: "SAHOL.IS" },
		{ name: "SASA Polyester", indices: ["BIST 100"], symbol: "SASA.IS" },
		{ name: "Sisecam", indices: ["BIST 100"], symbol: "SISE.IS" },
		{ name: "Sekerbank", indices: ["BIST 100"], symbol: "SKBNK.IS" },
		{ name: "TAV Havalimanlar", indices: ["BIST 100"], symbol: "TAVHL.IS" },
		{ name: "Turkcell", indices: ["BIST 100"], symbol: "TCELL.IS" },
		{ name: "THYAO", indices: ["BIST 100"], symbol: "THYAO.IS" },
		{ name: "Tekfen Holding", indices: ["BIST 100"], symbol: "TKFEN.IS" },
		{ name: "Teknosa", indices: ["BIST 100"], symbol: "TKNSA.IS" },
		{ name: "Tofas", indices: ["BIST 100"], symbol: "TOASO.IS" },
		{ name: "TSKB", indices: ["BIST 100"], symbol: "TSKB.IS" },
		{ name: "Turk Telekom", indices: ["BIST 100"], symbol: "TTKOM.IS" },
		{ name: "Turk Traktor", indices: ["BIST 100"], symbol: "TTRAK.IS" },
		{ name: "Tukas Gida", indices: ["BIST 100"], symbol: "TUKAS.IS" },
		{ name: "Tupras Turkiye", indices: ["BIST 100"], symbol: "TUPRS.IS" },
		{ name: "Ulker Biskuvi", indices: ["BIST 100"], symbol: "ULKER.IS" },
		{ name: "Vakif Bankasi", indices: ["BIST 100"], symbol: "VAKBN.IS" },
		{ name: "Vestel Beyaz", indices: ["BIST 100"], symbol: "VESTL.IS" },
		{
			name: "AG Anadolu Group Holding",
			indices: ["BIST 100"],
			symbol: "AGHOL.IS",
		},
		{
			name: "AG Anadolu Group Holding",
			indices: ["BIST 100"],
			symbol: "AGHOL.IS",
		},
		{
			name: "Yapi ve Kredi Bankasi",
			indices: ["BIST 100"],
			symbol: "YKBNK.IS",
		},
		{ name: "Zorlu Enerji", indices: ["BIST 100"], symbol: "ZOREN.IS" },
		{ name: "Bera", indices: ["BIST 100"], symbol: "BERA.IS" },
		{ name: "Pegasus", indices: ["BIST 100"], symbol: "PGSUS.IS" },
		{ name: "Tumosan", indices: ["BIST 100"], symbol: "TMSN.IS" },
		// { name: "ODAS", indices: ["BIST 100"], symbol: "ODAS.IS" },
		{
			name: "Peker Gayrimenkul Yatirim Ortakligi",
			indices: ["BIST 100"],
			symbol: "PEKGY.IS",
		},
		{ name: "Sok Marketler", indices: ["BIST 100"], symbol: "SOKM.IS" },
		{
			name: "Kontrolmatik Teknoloji",
			indices: ["BIST 100"],
			symbol: "KONTR.IS",
		},
		{ name: "Qua Granite Hayal", indices: ["BIST 100"], symbol: "QUAGR.IS" },
		{ name: "Can2 Termik A.S.", indices: ["BIST 100"], symbol: "CANTE.IS" },
		{
			name: "Girisim Elektrik Taahhut Ticaret Sanayi",
			indices: ["BIST 100"],
			symbol: "GESAN.IS",
		},
		{
			name: "Yeo Teknoloji Enerji ve Endustri",
			indices: ["BIST 100"],
			symbol: "YEOTK.IS",
		},
		{
			name: "Europen Endustri Insaat Sanayi Ve Ticaret",
			indices: ["BIST 100"],
			symbol: "EUREN.IS",
		},

		{ symbol: "MIATK.IS", name: "Mia Teknoloji AS", indices: ["BIST 100"] },
		{
			symbol: "SMRTG.IS",
			name: "Smart Gunes Enerjisi Teknolojileri",
			indices: ["BIST 100"],
		},
		{
			name: "Yayla Agro Gida Sanayi ve Ticaret",
			indices: ["BIST 100"],
			symbol: "YAYLA.IS",
		},

		{
			symbol: "KCAER.IS",
			name: "Kocaer Celik Sanayi ve Ticaret AS",
			indices: ["BIST 100"],
		},
		{ symbol: "ALFAS.IS", name: "Alfa Solar Enerji AS", indices: ["BIST 100"] },
		{
			symbol: "SDTTR.IS",
			name: "Sdt Uzay ve Savunma Teknolojileri",
			indices: ["BIST 100"],
		},
		{ symbol: "ASTOR.IS", name: "Astor Enerji AS", indices: ["BIST 100"] },
		{
			symbol: "AKFYE.IS",
			name: "Akfen Yenilenebilir Enerji AS",
			indices: ["BIST 100"],
		},
		{
			symbol: "EUPWR.IS",
			name: "Europower Enerji ve Otomasyon",
			indices: ["BIST 100"],
		},
		{
			symbol: "CWENE.IS",
			name: "Cw Enerji Muhendislik Ticaret ve Sanayi A.S.",
			indices: ["BIST 100"],
		},
		{
			symbol: "KAYSE.IS",
			name: "Kayseri Seker Fabrikasi AS",
			indices: ["BIST 100"],
		},
		{
			symbol: "KTLEV.IS",
			name: "Katilimevim Tasarruf Finansman AS",
			indices: ["BIST 100"],
		},
		{
			symbol: "KLSER.IS",
			name: "Kaleseramik Canakkale Kalebodur",
			indices: ["BIST 100"],
		},
		{
			symbol: "IZENR.IS",
			name: "Izdemir Enerji Elektrik Uretim AS",
			indices: ["BIST 100"],
		},
		{
			symbol: "ENERY.IS",
			name: "Enerya Enerji AS",
			indices: ["BIST 100"],
		},
		{
			symbol: "REEDR.IS",
			name: "Reeder Teknoloji Sanayi Ve Ticaret",
			indices: ["BIST 100"],
		},
		{
			symbol: "TABGD.IS",
			name: "Tab Gida Sanayi ve Ticaret AS",
			indices: ["BIST 100"],
		},

		{
			name: "1000 Yatirimlar Holding AS",
			indices: ["BIST 100"],
			symbol: "BINHO.IS",
		},

		{
			symbol: "AGROT.IS",
			name: "Agrotech Yuksek Teknoloji",
			indices: ["BIST 100"],
		},
		{
			symbol: "LMKDC.IS",
			name: "Limak Dogu Anadolu Cimento Sanayi",
			indices: ["BIST 100"],
		},
		{
			symbol: "OBAMS.IS",
			name: "Oba Makarnacilik Sanayi ve Ticaret",
			indices: ["BIST 100"],
		},
	],
};
