export const marketData = {
	indices: [
		{ name: "DAX", yahoo: "^GDAXI" },
		{ name: "MDAX", yahoo: "^MDAXI" },
		{ name: "SDAX", yahoo: "^SDAXI" },
		{ name: "CDAX", yahoo: "^CDAXX" },
		{ name: "TECDAX", yahoo: "^TECDAX" },
		{ name: "FTSE 100", yahoo: "^FTSE" },
		{ name: "OMX Helsinki 25", yahoo: "^OMXH25" },
		{ name: "CAC 40", yahoo: "^FCHI" },
		{ name: "DOW JONES", yahoo: "^DJI" },
		{ name: "NASDAQ 100", yahoo: "^IXIC" },
		{ name: "NIKKEI 225", yahoo: "^N225" },
		{ name: "S&P 100", yahoo: "^OEX" },
		{ name: "S&P 500", yahoo: "^GSPC" },
		{ name: "S&P 600", yahoo: "^SP600" },
		{ name: "BEL 20", yahoo: "^BFX" },
		{ name: "IBEX 35", yahoo: "^IBEX" },
		{ name: "AEX", yahoo: "^AEX" },
		{ name: "ASX 200", yahoo: "^AXJO" },
		{ name: "OMX Stockholm 30", yahoo: "^OMX" },
		{ name: "Switzerland 20", yahoo: "^SSMI" },
		{ name: "CAC Mid 60", yahoo: "^CM100" },
		{ name: "EURO STOXX 50", yahoo: "^STOXX50E" },
		{ name: "MOEX", yahoo: "MOEX.ME" },
		{ name: "BIST 100", yahoo: "^XU100" },
	],
	companies: [
		{
			name: "adidas AG",
			symbol: "ADS",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50"],
			industries: [
				"Cyclical Consumer Products",
				"Footwear",
				"Textiles & Apparel",
				"Consumer Cyclicals",
				"Sportswear",
				"Toiletries",
			],
			symbols: [
				{ yahoo: "ADS.F", google: "FRA:ADS", currency: "EUR" },
				{ yahoo: "ADDDF", google: "OTCMKTS:ADDDF", currency: "USD" },
				{ yahoo: "ADDYY", google: "OTCMKTS:ADDYY", currency: "USD" },
				{ yahoo: "ADS1.F", google: "FRA:ADS1", currency: "EUR" },
			],
		},
		{
			name: "Allianz SE",
			symbol: "ALV",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "ALV.F", google: "FRA:ALV", currency: "EUR" },
				{ yahoo: "AZSEY", google: "OTCMKTS:AZSEY", currency: "USD" },
				{ yahoo: "ALIZY", google: "OTCMKTS:ALIZY", currency: "USD" },
				{ yahoo: "ALVE.F", google: "FRA:ALVE", currency: "EUR" },
				{ yahoo: "ALIZF", google: "OTCMKTS:ALIZF", currency: "USD" },
			],
		},
		{
			name: "BASF SE",
			symbol: "BAS",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50"],
			industries: ["Diversified Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "BAS.F", google: "FRA:BAS", currency: "EUR" },
				{ yahoo: "BFFAF", google: "OTCMKTS:BFFAF", currency: "USD" },
				{ yahoo: "BASFY", google: "OTCMKTS:BASFY", currency: "USD" },
				{ yahoo: "BASA.F", google: "FRA:BASA", currency: "EUR" },
			],
		},
		{
			name: "Bayer AG",
			symbol: "BAYN",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "BAYN.F", google: "FRA:BAYN", currency: "EUR" },
				{ yahoo: "BAYZF", google: "OTCMKTS:BAYZF", currency: "USD" },
				{ yahoo: "BAYRY", google: "OTCMKTS:BAYRY", currency: "USD" },
				{ yahoo: "BAYA.F", google: "FRA:BAYA", currency: "EUR" },
			],
		},
		{
			name: "Beiersdorf AG",
			symbol: "BEI",
			country: "Germany",
			indices: ["DAX"],
			industries: [
				"Consumer Non-Cyclicals",
				"Personal Products",
				"Personal & Household Products & Services",
			],
			symbols: [
				{ yahoo: "BEI.F", google: "FRA:BEI", currency: "EUR" },
				{ yahoo: "BDRFY", google: "OTCMKTS:BDRFY", currency: "USD" },
				{ yahoo: "BEIA.F", google: "FRA:BEIA", currency: "EUR" },
				{ yahoo: "BDRFF", google: "OTCMKTS:BDRFF", currency: "USD" },
				{ yahoo: "0DQ7.L", google: "LON:0DQ7", currency: "GBP" },
			],
		},
		{
			name: "Bayerische Motoren Werke AG",
			symbol: "BMW",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50"],
			industries: [
				"Automotive",
				"Auto & Truck Manufacturers",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "BMW.F", google: "FRA:BMW", currency: "EUR" },
				{ yahoo: "BMWYY", google: "OTCMKTS:BMWYY", currency: "USD" },
				{ yahoo: "BMWB.F", google: "FRA:BMWB", currency: "EUR" },
				{ yahoo: "BMW3.F", google: "FRA:BMW3", currency: "EUR" },
			],
		},
		{
			name: "Continental AG",
			symbol: "CON",
			country: "Germany",
			indices: ["DAX"],
			industries: [
				"Automotive",
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "CON.F", google: "FRA:CON", currency: "EUR" },
				{ yahoo: "CTTAF", google: "OTCMKTS:CTTAF", currency: "USD" },
				{ yahoo: "CTTAY", google: "OTCMKTS:CTTAY", currency: "USD" },
				{ yahoo: "CONA.F", google: "FRA:CONA", currency: "EUR" },
			],
		},
		{
			name: "Covestro AG",
			symbol: "1COV",
			country: "Germany",
			indices: ["DAX"],
			industries: ["Chemicals", "Commodity Chemicals", "Basic Materials"],
			symbols: [
				{ yahoo: "1COV.F", google: "FRA:1COV", currency: "EUR" },
				{ yahoo: "COVTY", google: "OTCMKTS:COVTY", currency: "USD" },
				{ yahoo: "1CO1.F", google: "FRA:1CO1", currency: "EUR" },
				{ yahoo: "CVVTF", google: "OTCMKTS:CVVTF", currency: "USD" },
			],
		},
		{
			name: "Deutsche Bank AG",
			symbol: "DBK",
			country: "Germany",
			indices: ["DAX"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "DBK.F", google: "FRA:DBK", currency: "EUR" },
				{ yahoo: "DB", google: "NYSE:DB", currency: "USD" },
			],
		},
		{
			name: "Deutsche Börse AG",
			symbol: "DB1",
			country: "Germany",
			indices: ["BEL 20", "DAX", "EURO STOXX 50"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Financial & Commodity Market Operators",
			],
			symbols: [
				{ yahoo: "DB1.F", google: "FRA:DB1", currency: "EUR" },
				{ yahoo: "DBOEY", google: "OTCMKTS:DBOEY", currency: "USD" },
			],
		},
		{
			name: "Deutsche Lufthansa AG",
			symbol: "LHA",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Passenger Transportation Services",
				"Industrials",
				"Airlines",
				"Transportation",
			],
			symbols: [
				{ yahoo: "LHA.F", google: "FRA:LHA", currency: "EUR" },
				{ yahoo: "DLAKY", google: "OTCMKTS:DLAKY", currency: "USD" },
				{ yahoo: "DLAKF", google: "OTCMKTS:DLAKF", currency: "USD" },
				{ yahoo: "0H4A.L", google: "LON:0H4A", currency: "GBP" },
				{ yahoo: "LHAB.F", google: "FRA:LHAB", currency: "EUR" },
			],
		},
		{
			name: "Deutsche Post AG",
			symbol: "DPW",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50"],
			industries: [
				"Industrials",
				"Air Freight & Courier Services",
				"Freight & Logistics Services",
				"Transportation",
			],
			symbols: [
				{ yahoo: "DPW.F", google: "FRA:DPW", currency: "EUR" },
				{ yahoo: "DPSGY", google: "OTCMKTS:DPSGY", currency: "USD" },
				{ yahoo: "DPWA.F", google: "FRA:DPWA", currency: "EUR" },
				{ yahoo: "DPWN.F", google: "FRA:DPWN", currency: "EUR" },
				{ yahoo: "DHL.F", google: "FRA:DHL", currency: "EUR" },
				{ yahoo: "DHLGY", google: "OTCMKTS:DHLGY", currency: "USD" },
			],
		},
		{
			name: "Deutsche Telekom AG",
			symbol: "DTE",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50", "TECDAX"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "DTE.F", google: "FRA:DTE", currency: "EUR" },
				{ yahoo: "DTEGF", google: "OTCMKTS:DTEGF", currency: "USD" },
				{ yahoo: "DTEGY", google: "OTCMKTS:DTEGY", currency: "USD" },
				{ yahoo: "DTEA.F", google: "FRA:DTEA", currency: "EUR" },
				{ yahoo: "0MPH.L", google: "LON:0MPH", currency: "GBP" },
			],
		},
		{
			name: "E.ON SE",
			symbol: "EOAN",
			country: "Germany",
			indices: ["DAX"],
			industries: ["Energy"],
			symbols: [
				{ yahoo: "EOAN.F", google: "FRA:EOAN", currency: "EUR" },
				{ yahoo: "ENAKF", google: "OTCMKTS:ENAKF", currency: "USD" },
				{ yahoo: "EONGY", google: "OTCMKTS:EONGY", currency: "USD" },
				{ yahoo: "EOAA.F", google: "FRA:EOAA", currency: "EUR" },
			],
		},
		{
			name: "Fresenius Medical Care AG & Co. KGaA St",
			symbol: "FME",
			country: "Germany",
			indices: ["DAX"],
			industries: [
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Facilities & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "FME.F", google: "FRA:FME", currency: "EUR" },
				{ yahoo: "FMS", google: "NYSE:FMS", currency: "USD" },
				{ yahoo: "FMCQF", google: "OTCMKTS:FMCQF", currency: "USD" },
				{ yahoo: "FMEA.F", google: "FRA:FMEA", currency: "EUR" },
			],
		},
		{
			name: "Fresenius SE & Co. KGaA",
			symbol: "FRE",
			country: "Germany",
			indices: ["DAX"],
			industries: [
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Facilities & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "FRE.F", google: "FRA:FRE", currency: "EUR" },
				{ yahoo: "FSNUY", google: "OTCMKTS:FSNUY", currency: "USD" },
				{ yahoo: "FSNUF", google: "OTCMKTS:FSNUF", currency: "USD" },
				{ yahoo: "FREA.F", google: "FRA:FREA", currency: "EUR" },
			],
		},
		{
			name: "HeidelbergCement AG",
			symbol: "HEI",
			country: "Germany",
			indices: ["DAX"],
			industries: [
				"Building materials",
				"Construction Materials",
				"Mineral Resources",
				"Basic Materials",
			],
			symbols: [
				{ yahoo: "HEI.F", google: "FRA:HEI", currency: "EUR" },
				{ yahoo: "HLBZF", google: "OTCMKTS:HLBZF", currency: "USD" },
				{ yahoo: "HDELY", google: "OTCMKTS:HDELY", currency: "USD" },
				{ yahoo: "HEIU.F", google: "FRA:HEIU", currency: "EUR" },
			],
		},
		{
			name: "Henkel AG & Co. KGaA Vz",
			symbol: "HEN3",
			country: "Germany",
			indices: ["DAX"],
			industries: [
				"FMCG",
				"Specialty Chemicals",
				"Basic Materials",
				"Chemicals",
			],
			symbols: [
				{ yahoo: "HEN3.F", google: "FRA:HEN3", currency: "EUR" },
				{ yahoo: "HENKY", google: "OTCMKTS:HENKY", currency: "USD" },
				{ yahoo: "HEN.F", google: "FRA:HEN", currency: "EUR" },
				{ yahoo: "HENE.F", google: "FRA:HENE", currency: "EUR" },
			],
		},
		{
			name: "Infineon Technologies AG",
			symbol: "IFX",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50", "TECDAX"],
			industries: [
				"Semiconductors",
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
			],
			symbols: [
				{ yahoo: "IFX.F", google: "FRA:IFX", currency: "EUR" },
				{ yahoo: "IFNNF", google: "OTCMKTS:IFNNF", currency: "USD" },
				{ yahoo: "IFNNY", google: "OTCMKTS:IFNNY", currency: "USD" },
				{ yahoo: "IFXA.F", google: "FRA:IFXA", currency: "EUR" },
				{ yahoo: "0KED.L", google: "LON:0KED", currency: "GBP" },
			],
		},
		{
			name: "Linde PLC",
			symbol: "LIN",
			country: "United Kingdom",
			indices: ["DAX", "EURO STOXX 50", "S&P 100", "S&P 500"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "LIN.F", google: "FRA:LIN", currency: "EUR" },
				{ yahoo: "LIN", google: "NYSE:LIN", currency: "USD" },
				{ yahoo: "0M2B.L", google: "LON:0M2B", currency: "GBP" },
			],
		},
		{
			name: "MERCK Kommanditgesellschaft auf Aktien",
			symbol: "MRK",
			country: "Germany",
			indices: ["DAX"],
			industries: [
				"Chemicals",
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "MRK.F", google: "FRA:MRK", currency: "EUR" },
				{ yahoo: "MKGAF", google: "OTCMKTS:MKGAF", currency: "USD" },
				{ yahoo: "MRKC.F", google: "FRA:MRKC", currency: "EUR" },
				{ yahoo: "MKKGY", google: "OTCMKTS:MKKGY", currency: "USD" },
				{ yahoo: "0O14.L", google: "LON:0O14", currency: "GBP" },
				{ yahoo: "6MK.F", google: "FRA:6MK", currency: "EUR" },
			],
		},
		{
			name: "Münchener Rückversicherungs-Gesellschaft AG",
			symbol: "MUV2",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50"],
			industries: ["Insurance", "Financials", "Reinsurance"],
			symbols: [
				{ yahoo: "MUV2.F", google: "FRA:MUV2", currency: "EUR" },
				{ yahoo: "MURGY", google: "OTCMKTS:MURGY", currency: "USD" },
			],
			isins: ["DE0008430026"],
			metadata: { founded: 1880, employees: 39662 },
			akas: [],
		},
		{
			name: "RWE AG",
			symbol: "RWE",
			country: "Germany",
			indices: ["DAX"],
			industries: ["Energy", "Multiline Utilities", "Utilities"],
			symbols: [
				{ yahoo: "RWE.F", google: "FRA:RWE", currency: "EUR" },
				{ yahoo: "RWEOY", google: "OTCMKTS:RWEOY", currency: "USD" },
				{ yahoo: "RWEA.F", google: "FRA:RWEA", currency: "EUR" },
			],
		},
		{
			name: "SAP SE",
			symbol: "SAP",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50", "TECDAX"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "SAP.F", google: "FRA:SAP", currency: "EUR" },
				{ yahoo: "SAP", google: "NYSE:SAP", currency: "USD" },
				{ yahoo: "SAPGF", google: "OTCMKTS:SAPGF", currency: "USD" },
				{ yahoo: "SAPA.F", google: "FRA:SAPA", currency: "EUR" },
			],
		},
		{
			name: "Siemens AG",
			symbol: "SIE",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50"],
			industries: ["Industrials", "Industrial Conglomerates"],
			symbols: [
				{ yahoo: "SIE.F", google: "FRA:SIE", currency: "EUR" },
				{ yahoo: "SMAWF", google: "OTCMKTS:SMAWF", currency: "USD" },
				{ yahoo: "SIEGY", google: "OTCMKTS:SIEGY", currency: "USD" },
				{ yahoo: "SI", google: "NYSE:SI", currency: "USD" },
				{ yahoo: "SIEB.F", google: "FRA:SIEB", currency: "EUR" },
			],
		},
		{
			name: "thyssenkrupp AG",
			symbol: "TKA",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Conglomerate",
				"Metals & Mining",
				"Mineral Resources",
				"Basic Materials",
				"Steel",
			],
			symbols: [
				{ yahoo: "TKA.F", google: "FRA:TKA", currency: "EUR" },
				{ yahoo: "TYEKF", google: "OTCMKTS:TYEKF", currency: "USD" },
				{ yahoo: "TKAMY", google: "OTCMKTS:TKAMY", currency: "USD" },
				{ yahoo: "TKA1.F", google: "FRA:TKA1", currency: "EUR" },
			],
		},
		{
			name: "Volkswagen AG",
			symbol: "VOW3",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50"],
			industries: [
				"Automotive",
				"Auto & Truck Manufacturers",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "VOW3.F", google: "FRA:VOW3", currency: "EUR" },
				{ yahoo: "VLKAF", google: "OTCMKTS:VLKAF", currency: "USD" },
				{ yahoo: "VWAGY", google: "OTCMKTS:VWAGY", currency: "USD" },
				{ yahoo: "VOW.F", google: "FRA:VOW", currency: "EUR" },
			],
		},
		{
			name: "Vonovia SE",
			symbol: "VNA",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50"],
			industries: [
				"Real Estate",
				"Financials",
				"Real Estate Development & Operations",
				"Real Estate Operations",
			],
			symbols: [
				{ yahoo: "VNA.F", google: "FRA:VNA", currency: "EUR" },
				{ yahoo: "VNNVF", google: "OTCMKTS:VNNVF", currency: "USD" },
				{ yahoo: "VONOY", google: "OTCMKTS:VONOY", currency: "USD" },
				{ yahoo: "VNAA.F", google: "FRA:VNAA", currency: "EUR" },
				{ yahoo: "0QFT.L", google: "LON:0QFT", currency: "GBP" },
			],
		},
		{
			name: "Airbus SE",
			symbol: "AIR",
			country: "Netherlands",
			indices: ["DAX"],
			industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
			symbols: [
				{ yahoo: "AIR.F", google: "FRA:AIR", currency: "EUR" },
				{ yahoo: "EADSF", google: "OTCMKTS:EADSF", currency: "USD" },
				{ yahoo: "EADSY", google: "OTCMKTS:EADSY", currency: "USD" },
				{ yahoo: "AIR.MC", google: "BME:AIR", currency: "EUR" },
				{ yahoo: "AIRA.F", google: "FRA:AIRA", currency: "EUR" },
			],
		},
		{
			name: "Aroundtown SA",
			symbol: "AT1",
			country: "Luxembourg",
			indices: ["MDAX"],
			industries: [
				"Real Estate",
				"Financials",
				"Real Estate Development & Operations",
				"Real Estate Operations",
			],
			symbols: [
				{ yahoo: "AT1.F", google: "FRA:AT1", currency: "EUR" },
				{ yahoo: "AANNF", google: "OTCMKTS:AANNF", currency: "USD" },
				{ yahoo: "0RUH.L", google: "LON:0RUH", currency: "GBP" },
			],
		},
		{
			name: "Aurubis AG",
			symbol: "NDA",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Specialty Mining & Metals",
				"Basic Materials",
			],
			symbols: [
				{ yahoo: "NDA.F", google: "FRA:NDA", currency: "EUR" },
				{ yahoo: "NDA1.F", google: "FRA:NDA1", currency: "EUR" },
				{ yahoo: "AIAGF", google: "OTCMKTS:AIAGF", currency: "USD" },
				{ yahoo: "AIAGY", google: "OTCMKTS:AIAGY", currency: "USD" },
				{ yahoo: "0K7F.L", google: "LON:0K7F", currency: "GBP" },
			],
		},
		{
			name: "Bechtle AG",
			symbol: "BC8",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "BC8.F", google: "FRA:BC8", currency: "EUR" },
				{ yahoo: "BC8A.F", google: "FRA:BC8A", currency: "EUR" },
				{ yahoo: "BECTY", google: "OTCMKTS:BECTY", currency: "USD" },
				{ yahoo: "BHTLF", google: "OTCMKTS:BHTLF", currency: "USD" },
				{ yahoo: "0DPM.L", google: "LON:0DPM", currency: "GBP" },
			],
		},
		{
			name: "Brenntag AG",
			symbol: "BNR",
			country: "Germany",
			indices: ["DAX"],
			industries: ["Chemicals"],
			symbols: [
				{ yahoo: "BNR.F", google: "FRA:BNR", currency: "EUR" },
				{ yahoo: "BNTGY", google: "OTCMKTS:BNTGY", currency: "USD" },
				{ yahoo: "BNRA.F", google: "FRA:BNRA", currency: "EUR" },
				{ yahoo: "BNTGF", google: "OTCMKTS:BNTGF", currency: "USD" },
				{ yahoo: "BNRN.F", google: "FRA:BNRN", currency: "EUR" },
			],
		},
		{
			name: "Commerzbank AG",
			symbol: "CBK",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "CBK.F", google: "FRA:CBK", currency: "EUR" },
				{ yahoo: "CRZBY", google: "OTCMKTS:CRZBY", currency: "USD" },
				{ yahoo: "CRZBF", google: "OTCMKTS:CRZBF", currency: "USD" },
				{ yahoo: "ZZMS.F", google: "FRA:ZZMS", currency: "EUR" },
			],
		},
		{
			name: "CTS Eventim AG & Co. KGaA",
			symbol: "EVD",
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Ticket distribution"],
			symbols: [
				{ yahoo: "EVD.F", google: "FRA:EVD", currency: "EUR" },
				{ yahoo: "CEVMY", google: "OTCMKTS:CEVMY", currency: "USD" },
				{ yahoo: "EVD0.F", google: "FRA:EVD0", currency: "EUR" },
				{ yahoo: "CEVMF", google: "OTCMKTS:CEVMF", currency: "USD" },
				{ yahoo: "0EEE.L", google: "LON:0EEE", currency: "GBP" },
			],
		},
		{
			name: "Delivery Hero SE",
			symbol: "DHER",
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "DHER.F", google: "FRA:DHER", currency: "EUR" },
				{ yahoo: "DLVHF", google: "OTCMKTS:DLVHF", currency: "USD" },
				{ yahoo: "DELHY", google: "OTCMKTS:DELHY", currency: "USD" },
				{ yahoo: "0RTC.L", google: "LON:0RTC", currency: "GBP" },
			],
		},
		{
			name: "Deutsche Pfandbriefbank AG",
			symbol: "PBB",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "PBB.F", google: "FRA:PBB", currency: "EUR" },
				{ yahoo: "PBBGF", google: "OTCMKTS:PBBGF", currency: "USD" },
				{ yahoo: "0R9K.L", google: "LON:0R9K", currency: "GBP" },
			],
		},
		{
			name: "Deutsche Wohnen SE",
			symbol: "DWNI",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Real Estate",
				"Financials",
				"Real Estate Development & Operations",
				"Real Estate Operations",
			],
			symbols: [
				{ yahoo: "DWNI.F", google: "FRA:DWNI", currency: "EUR" },
				{ yahoo: "DTCWY", google: "OTCMKTS:DTCWY", currency: "USD" },
				{ yahoo: "DWHHF", google: "OTCMKTS:DWHHF", currency: "USD" },
				{ yahoo: "DWNP.F", google: "FRA:DWNP", currency: "EUR" },
			],
		},
		{
			name: "Dürr AG",
			symbol: "DUE",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "DUE.F", google: "FRA:DUE", currency: "EUR" },
				{ yahoo: "DUERF", google: "-", currency: "USD" },
			],
		},
		{
			name: "Evonik Industries AG",
			symbol: "EVK",
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Diversified Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "EVK.F", google: "FRA:EVK", currency: "EUR" },
				{ yahoo: "EVKIF", google: "OTCMKTS:EVKIF", currency: "USD" },
				{ yahoo: "EVKA.F", google: "FRA:EVKA", currency: "EUR" },
				{ yahoo: "EVKIY", google: "OTCMKTS:EVKIY", currency: "USD" },
			],
		},
		{
			name: "Evotec SE",
			symbol: "EVT",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: [
				"Biotechnology & Medical Research",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "EVT.F", google: "FRA:EVT", currency: "EUR" },
				{ yahoo: "EVTCY", google: "OTCMKTS:EVTCY", currency: "USD" },
				{ yahoo: "EVTA.F", google: "FRA:EVTA", currency: "EUR" },
				{ yahoo: "EVOTF", google: "OTCMKTS:EVOTF", currency: "USD" },
				{ yahoo: "EVO", google: "NASDAQ:EVO", currency: "USD" },
			],
		},
		{
			name: "Fielmann AG",
			symbol: "FIE",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Optician",
				"Other Specialty Retailers",
				"Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "FIE.F", google: "FRA:FIE", currency: "EUR" },
				{ yahoo: "FLMNF", google: "-", currency: "USD" },
				{ yahoo: "FIE1.F", google: "FRA:FIE1", currency: "EUR" },
				{ yahoo: "FLMNY", google: "OTCMKTS:FLMNY", currency: "USD" },
			],
		},
		{
			name: "Fraport AG",
			symbol: "FRA",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Industrials",
				"Transport Infrastructure",
				"Airport Services",
				"Transportation",
			],
			symbols: [
				{ yahoo: "FRA.F", google: "FRA:FRA", currency: "EUR" },
				{ yahoo: "FPRUF", google: "OTCMKTS:FPRUF", currency: "USD" },
				{ yahoo: "FPRUY", google: "OTCMKTS:FPRUY", currency: "USD" },
				{ yahoo: "FRAS.F", google: "FRA:FRAS", currency: "EUR" },
			],
		},
		{
			name: "freenet AG",
			symbol: "FNTN",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "FNTN.F", google: "FRA:FNTN", currency: "EUR" },
				{ yahoo: "FRTAF", google: "OTCMKTS:FRTAF", currency: "USD" },
				{ yahoo: "FRTAY", google: "OTCMKTS:FRTAY", currency: "USD" },
				{ yahoo: "FNA.F", google: "FRA:FNA", currency: "EUR" },
				{ yahoo: "0MV2.L", google: "LON:0MV2", currency: "GBP" },
			],
		},
		{
			name: "Fuchs Petrolub SE",
			symbol: "FPE3",
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "FPE.F", google: "FRA:FPE", currency: "EUR" },
				{ yahoo: "FUPEF", google: "OTCMKTS:FUPEF", currency: "USD" },
				{ yahoo: "FPE3.F", google: "FRA:FPE3", currency: "EUR" },
				{ yahoo: "FUPPF", google: "OTCMKTS:FUPPF", currency: "USD" },
				{ yahoo: "FUPBY", google: "OTCMKTS:FUPBY", currency: "USD" },
				{ yahoo: "FPE4.F", google: "FRA:FPE4", currency: "EUR" },
			],
		},
		{
			name: "GEA Group AG",
			symbol: "G1A",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "G1A.F", google: "FRA:G1A", currency: "EUR" },
				{ yahoo: "GEAGY", google: "OTCMKTS:GEAGY", currency: "USD" },
				{ yahoo: "GEAGF", google: "OTCMKTS:GEAGF", currency: "USD" },
			],
		},
		{
			name: "Gerresheimer AG",
			symbol: "GXI",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "GXI.F", google: "FRA:GXI", currency: "EUR" },
				{ yahoo: "GRRMF", google: "OTCMKTS:GRRMF", currency: "USD" },
				{ yahoo: "GRRMY", google: "OTCMKTS:GRRMY", currency: "USD" },
			],
		},
		{
			name: "Grand City Properties SA",
			symbol: "GYC",
			country: "Luxembourg",
			indices: ["SDAX"],
			industries: [
				"Real Estate",
				"Financials",
				"Real Estate Development & Operations",
				"Real Estate Operations",
			],
			symbols: [
				{ yahoo: "GYC.F", google: "FRA:GYC", currency: "EUR" },
				{ yahoo: "GYC1.F", google: "FRA:GYC1", currency: "EUR" },
				{ yahoo: "GRNNF", google: "OTCMKTS:GRNNF", currency: "USD" },
				{ yahoo: "GRDDY", google: "OTCMKTS:GRDDY", currency: "USD" },
				{ yahoo: "0RPK.L", google: "LON:0RPK", currency: "GBP" },
			],
		},
		{
			name: "Hannover Rück SE",
			symbol: "HNR1",
			country: "Germany",
			indices: ["DAX"],
			industries: ["Insurance", "Financials", "Reinsurance"],
			symbols: [
				{ yahoo: "HNR1.F", google: "FRA:HNR1", currency: "EUR" },
				{ yahoo: "HVRRY", google: "OTCMKTS:HVRRY", currency: "USD" },
			],
		},
		{
			name: "HELLA GmbH & Co. KGaA",
			symbol: "HLE",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "HLE.F", google: "FRA:HLE", currency: "EUR" },
				{ yahoo: "HLKHF", google: "OTCMKTS:HLKHF", currency: "USD" },
				{ yahoo: "HLLGY", google: "OTCMKTS:HLLGY", currency: "USD" },
				{ yahoo: "HLEA.F", google: "FRA:HLEA", currency: "EUR" },
				{ yahoo: "HLE0.F", google: "FRA:HLE0", currency: "EUR" },
				{ yahoo: "0R3U.L", google: "LON:0R3U", currency: "GBP" },
			],
		},
		{
			name: "HOCHTIEF AG",
			symbol: "HOT",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "HOT.F", google: "FRA:HOT", currency: "EUR" },
				{ yahoo: "HOCFY", google: "OTCMKTS:HOCFY", currency: "USD" },
				{ yahoo: "HOCFF", google: "OTCMKTS:HOCFF", currency: "USD" },
			],
		},
		{
			name: "Hugo Boss AG",
			symbol: "BOSS",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Cyclical Consumer Products",
				"Textiles & Apparel",
				"Apparel & Accessories",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "BOSS.F", google: "FRA:BOSS", currency: "EUR" },
				{ yahoo: "BOSSY", google: "OTCMKTS:BOSSY", currency: "USD" },
				{ yahoo: "BOSA.F", google: "FRA:BOSA", currency: "EUR" },
				{ yahoo: "HUGPF", google: "OTCMKTS:HUGPF", currency: "USD" },
			],
		},
		{
			name: "K+S AG",
			symbol: "SDF",
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Agricultural Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "SDF.F", google: "FRA:SDF", currency: "EUR" },
				{ yahoo: "KPLUY", google: "OTCMKTS:KPLUY", currency: "USD" },
				{ yahoo: "KPLUF", google: "OTCMKTS:KPLUF", currency: "USD" },
				{ yahoo: "SDF1.F", google: "FRA:SDF1", currency: "EUR" },
			],
		},
		{
			name: "KION GROUP AG",
			symbol: "KGX",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Heavy Machinery & Vehicles",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "KGX.F", google: "FRA:KGX", currency: "EUR" },
				{ yahoo: "KNNGF", google: "OTCMKTS:KNNGF", currency: "USD" },
				{ yahoo: "KIGRY", google: "OTCMKTS:KIGRY", currency: "USD" },
				{ yahoo: "KGX1.F", google: "FRA:KGX1", currency: "EUR" },
			],
		},
		{
			name: "LANXESS AG",
			symbol: "LXS",
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Diversified Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "LXS.F", google: "FRA:LXS", currency: "EUR" },
				{ yahoo: "LNXSF", google: "OTCMKTS:LNXSF", currency: "USD" },
				{ yahoo: "LX9B.F", google: "FRA:LX9B", currency: "EUR" },
				{ yahoo: "LNXSY", google: "OTCMKTS:LNXSY", currency: "USD" },
			],
		},
		{
			name: "LEG Immobilien AG",
			symbol: "LEG",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Real Estate",
				"Financials",
				"Real Estate Development & Operations",
				"Real Estate Operations",
			],
			symbols: [
				{ yahoo: "LEG.F", google: "FRA:LEG", currency: "EUR" },
				{ yahoo: "LEGIF", google: "OTCMKTS:LEGIF", currency: "USD" },
				{ yahoo: "0QC9.L", google: "LON:0QC9", currency: "GBP" },
			],
		},
		{
			name: "Metro AG",
			symbol: "B4B",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Food Retail & Distribution",
			],
			symbols: [
				{ yahoo: "B4B.F", google: "FRA:B4B", currency: "EUR" },
				{ yahoo: "MTTWF", google: "OTCMKTS:MTTWF", currency: "USD" },
				{ yahoo: "MEO.F", google: "FRA:MEO", currency: "EUR" },
				{ yahoo: "MTGGY", google: "OTCMKTS:MTGGY", currency: "USD" },
				{ yahoo: "B4B3.F", google: "FRA:B4B3", currency: "EUR" },
				{ yahoo: "0RTE.L", google: "LON:0RTE", currency: "GBP" },
			],
		},
		{
			name: "MorphoSys AG",
			symbol: "MOR",
			country: "Germany",
			indices: ["SDAX", "TECDAX"],
			industries: [
				"Biotechnology & Medical Research",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "MOR.F", google: "FRA:MOR", currency: "EUR" },
				{ yahoo: "MOR", google: "NASDAQ:MOR", currency: "USD" },
				{ yahoo: "MPSYF", google: "OTCMKTS:MPSYF", currency: "USD" },
				{ yahoo: "MOR2.F", google: "FRA:MOR2", currency: "EUR" },
			],
		},
		{
			name: "MTU Aero Engines AG",
			symbol: "MTX",
			country: "Germany",
			indices: ["DAX"],
			industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
			symbols: [
				{ yahoo: "MTX.F", google: "FRA:MTX", currency: "EUR" },
				{ yahoo: "MTUAF", google: "OTCMKTS:MTUAF", currency: "USD" },
				{ yahoo: "MTUAY", google: "OTCMKTS:MTUAY", currency: "USD" },
				{ yahoo: "MTX1.F", google: "FRA:MTX1", currency: "EUR" },
			],
		},
		{
			name: "Nemetschek SE",
			symbol: "NEM",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "NEM.F", google: "FRA:NEM", currency: "EUR" },
				{ yahoo: "NEMTF", google: "OTCMKTS:NEMTF", currency: "USD" },
				{ yahoo: "NEMKY", google: "OTCMKTS:NEMKY", currency: "USD" },
				{ yahoo: "NEM0.F", google: "FRA:NEM0", currency: "EUR" },
				{ yahoo: "0FDT.L", google: "LON:0FDT", currency: "GBP" },
			],
		},
		{
			name: "NORMA Group SE",
			symbol: "NOEJ",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "NOEJ.F", google: "FRA:NOEJ", currency: "EUR" },
				{ yahoo: "NOEJF", google: "OTCMKTS:NOEJF", currency: "USD" },
			],
		},
		{
			name: "ProSiebenSat.1 Media SE",
			symbol: "PSM",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Media & Publishing",
				"Cyclical Consumer Services",
				"Broadcasting",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "PSM.F", google: "FRA:PSM", currency: "EUR" },
				{ yahoo: "PBSFY", google: "OTCMKTS:PBSFY", currency: "USD" },
				{ yahoo: "PSMA.F", google: "FRA:PSMA", currency: "EUR" },
				{ yahoo: "PBSFF", google: "OTCMKTS:PBSFF", currency: "USD" },
			],
		},
		{
			name: "PUMA SE",
			symbol: "PUM",
			country: "Germany",
			indices: ["DAX"],
			industries: [
				"Footwear",
				"Textiles & Apparel",
				"Consumer Cyclicals",
				"Sportswear",
			],
			symbols: [
				{ yahoo: "PUM.F", google: "FRA:PUM", currency: "EUR" },
				{ yahoo: "PMMAF", google: "OTCMKTS:PMMAF", currency: "USD" },
				{ yahoo: "PUMSY", google: "OTCMKTS:PUMSY", currency: "USD" },
				{ yahoo: "PUMA.F", google: "FRA:PUMA", currency: "EUR" },
			],
		},
		{
			name: "QIAGEN N.V.",
			symbol: "QIA",
			country: "Netherlands",
			indices: ["DAX", "TECDAX"],
			industries: [
				"Biotechnology & Medical Research",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "QIA.F", google: "FRA:QIA", currency: "EUR" },
				{ yahoo: "QGEN", google: "NYSE:QGEN", currency: "USD" },
				{ yahoo: "0RLT.L", google: "LON:0RLT", currency: "GBP" },
			],
		},
		{
			name: "Rheinmetall AG",
			symbol: "RHM",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "RHM.F", google: "FRA:RHM", currency: "EUR" },
				{ yahoo: "RNMBF", google: "OTCMKTS:RNMBF", currency: "USD" },
				{ yahoo: "RNMBY", google: "OTCMKTS:RNMBY", currency: "USD" },
				{ yahoo: "RHMB.F", google: "FRA:RHMB", currency: "EUR" },
			],
		},
		{
			name: "RTL Group SA",
			symbol: "RRTL",
			country: "Luxembourg",
			indices: ["MDAX"],
			industries: [
				"Media & Publishing",
				"Cyclical Consumer Services",
				"Broadcasting",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "RRTL.F", google: "FRA:RRTL", currency: "EUR" },
				{ yahoo: "RGLXY", google: "OTCMKTS:RGLXY", currency: "USD" },
				{ yahoo: "RRTU.F", google: "FRA:RRTU", currency: "EUR" },
			],
		},
		{
			name: "Salzgitter AG",
			symbol: "SZG",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Basic Materials",
				"Steel",
			],
			symbols: [
				{ yahoo: "SZG.F", google: "FRA:SZG", currency: "EUR" },
				{ yahoo: "SZGPY", google: "OTCMKTS:SZGPY", currency: "USD" },
				{ yahoo: "SZGA.F", google: "FRA:SZGA", currency: "EUR" },
			],
		},
		{
			name: "Sartorius AG",
			symbol: "SRT3",
			country: "Germany",
			indices: ["DAX", "TECDAX"],
			industries: [
				"Medication",
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "SRT3.F", google: "FRA:SRT3", currency: "EUR" },
				{ yahoo: "SUVPF", google: "OTCMKTS:SUVPF", currency: "USD" },
				{ yahoo: "SARTF", google: "OTCMKTS:SARTF", currency: "USD" },
				{ yahoo: "SRT.F", google: "FRA:SRT", currency: "EUR" },
				{ yahoo: "0NIQ.L", google: "LON:0NIQ", currency: "GBP" },
				{ yahoo: "SOAGY", google: "OTCMKTS:SOAGY", currency: "USD" },
				{ yahoo: "SSSGY", google: "OTCMKTS:SSSGY", currency: "USD" },
				{ yahoo: "SRT4.F", google: "FRA:SRT4", currency: "EUR" },
			],
		},
		{
			name: "Schaeffler AG",
			symbol: "SHA",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "SHA.F", google: "FRA:SHA", currency: "EUR" },
				{ yahoo: "SFFLY", google: "OTCMKTS:SFFLY", currency: "USD" },
				{ yahoo: "SCFLF", google: "OTCMKTS:SCFLF", currency: "USD" },
				{ yahoo: "SHAU.F", google: "FRA:SHAU", currency: "EUR" },
			],
		},
		{
			name: "Scout24 AG",
			symbol: "G24",
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "G24.F", google: "FRA:G24", currency: "EUR" },
				{ yahoo: "SCOTF", google: "OTCMKTS:SCOTF", currency: "USD" },
				{ yahoo: "SCCTY", google: "OTCMKTS:SCCTY", currency: "USD" },
				{ yahoo: "G24A.F", google: "FRA:G24A", currency: "EUR" },
			],
		},
		{
			name: "Siemens Healthineers AG",
			symbol: "SHL",
			country: "Germany",
			indices: ["DAX", "TECDAX"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "SHL.F", google: "FRA:SHL", currency: "EUR" },
				{ yahoo: "SMMNY", google: "OTCMKTS:SMMNY", currency: "USD" },
				{ yahoo: "0PMJ.L", google: "LON:0PMJ", currency: "GBP" },
				{ yahoo: "SHL1.F", google: "FRA:SHL1", currency: "EUR" },
			],
		},
		{
			name: "Siltronic AG",
			symbol: "WAF",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "WAF.F", google: "FRA:WAF", currency: "EUR" },
				{ yahoo: "SSLLF", google: "OTCMKTS:SSLLF", currency: "USD" },
				{ yahoo: "WAF1.F", google: "FRA:WAF1", currency: "EUR" },
			],
		},
		{
			name: "Software AG",
			symbol: "SOW",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "SOW.F", google: "FRA:SOW", currency: "EUR" },
				{ yahoo: "STWRY", google: "OTCMKTS:STWRY", currency: "USD" },
				{ yahoo: "SWDAF", google: "OTCMKTS:SWDAF", currency: "USD" },
				{ yahoo: "0NAU.L", google: "LON:0NAU", currency: "GBP" },
				{ yahoo: "SOWA.F", google: "FRA:SOWA", currency: "EUR" },
			],
		},
		{
			name: "Symrise AG",
			symbol: "SY1",
			country: "Germany",
			indices: ["DAX"],
			industries: ["Specialty Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "SY1.F", google: "FRA:SY1", currency: "EUR" },
				{ yahoo: "SYIEY", google: "OTCMKTS:SYIEY", currency: "USD" },
				{ yahoo: "SYIEF", google: "OTCMKTS:SYIEF", currency: "USD" },
				{ yahoo: "SY1U.F", google: "FRA:SY1U", currency: "EUR" },
			],
		},
		{
			name: "TAG Immobilien AG",
			symbol: "TEG",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Real Estate",
				"Financials",
				"Real Estate Development & Operations",
				"Real Estate Operations",
			],
			symbols: [
				{ yahoo: "TEG.F", google: "FRA:TEG", currency: "EUR" },
				{ yahoo: "TAGOF", google: "OTCMKTS:TAGOF", currency: "USD" },
				{ yahoo: "TAGYY", google: "OTCMKTS:TAGYY", currency: "USD" },
			],
		},
		{
			name: "Telefónica Deutschland Holding AG",
			symbol: "O2D",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "O2D.F", google: "FRA:O2D", currency: "EUR" },
				{ yahoo: "TELDF", google: "OTCMKTS:TELDF", currency: "USD" },
			],
		},
		{
			name: "Uniper SE",
			symbol: "UN01",
			country: "Germany",
			indices: ["SDAX"],
			industries: ["Multiline Utilities", "Utilities"],
			symbols: [
				{ yahoo: "UN01.F", google: "FRA:UN01", currency: "EUR" },
				{ yahoo: "UNPRF", google: "OTCMKTS:UNPRF", currency: "USD" },
			],
		},
		{
			name: "United Internet AG",
			symbol: "UTDI",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "UTDI.F", google: "FRA:UTDI", currency: "EUR" },
				{ yahoo: "UDIRF", google: "OTCMKTS:UDIRF", currency: "USD" },
			],
		},
		{
			name: "Wacker Chemie AG",
			symbol: "WCH",
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Specialty Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "WCH.F", google: "FRA:WCH", currency: "EUR" },
				{ yahoo: "WKCMF", google: "OTCMKTS:WKCMF", currency: "USD" },
			],
		},
		{
			name: "Zalando SE",
			symbol: "ZAL",
			country: "Germany",
			indices: ["DAX"],
			industries: [
				"Retailers",
				"Apparel & Accessories Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "ZAL.F", google: "FRA:ZAL", currency: "EUR" },
				{ yahoo: "ZLDSF", google: "OTCMKTS:ZLDSF", currency: "USD" },
				{ yahoo: "ZLNDY", google: "OTCMKTS:ZLNDY", currency: "USD" },
				{ yahoo: "ZAL1.F", google: "FRA:ZAL1", currency: "EUR" },
			],
		},
		{
			name: "AIXTRON SE",
			symbol: "AIXA",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: [
				"Semiconductor Equipment & Testing",
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
			],
			symbols: [
				{ yahoo: "AIXA.F", google: "FRA:AIXA", currency: "EUR" },
				{ yahoo: "AIXXF", google: "OTCMKTS:AIXXF", currency: "USD" },
				{ yahoo: "AIX2.F", google: "FRA:AIX2", currency: "EUR" },
				{ yahoo: "AIIXY", google: "OTCMKTS:AIIXY", currency: "USD" },
				{ yahoo: "AIX.F", google: "FRA:AIX", currency: "EUR" },
				{ yahoo: "0NP9.L", google: "LON:0NP9", currency: "GBP" },
			],
		},
		{
			name: "BayWa AG vNa",
			symbol: "BYW6",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Fishing & Farming",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "BYW6.F", google: "FRA:BYW6", currency: "EUR" },
				{ yahoo: "BYW6", google: "OTCMKTS:BYW6", currency: "USD" },
				{ yahoo: "BYW.F", google: "FRA:BYW", currency: "EUR" },
				{ yahoo: "0AH7.L", google: "LON:0AH7", currency: "GBP" },
			],
		},
		{
			name: "Bilfinger SE",
			symbol: "GBF",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "GBF.F", google: "FRA:GBF", currency: "EUR" },
				{ yahoo: "BFLBY", google: "OTCMKTS:BFLBY", currency: "USD" },
				{ yahoo: "GBFU.F", google: "FRA:GBFU", currency: "EUR" },
				{ yahoo: "BFLBF", google: "OTCMKTS:BFLBF", currency: "USD" },
			],
		},
		{
			name: "Cancom SE",
			symbol: "COK",
			country: "Germany",
			indices: ["SDAX", "TECDAX"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "COK.F", google: "FRA:COK", currency: "EUR" },
				{ yahoo: "CCCMF", google: "OTCMKTS:CCCMF", currency: "USD" },
				{ yahoo: "0O0F.L", google: "LON:0O0F", currency: "GBP" },
			],
		},
		{
			name: "Carl Zeiss Meditec AG",
			symbol: "AFX",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "AFX.F", google: "FRA:AFX", currency: "EUR" },
				{ yahoo: "CZMWF", google: "OTCMKTS:CZMWF", currency: "USD" },
				{ yahoo: "CZMWY", google: "OTCMKTS:CZMWY", currency: "USD" },
				{ yahoo: "AFXA.F", google: "FRA:AFXA", currency: "EUR" },
			],
		},
		{
			name: "CEWE Stiftung & Co. KGaA",
			symbol: "CWC",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Industrials",
				"Professional & Commercial Services",
				"Commercial Printing Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "CWC.F", google: "FRA:CWC", currency: "EUR" },
				{ yahoo: "0N8F.L", google: "LON:0N8F", currency: "GBP" },
			],
		},
		{
			name: "CompuGroup Medical SE",
			symbol: "COP",
			country: "Germany",
			indices: ["SDAX", "TECDAX"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "COP.F", google: "FRA:COP", currency: "EUR" },
				{ yahoo: "CMPVF", google: "OTCMKTS:CMPVF", currency: "USD" },
				{ yahoo: "COPA.F", google: "FRA:COPA", currency: "EUR" },
				{ yahoo: "CMPUY", google: "OTCMKTS:CMPUY", currency: "USD" },
				{ yahoo: "0MSD.L", google: "LON:0MSD", currency: "GBP" },
			],
		},
		{
			name: "DEUTZ AG",
			symbol: "DEZ",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "DEZ.F", google: "FRA:DEZ", currency: "EUR" },
				{ yahoo: "DEUZF", google: "OTCMKTS:DEUZF", currency: "USD" },
			],
		},
		{
			name: "DIC ASSET AG",
			symbol: "DIC",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Real Estate",
				"Financials",
				"Real Estate Development & Operations",
				"Real Estate Operations",
			],
			symbols: [
				{ yahoo: "DIC.F", google: "FRA:DIC", currency: "EUR" },
				{ yahoo: "DDCCF", google: "OTCMKTS:DDCCF", currency: "USD" },
			],
		},
		{
			name: "Drägerwerk AG & Co. KGaA Vz",
			symbol: "DRW3",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "DRW3.F", google: "FRA:DRW3", currency: "EUR" },
				{ yahoo: "DGWPF", google: "OTCMKTS:DGWPF", currency: "USD" },
			],
		},
		{
			name: "DWS Group GmbH & Co. KGaA",
			symbol: "DWS",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "DWS.F", google: "FRA:DWS", currency: "EUR" },
				{ yahoo: "DWS", google: "OTCMKTS:DWS", currency: "USD" },
				{ yahoo: "0SAY.L", google: "LON:0SAY", currency: "GBP" },
			],
		},
		{
			name: "ENCAVIS AG ",
			symbol: "CAP",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Electric Utilities & IPPs",
				"Independent Power Producers",
				"Utilities",
			],
			symbols: [
				{ yahoo: "CAP.F", google: "FRA:CAP", currency: "EUR" },
				{ yahoo: "ECV.F", google: "FRA:ECV", currency: "EUR" },
				{
					yahoo: "DE0006095003.F",
					google: "FRA:DE0006095003",
					currency: "EUR",
				},
			],
		},
		{
			name: "Grenke AG",
			symbol: "GLJ",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Corporate Financials",
			],
			symbols: [
				{ yahoo: "GLJ.F", google: "FRA:GLJ", currency: "EUR" },
				{ yahoo: "GKSGF", google: "OTCMKTS:GKSGF", currency: "USD" },
			],
		},
		{
			name: "Hamborner REIT AG",
			symbol: "HAB",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "HAB.F", google: "FRA:HAB", currency: "EUR" },
				{ yahoo: "HABA.F", google: "FRA:HABA", currency: "EUR" },
				{ yahoo: "0MCG.L", google: "LON:0MCG", currency: "GBP" },
			],
		},
		{
			name: "Heidelberger Druckmaschinen AG",
			symbol: "HDD",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "HDD.F", google: "FRA:HDD", currency: "EUR" },
				{ yahoo: "HDD", google: "OTCMKTS:HDD", currency: "USD" },
				{ yahoo: "HDDF.F", google: "FRA:HDDF", currency: "EUR" },
				{ yahoo: "HBGRY", google: "OTCMKTS:HBGRY", currency: "USD" },
			],
		},
		{
			name: "HelloFresh SE",
			symbol: "HFG",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Food Retail & Distribution",
			],
			symbols: [
				{ yahoo: "HFG.F", google: "FRA:HFG", currency: "EUR" },
				{ yahoo: "HLFFF", google: "OTCMKTS:HLFFF", currency: "USD" },
				{ yahoo: "HELFY", google: "OTCMKTS:HELFY", currency: "USD" },
				{ yahoo: "HFG0.F", google: "FRA:HFG0", currency: "EUR" },
			],
		},
		{
			name: "HORNBACH HOLDING AG&CO.KGAA",
			symbol: "HBH",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Retailers",
				"Specialty Retailers",
				"Home Improvement Products & Services Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "HBH.F", google: "FRA:HBH", currency: "EUR" },
				{ yahoo: "HBH", google: "OTCMKTS:HBH", currency: "USD" },
				{ yahoo: "HBBHF", google: "OTCMKTS:HBBHF", currency: "USD" },
			],
		},
		{
			name: "INDUS HOLDING AG",
			symbol: "INH",
			country: "Germany",
			indices: ["SDAX"],
			industries: ["Industrials", "Industrial Conglomerates"],
			symbols: [
				{ yahoo: "INH.F", google: "FRA:INH", currency: "EUR" },
				{ yahoo: "INDXF", google: "OTCMKTS:INDXF", currency: "USD" },
				{ yahoo: "INDHF", google: "OTCMKTS:INDHF", currency: "USD" },
			],
		},
		{
			name: "JENOPTIK AG",
			symbol: "JEN",
			country: "Germany",
			indices: ["SDAX", "TECDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "JEN.F", google: "FRA:JEN", currency: "EUR" },
				{ yahoo: "JNPKF", google: "OTCMKTS:JNPKF", currency: "USD" },
			],
		},
		{
			name: "JOST Werke AG",
			symbol: "JST",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [{ yahoo: "JST.F", google: "FRA:JST", currency: "EUR" }],
		},
		{
			name: "JUNGHEINRICH AG",
			symbol: "JUN3",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Heavy Machinery & Vehicles",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "JUN3.F", google: "FRA:JUN3", currency: "EUR" },
				{ yahoo: "JGHAF", google: "OTCMKTS:JGHAF", currency: "USD" },
				{ yahoo: "JGHHY", google: "OTCMKTS:JGHHY", currency: "USD" },
				{ yahoo: "JUNU.F", google: "FRA:JUNU", currency: "EUR" },
				{ yahoo: "0EXP.L", google: "LON:0EXP", currency: "GBP" },
			],
		},
		{
			name: "KLOECKNER & CO SE",
			symbol: "KCO",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Basic Materials",
				"Steel",
			],
			symbols: [
				{ yahoo: "KCO.F", google: "FRA:KCO", currency: "EUR" },
				{ yahoo: "KCO", google: "OTCMKTS:KCO", currency: "USD" },
				{ yahoo: "KLKNF", google: "OTCMKTS:KLKNF", currency: "USD" },
				{ yahoo: "KCOV.F", google: "FRA:KCOV", currency: "EUR" },
			],
		},
		{
			name: "Krones AG",
			symbol: "KRN",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "KRN.F", google: "FRA:KRN", currency: "EUR" },
				{ yahoo: "KRNNF", google: "OTCMKTS:KRNNF", currency: "USD" },
				{ yahoo: "KRNU.F", google: "FRA:KRNU", currency: "EUR" },
				{ yahoo: "KRNTY", google: "OTCMKTS:KRNTY", currency: "USD" },
				{ yahoo: "0LQ4.L", google: "LON:0LQ4", currency: "GBP" },
			],
		},
		{
			name: "KWS SAAT SE",
			symbol: "KWS",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Fishing & Farming",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "KWS.F", google: "FRA:KWS", currency: "EUR" },
				{ yahoo: "KNKZF", google: "OTCMKTS:KNKZF", currency: "USD" },
			],
		},
		{
			name: "Nordex SE",
			symbol: "NDX1",
			country: "Germany",
			indices: ["SDAX", "TECDAX"],
			industries: [
				"Heavy Electrical Equipment",
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "NDX1.F", google: "FRA:NDX1", currency: "EUR" },
				{ yahoo: "NRDXF", google: "OTCMKTS:NRDXF", currency: "USD" },
				{ yahoo: "NDX.F", google: "FRA:NDX", currency: "EUR" },
				{ yahoo: "NRXXY", google: "OTCMKTS:NRXXY", currency: "USD" },
			],
		},
		{
			name: "Patrizia AG",
			symbol: "PAT",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Real Estate",
				"Financials",
				"Real Estate Development & Operations",
				"Real Estate Operations",
			],
			symbols: [
				{ yahoo: "PAT.F", google: "FRA:PAT", currency: "EUR" },
				{ yahoo: "PAT", google: "OTCMKTS:PAT", currency: "USD" },
				{ yahoo: "PTZIF", google: "OTCMKTS:PTZIF", currency: "USD" },
				{ yahoo: "0FJC.L", google: "LON:0FJC", currency: "GBP" },
			],
		},
		{
			name: "PFEIFFER VACUUM TECHNOLOGY AG",
			symbol: "PFV",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "PFV.F", google: "FRA:PFV", currency: "EUR" },
				{ yahoo: "PFV", google: "OTCMKTS:PFV", currency: "USD" },
				{ yahoo: "PFFVF", google: "OTCMKTS:PFFVF", currency: "USD" },
				{ yahoo: "0FQR.L", google: "LON:0FQR", currency: "GBP" },
			],
		},
		{
			name: "RATIONAL AG",
			symbol: "RAA",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Cyclical Consumer Products",
				"Appliances, Tools & Housewares",
				"Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "RAA.F", google: "FRA:RAA", currency: "EUR" },
				{ yahoo: "RTLLF", google: "OTCMKTS:RTLLF", currency: "USD" },
				{ yahoo: "RATIY", google: "OTCMKTS:RATIY", currency: "USD" },
				{ yahoo: "RAA1.F", google: "FRA:RAA1", currency: "EUR" },
			],
		},
		{
			name: "SAF Holland SA",
			symbol: "SFQ",
			country: "Luxembourg",
			indices: ["SDAX"],
			industries: [
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "SFQ.F", google: "FRA:SFQ", currency: "EUR" },
				{ yahoo: "SFQ", google: "OTCMKTS:SFQ", currency: "USD" },
				{ yahoo: "SFHLF", google: "OTCMKTS:SFHLF", currency: "USD" },
			],
		},
		{
			name: "SGL CARBON SE",
			symbol: "SGL",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Specialty Mining & Metals",
				"Basic Materials",
			],
			symbols: [
				{ yahoo: "SGL.F", google: "FRA:SGL", currency: "EUR" },
				{ yahoo: "SGLFF", google: "OTCMKTS:SGLFF", currency: "USD" },
			],
		},
		{
			name: "Sixt SE",
			symbol: "SIX2",
			country: "Germany",
			indices: ["MDAX"],
			industries: [
				"Passenger Transportation Services",
				"Industrials",
				"Passenger Transportation, Ground & Sea",
				"Transportation",
			],
			symbols: [
				{ yahoo: "SIX2.F", google: "FRA:SIX2", currency: "EUR" },
				{ yahoo: "SXT", google: "NYSE:SXT", currency: "USD" },
				{ yahoo: "SIXGF", google: "OTCMKTS:SIXGF", currency: "USD" },
				{ yahoo: "SIX3.F", google: "FRA:SIX3", currency: "EUR" },
			],
		},
		{
			name: "SMA Solar Technology AG",
			symbol: "S92",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "S92.F", google: "FRA:S92", currency: "EUR" },
				{ yahoo: "S92", google: "OTCMKTS:S92", currency: "USD" },
				{ yahoo: "SMTGF", google: "OTCMKTS:SMTGF", currency: "USD" },
				{ yahoo: "SMTGY", google: "OTCMKTS:SMTGY", currency: "USD" },
				{ yahoo: "S93.F", google: "FRA:S93", currency: "EUR" },
			],
		},
		{
			name: "Stabilus SA",
			symbol: "STM",
			country: "Luxembourg",
			indices: ["MDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "STM.F", google: "FRA:STM", currency: "EUR" },
				{ yahoo: "SIUAF", google: "OTCMKTS:SIUAF", currency: "USD" },
				{ yahoo: "STMU.F", google: "FRA:STMU", currency: "EUR" },
				{ yahoo: "SBLUY", google: "OTCMKTS:SBLUY", currency: "USD" },
			],
		},
		{
			name: "Südzucker AG",
			symbol: "SZU",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [{ yahoo: "SZU.F", google: "FRA:SZU", currency: "EUR" }],
		},
		{
			name: "Talanx",
			symbol: "TLX",
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "TLX.F", google: "FRA:TLX", currency: "EUR" },
				{ yahoo: "TLXC.F", google: "FRA:TLXC", currency: "EUR" },
				{ yahoo: "TLLXY", google: "OTCMKTS:TLLXY", currency: "USD" },
				{ yahoo: "TNXXF", google: "OTCMKTS:TNXXF", currency: "USD" },
			],
		},
		{
			name: "Wacker Neuson SE",
			symbol: "WAC",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Heavy Machinery & Vehicles",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "WAC.F", google: "FRA:WAC", currency: "EUR" },
				{ yahoo: "WKRCF", google: "OTCMKTS:WKRCF", currency: "USD" },
				{ yahoo: "0K11.L", google: "LON:0K11", currency: "GBP" },
			],
		},
		{
			name: "XING AG",
			symbol: "O1BC",
			country: "Germany",
			indices: ["SDAX"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "O1BC.F", google: "FRA:O1BC", currency: "EUR" },
				{ yahoo: "O1BC", google: "OTCMKTS:O1BC", currency: "USD" },
				{ yahoo: "NWO.F", google: "FRA:NWO", currency: "EUR" },
				{ yahoo: "XINXF", google: "OTCMKTS:XINXF", currency: "USD" },
			],
		},
		{
			name: "zooplus AG",
			symbol: "ZO1",
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "ZO1.F", google: "FRA:ZO1", currency: "EUR" },
				{ yahoo: "ZLPSF", google: "OTCMKTS:ZLPSF", currency: "USD" },
				{ yahoo: "ZOPLY", google: "OTCMKTS:ZOPLY", currency: "USD" },
				{ yahoo: "ZO1A.F", google: "FRA:ZO1A", currency: "EUR" },
				{ yahoo: "ZO10.F", google: "FRA:ZO10", currency: "EUR" },
			],
		},
		{
			name: "3i Group PLC",
			symbol: "III",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "IGQ5.F", google: "FRA:IGQ5", currency: "EUR" },
				{ yahoo: "TGOPY", google: "OTCMKTS:TGOPY", currency: "USD" },
				{ yahoo: "III.L", google: "LON:III", currency: "GBP" },
				{ yahoo: "IGQ.F", google: "FRA:IGQ", currency: "EUR" },
				{ yahoo: "TGOPF", google: "OTCMKTS:TGOPF", currency: "USD" },
			],
		},
		{
			name: "Admiral Group PLC",
			symbol: "ADM",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Insurance", "Property & Casualty Insurance", "Financials"],
			symbols: [
				{ yahoo: "FLN.F", google: "FRA:FLN", currency: "EUR" },
				{ yahoo: "ADM.L", google: "LON:ADM", currency: "GBP" },
				{ yahoo: "AMIGY", google: "OTCMKTS:AMIGY", currency: "USD" },
				{ yahoo: "AMIGF", google: "OTCMKTS:AMIGF", currency: "USD" },
			],
		},
		{
			name: "Anglo American PLC",
			symbol: "AAL",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Basic Materials",
				"Integrated Mining",
			],
			symbols: [
				{ yahoo: "NGLD.F", google: "FRA:NGLD", currency: "EUR" },
				{ yahoo: "AGPPF", google: "OTCMKTS:AGPPF", currency: "USD" },
				{ yahoo: "NGLOY", google: "OTCMKTS:NGLOY", currency: "USD" },
				{ yahoo: "NGLB.F", google: "FRA:NGLB", currency: "EUR" },
				{ yahoo: "AAL.L", google: "LON:AAL", currency: "GBP" },
			],
		},
		{
			name: "Antofagasta PLC",
			symbol: "ANTO",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Specialty Mining & Metals",
				"Basic Materials",
			],
			symbols: [
				{ yahoo: "FG1.F", google: "FRA:FG1", currency: "EUR" },
				{ yahoo: "ANFGF", google: "OTCMKTS:ANFGF", currency: "USD" },
				{ yahoo: "ANTO.L", google: "LON:ANTO", currency: "GBP" },
			],
		},
		{
			name: "Ashtead Group PLC",
			symbol: "AHT",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Business Support Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "AHT.F", google: "FRA:AHT", currency: "EUR" },
				{ yahoo: "AHT", google: "OTCMKTS:AHT", currency: "USD" },
				{ yahoo: "0LC.F", google: "FRA:0LC", currency: "EUR" },
				{ yahoo: "AHT.L", google: "LON:AHT", currency: "GBP" },
				{ yahoo: "ASHTY", google: "OTCMKTS:ASHTY", currency: "USD" },
				{ yahoo: "ASHTF", google: "OTCMKTS:ASHTF", currency: "USD" },
				{ yahoo: "0LCA.F", google: "FRA:0LCA", currency: "EUR" },
			],
		},
		{
			name: "Associated British Foods PLC",
			symbol: "ABF",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "ABF.F", google: "FRA:ABF", currency: "EUR" },
				{ yahoo: "ABF", google: "OTCMKTS:ABF", currency: "USD" },
				{ yahoo: "AFO1.F", google: "FRA:AFO1", currency: "EUR" },
				{ yahoo: "ABF.L", google: "LON:ABF", currency: "GBP" },
				{ yahoo: "ASBFY", google: "OTCMKTS:ASBFY", currency: "USD" },
				{ yahoo: "AFO2.F", google: "FRA:AFO2", currency: "EUR" },
				{ yahoo: "ASBFF", google: "OTCMKTS:ASBFF", currency: "USD" },
			],
		},
		{
			name: "AstraZeneca PLC",
			symbol: "AZN",
			country: "United Kingdom",
			indices: ["FTSE 100", "NASDAQ 100", "OMX Stockholm 30"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "ZEG.F", google: "FRA:ZEG", currency: "EUR" },
				{ yahoo: "AZN", google: "NYSE:AZN", currency: "USD" },
				{ yahoo: "ZEGA.F", google: "FRA:ZEGA", currency: "EUR" },
				{ yahoo: "AZNCF", google: "OTCMKTS:AZNCF", currency: "USD" },
				{ yahoo: "AZN.L", google: "LON:AZN", currency: "GBP" },
			],
		},
		{
			name: "Auto Trader Group PLC",
			symbol: "AUTO",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "AUTO.F", google: "FRA:AUTO", currency: "EUR" },
				{ yahoo: "AUTO", google: "OTCMKTS:AUTO", currency: "USD" },
				{ yahoo: "ATDRY", google: "OTCMKTS:ATDRY", currency: "USD" },
				{ yahoo: "AUTO.L", google: "LON:AUTO", currency: "GBP" },
				{ yahoo: "2UA.F", google: "FRA:2UA", currency: "EUR" },
				{ yahoo: "2UA0.F", google: "FRA:2UA0", currency: "EUR" },
				{ yahoo: "ATDRF", google: "OTCMKTS:ATDRF", currency: "USD" },
			],
		},
		{
			name: "Aviva PLC",
			symbol: "AV.",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Insurance", "Financials", "Life & Health Insurance"],
			symbols: [
				{ yahoo: "5V51.F", google: "FRA:5V51", currency: "EUR" },
				{ yahoo: "AVVIY", google: "OTCMKTS:AVVIY", currency: "USD" },
				{ yahoo: "GU8E.F", google: "FRA:GU8E", currency: "EUR" },
				{ yahoo: "AV.L", google: "LON:AV", currency: "GBP" },
				{ yahoo: "GU8.F", google: "FRA:GU8", currency: "EUR" },
				{ yahoo: "GU81.F", google: "FRA:GU81", currency: "EUR" },
				{ yahoo: "GU80.F", google: "FRA:GU80", currency: "EUR" },
			],
		},
		{
			name: "BAE Systems PLC",
			symbol: "BA.",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
			symbols: [
				{ yahoo: "BSP.F", google: "FRA:BSP", currency: "EUR" },
				{ yahoo: "BAESY", google: "OTCMKTS:BAESY", currency: "USD" },
				{ yahoo: "BA.L", google: "LON:BA", currency: "GBP" },
				{ yahoo: "BSPA.F", google: "FRA:BSPA", currency: "EUR" },
			],
		},
		{
			name: "Barclays PLC",
			symbol: "BARC",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "BCY2.F", google: "FRA:BCY2", currency: "EUR" },
				{ yahoo: "INPTF", google: "OTCMKTS:INPTF", currency: "USD" },
				{ yahoo: "BARC.L", google: "LON:BARC", currency: "GBP" },
				{ yahoo: "BCS", google: "NYSE:BCS", currency: "USD" },
				{ yahoo: "BCY.F", google: "FRA:BCY", currency: "EUR" },
				{ yahoo: "BCLYF", google: "OTCMKTS:BCLYF", currency: "USD" },
			],
		},
		{
			name: "Barratt Developments PLC",
			symbol: "BDEV",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Cyclical Consumer Products",
				"Homebuilding",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "BDEV.F", google: "FRA:BDEV", currency: "EUR" },
				{ yahoo: "BDEV", google: "OTCMKTS:BDEV", currency: "USD" },
				{ yahoo: "3BA.F", google: "FRA:3BA", currency: "EUR" },
				{ yahoo: "BTDPY", google: "OTCMKTS:BTDPY", currency: "USD" },
				{ yahoo: "BDEV.L", google: "LON:BDEV", currency: "GBP" },
				{ yahoo: "3BAA.F", google: "FRA:3BAA", currency: "EUR" },
				{ yahoo: "BTDPF", google: "OTCMKTS:BTDPF", currency: "USD" },
			],
		},
		{
			name: "Berkeley Group Holdings/The",
			symbol: "BKG",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Cyclical Consumer Products",
				"Homebuilding",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "BKG.F", google: "FRA:BKG", currency: "EUR" },
				{ yahoo: "BKG", google: "OTCMKTS:BKG", currency: "USD" },
				{ yahoo: "BKGFF", google: "OTCMKTS:BKGFF", currency: "USD" },
				{ yahoo: "42BA.F", google: "FRA:42BA", currency: "EUR" },
				{ yahoo: "BKG.L", google: "LON:BKG", currency: "GBP" },
				{ yahoo: "BKGFY", google: "OTCMKTS:BKGFY", currency: "USD" },
				{ yahoo: "42BB.F", google: "FRA:42BB", currency: "EUR" },
			],
		},
		{
			name: "BP PLC",
			symbol: "BP.",
			country: "United Kingdom",
			indices: ["FTSE 100", "MOEX"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "BSU", google: "FRA:BSU", currency: "USD" },
				{ yahoo: "BP", google: "NYSE:BP", currency: "USD" },
				{ yahoo: "BPE.F", google: "FRA:BPE", currency: "EUR" },
				{ yahoo: "BPAQF", google: "OTCMKTS:BPAQF", currency: "USD" },
				{ yahoo: "BSU.F", google: "FRA:BSU", currency: "EUR" },
				{ yahoo: "BP.L", google: "LON:BP", currency: "GBP" },
				{ yahoo: "BPE5.F", google: "FRA:BPE5", currency: "EUR" },
			],
		},
		{
			name: "British American Tobacco p.l.c.",
			symbol: "BATS",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Tobacco",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "BATS.F", google: "FRA:BATS", currency: "EUR" },
				{ yahoo: "BTAFF", google: "OTCMKTS:BTAFF", currency: "USD" },
				{ yahoo: "BMT.F", google: "FRA:BMT", currency: "EUR" },
				{ yahoo: "BTI", google: "NYSE:BTI", currency: "USD" },
				{ yahoo: "BATS.L", google: "LON:BATS", currency: "GBP" },
				{ yahoo: "BMTA.F", google: "FRA:BMTA", currency: "EUR" },
			],
		},
		{
			name: "British Land Company PLC",
			symbol: "BLND",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "BLDA.F", google: "FRA:BLDA", currency: "EUR" },
				{ yahoo: "BTLCY", google: "OTCMKTS:BTLCY", currency: "USD" },
				{ yahoo: "BLND.L", google: "LON:BLND", currency: "GBP" },
				{ yahoo: "BLD.F", google: "FRA:BLD", currency: "EUR" },
				{ yahoo: "BRLAF", google: "OTCMKTS:BRLAF", currency: "USD" },
			],
		},
		{
			name: "BT Group PLC",
			symbol: "BT.A",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "BTQ.F", google: "FRA:BTQ", currency: "EUR" },
				{ yahoo: "BT", google: "NYSE:BT", currency: "USD" },
				{ yahoo: "BTGOF", google: "OTCMKTS:BTGOF", currency: "USD" },
				{ yahoo: "BT-A.L", google: "LON:BT-A", currency: "GBP" },
			],
		},
		{
			name: "Bunzl PLC",
			symbol: "BNZL",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Industrials",
				"Diversified Trading & Distributing",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "BUZ1.F", google: "FRA:BUZ1", currency: "EUR" },
				{ yahoo: "BZLFF", google: "OTCMKTS:BZLFF", currency: "USD" },
				{ yahoo: "BNZL.L", google: "LON:BNZL", currency: "GBP" },
				{ yahoo: "BZLFY", google: "OTCMKTS:BZLFY", currency: "USD" },
				{ yahoo: "BUZ.F", google: "FRA:BUZ", currency: "EUR" },
			],
		},
		{
			name: "Burberry Group PLC",
			symbol: "BRBY",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Retailers",
				"Apparel & Accessories Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "BB2.F", google: "FRA:BB2", currency: "EUR" },
				{ yahoo: "BURBY", google: "OTCMKTS:BURBY", currency: "USD" },
				{ yahoo: "BRBY.L", google: "LON:BRBY", currency: "GBP" },
				{ yahoo: "BB2A.F", google: "FRA:BB2A", currency: "EUR" },
			],
		},
		{
			name: "Carnival Corporation",
			symbol: "CCL",
			country: "United Kingdom",
			indices: ["S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Cyclical Consumer Services",
				"Hotels, Motels & Cruise Lines",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "CVC1.F", google: "FRA:CVC1", currency: "EUR" },
				{ yahoo: "CCL", google: "NYSE:CCL", currency: "USD" },
				{ yahoo: "CCL.L", google: "LON:CCL", currency: "GBP" },
				{ yahoo: "CUK", google: "NYSE:CUK", currency: "USD" },
				{ yahoo: "CUKPF", google: "OTCMKTS:CUKPF", currency: "USD" },
				{ yahoo: "POH1.F", google: "FRA:POH1", currency: "EUR" },
				{ yahoo: "POH3.F", google: "FRA:POH3", currency: "EUR" },
			],
		},
		{
			name: "Centrica PLC",
			symbol: "CNA",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Multiline Utilities", "Utilities"],
			symbols: [
				{ yahoo: "CENB.F", google: "FRA:CENB", currency: "EUR" },
				{ yahoo: "CPYYY", google: "OTCMKTS:CPYYY", currency: "USD" },
				{ yahoo: "CNA.L", google: "LON:CNA", currency: "GBP" },
				{ yahoo: "CENN.F", google: "FRA:CENN", currency: "EUR" },
				{ yahoo: "CPYYF", google: "OTCMKTS:CPYYF", currency: "USD" },
			],
		},
		{
			name: "Coca-Cola HBC AG",
			symbol: "CCH",
			country: "Switzerland",
			indices: ["FTSE 100"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Beverages",
				"Non-Alcoholic Beverages",
			],
			symbols: [
				{ yahoo: "CCKC.F", google: "FRA:CCKC", currency: "EUR" },
				{ yahoo: "CCHGY", google: "OTCMKTS:CCHGY", currency: "USD" },
				{ yahoo: "CCH.L", google: "LON:CCH", currency: "GBP" },
				{ yahoo: "C0Q.F", google: "FRA:C0Q", currency: "EUR" },
				{ yahoo: "CCHBF", google: "OTCMKTS:CCHBF", currency: "USD" },
			],
		},
		{
			name: "Compass Group PLC",
			symbol: "CPG",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Hotels & Entertainment Services",
				"Restaurants & Bars",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "XGR2.F", google: "FRA:XGR2", currency: "EUR" },
				{ yahoo: "CMPGY", google: "OTCMKTS:CMPGY", currency: "USD" },
				{ yahoo: "CPG.L", google: "LON:CPG", currency: "GBP" },
				{ yahoo: "XGR.F", google: "FRA:XGR", currency: "EUR" },
				{ yahoo: "CMPGF", google: "OTCMKTS:CMPGF", currency: "USD" },
			],
		},
		{
			name: "CRH PLC",
			symbol: "CRH",
			country: "Ireland",
			indices: ["EURO STOXX 50", "FTSE 100"],
			industries: [
				"Construction Materials",
				"Mineral Resources",
				"Basic Materials",
			],
			symbols: [
				{ yahoo: "CRG.F", google: "FRA:CRG", currency: "EUR" },
				{ yahoo: "CRH", google: "NYSE:CRH", currency: "USD" },
				{ yahoo: "CRHCF", google: "OTCMKTS:CRHCF", currency: "USD" },
				{ yahoo: "XCRA.F", google: "FRA:XCRA", currency: "EUR" },
				{ yahoo: "CRH.L", google: "LON:CRH", currency: "GBP" },
			],
		},
		{
			name: "Croda International PLC",
			symbol: "CRDA",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Specialty Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "6CMB.F", google: "FRA:6CMB", currency: "EUR" },
				{ yahoo: "COIHF", google: "OTCMKTS:COIHF", currency: "USD" },
				{ yahoo: "COIHY", google: "OTCMKTS:COIHY", currency: "USD" },
				{ yahoo: "CRDA.L", google: "LON:CRDA", currency: "GBP" },
			],
		},
		{
			name: "DCC PLC",
			symbol: "DCC",
			country: "Ireland",
			indices: ["FTSE 100"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "DCC.F", google: "FRA:DCC", currency: "EUR" },
				{ yahoo: "DCCPF", google: "OTCMKTS:DCCPF", currency: "USD" },
				{ yahoo: "DCC.L", google: "LON:DCC", currency: "GBP" },
			],
		},
		{
			name: "Diageo PLC",
			symbol: "DGE",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Beverages",
				"Distillers & Wineries",
			],
			symbols: [
				{ yahoo: "GUIA.F", google: "FRA:GUIA", currency: "EUR" },
				{ yahoo: "DEO", google: "NYSE:DEO", currency: "USD" },
				{ yahoo: "DGE.L", google: "LON:DGE", currency: "GBP" },
				{ yahoo: "GUI.F", google: "FRA:GUI", currency: "EUR" },
				{ yahoo: "DGEAF", google: "OTCMKTS:DGEAF", currency: "USD" },
			],
		},
		{
			name: "DS Smith PLC",
			symbol: "SMDS",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Paper Packaging",
				"Basic Materials",
				"Containers & Packaging",
				"Applied Resources",
			],
			symbols: [
				{ yahoo: "1KR.F", google: "FRA:1KR", currency: "EUR" },
				{ yahoo: "DITHF", google: "OTCMKTS:DITHF", currency: "USD" },
				{ yahoo: "SMDS.L", google: "LON:SMDS", currency: "GBP" },
			],
		},
		{
			name: "Experian PLC",
			symbol: "EXPN",
			country: "Ireland",
			indices: ["FTSE 100"],
			industries: [
				"Professional Information Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "J2B.F", google: "FRA:J2B", currency: "EUR" },
				{ yahoo: "EXPGY", google: "OTCMKTS:EXPGY", currency: "USD" },
				{ yahoo: "EXPN.L", google: "LON:EXPN", currency: "GBP" },
				{ yahoo: "EXPGF", google: "OTCMKTS:EXPGF", currency: "USD" },
				{ yahoo: "J2BA.F", google: "FRA:J2BA", currency: "EUR" },
			],
		},
		{
			name: "Fresnillo PLC",
			symbol: "FRES",
			country: "Mexico",
			indices: ["FTSE 100"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Basic Materials",
				"Integrated Mining",
			],
			symbols: [
				{ yahoo: "FNL.F", google: "FRA:FNL", currency: "EUR" },
				{ yahoo: "FNLPF", google: "OTCMKTS:FNLPF", currency: "USD" },
				{ yahoo: "FRES.L", google: "LON:FRES", currency: "GBP" },
			],
		},
		{
			name: "GlaxoSmithKline PLC",
			symbol: "GSK",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "GS7A.F", google: "FRA:GS7A", currency: "EUR" },
				{ yahoo: "GSK", google: "NYSE:GSK", currency: "USD" },
				{ yahoo: "GLAXF", google: "OTCMKTS:GLAXF", currency: "USD" },
				{ yahoo: "GS7.F", google: "FRA:GS7", currency: "EUR" },
				{ yahoo: "GSK.L", google: "LON:GSK", currency: "GBP" },
				{ yahoo: "GS70.F", google: "FRA:GS70", currency: "EUR" },
				{ yahoo: "GS71.F", google: "FRA:GS71", currency: "EUR" },
			],
		},
		{
			name: "Glencore PLC",
			symbol: "GLEN",
			country: "Switzerland",
			indices: ["FTSE 100"],
			industries: ["Energy", "Fossil Fuels", "Coal"],
			symbols: [
				{ yahoo: "8GCA.F", google: "FRA:8GCA", currency: "EUR" },
				{ yahoo: "GLNCY", google: "OTCMKTS:GLNCY", currency: "USD" },
				{ yahoo: "GLEN.L", google: "LON:GLEN", currency: "GBP" },
				{ yahoo: "8GC.F", google: "FRA:8GC", currency: "EUR" },
			],
		},
		{
			name: "Halma PLC",
			symbol: "HLMA",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Electrical Components & Equipment",
			],
			symbols: [
				{ yahoo: "H11.F", google: "FRA:H11", currency: "EUR" },
				{ yahoo: "HLMAF", google: "OTCMKTS:HLMAF", currency: "USD" },
				{ yahoo: "HLMA.L", google: "LON:HLMA", currency: "GBP" },
				{ yahoo: "HALMY", google: "OTCMKTS:HALMY", currency: "USD" },
			],
		},
		{
			name: "Hargreaves Lansdown PLC",
			symbol: "HL.",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "DMB.F", google: "FRA:DMB", currency: "EUR" },
				{ yahoo: "HRGLY", google: "OTCMKTS:HRGLY", currency: "USD" },
				{ yahoo: "HL.L", google: "LON:HL", currency: "GBP" },
				{ yahoo: "HRGLF", google: "OTCMKTS:HRGLF", currency: "USD" },
			],
		},
		{
			name: "HSBC Holdings PLC",
			symbol: "HSBA",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "HBC1.F", google: "FRA:HBC1", currency: "EUR" },
				{ yahoo: "HSBC", google: "NYSE:HSBC", currency: "USD" },
				{ yahoo: "HSBA.L", google: "LON:HSBA", currency: "GBP" },
				{ yahoo: "HBCYF", google: "OTCMKTS:HBCYF", currency: "USD" },
				{ yahoo: "HBC2.F", google: "FRA:HBC2", currency: "EUR" },
			],
		},
		{
			name: "Imperial Brands PLC",
			symbol: "IMB",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Tobacco",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "ITBA.F", google: "FRA:ITBA", currency: "EUR" },
				{ yahoo: "IMBBF", google: "OTCMKTS:IMBBF", currency: "USD" },
				{ yahoo: "IMB.L", google: "LON:IMB", currency: "GBP" },
				{ yahoo: "IMBBY", google: "OTCMKTS:IMBBY", currency: "USD" },
				{ yahoo: "ITB.F", google: "FRA:ITB", currency: "EUR" },
			],
		},
		{
			name: "Informa PLC",
			symbol: "INF",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Media & Publishing",
				"Consumer Publishing",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "IEA.F", google: "FRA:IEA", currency: "EUR" },
				{ yahoo: "IFPJF", google: "OTCMKTS:IFPJF", currency: "USD" },
				{ yahoo: "INF.L", google: "LON:INF", currency: "GBP" },
				{ yahoo: "IFJPY", google: "OTCMKTS:IFJPY", currency: "USD" },
			],
		},
		{
			name: "InterContinental Hotels Group PLC",
			symbol: "IHG",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Hotels & Entertainment Services",
				"Cyclical Consumer Services",
				"Hotels, Motels & Cruise Lines",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "IC1B.F", google: "FRA:IC1B", currency: "EUR" },
				{ yahoo: "ICHGF", google: "OTCMKTS:ICHGF", currency: "USD" },
				{ yahoo: "IHG.L", google: "LON:IHG", currency: "GBP" },
				{ yahoo: "IC1H.F", google: "FRA:IC1H", currency: "EUR" },
				{ yahoo: "IHG", google: "NYSE:IHG", currency: "USD" },
			],
		},
		{
			name: "International Consolidated Airlines Group SA",
			symbol: "IAG",
			country: "United States",
			indices: ["FTSE 100", "IBEX 35"],
			industries: [
				"Environmental Services & Equipment",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "INR.F", google: "FRA:INR", currency: "EUR" },
				{ yahoo: "ICAGY", google: "OTCMKTS:ICAGY", currency: "USD" },
				{ yahoo: "IAG.MC", google: "BME:IAG", currency: "EUR" },
				{ yahoo: "IAG.L", google: "LON:IAG", currency: "GBP" },
				{ yahoo: "BABWF", google: "OTCMKTS:BABWF", currency: "USD" },
				{ yahoo: "INRA.F", google: "FRA:INRA", currency: "EUR" },
			],
		},
		{
			name: "Intertek Group PLC",
			symbol: "ITRK",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Business Support Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "IT1.F", google: "FRA:IT1", currency: "EUR" },
				{ yahoo: "IKTSF", google: "OTCMKTS:IKTSF", currency: "USD" },
				{ yahoo: "ITRK.L", google: "LON:ITRK", currency: "GBP" },
				{ yahoo: "IKTSY", google: "OTCMKTS:IKTSY", currency: "USD" },
			],
		},
		{
			name: "Kingfisher PLC",
			symbol: "KGF",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Retailers",
				"Specialty Retailers",
				"Home Improvement Products & Services Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "KFI1.F", google: "FRA:KFI1", currency: "EUR" },
				{ yahoo: "KGFHY", google: "OTCMKTS:KGFHY", currency: "USD" },
				{ yahoo: "KGF.L", google: "LON:KGF", currency: "GBP" },
				{ yahoo: "KGFHF", google: "OTCMKTS:KGFHF", currency: "USD" },
				{ yahoo: "KFI2.F", google: "FRA:KFI2", currency: "EUR" },
			],
		},
		{
			name: "Legal & General Group PLC",
			symbol: "LGEN",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "LGI.F", google: "FRA:LGEN", currency: "EUR" },
				{ yahoo: "LGGNY", google: "OTCMKTS:LGGNY", currency: "USD" },
				{ yahoo: "LGEN.L", google: "LON:LGEN", currency: "GBP" },
				{ yahoo: "LGGNF", google: "OTCMKTS:LGGNF", currency: "USD" },
			],
		},
		{
			name: "Lloyds Banking Group PLC",
			symbol: "LLOY",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "LLD2.F", google: "FRA:LLD2", currency: "EUR" },
				{ yahoo: "LYG", google: "NYSE:LYG", currency: "USD" },
				{ yahoo: "LLDTF", google: "OTCMKTS:LLDTF", currency: "USD" },
				{ yahoo: "LLOY.L", google: "LON:LLOY", currency: "GBP" },
				{ yahoo: "LLD.F", google: "FRA:LLD", currency: "EUR" },
			],
		},
		{
			name: "London Stock Exchange Group PLC",
			symbol: "LSE",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Real Estate",
				"Investment Banking & Investment Services",
				"Real Estate Development & Operations",
				"Financial & Commodity Market Operators",
				"Real Estate Operations",
				"Financials",
				"Banking & Investment Services",
			],
			symbols: [
				{ yahoo: "LS4C.F", google: "FRA:LSE", currency: "EUR" },
				{ yahoo: "LNSTY", google: "OTCMKTS:LNSTY", currency: "USD" },
				{ yahoo: "LS4D.F", google: "FRA:LS4D", currency: "EUR" },
				{ yahoo: "LSE.L", google: "LON:LSE", currency: "GBP" },
				{ yahoo: "LSEG.L", google: "LON:LSEG", currency: "GBP" },
			],
		},
		{
			name: "Melrose Industries PLC",
			symbol: "MRO",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Electrical Components & Equipment",
			],
			symbols: [
				{ yahoo: "27MA.F", google: "FRA:27MA", currency: "EUR" },
				{ yahoo: "MLSPF", google: "OTCMKTS:MLSPF", currency: "USD" },
				{ yahoo: "MRO.L", google: "LON:MRO", currency: "GBP" },
				{ yahoo: "27M.F", google: "FRA:27M", currency: "EUR" },
				{ yahoo: "27MB.F", google: "FRA:27MB", currency: "EUR" },
				{ yahoo: "MLSYY", google: "OTCMKTS:MLSYY", currency: "USD" },
			],
		},
		{
			name: "Mondi PLC",
			symbol: "MNDI",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Paper Packaging",
				"Basic Materials",
				"Containers & Packaging",
				"Applied Resources",
			],
			symbols: [
				{ yahoo: "KYCA.F", google: "FRA:KYCA", currency: "EUR" },
				{ yahoo: "MONDY", google: "OTCMKTS:MONDY", currency: "USD" },
				{ yahoo: "KYC.F", google: "FRA:KYC", currency: "EUR" },
				{ yahoo: "MNDI.L", google: "LON:MNDI", currency: "GBP" },
				{ yahoo: "MONDF", google: "OTCMKTS:MONDF", currency: "USD" },
			],
		},
		{
			name: "National Grid PLC",
			symbol: "NG.",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Multiline Utilities", "Utilities"],
			symbols: [
				{ yahoo: "NNGF.F", google: "FRA:NNGF", currency: "EUR" },
				{ yahoo: "NGG", google: "NYSE:NGG", currency: "USD" },
				{ yahoo: "NG.L", google: "LON:NG", currency: "GBP" },
				{ yahoo: "NGGTF", google: "OTCMKTS:NGGTF", currency: "USD" },
				{ yahoo: "NNGD.F", google: "FRA:NNGD", currency: "EUR" },
			],
		},
		{
			name: "NEXT PLC",
			symbol: "NXT",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Other Specialty Retailers",
				"Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "NXG.F", google: "FRA:NXG", currency: "EUR" },
				{ yahoo: "NXGPF", google: "OTCMKTS:NXGPF", currency: "USD" },
				{ yahoo: "NXT.L", google: "LON:NXT", currency: "GBP" },
				{ yahoo: "NXGPY", google: "OTCMKTS:NXGPY", currency: "USD" },
			],
		},
		{
			name: "Ocado Group PLC",
			symbol: "OCDO",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Diversified Retail",
				"Retailers",
				"Discount Stores",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "0OC.F", google: "FRA:0OC", currency: "EUR" },
				{ yahoo: "OCDGF", google: "OTCMKTS:OCDGF", currency: "USD" },
				{ yahoo: "0OC1.F", google: "FRA:0OC1", currency: "EUR" },
				{ yahoo: "OCDO.L", google: "LON:OCDO", currency: "GBP" },
				{ yahoo: "OCDDY", google: "OTCMKTS:OCDDY", currency: "USD" },
			],
		},
		{
			name: "Flutter Entertainment PLC",
			symbol: "PPB",
			country: "Ireland",
			indices: ["EURO STOXX 50", "FTSE 100"],
			industries: [
				"Hotels & Entertainment Services",
				"Casinos & Gaming",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "PPB.F", google: "FRA:PPB", currency: "EUR" },
				{ yahoo: "PDYPY", google: "OTCMKTS:PDYPY", currency: "USD" },
				{ yahoo: "FLTR.L", google: "LON:FLTR", currency: "GBP" },
				{ yahoo: "PPBA.F", google: "FRA:PPBA", currency: "EUR" },
			],
		},
		{
			name: "Pearson PLC",
			symbol: "PSON",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Media & Publishing",
				"Consumer Publishing",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "PES.F", google: "FRA:PES", currency: "EUR" },
				{ yahoo: "PSO", google: "NYSE:PSO", currency: "USD" },
				{ yahoo: "PSORF", google: "OTCMKTS:PSORF", currency: "USD" },
				{ yahoo: "PESA.F", google: "FRA:PESA", currency: "EUR" },
				{ yahoo: "PSON.L", google: "LON:PSON", currency: "GBP" },
			],
		},
		{
			name: "Persimmon PLC",
			symbol: "PSN",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Cyclical Consumer Products",
				"Homebuilding",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "OHP.F", google: "FRA:OHP", currency: "EUR" },
				{ yahoo: "PSMMY", google: "OTCMKTS:PSMMY", currency: "USD" },
				{ yahoo: "PSMMF", google: "OTCMKTS:PSMMF", currency: "USD" },
				{ yahoo: "PSN.L", google: "LON:PSN", currency: "GBP" },
				{ yahoo: "OHP0.F", google: "FRA:OHP0", currency: "EUR" },
			],
		},
		{
			name: "Phoenix Group Holdings PLC",
			symbol: "PHNX",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Insurance", "Financials", "Life & Health Insurance"],
			symbols: [
				{ yahoo: "1BF.F", google: "FRA:1BF", currency: "EUR" },
				{ yahoo: "PNXGF", google: "OTCMKTS:PNXGF", currency: "USD" },
				{ yahoo: "PHNX.L", google: "LON:PHNX", currency: "GBP" },
			],
		},
		{
			name: "Prudential PLC",
			symbol: "PRU",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Insurance", "Financials", "Life & Health Insurance"],
			symbols: [
				{ yahoo: "PRU.F", google: "FRA:PRU", currency: "EUR" },
				{ yahoo: "PUKPF", google: "OTCMKTS:PUKPF", currency: "USD" },
				{ yahoo: "PRU.L", google: "LON:PRU", currency: "GBP" },
				{ yahoo: "PUK", google: "NYSE:PUK", currency: "USD" },
				{ yahoo: "PRU2.F", google: "FRA:PRU2", currency: "EUR" },
			],
		},
		{
			name: "Reckitt Benckiser Group PLC",
			symbol: "RB.",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Consumer Non-Cyclicals",
				"Personal Products",
				"Personal & Household Products & Services",
			],
			symbols: [
				{ yahoo: "3RB.F", google: "FRA:3RB", currency: "EUR" },
				{ yahoo: "RBGLY", google: "OTCMKTS:RBGLY", currency: "USD" },
				{ yahoo: "3RBB.F", google: "FRA:3RBB", currency: "EUR" },
				{ yahoo: "RB.L", google: "LON:RB", currency: "GBP" },
				{ yahoo: "RKT.L", google: "LON:RKT", currency: "GBP" },
			],
		},
		{
			name: "RELX PLC",
			symbol: "REL",
			country: "United Kingdom",
			indices: ["FTSE 100", "AEX"],
			industries: [
				"Professional Information Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "RDED.F", google: "FRA:RDED", currency: "EUR" },
				{ yahoo: "RELX", google: "NYSE:RELX", currency: "USD" },
				{ yahoo: "REL.L", google: "LON:REL", currency: "GBP" },
				{ yahoo: "REN.AS", google: "AMS:REN", currency: "EUR" },
				{ yahoo: "RLXXF", google: "OTCMKTS:RLXXF", currency: "USD" },
				{ yahoo: "RDEB.F", google: "FRA:RDEB", currency: "EUR" },
			],
		},
		{
			name: "Rentokil Initial PLC",
			symbol: "RTO",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Business Support Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "RTO1.F", google: "FRA:RTO1", currency: "EUR" },
				{ yahoo: "RTOKY", google: "OTCMKTS:RTOKY", currency: "USD" },
				{ yahoo: "RTO.F", google: "FRA:RTO", currency: "EUR" },
				{ yahoo: "RTO.L", google: "LON:RTO", currency: "GBP" },
				{ yahoo: "RKLIF", google: "OTCMKTS:RKLIF", currency: "USD" },
				{ yahoo: "RTO", google: "NYSE:RTO", currency: "USD" },
			],
		},
		{
			name: "Rightmove PLC",
			symbol: "RMV",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "3JDA.F", google: "FRA:3JDA", currency: "EUR" },
				{ yahoo: "RTMVY", google: "OTCMKTS:RTMVY", currency: "USD" },
				{ yahoo: "RMV.L", google: "LON:RMV", currency: "GBP" },
				{ yahoo: "3JD.F", google: "FRA:3JD", currency: "EUR" },
			],
		},
		{
			name: "Rolls-Royce Holdings PLC",
			symbol: "RR.",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
			symbols: [
				{ yahoo: "RRU.F", google: "FRA:RRU", currency: "EUR" },
				{ yahoo: "RYCEF", google: "OTCMKTS:RYCEF", currency: "USD" },
				{ yahoo: "RR.L", google: "LON:RR", currency: "GBP" },
				{ yahoo: "RYCEY", google: "OTCMKTS:RYCEY", currency: "USD" },
				{ yahoo: "RRU1.F", google: "FRA:RRU1", currency: "EUR" },
			],
		},
		{
			name: "The Royal Bank of Scotland Group PLC",
			symbol: "RBS",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "RYS1.F", google: "FRA:RYS1", currency: "EUR" },
				{ yahoo: "RBS", google: "NYSE:RBS", currency: "USD" },
				{ yahoo: "NWG", google: "NYSE:NWG", currency: "USD" },
				{ yahoo: "NWG.L", google: "LON:NWG", currency: "GBP" },
				{ yahoo: "RBSPF", google: "OTCMKTS:RBSPF", currency: "USD" },
				{ yahoo: "RYSD.F", google: "FRA:RYSD", currency: "EUR" },
			],
		},
		{
			name: "The Sage Group PLC",
			symbol: "SGE",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "SGK1.F", google: "FRA:SGK1", currency: "EUR" },
				{ yahoo: "SGPYY", google: "OTCMKTS:SGPYY", currency: "USD" },
				{ yahoo: "SGE.L", google: "LON:SGE", currency: "GBP" },
				{ yahoo: "SGGEF", google: "OTCMKTS:SGGEF", currency: "USD" },
			],
		},
		{
			name: "J Sainsbury PLC",
			symbol: "SBRY",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Food Retail & Distribution",
			],
			symbols: [
				{ yahoo: "SUY1.F", google: "FRA:SUY1", currency: "EUR" },
				{ yahoo: "JSAIY", google: "OTCMKTS:JSAIY", currency: "USD" },
				{ yahoo: "SBRY.L", google: "LON:SBRY", currency: "GBP" },
				{ yahoo: "SUY.F", google: "FRA:SUY", currency: "EUR" },
				{ yahoo: "JSNSF", google: "OTCMKTS:JSNSF", currency: "USD" },
			],
		},
		{
			name: "Schroders PLC",
			symbol: "SDR",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "PYX.F", google: "FRA:PYX", currency: "EUR" },
				{ yahoo: "SHNWF", google: "OTCMKTS:SHNWF", currency: "USD" },
				{ yahoo: "SDR.L", google: "LON:SDR", currency: "GBP" },
				{ yahoo: "SDRC.L", google: "LON:SDRC", currency: "GBP" },
				{ yahoo: "PYXA.F", google: "FRA:PYXA", currency: "EUR" },
				{ yahoo: "SHNWD", google: "OTCMKTS:SHNWD", currency: "USD" },
				{ yahoo: "PYXB.F", google: "FRA:PYXB", currency: "EUR" },
			],
		},
		{
			name: "Scottish Mortgage Investment Trust PLC",
			symbol: "SMT",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Investment Trusts", "Collective Investments", "Financials"],
			symbols: [
				{ yahoo: "1IZ1.F", google: "FRA:1IZ1", currency: "EUR" },
				{ yahoo: "STMZF", google: "OTCMKTS:STMZF", currency: "USD" },
				{ yahoo: "SMT.L", google: "LON:SMT", currency: "GBP" },
			],
		},
		{
			name: "SEGRO PLC",
			symbol: "SGRO",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "S4VC.F", google: "FRA:S4VC", currency: "EUR" },
				{ yahoo: "ESGRO", google: "NASDAQ:ESGRO", currency: "USD" },
				{ yahoo: "SGRO.L", google: "LON:SGRO", currency: "GBP" },
				{ yahoo: "SEGXF", google: "OTCMKTS:SEGXF", currency: "USD" },
			],
		},
		{
			name: "Severn Trent PLC",
			symbol: "SVT",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Water Utilities", "Utilities"],
			symbols: [
				{ yahoo: "SVT1.F", google: "FRA:SVT1", currency: "EUR" },
				{ yahoo: "STRNY", google: "OTCMKTS:STRNY", currency: "USD" },
				{ yahoo: "SVT.L", google: "LON:SVT", currency: "GBP" },
				{ yahoo: "SVTRF", google: "OTCMKTS:SVTRF", currency: "USD" },
			],
		},
		{
			name: "Smith & Nephew PLC",
			symbol: "SN.",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "NPWA.F", google: "FRA:NPWA", currency: "EUR" },
				{ yahoo: "SNN", google: "NYSE:SNN", currency: "USD" },
				{ yahoo: "NPW1.F", google: "FRA:NPW1", currency: "EUR" },
				{ yahoo: "SNNUF", google: "OTCMKTS:SNNUF", currency: "USD" },
				{ yahoo: "SN.L", google: "LON:SN", currency: "GBP" },
			],
		},
		{
			name: "Smiths Group PLC",
			symbol: "SMIN",
			country: "United Kingdom",
			indices: ["CAC Mid 60", "FTSE 100"],
			industries: ["Industrials", "Industrial Conglomerates"],
			symbols: [
				{ yahoo: "QS2A.F", google: "FRA:QS2A", currency: "EUR" },
				{ yahoo: "SMGZY", google: "OTCMKTS:SMGZY", currency: "USD" },
				{ yahoo: "SMIN.L", google: "LON:SMIN", currency: "GBP" },
				{ yahoo: "SMGKF", google: "OTCMKTS:SMGKF", currency: "USD" },
			],
		},
		{
			name: "Smurfit Kappa Group PLC",
			symbol: "SKG",
			country: "Ireland",
			indices: ["FTSE 100"],
			industries: [
				"Paper Packaging",
				"Basic Materials",
				"Containers & Packaging",
				"Applied Resources",
			],
			symbols: [
				{ yahoo: "SK3.F", google: "FRA:SK3", currency: "EUR" },
				{ yahoo: "SMFTF", google: "OTCMKTS:SMFTF", currency: "USD" },
				{ yahoo: "SMFKY", google: "OTCMKTS:SMFKY", currency: "USD" },
				{ yahoo: "SKG.L", google: "LON:SKG", currency: "GBP" },
				{ yahoo: "SK30.F", google: "FRA:SK30", currency: "EUR" },
			],
		},
		{
			name: "Spirax-Sarco Engineering PLC",
			symbol: "SPX",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "1LNB.F", google: "FRA:1LNB", currency: "EUR" },
				{ yahoo: "SPXSF", google: "OTCMKTS:SPXSF", currency: "USD" },
				{ yahoo: "SPX.L", google: "LON:SPX", currency: "GBP" },
				{ yahoo: "SPXSY", google: "OTCMKTS:SPXSY", currency: "USD" },
			],
		},
		{
			name: "STERIS PLC",
			symbol: "SSE",
			country: "United Kingdom",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "2TG.F", google: "FRA:2TG", currency: "EUR" },
				{ yahoo: "STE", google: "NYSE:STE", currency: "USD" },
			],
		},
		{
			name: "St. James's Place PLC",
			symbol: "STJ",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "1IV.F", google: "FRA:1IV", currency: "EUR" },
				{ yahoo: "STJPF", google: "OTCMKTS:STJPF", currency: "USD" },
				{ yahoo: "STJ.L", google: "LON:STJ", currency: "GBP" },
			],
		},
		{
			name: "Standard Chartered PLC",
			symbol: "STAN",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "STD.F", google: "FRA:STD", currency: "EUR" },
				{ yahoo: "SCBFF", google: "OTCMKTS:SCBFF", currency: "USD" },
				{ yahoo: "STAN.L", google: "LON:STAN", currency: "GBP" },
				{ yahoo: "STD0.F", google: "FRA:STD0", currency: "EUR" },
				{ yahoo: "SCBFY", google: "OTCMKTS:SCBFY", currency: "USD" },
			],
		},
		{
			name: "Taylor Wimpey PLC",
			symbol: "TW.",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Cyclical Consumer Products",
				"Homebuilding",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "TWW.F", google: "FRA:TWW", currency: "EUR" },
				{ yahoo: "TWODF", google: "OTCMKTS:TWODF", currency: "USD" },
				{ yahoo: "TW.L", google: "LON:TW", currency: "GBP" },
				{ yahoo: "TWODY", google: "OTCMKTS:TWODY", currency: "USD" },
			],
		},
		{
			name: "Tesco PLC",
			symbol: "TSCO",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Food Retail & Distribution",
			],
			symbols: [
				{ yahoo: "TCO1.F", google: "FRA:TCO1", currency: "EUR" },
				{ yahoo: "TSCDY", google: "OTCMKTS:TSCDY", currency: "USD" },
				{ yahoo: "TSCO.L", google: "LON:TSCO", currency: "GBP" },
				{ yahoo: "TCO2.F", google: "FRA:TCO2", currency: "EUR" },
				{ yahoo: "TCO0.F", google: "FRA:TCO0", currency: "EUR" },
				{ yahoo: "TSCDF", google: "OTCMKTS:TSCDF", currency: "USD" },
			],
		},
		{
			name: "Unilever PLC",
			symbol: "ULVR",
			country: "United Kingdom",
			indices: ["AEX", "FTSE 100"],
			industries: [
				"Consumer Non-Cyclicals",
				"Personal Products",
				"Personal & Household Products & Services",
			],
			symbols: [
				{ yahoo: "UNVB.F", google: "FRA:UNVB", currency: "EUR" },
				{ yahoo: "UL", google: "NYSE:UL", currency: "USD" },
				{ yahoo: "UNA.AS", google: "AMS:UNA", currency: "EUR" },
				{ yahoo: "UNLYF", google: "OTCMKTS:UNLYF", currency: "USD" },
				{ yahoo: "ULVR.L", google: "LON:ULVR", currency: "GBP" },
				{ yahoo: "UN", google: "NYSE:UN", currency: "USD" },
				{ yahoo: "UNVA.F", google: "FRA:UNVA", currency: "EUR" },
			],
		},
		{
			name: "United Utilities Group PLC",
			symbol: "UU.",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Water Utilities", "Utilities"],
			symbols: [
				{ yahoo: "UUEC.F", google: "FRA:UUEC", currency: "EUR" },
				{ yahoo: "UUGRY", google: "OTCMKTS:UUGRY", currency: "USD" },
				{ yahoo: "UUEA.F", google: "FRA:UUEA", currency: "EUR" },
				{ yahoo: "UU.L", google: "LON:UU", currency: "GBP" },
			],
		},
		{
			name: "Vodafone Group PLC",
			symbol: "VOD",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Telecommunications Services",
				"Wireless Telecommunications Services",
			],
			symbols: [
				{ yahoo: "VODI.F", google: "FRA:VODI", currency: "EUR" },
				{ yahoo: "VOD", google: "NASDAQ:VOD", currency: "USD" },
				{ yahoo: "VOD.L", google: "LON:VOD", currency: "GBP" },
				{ yahoo: "VODJ.F", google: "FRA:VODJ", currency: "EUR" },
				{ yahoo: "VODPF", google: "OTCMKTS:VODPF", currency: "USD" },
			],
		},
		{
			name: "Whitbread PLC",
			symbol: "WTB",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Hotels & Entertainment Services",
				"Restaurants & Bars",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "WHF4", google: "FRA:WHF4", currency: "USD" },
				{ yahoo: "WTBDY", google: "OTCMKTS:WTBDY", currency: "USD" },
				{ yahoo: "WTB.L", google: "LON:WTB", currency: "GBP" },
				{ yahoo: "WHF4.F", google: "FRA:WHF4", currency: "EUR" },
				{ yahoo: "WTBCF", google: "OTCMKTS:WTBCF", currency: "USD" },
			],
		},
		{
			name: "WPP PLC",
			symbol: "WPP",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Media & Publishing",
				"Advertising & Marketing",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "0WP.F", google: "FRA:0WP", currency: "EUR" },
				{ yahoo: "WP", google: "NYSE:WP", currency: "USD" },
				{ yahoo: "WPPGF", google: "OTCMKTS:WPPGF", currency: "USD" },
				{ yahoo: "WPP.L", google: "LON:WPP", currency: "GBP" },
				{ yahoo: "WPP", google: "NYSE:WPP", currency: "USD" },
				{ yahoo: "0WPA.F", google: "FRA:0WPA", currency: "EUR" },
			],
		},
		{
			name: "Elisa Oyj",
			symbol: "ELI1V",
			country: "Finland",
			indices: ["CAC Mid 60", "OMX Helsinki 25"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "EIA.F", google: "FRA:EIA", currency: "EUR" },
				{ yahoo: "ELMUY", google: "OTCMKTS:ELMUY", currency: "USD" },
				{ yahoo: "ELMUF", google: "OTCMKTS:ELMUF", currency: "USD" },
			],
		},
		{
			name: "Fortum Oyj",
			symbol: "FUM1V",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: ["Utilities"],
			symbols: [
				{ yahoo: "FOT.F", google: "FRA:FOT", currency: "EUR" },
				{ yahoo: "FOJCF", google: "OTCMKTS:FOJCF", currency: "USD" },
				{ yahoo: "FOJCY", google: "OTCMKTS:FOJCY", currency: "USD" },
				{ yahoo: "0HAH.L", google: "LON:0HAH", currency: "GBP" },
				{ yahoo: "FOT0.F", google: "FRA:FOT0", currency: "EUR" },
			],
		},
		{
			name: "Kesko Oyj",
			symbol: "KESBV",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Food Retail & Distribution",
			],
			symbols: [
				{ yahoo: "KEK.F", google: "FRA:KEK", currency: "EUR" },
				{ yahoo: "KKOYF", google: "OTCMKTS:KKOYF", currency: "USD" },
				{ yahoo: "KEK1.F", google: "FRA:KEK1", currency: "EUR" },
				{ yahoo: "0BNS.L", google: "LON:0BNS", currency: "GBP" },
				{ yahoo: "KKOYY", google: "OTCMKTS:KKOYY", currency: "USD" },
			],
		},
		{
			name: "KONE Oyj",
			symbol: "KNEBV",
			country: "Finland",
			indices: ["EURO STOXX 50", "OMX Helsinki 25"],
			industries: [
				"Heavy Electrical Equipment",
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "KC4.F", google: "FRA:KC4", currency: "EUR" },
				{ yahoo: "KNYJF", google: "OTCMKTS:KNYJF", currency: "USD" },
				{ yahoo: "KNYJY", google: "OTCMKTS:KNYJY", currency: "USD" },
				{ yahoo: "0II2.L", google: "LON:0II2", currency: "GBP" },
			],
		},
		{
			name: "Neste Oyj",
			symbol: "NESTE",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "NEF.F", google: "FRA:NEF", currency: "EUR" },
				{ yahoo: "NTOIY", google: "OTCMKTS:NTOIY", currency: "USD" },
				{ yahoo: "NEFA.F", google: "FRA:NEFA", currency: "EUR" },
			],
		},
		{
			name: "Nokia Corporation",
			symbol: "NOKIA",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Communications & Networking",
			],
			symbols: [
				{ yahoo: "NOA3.F", google: "FRA:NOA3", currency: "EUR" },
				{ yahoo: "NOK", google: "NYSE:NOK", currency: "USD" },
				{ yahoo: "NOKBF", google: "OTCMKTS:NOKBF", currency: "USD" },
				{ yahoo: "NOAA.F", google: "FRA:NOAA", currency: "EUR" },
			],
		},
		{
			name: "Nokian Renkaat Oyj",
			symbol: "NRE1V",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Tires & Rubber Products",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "NRE.F", google: "FRA:NRE", currency: "EUR" },
				{ yahoo: "NKRKY", google: "OTCMKTS:NKRKY", currency: "USD" },
				{ yahoo: "NKRKF", google: "OTCMKTS:NKRKF", currency: "USD" },
				{ yahoo: "NRE0.F", google: "FRA:NRE0", currency: "EUR" },
			],
		},
		{
			name: "Orion Oyj",
			symbol: "ORNBV",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "OFK.F", google: "FRA:OFK", currency: "EUR" },
				{ yahoo: "ORINF", google: "OTCMKTS:ORINF", currency: "USD" },
				{ yahoo: "ORINY", google: "OTCMKTS:ORINY", currency: "USD" },
				{ yahoo: "0M2N.L", google: "LON:0M2N", currency: "GBP" },
				{ yahoo: "OFK0.F", google: "FRA:OFK0", currency: "EUR" },
				{ yahoo: "OEC", google: "NYSE:OEC", currency: "USD" },
				{ yahoo: "0OE.F", google: "FRA:0OE", currency: "EUR" },
			],
		},
		{
			name: "Outokumpu Oyj",
			symbol: "OUT1V",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Basic Materials",
				"Steel",
			],
			symbols: [
				{ yahoo: "OUTA.F", google: "FRA:OUTA", currency: "EUR" },
				{ yahoo: "OUTFF", google: "OTCMKTS:OUTFF", currency: "USD" },
				{ yahoo: "OUTKY", google: "OTCMKTS:OUTKY", currency: "USD" },
				{ yahoo: "0FJ8.L", google: "LON:0FJ8", currency: "GBP" },
			],
		},
		{
			name: "Sampo Oyj",
			symbol: "SAMPO",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: ["Insurance", "Property & Casualty Insurance", "Financials"],
			symbols: [
				{ yahoo: "SMPA.F", google: "FRA:SMPA", currency: "EUR" },
				{ yahoo: "SAXPF", google: "OTCMKTS:SAXPF", currency: "USD" },
				{ yahoo: "SAXPY", google: "OTCMKTS:SAXPY", currency: "USD" },
				{ yahoo: "SMP.F", google: "FRA:SMP", currency: "EUR" },
				{ yahoo: "SMP0.F", google: "FRA:SMP0", currency: "EUR" },
			],
		},
		{
			name: "Stora Enso Oyj R",
			symbol: "STERV",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Paper & Forest Products",
				"Paper Products",
				"Basic Materials",
				"Applied Resources",
			],
			symbols: [
				{ yahoo: "ENUR.F", google: "FRA:ENUR", currency: "EUR" },
				{ yahoo: "SEOJF", google: "OTCMKTS:SEOJF", currency: "USD" },
				{ yahoo: "SEOAY", google: "OTCMKTS:SEOAY", currency: "USD" },
				{ yahoo: "ENUS.F", google: "FRA:ENUS", currency: "EUR" },
				{ yahoo: "ENUN.F", google: "FRA:ENUN", currency: "EUR" },
				{ yahoo: "ENUA.F", google: "FRA:ENUA", currency: "EUR" },
			],
		},
		{
			name: "UPM-Kymmene Oyj",
			symbol: "UPM1V",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [],
			symbols: [
				{ yahoo: "RPL.F", google: "FRA:RPL", currency: "EUR" },
				{ yahoo: "UPMKF", google: "OTCMKTS:UPMKF", currency: "USD" },
				{ yahoo: "UPMMY", google: "OTCMKTS:UPMMY", currency: "USD" },
				{ yahoo: "0NV5.L", google: "LON:0NV5", currency: "GBP" },
			],
		},
		{
			name: "Cargotec Corporation",
			symbol: "CGCBV",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Heavy Machinery & Vehicles",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "C1C.F", google: "FRA:C1C", currency: "EUR" },
				{ yahoo: "CYJBY", google: "OTCMKTS:CYJBY", currency: "USD" },
				{ yahoo: "CYJBF", google: "OTCMKTS:CYJBF", currency: "USD" },
				{ yahoo: "0MGH.L", google: "LON:0MGH", currency: "GBP" },
			],
		},
		{
			name: "Huhtamäki Oyj",
			symbol: "HUH1V",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Paper Packaging",
				"Basic Materials",
				"Containers & Packaging",
				"Applied Resources",
			],
			symbols: [
				{ yahoo: "HUKI.F", google: "FRA:HUKI", currency: "EUR" },
				{ yahoo: "HOYFF", google: "OTCMKTS:HOYFF", currency: "USD" },
			],
		},
		{
			name: "Konecranes PLC",
			symbol: "KCR",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Heavy Machinery & Vehicles",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "K34.F", google: "FRA:K34", currency: "EUR" },
				{ yahoo: "KNCRY", google: "OTCMKTS:KNCRY", currency: "USD" },
				{ yahoo: "KNCRF", google: "OTCMKTS:KNCRF", currency: "USD" },
				{ yahoo: "0MET.L", google: "LON:0MET", currency: "GBP" },
			],
		},
		{
			name: "Metsä Board Oyj",
			symbol: "METSB",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Paper & Forest Products",
				"Paper Products",
				"Basic Materials",
				"Applied Resources",
			],
			symbols: [
				{ yahoo: "MSRB.F", google: "FRA:MSRB", currency: "EUR" },
				{ yahoo: "MTSAF", google: "OTCMKTS:MTSAF", currency: "USD" },
				{ yahoo: "0O79.L", google: "LON:0O79", currency: "GBP" },
			],
		},
		{
			name: "Nordea Bank Abp",
			symbol: "NDA-FI",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "04Q.F", google: "FRA:04Q", currency: "EUR" },
				{ yahoo: "NRDBY", google: "OTCMKTS:NRDBY", currency: "USD" },
				{ yahoo: "0N4T.L", google: "LON:0N4T", currency: "GBP" },
				{ yahoo: "04QA.F", google: "FRA:04QA", currency: "EUR" },
			],
		},
		{
			name: "Telia Company",
			wiki_name: "Telia Company",
			symbol: "TELIA",
			country: "Sweden",
			indices: ["OMX Helsinki 25", "OMX Stockholm 30"],
			industries: [
				"Telecommunication",
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "ZWS.F", google: "FRA:ZWS", currency: "EUR" },
				{ yahoo: "TLSNY", google: "OTCMKTS:TLSNY", currency: "USD" },
				{ yahoo: "TLSNF", google: "OTCMKTS:TLSNF", currency: "USD" },
				{ yahoo: "TLS.F", google: "FRA:TLS", currency: "EUR" },
				{ yahoo: "TLSG.F", google: "FRA:TLSG", currency: "EUR" },
			],
			isins: ["SE0000667925"],
			metadata: { founded: 2003, employees: 20800 },
			akas: [],
		},
		{
			name: "Valmet Oyj",
			symbol: "VALMT",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "2VO.F", google: "FRA:2VO", currency: "EUR" },
				{ yahoo: "VOYJF", google: "OTCMKTS:VOYJF", currency: "USD" },
				{ yahoo: "VLMTY", google: "OTCMKTS:VLMTY", currency: "USD" },
				{ yahoo: "0QIW.L", google: "LON:0QIW", currency: "GBP" },
			],
		},
		{
			name: "Air Liquide SA",
			symbol: "AI",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "AIL.F", google: "FRA:AIL", currency: "EUR" },
				{ yahoo: "AIQUF", google: "OTCMKTS:AIQUF", currency: "USD" },
				{ yahoo: "AIQUY", google: "OTCMKTS:AIQUY", currency: "USD" },
				{ yahoo: "0NWF.L", google: "LON:0NWF", currency: "GBP" },
				{ yahoo: "AILA.F", google: "FRA:AILA", currency: "EUR" },
			],
		},
		{
			name: "ArcelorMittal",
			symbol: "MT",
			country: "Luxembourg",
			indices: ["CAC 40", "AEX", "IBEX 35"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Basic Materials",
				"Steel",
			],
			symbols: [
				{ yahoo: "ARRD.F", google: "FRA:ARRD", currency: "EUR" },
				{ yahoo: "MT", google: "NYSE:MT", currency: "USD" },
				{ yahoo: "MT.AS", google: "AMS:MT", currency: "EUR" },
				{ yahoo: "MTS.MC", google: "BME:MTS", currency: "EUR" },
				{ yahoo: "AMSYF", google: "OTCMKTS:AMSYF", currency: "USD" },
				{ yahoo: "ARRJ.F", google: "FRA:ARRJ", currency: "EUR" },
			],
		},
		{
			name: "Atos SE",
			symbol: "ATO",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "AXI.F", google: "FRA:AXI", currency: "EUR" },
				{ yahoo: "AEXAY", google: "OTCMKTS:AEXAY", currency: "USD" },
				{ yahoo: "AXI0.F", google: "FRA:AXI0", currency: "EUR" },
				{ yahoo: "AEXAF", google: "OTCMKTS:AEXAF", currency: "USD" },
				{ yahoo: "0DNH.L", google: "LON:0DNH", currency: "GBP" },
			],
		},
		{
			name: "AXA SA",
			symbol: "CS",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: ["Insurance", "Financials", "Life & Health Insurance"],
			symbols: [
				{ yahoo: "AXAA.F", google: "FRA:AXAA", currency: "EUR" },
				{ yahoo: "AXAHF", google: "OTCMKTS:AXP", currency: "USD" },
				{ yahoo: "AXAHY", google: "OTCMKTS:AXAHY", currency: "USD" },
				{ yahoo: "AXA.F", google: "FRA:AXA", currency: "EUR" },
			],
		},
		{
			name: "BNP Paribas SA",
			symbol: "BNP",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "BNP.F", google: "FRA:BNP", currency: "EUR" },
				{ yahoo: "BNPQF", google: "OTCMKTS:BNPQF", currency: "USD" },
				{ yahoo: "BNPQY", google: "OTCMKTS:BNPQY", currency: "USD" },
				{ yahoo: "BNPH.F", google: "FRA:BNPH", currency: "EUR" },
				{ yahoo: "0HB5.L", google: "LON:0HB5", currency: "GBP" },
			],
		},
		{
			name: "Bouygues SA",
			symbol: "EN",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "BYG.F", google: "FRA:BYG", currency: "EUR" },
				{ yahoo: "BOUYF", google: "OTCMKTS:BOUYF", currency: "USD" },
				{ yahoo: "BOUYY", google: "OTCMKTS:BOUYY", currency: "USD" },
				{ yahoo: "BYG0.F", google: "FRA:BYG0", currency: "EUR" },
			],
		},
		{
			name: "Capgemini SE",
			symbol: "CAP",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "CGM.F", google: "FRA:CGM", currency: "EUR" },
				{ yahoo: "CAPMF", google: "OTCMKTS:CAPMF", currency: "USD" },
				{ yahoo: "CGEMY", google: "OTCMKTS:CGEMY", currency: "USD" },
				{ yahoo: "CGMA.F", google: "FRA:CGMA", currency: "EUR" },
			],
		},
		{
			name: "Carrefour SA",
			symbol: "CA",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Food Retail & Distribution",
			],
			symbols: [
				{ yahoo: "CAR.F", google: "FRA:CAR", currency: "EUR" },
				{ yahoo: "CRERF", google: "OTCMKTS:CRERF", currency: "USD" },
				{ yahoo: "CRRFY", google: "OTCMKTS:CRRFY", currency: "USD" },
				{ yahoo: "CAR1.F", google: "FRA:CAR1", currency: "EUR" },
				{ yahoo: "0P00001NK3.F", google: "FRA:0P00001NK3", currency: "EUR" },
			],
		},
		{
			name: "Crédit Agricole SA",
			symbol: "ACA",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "XCA.F", google: "FRA:XCA", currency: "EUR" },
				{ yahoo: "CRARF", google: "OTCMKTS:CRARF", currency: "USD" },
			],
		},
		{
			name: "Danull SA",
			symbol: "BN",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "BSN.F", google: "FRA:BSN", currency: "EUR" },
				{ yahoo: "GPDNF", google: "OTCMKTS:GPDNF", currency: "USD" },
				{ yahoo: "DANOY", google: "OTCMKTS:DANOY", currency: "USD" },
				{ yahoo: "0KFX.L", google: "LON:0KFX", currency: "GBP" },
				{ yahoo: "BSND.F", google: "FRA:BSND", currency: "EUR" },
			],
		},
		{
			name: "Dassault Systèmes SE",
			symbol: "DSY",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "DSY.F", google: "FRA:DSY", currency: "EUR" },
				{ yahoo: "DASTY", google: "OTCMKTS:DASTY", currency: "USD" },
			],
		},
		{
			name: "Engie SA",
			symbol: "ENGI",
			country: "France",
			indices: ["CAC 40"],
			industries: ["Multiline Utilities", "Utilities"],
			symbols: [
				{ yahoo: "GZF.F", google: "FRA:GZF", currency: "EUR" },
				{ yahoo: "ENGIY", google: "OTCMKTS:ENGIY", currency: "USD" },
				{ yahoo: "GZFB.F", google: "FRA:GZFB", currency: "EUR" },
				{ yahoo: "0LD0.L", google: "LON:0LD0", currency: "GBP" },
			],
		},
		{
			name: "EssilorLuxottica SA",
			symbol: "EL",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "ESL.F", google: "FRA:ESL", currency: "EUR" },
				{ yahoo: "ESLOF", google: "OTCMKTS:ESLOF", currency: "USD" },
				{ yahoo: "ESLOY", google: "OTCMKTS:ESLOY", currency: "USD" },
				{ yahoo: "ESLC.F", google: "FRA:ESLC", currency: "EUR" },
			],
		},
		{
			name: "Hermès International SCA",
			symbol: "RMS",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Cyclical Consumer Products",
				"Textiles & Apparel",
				"Apparel & Accessories",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "HMI.F", google: "FRA:HMI", currency: "EUR" },
				{ yahoo: "HESAY", google: "OTCMKTS:HESAY", currency: "USD" },
			],
		},
		{
			name: "Kering SA",
			symbol: "KER",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Retailers",
				"Apparel & Accessories Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "PPX.F", google: "FRA:PPX", currency: "EUR" },
				{ yahoo: "PPRUF", google: "OTCMKTS:PPRUF", currency: "USD" },
				{ yahoo: "PPRUY", google: "OTCMKTS:PPRUY", currency: "USD" },
				{ yahoo: "PPXB.F", google: "FRA:PPXB", currency: "EUR" },
				{ yahoo: "0IIH.L", google: "LON:0IIH", currency: "GBP" },
			],
		},
		{
			name: "L'Oréal SA",
			wiki_name: "L'Oréal S.A.",
			symbol: "OR",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Consumer Non-Cyclicals",
				"Personal Products",
				"Personal & Household Products & Services",
			],
			symbols: [
				{ yahoo: "LOR.F", google: "FRA:LOR", currency: "EUR" },
				{ yahoo: "LRLCF", google: "OTCMKTS:LRLCF", currency: "USD" },
			],
		},
		{
			name: "Legrand SA",
			symbol: "LR",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Electrical Components & Equipment",
			],
			symbols: [
				{ yahoo: "LRC.F", google: "FRA:LRC", currency: "EUR" },
				{ yahoo: "LGRDY", google: "OTCMKTS:LGRDY", currency: "USD" },
				{ yahoo: "LGRVF", google: "OTCMKTS:LGRVF", currency: "USD" },
				{ yahoo: "LRC0.F", google: "FRA:LRC0", currency: "EUR" },
			],
		},
		{
			name: "Compagnie Générale des établissements Michelin",
			symbol: "ML",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Tires & Rubber Products",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "MCH.F", google: "FRA:MCH", currency: "EUR" },
				{ yahoo: "MGDDF", google: "OTCMKTS:MGDDF", currency: "USD" },
				{ yahoo: "MCHA.F", google: "FRA:MCHA", currency: "EUR" },
			],
		},
		{
			name: "Orange Belgium SA",
			symbol: "ORA",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "MOS.F", google: "FRA:MOS", currency: "EUR" },
				{ yahoo: "OCBI", google: "OTCMKTS:OCBI", currency: "USD" },
				{ yahoo: "FTE.F", google: "FRA:FTE", currency: "EUR" },
				{ yahoo: "ORAN", google: "NYSE:ORAN", currency: "USD" },
				{ yahoo: "FNCTF", google: "OTCMKTS:FNCTF", currency: "USD" },
				{ yahoo: "MBISF", google: "OTCMKTS:MBISF", currency: "USD" },
				{ yahoo: "0FBS.L", google: "LON:0FBS", currency: "GBP" },
			],
		},
		{
			name: "Pernod Ricard SA",
			symbol: "RI",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Beverages",
				"Distillers & Wineries",
			],
			symbols: [
				{ yahoo: "PER.F", google: "FRA:PER", currency: "EUR" },
				{ yahoo: "PDRDF", google: "OTCMKTS:PDRDF", currency: "USD" },
				{ yahoo: "PDRDY", google: "OTCMKTS:PDRDY", currency: "USD" },
				{ yahoo: "PRNDY", google: "OTCMKTS:PRNDY", currency: "USD" },
				{ yahoo: "PER0.F", google: "FRA:PER0", currency: "EUR" },
				{ yahoo: "0HAT.L", google: "LON:0HAT", currency: "GBP" },
			],
		},
		{
			name: "Publicis Groupe SA",
			symbol: "PUB",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Media & Publishing",
				"Advertising & Marketing",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "PU4.F", google: "FRA:PU4", currency: "EUR" },
				{ yahoo: "PGPEF", google: "OTCMKTS:PGPEF", currency: "USD" },
				{ yahoo: "PUBGY", google: "OTCMKTS:PUBGY", currency: "USD" },
				{ yahoo: "0FQI.L", google: "LON:0FQI", currency: "GBP" },
				{ yahoo: "PU41.F", google: "FRA:PU41", currency: "EUR" },
			],
		},
		{
			name: "Renault SA",
			symbol: "RNO",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Auto & Truck Manufacturers",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "RNL.F", google: "FRA:RNL", currency: "EUR" },
				{ yahoo: "RNSDF", google: "OTCMKTS:RNSDF", currency: "USD" },
				{ yahoo: "RNLSY", google: "OTCMKTS:RNLSY", currency: "USD" },
				{ yahoo: "RNL1.F", google: "FRA:RNL1", currency: "EUR" },
			],
		},
		{
			name: "Safran SA",
			symbol: "SAF",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
			symbols: [
				{ yahoo: "SEJ1.F", google: "FRA:SEJ1", currency: "EUR" },
				{ yahoo: "SAFRF", google: "OTCMKTS:SAFRF", currency: "USD" },
				{ yahoo: "SAFRY", google: "OTCMKTS:SAFRY", currency: "USD" },
				{ yahoo: "SEJU.F", google: "FRA:SEJU", currency: "EUR" },
			],
		},
		{
			name: "Compagnie de Saint-Gobain SA",
			symbol: "SGO",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Cyclical Consumer Products",
				"Construction Supplies & Fixtures",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "GOB.F", google: "FRA:GOB", currency: "EUR" },
				{ yahoo: "CODGF", google: "OTCMKTS:CODGF", currency: "USD" },
				{ yahoo: "CODYY", google: "OTCMKTS:CODYY", currency: "USD" },
				{ yahoo: "COD.L", google: "LON:COD", currency: "GBP" },
				{ yahoo: "GOBU.F", google: "FRA:GOBU", currency: "EUR" },
			],
		},
		{
			name: "Sanofi",
			symbol: "SAN",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "SNW.F", google: "FRA:SNW", currency: "EUR" },
				{ yahoo: "SNYNF", google: "OTCMKTS:SNYNF", currency: "USD" },
				{ yahoo: "SNY", google: "NASDAQ:SNY", currency: "USD" },
				{ yahoo: "SNW2.F", google: "FRA:SNW2", currency: "EUR" },
			],
		},
		{
			name: "Schneider Electric S.E.",
			symbol: "SU",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Electrical Components & Equipment",
			],
			symbols: [
				{ yahoo: "SND.F", google: "FRA:SND", currency: "EUR" },
				{ yahoo: "SBGSF", google: "OTCMKTS:SBGSF", currency: "USD" },
				{ yahoo: "SBGSY", google: "OTCMKTS:SBGSY", currency: "USD" },
				{ yahoo: "SNDB.F", google: "FRA:SNDB", currency: "EUR" },
			],
		},
		{
			name: "STMicroelectronics N.V.",
			symbol: "STM",
			country: "Switzerland",
			indices: ["CAC 40"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "SGM.F", google: "FRA:SGM", currency: "EUR" },
				{ yahoo: "STMEF", google: "OTCMKTS:STMEF", currency: "USD" },
				{ yahoo: "0L9Y.L", google: "LON:0L9Y", currency: "GBP" },
				{ yahoo: "STM", google: "NYSE:STM", currency: "USD" },
				{ yahoo: "SGMR.F", google: "FRA:SGMR", currency: "EUR" },
			],
		},
		{
			name: "TOTAL SA",
			symbol: "FP",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Integrated Oil & Gas",
				"Oil & Gas",
			],
			symbols: [
				{ yahoo: "TOTB.F", google: "FRA:TOTB", currency: "EUR" },
				{ yahoo: "TTFNF", google: "OTCMKTS:TTFNF", currency: "USD" },
				{ yahoo: "TOT", google: "NYSE:TOT", currency: "USD" },
				{ yahoo: "TTE", google: "NYSE:TTE", currency: "USD" },
				{ yahoo: "TTE.L", google: "LON:TTE", currency: "GBP" },
				{ yahoo: "TOTA.F", google: "FRA:TOTA", currency: "EUR" },
			],
		},
		{
			name: "Unibail-Rodamco-Westfield",
			symbol: "URW",
			country: "France",
			indices: ["AEX", "CAC 40"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "1BR1.F", google: "FRA:1BR1", currency: "EUR" },
				{ yahoo: "UNBLF", google: "OTCMKTS:UNBLF", currency: "USD" },
				{ yahoo: "URW.AS", google: "AMS:URW", currency: "EUR" },
				{ yahoo: "UBLB.F", google: "FRA:UBLB", currency: "EUR" },
				{ yahoo: "UNIRF", google: "OTCMKTS:UNIRF", currency: "USD" },
			],
		},
		{
			name: "Veolia Environnement SA",
			symbol: "VIE",
			country: "France",
			indices: ["CAC 40"],
			industries: ["Water Utilities", "Utilities"],
			symbols: [
				{ yahoo: "VVD.F", google: "FRA:VVD", currency: "EUR" },
				{ yahoo: "VEOEY", google: "OTCMKTS:VEOEY", currency: "USD" },
				{ yahoo: "VVDH.F", google: "FRA:VVDH", currency: "EUR" },
			],
		},
		{
			name: "VINCI SA",
			symbol: "DG",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "SQU.F", google: "FRA:SQU", currency: "EUR" },
				{ yahoo: "VCISY", google: "OTCMKTS:VCISY", currency: "USD" },
				{ yahoo: "0NQM.L", google: "LON:0NQM", currency: "GBP" },
				{ yahoo: "SQUA.F", google: "FRA:SQUA", currency: "EUR" },
				{ yahoo: "VCISF", google: "OTCMKTS:VCISF", currency: "USD" },
			],
		},
		{
			name: "Vivendi SA",
			symbol: "VIV",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Media & Publishing",
				"Entertainment Production",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "VVU.F", google: "FRA:VVU", currency: "EUR" },
				{ yahoo: "VIVEF", google: "OTCMKTS:VIVEF", currency: "USD" },
				{ yahoo: "VIVHY", google: "OTCMKTS:VIVHY", currency: "USD" },
				{ yahoo: "VVUD.F", google: "FRA:VVUD", currency: "EUR" },
				{ yahoo: "0IIF.L", google: "LON:0IIF", currency: "GBP" },
			],
		},
		{
			name: "Worldline SA",
			symbol: "WLN",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Financial & Commodity Market Operators",
			],
			symbols: [
				{ yahoo: "WO6.F", google: "FRA:WO6", currency: "EUR" },
				{ yahoo: "WWLNF", google: "OTCMKTS:WWLNF", currency: "USD" },
				{ yahoo: "WRDLY", google: "OTCMKTS:WRDLY", currency: "USD" },
				{ yahoo: "WO60.F", google: "FRA:WO60", currency: "EUR" },
				{ yahoo: "0QVI.L", google: "LON:0QVI", currency: "GBP" },
			],
		},
		{
			name: "3M Company",
			symbol: "MMM",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: ["Industrials", "Industrial Conglomerates"],
			symbols: [
				{ yahoo: "MMM.F", google: "FRA:MMM", currency: "EUR" },
				{ yahoo: "MMM", google: "NYSE:MMM", currency: "USD" },
			],
		},
		{
			name: "American Express Company",
			symbol: "AXP",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Consumer Lending",
			],
			symbols: [
				{ yahoo: "AEC1.F", google: "FRA:AEC1", currency: "EUR" },
				{ yahoo: "AXP", google: "NYSE:AXP", currency: "USD" },
				{ yahoo: "0R3C.L", google: "LON:0R3C", currency: "GBP" },
			],
		},
		{
			name: "Apple Inc.",
			symbol: "AAPL",
			country: "United States",
			indices: ["DOW JONES", "NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Computers, Phones & Household Electronics",
				"Technology",
				"Technology Equipment",
				"Phones & Handheld Devices",
			],
			symbols: [
				{ yahoo: "APC.F", google: "FRA:APC", currency: "EUR" },
				{ yahoo: "AAPL", google: "NASDAQ:AAPL", currency: "USD" },
			],
		},
		{
			name: "The Boeing Company",
			symbol: "BA",
			country: "United States",
			indices: ["CAC 40", "DOW JONES", "EURO STOXX 50", "S&P 100", "S&P 500"],
			industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
			symbols: [
				{ yahoo: "BCO.F", google: "FRA:BCO", currency: "EUR" },
				{ yahoo: "BA", google: "NYSE:BA", currency: "USD" },
				{ yahoo: "BOE.L", google: "LON:BOE", currency: "GBP" },
				{ yahoo: "0BOE.L", google: "LON:0BOE", currency: "GBP" },
				{ yahoo: "BCO0.F", google: "FRA:BCO0", currency: "EUR" },
			],
		},
		{
			name: "Caterpillar Inc.",
			symbol: "CAT",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Heavy Machinery & Vehicles",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "CAT1.F", google: "FRA:CAT1", currency: "EUR" },
				{ yahoo: "CAT", google: "NYSE:CAT", currency: "USD" },
				{ yahoo: "0Q18.L", google: "LON:0Q18", currency: "GBP" },
			],
		},
		{
			name: "Chevron Corporation",
			symbol: "CVX",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "CHV.F", google: "FRA:CHV", currency: "EUR" },
				{ yahoo: "CVX", google: "NYSE:CVX", currency: "USD" },
			],
		},
		{
			name: "Cisco Systems Inc.",
			symbol: "CSCO",
			country: "United States",
			indices: ["DOW JONES", "NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Communications & Networking",
			],
			symbols: [
				{ yahoo: "CIS.F", google: "FRA:CIS", currency: "EUR" },
				{ yahoo: "CSCO", google: "NASDAQ:CSCO", currency: "USD" },
				{ yahoo: "CIS0.F", google: "FRA:CIS0", currency: "EUR" },
			],
		},
		{
			name: "The Coca-Cola Company",
			symbol: "KO",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Beverages",
				"Non-Alcoholic Beverages",
			],
			symbols: [
				{ yahoo: "CCC3.F", google: "FRA:CCC3", currency: "EUR" },
				{ yahoo: "KO", google: "OTCMKTS:KO", currency: "USD" },
			],
		},
		{
			name: "DowDuPont Inc.",
			symbol: "DOW",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "6D81.F", google: "FRA:6D81", currency: "EUR" },
				{ yahoo: "DD", google: "NYSE:DD", currency: "USD" },
			],
		},
		{
			name: "Exxon Mobil Corporation",
			symbol: "XOM",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "XONA.F", google: "FRA:XONA", currency: "EUR" },
				{ yahoo: "XOM", google: "NYSE:XOM", currency: "USD" },
				{ yahoo: "XON", google: "NYSE:XON", currency: "USD" },
			],
		},
		{
			name: "The Goldman Sachs Group Inc.",
			symbol: "GS",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Banking & Brokerage Services",
			],
			symbols: [
				{ yahoo: "GOS.F", google: "FRA:GOS", currency: "EUR" },
				{ yahoo: "GS-PK", google: "NYSE:GS", currency: "USD" },
				{ yahoo: "GS", google: "NYSE:GS", currency: "USD" },
				{ yahoo: "GOS0.F", google: "FRA:GOS0", currency: "EUR" },
			],
		},
		{
			name: "The Home Depot Inc.",
			symbol: "HD",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Retailers",
				"Specialty Retailers",
				"Home Improvement Products & Services Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "HDI.F", google: "FRA:HDI", currency: "EUR" },
				{ yahoo: "HD", google: "NYSE:HD", currency: "USD" },
				{ yahoo: "HDI0.F", google: "FRA:HDI0", currency: "EUR" },
			],
		},
		{
			name: "International Business Machines Corporation",
			symbol: "IBM",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "IBM.F", google: "FRA:IBM", currency: "EUR" },
				{ yahoo: "IBM", google: "NYSE:IBM", currency: "USD" },
				{ yahoo: "IBM.L", google: "LON:IBM", currency: "GBP" },
				{ yahoo: "IBM0.F", google: "FRA:IBM0", currency: "EUR" },
			],
		},
		{
			name: "Intel Corporation",
			symbol: "INTC",
			country: "United States",
			indices: ["DOW JONES", "NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "INL.F", google: "FRA:INL", currency: "EUR" },
				{ yahoo: "INTC", google: "NASDAQ:INTC", currency: "USD" },
				{ yahoo: "INL0.F", google: "FRA:INL0", currency: "EUR" },
			],
		},
		{
			name: "Johnson & Johnson",
			symbol: "JNJ",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "JNJ.F", google: "FRA:JNJ", currency: "EUR" },
				{ yahoo: "JNJ", google: "NYSE:JNJ", currency: "USD" },
			],
		},
		{
			name: "JPMorgan Chase & Co.",
			symbol: "JPM",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "CMC.F", google: "FRA:CMC", currency: "EUR" },
				{ yahoo: "JPM", google: "NYSE:JPM", currency: "USD" },
			],
		},
		{
			name: "McDonald's Corporation",
			symbol: "MCD",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Restaurants & Bars",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "MDO.F", google: "FRA:MDO", currency: "EUR" },
				{ yahoo: "MCD", google: "NYSE:MCD", currency: "USD" },
				{ yahoo: "MDO0.F", google: "FRA:MDO0", currency: "EUR" },
			],
		},
		{
			name: "Nike, Inc.",
			wiki_name: "Nike, Inc.",
			symbol: "NKE",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: ["Apparel", "Accessories", "Sports equipment"],
			symbols: [
				{ yahoo: "NKE.F", google: "FRA:NKE", currency: "EUR" },
				{ yahoo: "NKE", google: "NYSE:NKE", currency: "USD" },
			],
		},
		{
			name: "Merck & Co. Inc.",
			symbol: "MRK",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [{ yahoo: "MRK", google: "NYSE:MRK", currency: "USD" }],
		},
		{
			name: "Microsoft Corporation",
			symbol: "MSFT",
			country: "United States",
			indices: ["DOW JONES", "NASDAQ 100", "S&P 100", "S&P 500"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "MSF.F", google: "FRA:MSF", currency: "EUR" },
				{ yahoo: "MSFT", google: "NASDAQ:MSFT", currency: "USD" },
				{ yahoo: "MSF0.F", google: "FRA:MSF0", currency: "EUR" },
			],
		},
		{
			name: "Pfizer Inc.",
			symbol: "PFE",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "PFE.F", google: "FRA:PFE", currency: "EUR" },
				{ yahoo: "PFE", google: "NYSE:PFE", currency: "USD" },
			],
		},
		{
			name: "The Procter & Gamble Company",
			symbol: "PG",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Personal Products",
				"Personal & Household Products & Services",
			],
			symbols: [
				{ yahoo: "PRG.F", google: "FRA:PRG", currency: "EUR" },
				{ yahoo: "PG", google: "NYSE:PG", currency: "USD" },
				{ yahoo: "0NOF.L", google: "LON:0NOF", currency: "GBP" },
			],
		},
		{
			name: "The Travelers Companies Inc.",
			symbol: "TRV",
			country: "United States",
			indices: ["DOW JONES", "S&P 500"],
			industries: ["Insurance", "Property & Casualty Insurance", "Financials"],
			symbols: [
				{ yahoo: "PA9.F", google: "FRA:PA9", currency: "EUR" },
				{ yahoo: "TRV", google: "NYSE:TRV", currency: "USD" },
				{ yahoo: "0R03.L", google: "LON:0R03", currency: "GBP" },
			],
		},
		{
			name: "UnitedHealth Group Inc.",
			symbol: "UNH",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Managed Health care",
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "UNH.F", google: "FRA:UNH", currency: "EUR" },
				{ yahoo: "UNH", google: "NYSE:UNH", currency: "USD" },
			],
		},
		{
			name: "Verizon Communications Inc.",
			symbol: "VZ",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "BAC.F", google: "FRA:BAC", currency: "EUR" },
				{ yahoo: "VZ", google: "NYSE:VZ", currency: "USD" },
				{ yahoo: "BACB.F", google: "FRA:BACB", currency: "EUR" },
			],
		},
		{
			name: "Visa Inc.",
			symbol: "V",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "3V64.F", google: "FRA:3V64", currency: "EUR" },
				{ yahoo: "V", google: "NYSE:V", currency: "USD" },
				{ yahoo: "3V6.F", google: "FRA:3V6", currency: "EUR" },
			],
		},
		{
			name: "Walmart Inc.",
			symbol: "WMT",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Food Retail & Distribution",
			],
			symbols: [
				{ yahoo: "WMT.F", google: "FRA:WMT", currency: "EUR" },
				{ yahoo: "WMT", google: "NYSE:WMT", currency: "USD" },
				{ yahoo: "WMTD.F", google: "FRA:WMTD", currency: "EUR" },
			],
		},
		{
			name: "Walgreens Boots Alliance Inc.",
			symbol: "WBA",
			country: "United States",
			indices: ["DOW JONES", "NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Drug Retailers",
			],
			symbols: [
				{ yahoo: "W8A.F", google: "FRA:W8A", currency: "EUR" },
				{ yahoo: "WBA", google: "NASDAQ:WBA", currency: "USD" },
				{ yahoo: "0LSZ.L", google: "LON:0LSZ", currency: "GBP" },
			],
		},
		{
			name: "The Walt Disney Company",
			symbol: "DIS",
			country: "United States",
			indices: ["S&P 100", "S&P 500", "DOW JONES"],
			industries: [
				"Media & Publishing",
				"Cyclical Consumer Services",
				"Broadcasting",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "WDP.F", google: "FRA:WDP", currency: "EUR" },
				{ yahoo: "DIS", google: "OTCMKTS:DIS", currency: "USD" },
				{ yahoo: "WDP0.F", google: "FRA:WDP0", currency: "EUR" },
			],
		},
		// {
		// 	name: "Activision Blizzard Inc.",
		// 	symbol: "ATVI",
		// 	country: "United States",
		// 	indices: ["NASDAQ 100", "S&P 500"],
		// 	industries: ["Technology", "Software & IT Services", "Internet Services"],
		// 	symbols: [
		// 		{ yahoo: "AIY.F", google: "FRA:AIY", currency: "EUR" },
		// 		{ yahoo: "ATVI", google: "NASDAQ:ATVI", currency: "USD" },
		// 	],
		// 	metadata: { founded: 2008, employees: 9500 },
		// 	isins: ["US00507V1098"],
		// 	akas: [],
		// },
		{
			name: "Adobe Inc.",
			symbol: "ADBE",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "ADB.F", google: "FRA:ADB", currency: "EUR" },
				{ yahoo: "ADBE", google: "NASDAQ:ADBE", currency: "USD" },
			],
		},
		{
			name: "Akamai Technologies Inc.",
			symbol: "AKAM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "AK3.F", google: "FRA:AK3", currency: "EUR" },
				{ yahoo: "AKAM", google: "NASDAQ:AKAM", currency: "USD" },
				{ yahoo: "0HBQ.L", google: "LON:0HBQ", currency: "GBP" },
			],
		},
		{
			name: "Alphabet Inc.",
			symbol: "GOOGL",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "ABEC.F", google: "FRA:ABEC", currency: "EUR" },
				{ yahoo: "GOOGL", google: "NASDAQ:GOOGL", currency: "USD" },
				{ yahoo: "ABEA.F", google: "FRA:ABEA", currency: "EUR" },
				{ yahoo: "GOOG", google: "NASDAQ:GOOG", currency: "USD" },
			],
		},
		{
			name: "Amazon.com Inc.",
			symbol: "AMZN",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Diversified Retail",
				"Retailers",
				"Department Stores",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "AMZ.F", google: "FRA:AMZ", currency: "EUR" },
				{ yahoo: "AMZN", google: "NASDAQ:AMZN", currency: "USD" },
			],
		},
		{
			name: "American Airlines Group Inc.",
			symbol: "AAL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Passenger Transportation Services",
				"Industrials",
				"Airlines",
				"Transportation",
			],
			symbols: [
				{ yahoo: "A1G.F", google: "FRA:A1G", currency: "EUR" },
				{ yahoo: "AAL", google: "NASDAQ:AAL", currency: "USD" },
				{ yahoo: "0HE6.L", google: "LON:0HE6", currency: "GBP" },
			],
		},
		{
			name: "Amgen Inc.",
			symbol: "AMGN",
			country: "United States",
			indices: ["DOW JONES", "NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "AMG.F", google: "FRA:AMG", currency: "EUR" },
				{ yahoo: "AMGN", google: "NASDAQ:AMGN", currency: "USD" },
			],
		},
		{
			name: "Analog Devices Inc.",
			symbol: "ADI",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "ANL.F", google: "FRA:ANL", currency: "EUR" },
				{ yahoo: "ADI", google: "NASDAQ:ADI", currency: "USD" },
				{ yahoo: "0HFN.L", google: "LON:0HFN", currency: "GBP" },
			],
		},
		{
			name: "Applied Materials Inc.",
			symbol: "AMAT",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Semiconductor Equipment & Testing",
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
			],
			symbols: [
				{ yahoo: "AP2.F", google: "FRA:AP2", currency: "EUR" },
				{ yahoo: "AMAT", google: "NASDAQ:AMAT", currency: "USD" },
			],
		},
		{
			name: "Autodesk Inc.",
			symbol: "ADSK",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "AUD.F", google: "FRA:AUD", currency: "EUR" },
				{ yahoo: "ADSK", google: "NASDAQ:ADSK", currency: "USD" },
				{ yahoo: "0HJF.L", google: "LON:0HJF", currency: "GBP" },
			],
		},
		{
			name: "Automatic Data Processing Inc.",
			symbol: "ADP",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "ADP.F", google: "FRA:ADP", currency: "EUR" },
				{ yahoo: "ADP", google: "NASDAQ:ADP", currency: "USD" },
				{ yahoo: "0HJI.L", google: "LON:0HJI", currency: "GBP" },
			],
		},
		{
			name: "Baidu Inc.",
			symbol: "BIDU",
			country: "China",
			indices: ["NASDAQ 100"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "B1C.F", google: "FRA:B1C", currency: "EUR" },
				{ yahoo: "BIDU", google: "NASDAQ:BIDU", currency: "USD" },
				{ yahoo: "BAIDF", google: "OTCMKTS:BAIDF", currency: "USD" },
			],
		},
		{
			name: "Biogen Inc.",
			symbol: "BIIB",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "IDP.F", google: "FRA:IDP", currency: "EUR" },
				{ yahoo: "BIIB", google: "NASDAQ:BIIB", currency: "USD" },
			],
		},
		{
			name: "Charter Communications Inc.",
			symbol: "CHTR",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Media & Publishing",
				"Cyclical Consumer Services",
				"Broadcasting",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "CQD.F", google: "FRA:CQD", currency: "EUR" },
				{ yahoo: "CHTR", google: "NASDAQ:CHTR", currency: "USD" },
				{ yahoo: "0HW4.L", google: "LON:0HW4", currency: "GBP" },
			],
		},
		{
			name: "Cognizant Technology Solutions Corporation",
			symbol: "CTSH",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "COZ.F", google: "FRA:COZ", currency: "EUR" },
				{ yahoo: "CTSH", google: "NASDAQ:CTSH", currency: "USD" },
				{ yahoo: "0QZ5.L", google: "LON:0QZ5", currency: "GBP" },
			],
		},
		{
			name: "Comcast Corporation",
			symbol: "CMCSA",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Media & Publishing",
				"Cyclical Consumer Services",
				"Broadcasting",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "CTP2.F", google: "FRA:CTP2", currency: "EUR" },
				{ yahoo: "CMCSA", google: "NASDAQ:CMCSA", currency: "USD" },
			],
		},
		{
			name: "Costco Wholesale Corporation",
			symbol: "COST",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Diversified Retail",
				"Retailers",
				"Discount Stores",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "CTO.F", google: "FRA:CTO", currency: "EUR" },
				{ yahoo: "COST", google: "NASDAQ:COST", currency: "USD" },
				{ yahoo: "0I47.L", google: "LON:0I47", currency: "GBP" },
				{ yahoo: "CTO0.F", google: "FRA:CTO0", currency: "EUR" },
			],
		},
		{
			name: "CSX Corporation",
			symbol: "CSX",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Ground Freight & Logistics",
				"Industrials",
				"Freight & Logistics Services",
				"Transportation",
			],
			symbols: [
				{ yahoo: "CXR.F", google: "FRA:CXR", currency: "EUR" },
				{ yahoo: "CSX", google: "NASDAQ:CSX", currency: "USD" },
				{ yahoo: "0HRJ.L", google: "LON:0HRJ", currency: "GBP" },
			],
		},
		{
			name: "Dentyply Sirona Inc.",
			symbol: "XRAY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "DY2.F", google: "FRA:DY2", currency: "EUR" },
				{ yahoo: "XRAY", google: "NASDAQ:XRAY", currency: "USD" },
			],
		},
		{
			name: "DISH Network Corporation",
			symbol: "DISH",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Media & Publishing",
				"Cyclical Consumer Services",
				"Broadcasting",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "EOT.F", google: "FRA:EOT", currency: "EUR" },
				{ yahoo: "DISH", google: "NASDAQ:DISH", currency: "USD" },
				{ yahoo: "0IBG.L", google: "LON:0IBG", currency: "GBP" },
			],
		},
		{
			name: "Dollar Tree Inc.",
			symbol: "DLTR",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Diversified Retail",
				"Retailers",
				"Discount Stores",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "DT3.F", google: "FRA:DT3", currency: "EUR" },
				{ yahoo: "DLTR", google: "NASDAQ:DLTR", currency: "USD" },
				{ yahoo: "0IC8.L", google: "LON:0IC8", currency: "GBP" },
			],
		},
		{
			name: "eBay Inc.",
			symbol: "EBAY",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "EBA.F", google: "FRA:EBA", currency: "EUR" },
				{ yahoo: "EBAY", google: "NASDAQ:EBAY", currency: "USD" },
			],
		},
		{
			name: "Electronic Arts Inc.",
			symbol: "EA",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "ERT.F", google: "FRA:ERT", currency: "EUR" },
				{ yahoo: "EA", google: "NASDAQ:EA", currency: "USD" },
				{ yahoo: "0IFX.L", google: "LON:0IFX", currency: "GBP" },
			],
		},
		{
			name: "Expedia Group Inc.",
			symbol: "EXPE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Leisure & Recreation",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "E3X1.F", google: "FRA:E3X1", currency: "EUR" },
				{ yahoo: "EXPE", google: "NASDAQ:EXPE", currency: "USD" },
				{ yahoo: "0R1T.L", google: "LON:0R1T", currency: "GBP" },
			],
		},
		{
			name: "Fastenal Company",
			symbol: "FAST",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "FAS.F", google: "FRA:FAS", currency: "EUR" },
				{ yahoo: "FAST", google: "NASDAQ:FAST", currency: "USD" },
				{ yahoo: "0IKW.L", google: "LON:0IKW", currency: "GBP" },
			],
		},
		// {
		// 	name: "Fiserv Inc.",
		// 	symbol: "FISV",
		// 	country: "United States",
		// 	indices: ["NASDAQ 100", "S&P 500"],
		// 	industries: [
		// 		"Business Support Services",
		// 		"Industrials",
		// 		"Professional & Commercial Services",
		// 		"Industrial & Commercial Services",
		// 	],
		// 	symbols: [
		// 		{ yahoo: "FIV.F", google: "FRA:FIV", currency: "EUR" },
		// 		{ yahoo: "FISV", google: "NASDAQ:FISV", currency: "USD" },
		// 		{ yahoo: "0IP9.L", google: "LON:0IP9", currency: "GBP" },
		// 		{ yahoo: "FI", google: "NYSE:FI", currency: "USD" },
		// 	],
		// 	metadata: { founded: 1984, employees: 44000 },
		// 	isins: [],
		// 	akas: [],
		// },
		{
			name: "Gilead Sciences Inc.",
			symbol: "GILD",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Biotechnology & Medical Research",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "GIS.F", google: "FRA:GIS", currency: "EUR" },
				{ yahoo: "GILD", google: "NASDAQ:GILD", currency: "USD" },
			],
		},
		{
			name: "Henry Schein Inc.",
			symbol: "HSIC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "HS2.F", google: "FRA:HS2", currency: "EUR" },
				{ yahoo: "HSIC", google: "NASDAQ:HSIC", currency: "USD" },
				{ yahoo: "0L3C.L", google: "LON:0L3C", currency: "GBP" },
			],
		},
		{
			name: "Illumina Inc.",
			symbol: "ILMN",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "ILU.F", google: "FRA:ILU", currency: "EUR" },
				{ yahoo: "ILMN", google: "NASDAQ:ILMN", currency: "USD" },
				{ yahoo: "0J8Z.L", google: "LON:0J8Z", currency: "GBP" },
			],
		},
		{
			name: "Incyte Corporation",
			symbol: "INCY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Biotechnology & Medical Research",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "ICY.F", google: "FRA:ICY", currency: "EUR" },
				{ yahoo: "INCY", google: "NASDAQ:INCY", currency: "USD" },
				{ yahoo: "0J9P.L", google: "LON:0J9P", currency: "GBP" },
			],
		},
		{
			name: "Intuit Inc.",
			symbol: "INTU",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "ITU.F", google: "FRA:ITU", currency: "EUR" },
				{ yahoo: "INTU", google: "NASDAQ:INTU", currency: "USD" },
				{ yahoo: "0JCT.L", google: "LON:0JCT", currency: "GBP" },
			],
		},
		{
			name: "Intuitive Surgical Inc.",
			symbol: "ISRG",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "IUI1.F", google: "FRA:IUI1", currency: "EUR" },
				{ yahoo: "ISRG", google: "NASDAQ:ISRG", currency: "USD" },
				{ yahoo: "0R29.L", google: "LON:0R29", currency: "GBP" },
			],
		},
		{
			name: "JD.com Inc.",
			symbol: "JD",
			country: "China",
			indices: ["NASDAQ 100"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "013A.F", google: "FRA:013A", currency: "EUR" },
				{ yahoo: "JD", google: "NASDAQ:JD", currency: "USD" },
				{ yahoo: "JDCMF", google: "OTCMKTS:JDCMF", currency: "USD" },
				{ yahoo: "013C.F", google: "FRA:013C", currency: "EUR" },
			],
		},
		{
			name: "The Kraft Heinz Company",
			symbol: "KHC",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "KHNZ.F", google: "FRA:KHNZ", currency: "EUR" },
				{ yahoo: "KHC", google: "NASDAQ:KHC", currency: "USD" },
				{ yahoo: "0JRV.L", google: "LON:0JRV", currency: "GBP" },
			],
		},
		{
			name: "Lam Research Corporation",
			symbol: "LRCX",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Semiconductor Equipment & Testing",
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
			],
			symbols: [
				{ yahoo: "LAR.F", google: "FRA:LAR", currency: "EUR" },
				{ yahoo: "LRCX", google: "NASDAQ:LRCX", currency: "USD" },
				{ yahoo: "0JT5.L", google: "LON:0JT5", currency: "GBP" },
			],
		},
		{
			name: "Marriott International Inc.",
			symbol: "MAR",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Cyclical Consumer Services",
				"Hotels, Motels & Cruise Lines",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "MAQ.F", google: "FRA:MAQ", currency: "EUR" },
				{ yahoo: "MAR", google: "NASDAQ:MAR", currency: "USD" },
				{ yahoo: "0JYW.L", google: "LON:0JYW", currency: "GBP" },
				{ yahoo: "0P0000KQL0", google: "OTCMKTS:0P0000KQL0", currency: "USD" },
				{ yahoo: "0P0001I1JH", google: "OTCMKTS:0P0001I1JH", currency: "USD" },
			],
		},
		{
			name: "Microchip Technology Inc.",
			symbol: "MCHP",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "MCP.F", google: "FRA:MCP", currency: "EUR" },
				{ yahoo: "MCHP", google: "NASDAQ:MCHP", currency: "USD" },
				{ yahoo: "0K19.L", google: "LON:0K19", currency: "GBP" },
			],
		},
		{
			name: "Micron Technology Inc.",
			symbol: "MU",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "MTE.F", google: "FRA:MTE", currency: "EUR" },
				{ yahoo: "MU", google: "NASDAQ:MU", currency: "USD" },
				{ yahoo: "0R2T.L", google: "LON:0R2T", currency: "GBP" },
			],
		},
		{
			name: "Mondelez International Inc.",
			symbol: "MDLZ",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "KTF.F", google: "FRA:KTF", currency: "EUR" },
				{ yahoo: "MDLZ", google: "NASDAQ:MDLZ", currency: "USD" },
				{ yahoo: "0R0G.L", google: "LON:0R0G", currency: "GBP" },
			],
		},
		{
			name: "Monster Beverage Corporation",
			symbol: "MNST",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Beverages",
				"Non-Alcoholic Beverages",
			],
			symbols: [
				{ yahoo: "MOB.F", google: "FRA:MOB", currency: "EUR" },
				{ yahoo: "MNST", google: "NASDAQ:MNST", currency: "USD" },
				{ yahoo: "0K34.L", google: "LON:0K34", currency: "GBP" },
			],
		},
		{
			name: "NetApp Inc.",
			symbol: "NTAP",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Computers, Phones & Household Electronics",
				"Technology",
				"Technology Equipment",
				"Computer Hardware",
			],
			symbols: [
				{ yahoo: "NTA.F", google: "FRA:NTA", currency: "EUR" },
				{ yahoo: "NTAP", google: "NASDAQ:NTAP", currency: "USD" },
				{ yahoo: "0K6F.L", google: "LON:0K6F", currency: "GBP" },
			],
		},
		{
			name: "NetEase Inc.",
			symbol: "NTES",
			country: "China",
			indices: ["NASDAQ 100"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "NEH.F", google: "FRA:NEH", currency: "EUR" },
				{ yahoo: "NTES", google: "NASDAQ:NTES", currency: "USD" },
				{ yahoo: "NETTF", google: "OTCMKTS:NETTF", currency: "USD" },
				{ yahoo: "4Y01.F", google: "FRA:4Y01", currency: "EUR" },
				{ yahoo: "0K6G.L", google: "LON:0K6G", currency: "GBP" },
			],
		},
		{
			name: "Netflix Inc.",
			symbol: "NFLX",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "NFC.F", google: "FRA:NFC", currency: "EUR" },
				{ yahoo: "NFLX", google: "NASDAQ:NFLX", currency: "USD" },
				{ yahoo: "NFC1.F", google: "FRA:NFC1", currency: "EUR" },
			],
		},
		{
			name: "NVIDIA Corporation",
			symbol: "NVDA",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "NVD.F", google: "FRA:NVD", currency: "EUR" },
				{ yahoo: "NVDA", google: "NASDAQ:NVDA", currency: "USD" },
				{ yahoo: "NVDG.F", google: "FRA:NVDG", currency: "EUR" },
			],
		},
		{
			name: "NXP Semiconductors N.V.",
			symbol: "NXPI",
			country: "Netherlands",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "VNX.F", google: "FRA:VNX", currency: "EUR" },
				{ yahoo: "NXPI", google: "NASDAQ:NXPI", currency: "USD" },
				{ yahoo: "0EDE.L", google: "LON:0EDE", currency: "GBP" },
			],
		},
		{
			name: "O'Reilly Automotive Inc.",
			symbol: "ORLY",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Auto Vehicles, Parts & Service Retailers",
				"Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "OM6.F", google: "FRA:OM6", currency: "EUR" },
				{ yahoo: "ORLY", google: "NASDAQ:ORLY", currency: "USD" },
				{ yahoo: "0KAB.L", google: "LON:0KAB", currency: "GBP" },
			],
		},
		{
			name: "PACCAR Inc",
			symbol: "PCAR",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Heavy Machinery & Vehicles",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "PAE.F", google: "FRA:PAE", currency: "EUR" },
				{ yahoo: "PCAR", google: "NASDAQ:PCAR", currency: "USD" },
				{ yahoo: "0KET.L", google: "LON:0KET", currency: "GBP" },
			],
		},
		{
			name: "Paychex Inc.",
			symbol: "PAYX",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Industrials",
				"Professional & Commercial Services",
				"Employment Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "PCX.F", google: "FRA:PCX", currency: "EUR" },
				{ yahoo: "PAYX", google: "NASDAQ:PAYX", currency: "USD" },
				{ yahoo: "0KGE.L", google: "LON:0KGE", currency: "GBP" },
			],
		},
		{
			name: "PayPal Holdings Inc.",
			symbol: "PYPL",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "2PP.F", google: "FRA:2PP", currency: "EUR" },
				{ yahoo: "PYPL", google: "NASDAQ:PYPL", currency: "USD" },
				{ yahoo: "2PP0.F", google: "FRA:2PP0", currency: "EUR" },
			],
		},
		{
			name: "Qualcomm Inc.",
			symbol: "QCOM",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "QCI.F", google: "FRA:QCI", currency: "EUR" },
				{ yahoo: "QCOM", google: "NASDAQ:QCOM", currency: "USD" },
			],
		},
		{
			name: "Regeneron Pharmaceuticals Inc.",
			symbol: "REGN",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Biotechnology & Medical Research",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "RGO.F", google: "FRA:RGO", currency: "EUR" },
				{ yahoo: "REGN", google: "NASDAQ:REGN", currency: "USD" },
				{ yahoo: "0R2M.L", google: "LON:0R2M", currency: "GBP" },
			],
		},
		{
			name: "Ross Stores Inc.",
			symbol: "ROST",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Retailers",
				"Apparel & Accessories Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "RSO.F", google: "FRA:RSO", currency: "EUR" },
				{ yahoo: "ROST", google: "NASDAQ:ROST", currency: "USD" },
				{ yahoo: "0KXO.L", google: "LON:0KXO", currency: "GBP" },
			],
		},
		{
			name: "Seagate Technology PLC",
			symbol: "STX",
			country: "Ireland",
			indices: ["S&P 500"],
			industries: [
				"Computers, Phones & Household Electronics",
				"Technology",
				"Technology Equipment",
				"Computer Hardware",
			],
			symbols: [
				{ yahoo: "STT.F", google: "FRA:STT", currency: "EUR" },
				{ yahoo: "STX", google: "NASDAQ:STX", currency: "USD" },
			],
		},
		{
			name: "Sirius XM Holdings Inc.",
			symbol: "SIRI",
			country: "United States",
			indices: ["NASDAQ 100"],
			industries: [
				"Media & Publishing",
				"Cyclical Consumer Services",
				"Broadcasting",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "RDO.F", google: "FRA:RDO", currency: "EUR" },
				{ yahoo: "SIRI", google: "NASDAQ:SIRI", currency: "USD" },
				{ yahoo: "0L6Z.L", google: "LON:0L6Z", currency: "GBP" },
			],
		},
		{
			name: "Skyworks Solutions Inc.",
			symbol: "SWKS",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "AWM.F", google: "FRA:AWM", currency: "EUR" },
				{ yahoo: "SWKS", google: "NASDAQ:SWKS", currency: "USD" },
				{ yahoo: "0L77.L", google: "LON:0L77", currency: "GBP" },
			],
		},
		{
			name: "Starbucks Corporation",
			symbol: "SBUX",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Restaurants & Bars",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "SRB.F", google: "FRA:SRB", currency: "EUR" },
				{ yahoo: "SBUX", google: "NASDAQ:SBUX", currency: "USD" },
				{ yahoo: "SRB0.F", google: "FRA:SRB0", currency: "EUR" },
			],
		},
		{
			name: "T-Mobile US Inc.",
			symbol: "TMUS",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Telecommunications Services",
				"Wireless Telecommunications Services",
			],
			symbols: [
				{ yahoo: "TM5.F", google: "FRA:TM5", currency: "EUR" },
				{ yahoo: "TMUS", google: "NASDAQ:TMUS", currency: "USD" },
				{ yahoo: "0R2L.L", google: "LON:0R2L", currency: "GBP" },
			],
		},
		{
			name: "Tesla Inc.",
			symbol: "TSLA",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Auto & Truck Manufacturers",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "TL0.F", google: "FRA:TL0", currency: "EUR" },
				{ yahoo: "TSLA", google: "NASDAQ:TSLA", currency: "USD" },
			],
		},
		{
			name: "Texas Instruments Inc.",
			symbol: "TXN",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "TII.F", google: "FRA:TII", currency: "EUR" },
				{ yahoo: "TXN", google: "NASDAQ:TXN", currency: "USD" },
				{ yahoo: "0R2H.L", google: "LON:0R2H", currency: "GBP" },
			],
		},
		{
			name: "Tractor Supply Company",
			symbol: "TSCO",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Other Specialty Retailers",
				"Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "TR4.F", google: "FRA:TR4", currency: "EUR" },
				{ yahoo: "TSCO", google: "NASDAQ:TSCO", currency: "USD" },
			],
		},
		{
			name: "Ulta Beauty Inc.",
			symbol: "ULTA",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Other Specialty Retailers",
				"Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "34.F", google: "FRA:34", currency: "EUR" },
				{ yahoo: "ULTA", google: "NASDAQ:ULTA", currency: "USD" },
				{ yahoo: "34U.F", google: "FRA:34U", currency: "EUR" },
				{ yahoo: "0LIB.L", google: "LON:0LIB", currency: "GBP" },
			],
		},
		{
			name: "Verisk Analytics Inc.",
			symbol: "VRSK",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "VA7A.F", google: "FRA:VA7A", currency: "EUR" },
				{ yahoo: "VRSK", google: "NASDAQ:VRSK", currency: "USD" },
				{ yahoo: "0LP3.L", google: "LON:0LP3", currency: "GBP" },
			],
		},
		{
			name: "Vertex Pharmaceuticals Inc.",
			symbol: "VRTX",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Biotechnology & Medical Research",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "VX1.F", google: "FRA:VX1", currency: "EUR" },
				{ yahoo: "VRTX", google: "NASDAQ:VRTX", currency: "USD" },
				{ yahoo: "0QZU.L", google: "LON:0QZU", currency: "GBP" },
			],
		},
		{
			name: "Western Digital Corporation",
			symbol: "WDC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Computers, Phones & Household Electronics",
				"Technology",
				"Technology Equipment",
				"Computer Hardware",
			],
			symbols: [
				{ yahoo: "WDC.F", google: "FRA:WDC", currency: "EUR" },
				{ yahoo: "WDC", google: "NASDAQ:WDC", currency: "USD" },
				{ yahoo: "0QZF.L", google: "LON:0QZF", currency: "GBP" },
			],
		},
		{
			name: "Abbott Laboratories",
			symbol: "ABT",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "ABL.F", google: "FRA:ABL", currency: "EUR" },
				{ yahoo: "ABT", google: "NYSE:ABT", currency: "USD" },
			],
		},
		{
			name: "AbbVie Inc.",
			symbol: "ABBV",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "4AB.F", google: "FRA:4AB", currency: "EUR" },
				{ yahoo: "ABBV", google: "NYSE:ABBV", currency: "USD" },
				{ yahoo: "0QCV.L", google: "LON:0QCV", currency: "GBP" },
			],
		},
		{
			name: "Accenture PLC",
			symbol: "ACN",
			country: "Ireland",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "CSA.F", google: "FRA:CSA", currency: "EUR" },
				{ yahoo: "ACN", google: "NYSE:ACN", currency: "USD" },
				{ yahoo: "0Y0Y.L", google: "LON:0Y0Y", currency: "GBP" },
			],
		},
		{
			name: "The Allstate Corporation",
			symbol: "ALL",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance", "Property & Casualty Insurance", "Financials"],
			symbols: [
				{ yahoo: "ALS.F", google: "FRA:ALS", currency: "EUR" },
				{ yahoo: "ALL", google: "NYSE:ALL", currency: "USD" },
				{ yahoo: "0HCZ.L", google: "LON:0HCZ", currency: "GBP" },
			],
		},
		{
			name: "Altria Group Inc.",
			symbol: "MO",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Tobacco",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "PHM7.F", google: "FRA:PHM7", currency: "EUR" },
				{ yahoo: "MO", google: "NYSE:MO", currency: "USD" },
			],
		},
		{
			name: "American International Group Inc.",
			symbol: "AIG",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "AINN.F", google: "FRA:AINN", currency: "EUR" },
				{ yahoo: "AIG", google: "NYSE:AIG", currency: "USD" },
				{ yahoo: "0OAL.L", google: "LON:0OAL", currency: "GBP" },
			],
		},
		{
			name: "AT&T Inc",
			symbol: "T",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Telecommunications Services",
				"Wireless Telecommunications Services",
			],
			symbols: [
				{ yahoo: "SOBA.F", google: "FRA:SOBA", currency: "EUR" },
				{ yahoo: "T", google: "NYSE:T", currency: "USD" },
			],
		},
		{
			name: "Bank of America Corporation",
			symbol: "BAC",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "NCB.F", google: "FRA:NCB", currency: "EUR" },
				{ yahoo: "BAC", google: "NYSE:BAC", currency: "USD" },
				{ yahoo: "BOAPL", google: "OTCMKTS:BOAPL", currency: "USD" },
			],
		},
		{
			name: "The Bank of New York Mellon Corporation",
			symbol: "BK",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "BN9.F", google: "FRA:BN9", currency: "EUR" },
				{ yahoo: "BK", google: "NYSE:BK", currency: "USD" },
				{ yahoo: "0HLQ.L", google: "LON:0HLQ", currency: "GBP" },
			],
		},
		{
			name: "Berkshire Hathaway Inc.",
			symbol: "BRK-B",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "BRYN.F", google: "FRA:BRYN", currency: "EUR" },
				{ yahoo: "BRK-B", google: "NYSE:BRK.B", currency: "USD" },
				{ yahoo: "BRH.F", google: "FRA:BRH", currency: "EUR" },
			],
		},
		{
			name: "BlackRock Inc.",
			symbol: "BLK",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "BLQA.F", google: "FRA:BLQA", currency: "EUR" },
				{ yahoo: "BLK", google: "NYSE:BLK", currency: "USD" },
				{ yahoo: "0QZZ.L", google: "LON:0QZZ", currency: "GBP" },
			],
		},
		{
			name: "Booking Holdings Inc.",
			symbol: "BKNG",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Leisure & Recreation",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "PCE1.F", google: "FRA:PCE1", currency: "EUR" },
				{ yahoo: "BKNG", google: "NASDAQ:BKNG", currency: "USD" },
				{ yahoo: "0W2Y.L", google: "LON:0W2Y", currency: "GBP" },
			],
		},
		{
			name: "Bristol-Myers Squibb Company",
			symbol: "BMY",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "BRM.F", google: "FRA:BRM", currency: "EUR" },
				{ yahoo: "BMY", google: "NYSE:BMY", currency: "USD" },
				{ yahoo: "BMYMP", google: "OTCMKTS:BMYMP", currency: "USD" },
			],
		},
		{
			name: "Capital One Financial Corporation",
			symbol: "COF",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Consumer Lending",
			],
			symbols: [
				{ yahoo: "CFX.F", google: "FRA:CFX", currency: "EUR" },
				{ yahoo: "COF", google: "NYSE:COF", currency: "USD" },
				{ yahoo: "0HT4.L", google: "LON:0HT4", currency: "GBP" },
				{ yahoo: "CFXE.F", google: "FRA:CFXE", currency: "EUR" },
				{ yahoo: "CFX1.F", google: "FRA:CFX1", currency: "EUR" },
			],
		},
		{
			name: "Citigroup Inc.",
			symbol: "C",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "TRVC.F", google: "FRA:TRVC", currency: "EUR" },
				{ yahoo: "C", google: "NYSE:C", currency: "USD" },
			],
		},
		{
			name: "Colgate-Palmolive Company",
			symbol: "CL",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Personal Products",
				"Personal & Household Products & Services",
			],
			symbols: [
				{ yahoo: "CPA.F", google: "FRA:CPA", currency: "EUR" },
				{ yahoo: "CL", google: "NYSE:CL", currency: "USD" },
				{ yahoo: "0P59.L", google: "LON:0P59", currency: "GBP" },
			],
		},
		{
			name: "ConocoPhillips",
			symbol: "COP",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas",
				"Oil & Gas Exploration and Production",
			],
			symbols: [
				{ yahoo: "YCP.F", google: "FRA:YCP", currency: "EUR" },
				{ yahoo: "COP", google: "NYSE:COP", currency: "USD" },
				{ yahoo: "0QZA.L", google: "LON:0QZA", currency: "GBP" },
			],
		},
		{
			name: "CVS Health Corporation",
			symbol: "CVS",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Facilities & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "CVS.F", google: "FRA:CVS", currency: "EUR" },
				{ yahoo: "CVS", google: "NYSE:CVS", currency: "USD" },
				{ yahoo: "0HRS.L", google: "LON:0HRS", currency: "GBP" },
			],
		},
		{
			name: "Danaher Corporation",
			symbol: "DHR",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "DAP.F", google: "FRA:DAP", currency: "EUR" },
				{ yahoo: "DHR", google: "NYSE:DHR", currency: "USD" },
				{ yahoo: "0R2B.L", google: "LON:0R2B", currency: "GBP" },
			],
		},
		{
			name: "Duke Energy Corporation",
			symbol: "DUK",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "D2MN.F", google: "FRA:D2MN", currency: "EUR" },
				{ yahoo: "DUK", google: "NYSE:DUK", currency: "USD" },
				{ yahoo: "0ID1.L", google: "LON:0ID1", currency: "GBP" },
			],
		},
		{
			name: "Eli Lilly and Company",
			symbol: "LLY",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "LLY.F", google: "FRA:LLY", currency: "EUR" },
				{ yahoo: "LLY", google: "NYSE:LLY", currency: "USD" },
				{ yahoo: "0Q1G.L", google: "LON:0Q1G", currency: "GBP" },
			],
		},
		{
			name: "Emerson Electric Co.",
			symbol: "EMR",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Electrical Components & Equipment",
			],
			symbols: [
				{ yahoo: "EMR.F", google: "FRA:EMR", currency: "EUR" },
				{ yahoo: "EMR", google: "NYSE:EMR", currency: "USD" },
				{ yahoo: "0R33.L", google: "LON:0R33", currency: "GBP" },
			],
		},
		{
			name: "Exelon Corporation",
			symbol: "EXC",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "PEO.F", google: "FRA:PEO", currency: "EUR" },
				{ yahoo: "EXC", google: "NASDAQ:EXC", currency: "USD" },
				{ yahoo: "0IJN.L", google: "LON:0IJN", currency: "GBP" },
			],
		},
		{
			name: "FedEx Corporation",
			symbol: "FDX",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Industrials",
				"Air Freight & Courier Services",
				"Freight & Logistics Services",
				"Transportation",
			],
			symbols: [
				{ yahoo: "FDX.F", google: "FRA:FDX", currency: "EUR" },
				{ yahoo: "FDX", google: "NYSE:FDX", currency: "USD" },
				{ yahoo: "0QZX.L", google: "LON:0QZX", currency: "GBP" },
			],
		},
		{
			name: "Ford Motor Company",
			symbol: "F",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Auto & Truck Manufacturers",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "FMC1.F", google: "FRA:FMC1", currency: "EUR" },
				{ yahoo: "F", google: "NYSE:F", currency: "USD" },
			],
		},
		{
			name: "General Electric Company",
			symbol: "GE",
			country: "United States",
			indices: ["CAC 40", "S&P 100", "S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "GEC.F", google: "FRA:GEC", currency: "EUR" },
				{ yahoo: "GE", google: "NYSE:GE", currency: "USD" },
				{ yahoo: "GCP.F", google: "FRA:GCP", currency: "EUR" },
				{ yahoo: "GEC.L", google: "LON:GEC", currency: "GBP" },
				{ yahoo: "49P.F", google: "FRA:49P", currency: "EUR" },
			],
		},
		{
			name: "General Motors Company",
			symbol: "GM",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Telecommunications Services",
				"Discount Stores",
				"Consumer Non-Cyclicals",
				"Retailers",
				"Integrated Telecommunications Services",
				"Food & Tobacco",
				"Food & Beverages",
				"Food Processing",
				"Diversified Retail",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "8GM.F", google: "FRA:8GM", currency: "EUR" },
				{ yahoo: "GM", google: "NYSE:GM", currency: "USD" },
				{ yahoo: "0R0E.L", google: "LON:0R0E", currency: "GBP" },
			],
		},
		{
			name: "Honeywell International Inc.",
			symbol: "HON",
			country: "United States",
			indices: ["DOW JONES", "NASDAQ 100", "S&P 100", "S&P 500"],
			industries: ["Industrials", "Industrial Conglomerates"],
			symbols: [
				{ yahoo: "ALD.F", google: "FRA:ALD", currency: "EUR" },
				{ yahoo: "HON", google: "NASDAQ:HON", currency: "USD" },
				{ yahoo: "HON.L", google: "LON:HON", currency: "GBP" },
				{ yahoo: "ALDB.F", google: "FRA:ALDB", currency: "EUR" },
			],
		},
		{
			name: "Kinder Morgan Inc.",
			symbol: "KMI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas Transportation Services",
				"Oil & Gas Related Equipment and Services",
			],
			symbols: [
				{ yahoo: "2KD.F", google: "FRA:2KD", currency: "EUR" },
				{ yahoo: "KMI", google: "NYSE:KMI", currency: "USD" },
				{ yahoo: "0JR2.L", google: "LON:0JR2", currency: "GBP" },
			],
		},
		{
			name: "Lowe's Companies Inc.",
			symbol: "LOW",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Retailers",
				"Specialty Retailers",
				"Home Improvement Products & Services Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "LWE.F", google: "FRA:LWE", currency: "EUR" },
				{ yahoo: "LOW", google: "NYSE:LOW", currency: "USD" },
				{ yahoo: "0JVQ.L", google: "LON:0JVQ", currency: "GBP" },
			],
		},
		{
			name: "Mastercard Inc.",
			symbol: "MA",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "M4I.F", google: "FRA:M4I", currency: "EUR" },
				{ yahoo: "MA", google: "NYSE:MA", currency: "USD" },
				{ yahoo: "M4I0.F", google: "FRA:M4I0", currency: "EUR" },
			],
		},
		{
			name: "Medtronic PLC",
			symbol: "MDT",
			country: "Ireland",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "2M6.F", google: "FRA:2M6", currency: "EUR" },
				{ yahoo: "MDT", google: "NYSE:MDT", currency: "USD" },
				{ yahoo: "0Y6X.L", google: "LON:0Y6X", currency: "GBP" },
			],
		},
		{
			name: "MetLife Inc.",
			symbol: "MET",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: ["Insurance", "Financials", "Life & Health Insurance"],
			symbols: [
				{ yahoo: "MWZ.F", google: "FRA:MWZ", currency: "EUR" },
				{ yahoo: "MET", google: "NYSE:MET", currency: "USD" },
				{ yahoo: "0K0X.L", google: "LON:0K0X", currency: "GBP" },
			],
		},
		{
			name: "Morgan Stanley",
			symbol: "MS",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Banking & Brokerage Services",
			],
			symbols: [
				{ yahoo: "DWD.F", google: "FRA:DWD", currency: "EUR" },
				{ yahoo: "MS", google: "NYSE:MS", currency: "USD" },
				{ yahoo: "MNSLV", google: "OTCMKTS:MNSLV", currency: "USD" },
			],
		},
		{
			name: "NextEra Energy Inc.",
			symbol: "NEE",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "FP3.F", google: "FRA:FP3", currency: "EUR" },
				{ yahoo: "NEE", google: "NYSE:NEE", currency: "USD" },
				{ yahoo: "0K80.L", google: "LON:0K80", currency: "GBP" },
				{ yahoo: "NEEXU", google: "OTCMKTS:NEEXU", currency: "USD" },
			],
		},
		{
			name: "Occidental Petroleum Corporation",
			symbol: "OXY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas",
				"Oil & Gas Exploration and Production",
			],
			symbols: [
				{ yahoo: "OPC.F", google: "FRA:OPC", currency: "EUR" },
				{ yahoo: "OXY", google: "NYSE:OXY", currency: "USD" },
				{ yahoo: "0KAK.L", google: "LON:0KAK", currency: "GBP" },
			],
		},
		{
			name: "Oracle Corporation",
			symbol: "ORCL",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "ORC.F", google: "FRA:ORC", currency: "EUR" },
				{ yahoo: "ORCL", google: "NYSE:ORCL", currency: "USD" },
				{ yahoo: "OCJ.F", google: "FRA:OCJ", currency: "EUR" },
				{ yahoo: "OCLCF", google: "OTCMKTS:OCLCF", currency: "USD" },
			],
		},
		{
			name: "Philip Morris International Inc.",
			symbol: "PM",
			country: "Netherlands",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "4I1.F", google: "FRA:4I1", currency: "EUR" },
				{ yahoo: "PM", google: "NYSE:PM", currency: "USD" },
			],
		},
		// {
		// 	name: "Raytheon Company",
		// 	symbol: "RTN",
		// 	country: "United States",
		// 	indices: ["S&P 100", "S&P 500"],
		// 	industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
		// 	symbols: [
		// 		{ yahoo: "RTN1.F", google: "FRA:RTN1", currency: "EUR" },
		// 		{ yahoo: "0R2N.L", google: "LON:0R2N", currency: "GBP" },
		// 		{ yahoo: "5UR.F", google: "FRA:5UR", currency: "EUR" },
		// 		{ yahoo: "RTX", google: "NYSE:RTX", currency: "USD" },
		// 	],
		// 	metadata: { founded: 2020, employees: 195000 },
		// 	isins: [],
		// 	akas: [],
		// },
		{
			name: "Schlumberger Limited",
			symbol: "SLB",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil Related Services and Equipment",
				"Oil & Gas Related Equipment and Services",
			],
			symbols: [
				{ yahoo: "SCL.F", google: "FRA:SCL", currency: "EUR" },
				{ yahoo: "SLB", google: "NYSE:SLB", currency: "USD" },
			],
		},
		{
			name: "Simon Property Group Inc.",
			symbol: "SPG",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "SQI.F", google: "FRA:SQI", currency: "EUR" },
				{ yahoo: "SPG", google: "NYSE:SPG", currency: "USD" },
				{ yahoo: "0L6P.L", google: "LON:0L6P", currency: "GBP" },
			],
		},
		{
			name: "Southern Company",
			symbol: "SO",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "SOT.F", google: "FRA:SOT", currency: "EUR" },
				{ yahoo: "SO", google: "NYSE:SO", currency: "USD" },
				{ yahoo: "0L8A.L", google: "LON:0L8A", currency: "GBP" },
			],
		},
		{
			name: "Target Corporation",
			symbol: "TGT",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Diversified Retail",
				"Retailers",
				"Discount Stores",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "DYH.F", google: "FRA:DYH", currency: "EUR" },
				{ yahoo: "TGT", google: "NYSE:TGT", currency: "USD" },
				{ yahoo: "0LD8.L", google: "LON:0LD8", currency: "GBP" },
			],
		},
		{
			name: "Union Pacific Corporation",
			symbol: "UNP",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Ground Freight & Logistics",
				"Industrials",
				"Freight & Logistics Services",
				"Transportation",
			],
			symbols: [
				{ yahoo: "UNP.F", google: "FRA:UNP", currency: "EUR" },
				{ yahoo: "UNP", google: "NYSE:UNP", currency: "USD" },
				{ yahoo: "0R2E.L", google: "LON:0R2E", currency: "GBP" },
			],
		},
		{
			name: "United Parcel Service Inc.",
			symbol: "UPS",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
			symbols: [
				{ yahoo: "UPAB.F", google: "FRA:UPAB", currency: "EUR" },
				{ yahoo: "UPS", google: "NYSE:UPS", currency: "USD" },
				{ yahoo: "0R08.L", google: "LON:0R08", currency: "GBP" },
			],
		},
		{
			name: "U.S. Bancorp",
			symbol: "USB",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "UB5.F", google: "FRA:UB5", currency: "EUR" },
				{ yahoo: "USB", google: "NYSE:USB", currency: "USD" },
				{ yahoo: "0LHY.L", google: "LON:0LHY", currency: "GBP" },
			],
		},
		{
			name: "Wells Fargo & Company",
			symbol: "WFC",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "NWT.F", google: "FRA:NWT", currency: "EUR" },
				{ yahoo: "WFC", google: "NYSE:WFC", currency: "USD" },
			],
		},
		{
			name: "Abiomed Inc.",
			symbol: "ABMD",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "AIO.F", google: "FRA:AIO", currency: "EUR" },
				{ yahoo: "ABMD", google: "NASDAQ:ABMD", currency: "USD" },
				{ yahoo: "0H7S.L", google: "LON:0H7S", currency: "GBP" },
			],
		},
		{
			name: "Advance Auto Parts Inc.",
			symbol: "AAP",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Auto Vehicles, Parts & Service Retailers",
				"Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "AWN.F", google: "FRA:AWN", currency: "EUR" },
				{ yahoo: "AAP", google: "NYSE:AAP", currency: "USD" },
				{ yahoo: "0H9G.L", google: "LON:0H9G", currency: "GBP" },
			],
		},
		{
			name: "Advanced Micro Devices Inc.",
			symbol: "AMD",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Environmental Services & Equipment",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "AMD.F", google: "FRA:AMD", currency: "EUR" },
				{ yahoo: "AMD", google: "NASDAQ:AMD", currency: "USD" },
			],
			metadata: {
				founded: 1969,
				employees:
					"{{wikidata|properties|qualifier|references|normal+|employees}}",
			},
			isins: [],
			akas: [],
		},
		{
			name: "The AES Corporation",
			symbol: "AES",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "AES.F", google: "FRA:AES", currency: "EUR" },
				{ yahoo: "AES", google: "NYSE:AES", currency: "USD" },
				{ yahoo: "0H6G.L", google: "LON:0H6G", currency: "GBP" },
			],
		},
		{
			name: "Aflac Inc.",
			symbol: "AFL",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance", "Financials", "Life & Health Insurance"],
			symbols: [
				{ yahoo: "AFL.F", google: "FRA:AFL", currency: "EUR" },
				{ yahoo: "AFL", google: "NYSE:AFL", currency: "USD" },
				{ yahoo: "0H68.L", google: "LON:0H68", currency: "GBP" },
			],
		},
		{
			name: "Agilent Technologies Inc.",
			symbol: "A",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "AG8.F", google: "FRA:AG8", currency: "EUR" },
				{ yahoo: "A", google: "NYSE:A", currency: "USD" },
				{ yahoo: "0HAV.L", google: "LON:0HAV", currency: "GBP" },
			],
		},
		{
			name: "Air Products and Chemicals Inc.",
			symbol: "APD",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "AP3.F", google: "FRA:AP3", currency: "EUR" },
				{ yahoo: "APD", google: "NYSE:APD", currency: "USD" },
				{ yahoo: "0HBH.L", google: "LON:0HBH", currency: "GBP" },
			],
		},
		{
			name: "Alaska Air Group Inc.",
			symbol: "ALK",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Passenger Transportation Services",
				"Industrials",
				"Airlines",
				"Transportation",
			],
			symbols: [
				{ yahoo: "ALK.F", google: "FRA:ALK", currency: "EUR" },
				{ yahoo: "ALK", google: "NYSE:ALK", currency: "USD" },
				{ yahoo: "0HC3.L", google: "LON:0HC3", currency: "GBP" },
			],
		},
		{
			name: "Albemarle Corporation",
			symbol: "ALB",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "AMC.F", google: "FRA:AMC", currency: "EUR" },
				{ yahoo: "ALB", google: "NYSE:ALB", currency: "USD" },
				{ yahoo: "0HC7.L", google: "LON:0HC7", currency: "GBP" },
			],
		},
		{
			name: "Alexandria Real Estate Equities Inc.",
			symbol: "ARE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "A6W.F", google: "FRA:A6W", currency: "EUR" },
				{ yahoo: "ARE", google: "NYSE:ARE", currency: "USD" },
				{ yahoo: "0HCH.L", google: "LON:0HCH", currency: "GBP" },
			],
		},
		{
			name: "Align Technology Inc.",
			symbol: "ALGN",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "AFW.F", google: "FRA:AFW", currency: "EUR" },
				{ yahoo: "ALGN", google: "NASDAQ:ALGN", currency: "USD" },
				{ yahoo: "0HCK.L", google: "LON:0HCK", currency: "GBP" },
			],
		},
		{
			name: "Allegion PLC",
			symbol: "ALLE",
			country: "Ireland",
			indices: ["S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Communications & Networking",
			],
			symbols: [
				{ yahoo: "60A.F", google: "FRA:60A", currency: "EUR" },
				{ yahoo: "ALLE", google: "NYSE:ALLE", currency: "USD" },
				{ yahoo: "0Y5C.L", google: "LON:0Y5C", currency: "GBP" },
			],
		},
		{
			name: "Ameren Corporation",
			symbol: "AEE",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Multiline Utilities", "Utilities"],
			symbols: [
				{ yahoo: "AE4.F", google: "FRA:AE4", currency: "EUR" },
				{ yahoo: "AEE", google: "NYSE:AEE", currency: "USD" },
				{ yahoo: "0HE2.L", google: "LON:0HE2", currency: "GBP" },
			],
		},
		{
			name: "American Electric Power Company Inc.",
			symbol: "AEP",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "AEP.F", google: "FRA:AEP", currency: "EUR" },
				{ yahoo: "AEP", google: "NASDAQ:AEP", currency: "USD" },
				{ yahoo: "0HEC.L", google: "LON:0HEC", currency: "GBP" },
			],
		},
		{
			name: "American Tower Corporation",
			symbol: "AMT",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Specialized REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "A0T.F", google: "FRA:A0T", currency: "EUR" },
				{ yahoo: "AMT", google: "NYSE:AMT", currency: "USD" },
				{ yahoo: "0HEU.L", google: "LON:0HEU", currency: "GBP" },
			],
		},
		{
			name: "American Water Works Company Inc.",
			symbol: "AWK",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Water Utilities", "Utilities"],
			symbols: [
				{ yahoo: "AWC.F", google: "FRA:AWC", currency: "EUR" },
				{ yahoo: "AWK", google: "NYSE:AWK", currency: "USD" },
				{ yahoo: "0HEW.L", google: "LON:0HEW", currency: "GBP" },
			],
		},
		{
			name: "Ameriprise Financial Inc.",
			symbol: "AMP",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "A4S.F", google: "FRA:A4S", currency: "EUR" },
				{ yahoo: "AMP", google: "NYSE:AMP", currency: "USD" },
				{ yahoo: "0HF6.L", google: "LON:0HF6", currency: "GBP" },
			],
		},
		{
			name: "AmerisourceBergen Corporation",
			symbol: "ABC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Drug Retailers",
			],
			symbols: [
				{ yahoo: "ABG.F", google: "FRA:ABG", currency: "EUR" },
				{ yahoo: "ABC", google: "NYSE:ABC", currency: "USD" },
				{ yahoo: "0HF3.L", google: "LON:0HF3", currency: "GBP" },
			],
		},
		{
			name: "AMETEK Inc.",
			symbol: "AME",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Electrical Components & Equipment",
			],
			symbols: [
				{ yahoo: "AK1.F", google: "FRA:AK1", currency: "EUR" },
				{ yahoo: "AME", google: "NYSE:AME", currency: "USD" },
				{ yahoo: "0HF7.L", google: "LON:0HF7", currency: "GBP" },
			],
		},
		{
			name: "Amphenol Corporation",
			symbol: "APH",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Electronic Equipments & Parts",
			],
			symbols: [
				{ yahoo: "XPH.F", google: "FRA:XPH", currency: "EUR" },
				{ yahoo: "APH", google: "NYSE:APH", currency: "USD" },
				{ yahoo: "0HFB.L", google: "LON:0HFB", currency: "GBP" },
			],
		},
		{
			name: "ANSYS Inc.",
			symbol: "ANSS",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "AKX.F", google: "FRA:AKX", currency: "EUR" },
				{ yahoo: "ANSS", google: "NASDAQ:ANSS", currency: "USD" },
				{ yahoo: "0HG3.L", google: "LON:0HG3", currency: "GBP" },
			],
		},
		{
			name: "A. O. Smith Corporation",
			symbol: "AOS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Electrical Components & Equipment",
			],
			symbols: [
				{ yahoo: "3SM.F", google: "FRA:3SM", currency: "EUR" },
				{ yahoo: "AOS", google: "NYSE:AOS", currency: "USD" },
				{ yahoo: "0L7A.L", google: "LON:0L7A", currency: "GBP" },
			],
		},
		{
			name: "Aon PLC",
			symbol: "AON",
			country: "United Kingdom",
			indices: ["S&P 500"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "9H6.F", google: "FRA:9H6", currency: "EUR" },
				{ yahoo: "AON", google: "NYSE:AON", currency: "USD" },
				{ yahoo: "0XHL.L", google: "LON:0XHL", currency: "GBP" },
				{ yahoo: "4VK.F", google: "FRA:4VK", currency: "EUR" },
			],
		},
		{
			name: "Apache Corporation",
			symbol: "APA",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas",
				"Oil & Gas Exploration and Production",
			],
			symbols: [
				{ yahoo: "APA.F", google: "FRA:APA", currency: "EUR" },
				{ yahoo: "APA", google: "NASDAQ:APA", currency: "USD" },
				{ yahoo: "0HGC.L", google: "LON:0HGC", currency: "GBP" },
			],
		},
		{
			name: "Aptiv PLC",
			symbol: "APTV",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "D7A.F", google: "FRA:D7A", currency: "EUR" },
				{ yahoo: "APTV", google: "NYSE:APTV", currency: "USD" },
				{ yahoo: "0YCP.L", google: "LON:0YCP", currency: "GBP" },
			],
		},
		{
			name: "Archer-Daniels-Midland Company",
			symbol: "ADM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "ADM.F", google: "FRA:ADM", currency: "EUR" },
				{ yahoo: "ADM", google: "NYSE:ADM", currency: "USD" },
				{ yahoo: "0JQQ.L", google: "LON:0JQQ", currency: "GBP" },
			],
		},
		{
			name: "Arista Networks Inc.",
			symbol: "ANET",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Communications & Networking",
			],
			symbols: [
				{ yahoo: "117.F", google: "FRA:117", currency: "EUR" },
				{ yahoo: "ANET", google: "NYSE:ANET", currency: "USD" },
				{ yahoo: "0HHR.L", google: "LON:0HHR", currency: "GBP" },
			],
		},
		{
			name: "Arthur J. Gallagher & Co.",
			symbol: "AJG",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "GAH.F", google: "FRA:GAH", currency: "EUR" },
				{ yahoo: "AJG", google: "NYSE:AJG", currency: "USD" },
				{ yahoo: "0ITL.L", google: "LON:0ITL", currency: "GBP" },
			],
		},
		{
			name: "Assurant Inc.",
			symbol: "AIZ",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "AIZ.F", google: "FRA:AIZ", currency: "EUR" },
				{ yahoo: "AIZ", google: "NYSE:AIZ", currency: "USD" },
				{ yahoo: "0HIN.L", google: "LON:0HIN", currency: "GBP" },
				{ yahoo: "ZAS.F", google: "FRA:ZAS", currency: "EUR" },
			],
		},
		{
			name: "Atmos Energy Corporation",
			symbol: "ATO",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Natural Gas Utilities", "Utilities"],
			symbols: [
				{ yahoo: "AEO.F", google: "FRA:AEO", currency: "EUR" },
				{ yahoo: "ATO", google: "NYSE:ATO", currency: "USD" },
			],
		},
		{
			name: "AutoZone Inc.",
			symbol: "AZO",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Auto Vehicles, Parts & Service Retailers",
				"Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "AZ5.F", google: "FRA:AZ5", currency: "EUR" },
				{ yahoo: "AZO", google: "NYSE:AZO", currency: "USD" },
				{ yahoo: "0HJL.L", google: "LON:0HJL", currency: "GBP" },
			],
		},
		{
			name: "AvalonBay Communities Inc.",
			symbol: "AVB",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Real Estate",
				"Financials",
				"Residential REITs",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "WV8.F", google: "FRA:WV8", currency: "EUR" },
				{ yahoo: "AVB", google: "NYSE:AVB", currency: "USD" },
				{ yahoo: "0HJO.L", google: "LON:0HJO", currency: "GBP" },
			],
		},
		{
			name: "Avery Dennison Corporation",
			symbol: "AVY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Non-Paper Containers & Packaging",
				"Basic Materials",
				"Containers & Packaging",
				"Applied Resources",
			],
			symbols: [
				{ yahoo: "AV3.F", google: "FRA:AV3", currency: "EUR" },
				{ yahoo: "AVY", google: "NYSE:AVY", currency: "USD" },
				{ yahoo: "0HJR.L", google: "LON:0HJR", currency: "GBP" },
			],
		},
		{
			name: "Baker Hughes Company",
			wiki_name: "Baker Hughes Company",
			symbol: "BKR",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Hydrocarbon exploration"],
			symbols: [
				{ yahoo: "BKR", google: "NYSE:BKR", currency: "USD" },
				{ yahoo: "68V.F", google: "FRA:68V", currency: "EUR" },
				{ yahoo: "0RR8.L", google: "LON:0RR8", currency: "GBP" },
			],
			isins: ["US05722G1004"],
			metadata: { founded: 1907, employees: 60000 },
			akas: [],
		},
		{
			name: "Ball Corporation",
			symbol: "BLL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Non-Paper Containers & Packaging",
				"Basic Materials",
				"Containers & Packaging",
				"Applied Resources",
			],
			symbols: [
				{ yahoo: "BL8.F", google: "FRA:BL8", currency: "EUR" },
				{ yahoo: "BLL", google: "NYSE:BLL", currency: "USD" },
				{ yahoo: "0HL5.L", google: "LON:0HL5", currency: "GBP" },
				{ yahoo: "BALY", google: "NYSE:BALY", currency: "USD" },
				{ yahoo: "BALL", google: "NYSE:BALL", currency: "USD" },
			],
		},
		{
			name: "Baxter International Inc.",
			symbol: "BAX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "BTL.F", google: "FRA:BTL", currency: "EUR" },
				{ yahoo: "BAX", google: "NYSE:BAX", currency: "USD" },
				{ yahoo: "0QK8.L", google: "LON:0QK8", currency: "GBP" },
			],
		},
		{
			name: "Becton, Dickinson and Company",
			symbol: "BDX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "BOX.F", google: "FRA:BOX", currency: "EUR" },
				{ yahoo: "BDX", google: "NYSE:BDX", currency: "USD" },
				{ yahoo: "0R19.L", google: "LON:0R19", currency: "GBP" },
			],
		},
		{
			name: "Best Buy Co. Inc.",
			symbol: "BBY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Computer & Electronics Retailers",
				"Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "BUY.F", google: "FRA:BUY", currency: "EUR" },
				{ yahoo: "BBY", google: "NYSE:BBY", currency: "USD" },
				{ yahoo: "0R18.L", google: "LON:0R18", currency: "GBP" },
			],
		},
		{
			name: "BorgWarner Inc.",
			symbol: "BWA",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "BGW.F", google: "FRA:BGW", currency: "EUR" },
				{ yahoo: "BWA", google: "NYSE:BWA", currency: "USD" },
				{ yahoo: "0HOU.L", google: "LON:0HOU", currency: "GBP" },
			],
		},
		{
			name: "Boston Properties Inc.",
			symbol: "BXP",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "BO9.F", google: "FRA:BO9", currency: "EUR" },
				{ yahoo: "BXP", google: "NYSE:BXP", currency: "USD" },
				{ yahoo: "0HOX.L", google: "LON:0HOX", currency: "GBP" },
			],
		},
		{
			name: "Boston Scientific Corporation",
			symbol: "BSX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "BSX.F", google: "FRA:BSX", currency: "EUR" },
				{ yahoo: "BSX", google: "NYSE:BSX", currency: "USD" },
				{ yahoo: "0HOY.L", google: "LON:0HOY", currency: "GBP" },
			],
		},
		{
			name: "Broadcom Inc.",
			symbol: "AVGO",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
				"Semiconductors",
			],
			symbols: [
				{ yahoo: "1YD.F", google: "FRA:1YD", currency: "EUR" },
				{ yahoo: "AVGO", google: "NASDAQ:AVGO", currency: "USD" },
				{ yahoo: "0YXG.L", google: "LON:0YXG", currency: "GBP" },
			],
		},
		{
			name: "Broadridge Financial Solutions Inc.",
			symbol: "BR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Professional Information Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "5B9.F", google: "FRA:5B9", currency: "EUR" },
				{ yahoo: "BR", google: "NYSE:BR", currency: "USD" },
				{ yahoo: "0HPW.L", google: "LON:0HPW", currency: "GBP" },
			],
		},
		{
			name: "Brown-Forman Corporation",
			symbol: "BF-B",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Beverages",
				"Distillers & Wineries",
			],
			symbols: [
				{ yahoo: "BF5B.F", google: "FRA:BF5B", currency: "EUR" },
				{ yahoo: "BF-B", google: "NYSE:BF.B", currency: "USD" },
				{ yahoo: "0HQ3.L", google: "LON:0HQ3", currency: "GBP" },
				{ yahoo: "BF5A.F", google: "FRA:BF5A", currency: "EUR" },
			],
		},
		{
			name: "C.H. Robinson Worldwide Inc.",
			symbol: "CHRW",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Ground Freight & Logistics",
				"Industrials",
				"Freight & Logistics Services",
				"Transportation",
			],
			symbols: [
				{ yahoo: "CH1A.F", google: "FRA:CH1A", currency: "EUR" },
				{ yahoo: "CHRW", google: "NASDAQ:CHRW", currency: "USD" },
				{ yahoo: "0HQW.L", google: "LON:0HQW", currency: "GBP" },
			],
		},
		{
			name: "Cadence Design Systems Inc.",
			symbol: "CDNS",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "CDS.F", google: "FRA:CDS", currency: "EUR" },
				{ yahoo: "CDNS", google: "NASDAQ:CDNS", currency: "USD" },
				{ yahoo: "0HS2.L", google: "LON:0HS2", currency: "GBP" },
			],
		},
		{
			name: "Campbell Soup Company",
			symbol: "CPB",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "CSC.F", google: "FRA:CSC", currency: "EUR" },
				{ yahoo: "CPB", google: "NYSE:CPB", currency: "USD" },
				{ yahoo: "0HST.L", google: "LON:0HST", currency: "GBP" },
			],
		},
		{
			name: "Cardinal Health Inc.",
			symbol: "CAH",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Drug Retailers",
			],
			symbols: [
				{ yahoo: "CLH.F", google: "FRA:CLH", currency: "EUR" },
				{ yahoo: "CAH", google: "NYSE:CAH", currency: "USD" },
				{ yahoo: "0HTG.L", google: "LON:0HTG", currency: "GBP" },
			],
		},
		{
			name: "CarMax Inc.",
			symbol: "KMX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Auto Vehicles, Parts & Service Retailers",
				"Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "XA4.F", google: "FRA:XA4", currency: "EUR" },
				{ yahoo: "KMX", google: "NYSE:KMX", currency: "USD" },
				{ yahoo: "0HTQ.L", google: "LON:0HTQ", currency: "GBP" },
			],
		},
		{
			name: "CBRE Group Inc.",
			symbol: "CBRE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Real Estate Services",
				"Real Estate",
				"Financials",
				"Real Estate Operations",
			],
			symbols: [
				{ yahoo: "RF6.F", google: "FRA:RF6", currency: "EUR" },
				{ yahoo: "CBRE", google: "NYSE:CBRE", currency: "USD" },
				{ yahoo: "0HQP.L", google: "LON:0HQP", currency: "GBP" },
			],
		},
		{
			name: "Celanese Corporation",
			symbol: "CE",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "DG3.F", google: "FRA:DG3", currency: "EUR" },
				{ yahoo: "CE", google: "NYSE:CE", currency: "USD" },
				{ yahoo: "0HUR.L", google: "LON:0HUR", currency: "GBP" },
			],
		},
		{
			name: "Centene Corporation",
			symbol: "CNC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Managed Health care",
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "QEN.F", google: "FRA:QEN", currency: "EUR" },
				{ yahoo: "CNC", google: "NYSE:CNC", currency: "USD" },
				{ yahoo: "0HVB.L", google: "LON:0HVB", currency: "GBP" },
			],
		},
		{
			name: "CenterPoint Energy Inc.",
			symbol: "CNP",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Multiline Utilities", "Utilities"],
			symbols: [
				{ yahoo: "HOU.F", google: "FRA:HOU", currency: "EUR" },
				{ yahoo: "CNP-PB", google: "NYSE:CNP-PB", currency: "USD" },
				{ yahoo: "CNP", google: "NYSE:CNP", currency: "USD" },
				{ yahoo: "0HVF.L", google: "LON:0HVF", currency: "GBP" },
			],
		},
		{
			name: "CF Industries Holdings Inc.",
			symbol: "CF",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Agricultural Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "C4F.F", google: "FRA:C4F", currency: "EUR" },
				{ yahoo: "CF", google: "NYSE:CF", currency: "USD" },
				{ yahoo: "0HQU.L", google: "LON:0HQU", currency: "GBP" },
			],
		},
		{
			name: "Chipotle Mexican Grill Inc.",
			symbol: "CMG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Restaurants & Bars",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "C9F.F", google: "FRA:C9F", currency: "EUR" },
				{ yahoo: "CMG", google: "NYSE:CMG", currency: "USD" },
				{ yahoo: "0HXW.L", google: "LON:0HXW", currency: "GBP" },
			],
		},
		{
			name: "Chubb Limited",
			symbol: "CB",
			country: "Switzerland",
			indices: ["S&P 500"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "AEX.F", google: "FRA:AEX", currency: "EUR" },
				{ yahoo: "CB", google: "NYSE:CB", currency: "USD" },
				{ yahoo: "0VQD.L", google: "LON:0VQD", currency: "GBP" },
			],
		},
		{
			name: "Church & Dwight Co. Inc.",
			symbol: "CHD",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Personal & Household Products & Services",
				"Household Products",
			],
			symbols: [
				{ yahoo: "CXU.F", google: "FRA:CXU", currency: "EUR" },
				{ yahoo: "CHD", google: "NYSE:CHD", currency: "USD" },
				{ yahoo: "0R13.L", google: "LON:0R13", currency: "GBP" },
			],
		},
		{
			name: "Cigna Corporation",
			symbol: "CI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Managed Health care",
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "CGN.F", google: "FRA:CGN", currency: "EUR" },
				{ yahoo: "CI", google: "NYSE:CI", currency: "USD" },
				{ yahoo: "0A77.L", google: "LON:0A77", currency: "GBP" },
			],
		},
		{
			name: "Cincinnati Financial Corporation",
			symbol: "CINF",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance", "Property & Casualty Insurance", "Financials"],
			symbols: [
				{ yahoo: "CCJ.F", google: "FRA:CCJ", currency: "EUR" },
				{ yahoo: "CINF", google: "NASDAQ:CINF", currency: "USD" },
				{ yahoo: "0HYE.L", google: "LON:0HYE", currency: "GBP" },
			],
		},
		{
			name: "Cintas Corporation",
			symbol: "CTAS",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Business Support Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "CIT.F", google: "FRA:CIT", currency: "EUR" },
				{ yahoo: "CTAS", google: "NASDAQ:CTAS", currency: "USD" },
				{ yahoo: "0HYJ.L", google: "LON:0HYJ", currency: "GBP" },
			],
		},
		{
			name: "Citizens Financial Group Inc.",
			symbol: "CFG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "1C5.F", google: "FRA:1C5", currency: "EUR" },
				{ yahoo: "CFG", google: "NYSE:CFG", currency: "USD" },
				{ yahoo: "0HYP.L", google: "LON:0HYP", currency: "GBP" },
			],
		},
		{
			name: "The Clorox Company",
			symbol: "CLX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Personal & Household Products & Services",
				"Household Products",
			],
			symbols: [
				{ yahoo: "CXX.F", google: "FRA:CXX", currency: "EUR" },
				{ yahoo: "CLX", google: "NYSE:CLX", currency: "USD" },
				{ yahoo: "0I0J.L", google: "LON:0I0J", currency: "GBP" },
			],
		},
		{
			name: "CME Group Inc.",
			symbol: "CME",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Financial & Commodity Market Operators",
			],
			symbols: [
				{ yahoo: "MX4A.F", google: "FRA:MX4A", currency: "EUR" },
				{ yahoo: "CME", google: "NASDAQ:CME", currency: "USD" },
				{ yahoo: "0HR2.L", google: "LON:0HR2", currency: "GBP" },
			],
		},
		{
			name: "CMS Energy Corporation",
			symbol: "CMS",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Multiline Utilities", "Utilities"],
			symbols: [
				{ yahoo: "CSG.F", google: "FRA:CSG", currency: "EUR" },
				{ yahoo: "CMS-PB", google: "NYSE:CMS-PB", currency: "USD" },
				{ yahoo: "CMS", google: "NYSE:CMS", currency: "USD" },
				{ yahoo: "0HR4.L", google: "LON:0HR4", currency: "GBP" },
			],
		},
		{
			name: "Comerica Inc.",
			symbol: "CMA",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "CA3.F", google: "FRA:CA3", currency: "EUR" },
				{ yahoo: "CMA", google: "NYSE:CMA", currency: "USD" },
			],
		},
		{
			name: "Conagra Brands Inc.",
			symbol: "CAG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "CAO.F", google: "FRA:CAO", currency: "EUR" },
				{ yahoo: "CAG", google: "NYSE:CAG", currency: "USD" },
				{ yahoo: "0I2P.L", google: "LON:0I2P", currency: "GBP" },
			],
		},
		{
			name: "Consolidated Edison Inc.",
			symbol: "ED",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Real Estate",
				"Financials",
				"Hotels & Entertainment Services",
				"Residential & Commercial RETIs",
				"Leisure & Recreation",
				"Cyclical Consumer Services",
				"Residential REITs",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "EDC.F", google: "FRA:EDC", currency: "EUR" },
				{ yahoo: "ED", google: "NYSE:ED", currency: "USD" },
				{ yahoo: "0I35.L", google: "LON:0I35", currency: "GBP" },
			],
		},
		{
			name: "Constellation Brands Inc.",
			symbol: "STZ",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Beverages",
				"Distillers & Wineries",
			],
			symbols: [
				{ yahoo: "CB1A.F", google: "FRA:CB1A", currency: "EUR" },
				{ yahoo: "STZ", google: "NYSE:STZ", currency: "USD" },
				{ yahoo: "CEG", google: "NASDAQ:CEG", currency: "USD" },
			],
		},
		{
			name: "The Cooper Companies Inc.",
			symbol: "COO",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "CP6.F", google: "FRA:CP6", currency: "EUR" },
				{ yahoo: "COO", google: "NYSE:COO", currency: "USD" },
				{ yahoo: "0I3I.L", google: "LON:0I3I", currency: "GBP" },
			],
		},
		{
			name: "Copart Inc.",
			symbol: "CPRT",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Auto Vehicles, Parts & Service Retailers",
				"Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "CO6.F", google: "FRA:CO6", currency: "EUR" },
				{ yahoo: "CPRT", google: "NASDAQ:CPRT", currency: "USD" },
			],
		},
		{
			name: "Corning Inc.",
			symbol: "GLW",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Electronic Equipments & Parts",
			],
			symbols: [
				{ yahoo: "GLW.F", google: "FRA:GLW", currency: "EUR" },
				{ yahoo: "GLW", google: "NYSE:GLW", currency: "USD" },
			],
		},
		{
			name: "Crown Castle International Corp",
			symbol: "CCI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Specialized REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "8CW.F", google: "FRA:8CW", currency: "EUR" },
				{ yahoo: "CCI", google: "NYSE:CCI", currency: "USD" },
				{ yahoo: "0I4W.L", google: "LON:0I4W", currency: "GBP" },
			],
		},
		{
			name: "Cummins Inc.",
			symbol: "CMI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "CUM.F", google: "FRA:CUM", currency: "EUR" },
				{ yahoo: "CMI", google: "NYSE:CMI", currency: "USD" },
				{ yahoo: "0I58.L", google: "LON:0I58", currency: "GBP" },
			],
		},
		{
			name: "Darden Restaurants Inc.",
			symbol: "DRI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Restaurants & Bars",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "DDN.F", google: "FRA:DDN", currency: "EUR" },
				{ yahoo: "DRI", google: "NYSE:DRI", currency: "USD" },
				{ yahoo: "0I77.L", google: "LON:0I77", currency: "GBP" },
			],
		},
		{
			name: "DaVita Inc.",
			symbol: "DVA",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Facilities & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "TRL.F", google: "FRA:TRL", currency: "EUR" },
				{ yahoo: "DVA", google: "NYSE:DVA", currency: "USD" },
				{ yahoo: "0I7E.L", google: "LON:0I7E", currency: "GBP" },
			],
		},
		{
			name: "Deere & Company",
			symbol: "DE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Heavy Machinery & Vehicles",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "DCO.F", google: "FRA:DCO", currency: "EUR" },
				{ yahoo: "DE", google: "NYSE:DE", currency: "USD" },
				{ yahoo: "0R2P.L", google: "LON:0R2P", currency: "GBP" },
			],
		},
		{
			name: "Delta Air Lines Inc.",
			symbol: "DAL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Passenger Transportation Services",
				"Industrials",
				"Airlines",
				"Transportation",
			],
			symbols: [
				{ yahoo: "OYC.F", google: "FRA:OYC", currency: "EUR" },
				{ yahoo: "DAL", google: "NYSE:DAL", currency: "USD" },
			],
		},
		{
			name: "Devon Energy Corporation",
			symbol: "DVN",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas",
				"Oil & Gas Exploration and Production",
			],
			symbols: [
				{ yahoo: "DY6.F", google: "FRA:DY6", currency: "EUR" },
				{ yahoo: "DVN", google: "NYSE:DVN", currency: "USD" },
				{ yahoo: "0I8W.L", google: "LON:0I8W", currency: "GBP" },
			],
		},
		{
			name: "Diamondback Energy Inc.",
			symbol: "FANG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas",
				"Oil & Gas Exploration and Production",
			],
			symbols: [
				{ yahoo: "7DB.F", google: "FRA:7DB", currency: "EUR" },
				{ yahoo: "FANG", google: "NASDAQ:FANG", currency: "USD" },
			],
		},
		{
			name: "Digital Realty Trust Inc.",
			symbol: "DLR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "FQI.F", google: "FRA:FQI", currency: "EUR" },
				{ yahoo: "DLR", google: "NYSE:DLR", currency: "USD" },
				{ yahoo: "0I9F.L", google: "LON:0I9F", currency: "GBP" },
			],
		},
		{
			name: "Discover Financials",
			symbol: "DFS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Consumer Lending",
			],
			symbols: [
				{ yahoo: "DC7.F", google: "FRA:DC7", currency: "EUR" },
				{ yahoo: "DFS", google: "NYSE:DFS", currency: "USD" },
			],
		},
		{
			name: "Dollar General Corporation",
			symbol: "DG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Diversified Retail",
				"Retailers",
				"Discount Stores",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "7DG.F", google: "FRA:7DG", currency: "EUR" },
				{ yahoo: "DG", google: "NYSE:DG", currency: "USD" },
				{ yahoo: "0IC7.L", google: "LON:0IC7", currency: "GBP" },
			],
		},
		{
			name: "Dominion Energy Inc.",
			symbol: "D",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "DOD.F", google: "FRA:DOD", currency: "EUR" },
				{ yahoo: "D", google: "NYSE:D", currency: "USD" },
				{ yahoo: "0IC9.L", google: "LON:0IC9", currency: "GBP" },
			],
		},
		{
			name: "Dover Corporation",
			symbol: "DOV",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "DOV.F", google: "FRA:DOV", currency: "EUR" },
				{ yahoo: "DOV", google: "NYSE:DOV", currency: "USD" },
				{ yahoo: "0ICP.L", google: "LON:0ICP", currency: "GBP" },
			],
		},
		{
			name: "D.R. Horton Inc.",
			symbol: "DHI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Homebuilding",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "HO2.F", google: "FRA:HO2", currency: "EUR" },
				{ yahoo: "DHI", google: "NYSE:DHI", currency: "USD" },
			],
		},
		{
			name: "DTE Energy Company",
			symbol: "DTE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "DGY.F", google: "FRA:DGY", currency: "EUR" },
				{ yahoo: "DTE", google: "NYSE:DTE", currency: "USD" },
				{ yahoo: "0I6Q.L", google: "LON:0I6Q", currency: "GBP" },
			],
		},
		{
			name: "DXC Technology Company",
			symbol: "DXC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "2XT.F", google: "FRA:2XT", currency: "EUR" },
				{ yahoo: "DXC", google: "NYSE:DXC", currency: "USD" },
				{ yahoo: "0I6U.L", google: "LON:0I6U", currency: "GBP" },
			],
		},
		{
			name: "Eastman Chemical Company",
			symbol: "EMN",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "EAC.F", google: "FRA:EAC", currency: "EUR" },
				{ yahoo: "EMN", google: "NYSE:EMN", currency: "USD" },
				{ yahoo: "0IF3.L", google: "LON:0IF3", currency: "GBP" },
			],
		},
		{
			name: "Eaton Corporation PLC",
			symbol: "ETN",
			country: "Ireland",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Electrical Components & Equipment",
			],
			symbols: [
				{ yahoo: "3EC.F", google: "FRA:3EC", currency: "EUR" },
				{ yahoo: "ETN", google: "NYSE:ETN", currency: "USD" },
				{ yahoo: "0Y3K.L", google: "LON:0Y3K", currency: "GBP" },
			],
		},
		{
			name: "Ecolab Inc.",
			symbol: "ECL",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Specialty Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "ECJ.F", google: "FRA:ECJ", currency: "EUR" },
				{ yahoo: "ECL", google: "NYSE:ECL", currency: "USD" },
				{ yahoo: "0IFA.L", google: "LON:0IFA", currency: "GBP" },
			],
		},
		{
			name: "Edison International",
			symbol: "EIX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "EIX.F", google: "FRA:EIX", currency: "EUR" },
				{ yahoo: "EIX", google: "NYSE:EIX", currency: "USD" },
				{ yahoo: "0IFJ.L", google: "LON:0IFJ", currency: "GBP" },
			],
		},
		{
			name: "Edwards Lifesciences Corporation",
			symbol: "EW",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "EWL.F", google: "FRA:EWL", currency: "EUR" },
				{ yahoo: "EW", google: "NYSE:EW", currency: "USD" },
			],
		},
		{
			name: "Entergy Corporation",
			symbol: "ETR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "ETY.F", google: "FRA:ETY", currency: "EUR" },
				{ yahoo: "ETR", google: "NYSE:ETR", currency: "USD" },
				{ yahoo: "0IHP.L", google: "LON:0IHP", currency: "GBP" },
			],
		},
		{
			name: "EOG Resources Inc.",
			symbol: "EOG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas",
				"Oil & Gas Exploration and Production",
			],
			symbols: [
				{ yahoo: "EO5.F", google: "FRA:EO5", currency: "EUR" },
				{ yahoo: "EOG", google: "NYSE:EOG", currency: "USD" },
				{ yahoo: "0IDR.L", google: "LON:0IDR", currency: "GBP" },
			],
		},
		{
			name: "Equifax Inc.",
			symbol: "EFX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Professional Information Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "EFX.F", google: "FRA:EFX", currency: "EUR" },
				{ yahoo: "EFX", google: "NYSE:EFX", currency: "USD" },
				{ yahoo: "0II3.L", google: "LON:0II3", currency: "GBP" },
			],
		},
		{
			name: "Equinix Inc. (REIT)",
			symbol: "EQIX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "EQN2.F", google: "FRA:EQN2", currency: "EUR" },
				{ yahoo: "EQIX", google: "NASDAQ:EQIX", currency: "USD" },
				{ yahoo: "0II4.L", google: "LON:0II4", currency: "GBP" },
			],
		},
		{
			name: "Equity Residential",
			symbol: "EQR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Real Estate",
				"Financials",
				"Residential REITs",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "EQR", google: "NYSE:EQR", currency: "USD" },
				{ yahoo: "0IIB.L", google: "LON:0IIB", currency: "GBP" },
				{ yahoo: "EQR.F", google: "FRA:EQR", currency: "EUR" },
			],
		},
		{
			name: "Essex Property Trust Inc.",
			symbol: "ESS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Real Estate",
				"Financials",
				"Residential REITs",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "EXP.F", google: "FRA:EXP", currency: "EUR" },
				{ yahoo: "ESS", google: "NYSE:ESS", currency: "USD" },
				{ yahoo: "0IIR.L", google: "LON:0IIR", currency: "GBP" },
			],
		},
		{
			name: "Everest Re Group, Ltd.",
			symbol: "RE",
			country: "Bermuda",
			indices: ["S&P 500"],
			industries: ["Insurance", "Financials", "Reinsurance"],
			symbols: [
				{ yahoo: "ERE.F", google: "FRA:ERE", currency: "EUR" },
				{ yahoo: "RE", google: "NYSE:RE", currency: "USD" },
				{ yahoo: "0U96.L", google: "LON:0U96", currency: "GBP" },
			],
		},
		{
			name: "Evergy Inc.",
			symbol: "EVRG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "3E7.F", google: "FRA:3E7", currency: "EUR" },
				{ yahoo: "EVRG", google: "NYSE:EVRG", currency: "USD" },
			],
		},
		{
			name: "Eversource Energy",
			symbol: "ES",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "NWJ.F", google: "FRA:NWJ", currency: "EUR" },
				{ yahoo: "ES", google: "NYSE:ES", currency: "USD" },
				{ yahoo: "0IJ2.L", google: "LON:0IJ2", currency: "GBP" },
			],
		},
		{
			name: "Expeditors International of Washington Inc.",
			symbol: "EXPD",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrials",
				"Air Freight & Courier Services",
				"Freight & Logistics Services",
				"Transportation",
			],
			symbols: [
				{ yahoo: "EW1.F", google: "FRA:EW1", currency: "EUR" },
				{ yahoo: "EXPD", google: "NASDAQ:EXPD", currency: "USD" },
				{ yahoo: "0IJR.L", google: "LON:0IJR", currency: "GBP" },
			],
		},
		{
			name: "Extra Space Storage Inc.",
			symbol: "EXR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Specialized REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "FG8.F", google: "FRA:FG8", currency: "EUR" },
				{ yahoo: "EXR", google: "NYSE:EXR", currency: "USD" },
				{ yahoo: "0IJV.L", google: "LON:0IJV", currency: "GBP" },
			],
		},
		{
			name: "F5 Networks Inc.",
			symbol: "FFIV",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "FFV.F", google: "FRA:FFV", currency: "EUR" },
				{ yahoo: "FFIV", google: "NASDAQ:FFIV", currency: "USD" },
				{ yahoo: "0IL6.L", google: "LON:0IL6", currency: "GBP" },
			],
		},
		{
			name: "Federal Realty Investment Trust",
			symbol: "FRT",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "FRT", google: "NYSE:FRT", currency: "USD" },
				{ yahoo: "0IL1.L", google: "LON:0IL1", currency: "GBP" },
				{ yahoo: "WX2.F", google: "FRA:WX2", currency: "EUR" },
				{ yahoo: "QM1.F", google: "FRA:QM1", currency: "EUR" },
			],
		},
		{
			name: "Fidelity National Information Services Inc.",
			symbol: "FIS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Financial & Commodity Market Operators",
			],
			symbols: [
				{ yahoo: "ZGY.F", google: "FRA:ZGY", currency: "EUR" },
				{ yahoo: "FIS", google: "NYSE:FIS", currency: "USD" },
				{ yahoo: "0ILW.L", google: "LON:0ILW", currency: "GBP" },
			],
		},
		{
			name: "Fifth Third Bancorp",
			symbol: "FITB",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "FFH.F", google: "FRA:FFH", currency: "EUR" },
				{ yahoo: "FITB", google: "NASDAQ:FITB", currency: "USD" },
				{ yahoo: "0IM1.L", google: "LON:0IM1", currency: "GBP" },
			],
		},
		{
			name: "First Republic Bank",
			symbol: "FRC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "81R.F", google: "FRA:81R", currency: "EUR" },
				{ yahoo: "FRC", google: "NYSE:FRC", currency: "USD" },
				{ yahoo: "FRCB", google: "OTCMKTS:FRCB", currency: "USD" },
			],
		},
		{
			name: "FirstEnergy Corp.",
			symbol: "FE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "FE7.F", google: "FRA:FE7", currency: "EUR" },
				{ yahoo: "FE", google: "NYSE:FE", currency: "USD" },
				{ yahoo: "0IPB.L", google: "LON:0IPB", currency: "GBP" },
			],
		},
		{
			name: "FleetCor Technologies Inc.",
			symbol: "FLT",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Business Support Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "07G.F", google: "FRA:07G", currency: "EUR" },
				{ yahoo: "FLT", google: "NYSE:FLT", currency: "USD" },
				{ yahoo: "0IPN.L", google: "LON:0IPN", currency: "GBP" },
			],
		},
		{
			name: "FMC Corporation",
			symbol: "FMC",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Diversified Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "FMQ.F", google: "FRA:FMQ", currency: "EUR" },
				{ yahoo: "FMC", google: "NYSE:FMC", currency: "USD" },
				{ yahoo: "0IK3.L", google: "LON:0IK3", currency: "GBP" },
			],
		},
		{
			name: "Fortinet Inc.",
			symbol: "FTNT",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "FO8.F", google: "FRA:FO8", currency: "EUR" },
				{ yahoo: "FTNT", google: "NASDAQ:FTNT", currency: "USD" },
				{ yahoo: "0IR9.L", google: "LON:0IR9", currency: "GBP" },
			],
		},
		{
			name: "Fortive Corporation",
			symbol: "FTV",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "F03.F", google: "FRA:F03", currency: "EUR" },
				{ yahoo: "FTV", google: "NYSE:FTV", currency: "USD" },
				{ yahoo: "0IRE.L", google: "LON:0IRE", currency: "GBP" },
			],
		},
		{
			name: "Fortune Brands Home & Security Inc.",
			symbol: "FBHS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Construction Supplies & Fixtures",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "2FB.F", google: "FRA:2FB", currency: "EUR" },
				{ yahoo: "FBHS", google: "NYSE:FBHS", currency: "USD" },
				{ yahoo: "0IRN.L", google: "LON:0IRN", currency: "GBP" },
			],
		},
		{
			name: "Fox Corporation",
			symbol: "FOXA",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Media & Publishing",
				"Cyclical Consumer Services",
				"Broadcasting",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "FOXA", google: "NASDAQ:FOXA", currency: "USD" },
				{ yahoo: "FXBY", google: "OTCMKTS:FXBY", currency: "USD" },
				{ yahoo: "FOX", google: "NASDAQ:FOX", currency: "USD" },
				{ yahoo: "FO5B.F", google: "FRA:FO5B", currency: "EUR" },
				{ yahoo: "0A0Y.L", google: "LON:0A0Y", currency: "GBP" },
			],
		},
		{
			name: "Franklin Resources Inc.",
			symbol: "BEN",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "FRK.F", google: "FRA:FRK", currency: "EUR" },
				{ yahoo: "BEN", google: "NYSE:BEN", currency: "USD" },
				{ yahoo: "0RT6.L", google: "LON:0RT6", currency: "GBP" },
			],
		},
		{
			name: "Freeport-McMoRan Inc.",
			symbol: "FCX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Basic Materials",
				"Integrated Mining",
			],
			symbols: [
				{ yahoo: "FPMB.F", google: "FRA:FPMB", currency: "EUR" },
				{ yahoo: "FCX", google: "NYSE:FCX", currency: "USD" },
			],
		},
		{
			name: "Garmin Ltd.",
			symbol: "GRMN",
			country: "Switzerland",
			indices: ["S&P 500"],
			industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
			symbols: [
				{ yahoo: "GEY.F", google: "FRA:GEY", currency: "EUR" },
				{ yahoo: "GRMN", google: "NASDAQ:GRMN", currency: "USD" },
			],
		},
		{
			name: "Gartner Inc.",
			symbol: "IT",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "GGRA.F", google: "FRA:GGRA", currency: "EUR" },
				{ yahoo: "IT", google: "NYSE:IT", currency: "USD" },
				{ yahoo: "0ITV.L", google: "LON:0ITV", currency: "GBP" },
			],
		},
		{
			name: "General Mills Inc.",
			symbol: "GIS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "GRM.F", google: "FRA:GRM", currency: "EUR" },
				{ yahoo: "GIS", google: "NYSE:GIS", currency: "USD" },
				{ yahoo: "0R1X.L", google: "LON:0R1X", currency: "GBP" },
			],
		},
		{
			name: "Genuine Parts Company",
			symbol: "GPC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "GPT.F", google: "FRA:GPT", currency: "EUR" },
				{ yahoo: "GPC", google: "NYSE:GPC", currency: "USD" },
				{ yahoo: "0IUX.L", google: "LON:0IUX", currency: "GBP" },
			],
		},
		{
			name: "Global Payments Inc.",
			symbol: "GPN",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Business Support Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "GLO.F", google: "FRA:GLO", currency: "EUR" },
				{ yahoo: "GPN", google: "NYSE:GPN", currency: "USD" },
				{ yahoo: "0IW7.L", google: "LON:0IW7", currency: "GBP" },
			],
		},
		{
			name: "W.W. Grainger Inc.",
			symbol: "GWW",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrials",
				"Real Estate",
				"Real Estate Development & Operations",
				"Industrial Machinery & Equipment",
				"Machinery, Equipment & Components",
				"Real Estate Operations",
				"Industrial Goods",
				"Financials",
			],
			symbols: [
				{ yahoo: "GWW.F", google: "FRA:GWW", currency: "EUR" },
				{ yahoo: "GWW", google: "NYSE:GWW", currency: "USD" },
				{ yahoo: "0IZI.L", google: "LON:0IZI", currency: "GBP" },
			],
		},
		{
			name: "Halliburton Company",
			symbol: "HAL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil Related Services and Equipment",
				"Oil & Gas Related Equipment and Services",
			],
			symbols: [
				{ yahoo: "HAL.F", google: "FRA:HAL", currency: "EUR" },
				{ yahoo: "HAL", google: "NYSE:HAL", currency: "USD" },
				{ yahoo: "0R23.L", google: "LON:0R23", currency: "GBP" },
			],
		},
		{
			name: "The Hartford Financials Group Inc.",
			symbol: "HIG",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "HFF.F", google: "FRA:HFF", currency: "EUR" },
				{ yahoo: "HIG", google: "NYSE:HIG", currency: "USD" },
			],
		},
		{
			name: "Hasbro Inc.",
			symbol: "HAS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Leisure Products",
				"Toys & Juvenile Products",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "HAS.F", google: "FRA:HAS", currency: "EUR" },
				{ yahoo: "HAS", google: "NASDAQ:HAS", currency: "USD" },
				{ yahoo: "0J3K.L", google: "LON:0J3K", currency: "GBP" },
			],
		},
		{
			name: "HCA Healthcare Inc.",
			symbol: "HCA",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Facilities & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "2BH.F", google: "FRA:2BH", currency: "EUR" },
				{ yahoo: "HCA", google: "NYSE:HCA", currency: "USD" },
				{ yahoo: "0J1R.L", google: "LON:0J1R", currency: "GBP" },
			],
		},
		{
			name: "Healthpeak Properties, Inc.",
			wiki_name: "Healthpeak Properties, Inc.",
			symbol: "PEAK",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Real estate investment trust",
				"Specialized REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "PEAK", google: "NYSE:PEAK", currency: "USD" },
				{ yahoo: "HC5.F", google: "FRA:HC5", currency: "EUR" },
				{ yahoo: "HCP", google: "NYSE:HCP", currency: "USD" },
			],
			isins: ["US42250P1030"],
			metadata: { founded: 1985, employees: 204 },
			akas: [],
		},
		{
			name: "The Hershey Company",
			symbol: "HSY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "HSY.F", google: "FRA:HSY", currency: "EUR" },
				{ yahoo: "HSY", google: "NYSE:HSY", currency: "USD" },
				{ yahoo: "0J4X.L", google: "LON:0J4X", currency: "GBP" },
			],
		},
		{
			name: "Hess Corporation",
			symbol: "HES",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "AHC.F", google: "FRA:AHC", currency: "EUR" },
				{ yahoo: "HES", google: "NYSE:HES", currency: "USD" },
				{ yahoo: "0J50.L", google: "LON:0J50", currency: "GBP" },
			],
		},
		{
			name: "Hewlett Packard Enterprise Company",
			symbol: "HPE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Computers, Phones & Household Electronics",
				"Technology",
				"Technology Equipment",
				"Computer Hardware",
			],
			symbols: [
				{ yahoo: "2HP.F", google: "FRA:2HP", currency: "EUR" },
				{ yahoo: "HPE", google: "NYSE:HPE", currency: "USD" },
				{ yahoo: "0J51.L", google: "LON:0J51", currency: "GBP" },
			],
		},
		{
			name: "Hilton Worldwide Holdings Inc.",
			symbol: "HLT",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Cyclical Consumer Services",
				"Hotels, Motels & Cruise Lines",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "HI91.F", google: "FRA:HI91", currency: "EUR" },
				{ yahoo: "HLT", google: "NYSE:HLT", currency: "USD" },
				{ yahoo: "0J5I.L", google: "LON:0J5I", currency: "GBP" },
			],
		},
		{
			name: "Hologic Inc.",
			symbol: "HOLX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "HO1.F", google: "FRA:HO1", currency: "EUR" },
				{ yahoo: "HOLX", google: "NASDAQ:HOLX", currency: "USD" },
				{ yahoo: "0J5Q.L", google: "LON:0J5Q", currency: "GBP" },
			],
		},
		{
			name: "Hormel Foods Corporation",
			symbol: "HRL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "HO7.F", google: "FRA:HO7", currency: "EUR" },
				{ yahoo: "HRL", google: "NYSE:HRL", currency: "USD" },
				{ yahoo: "0J5Z.L", google: "LON:0J5Z", currency: "GBP" },
			],
		},
		{
			name: "Host Hotels & Resorts Inc.",
			symbol: "HST",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Specialized REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "HMT.F", google: "FRA:HMT", currency: "EUR" },
				{ yahoo: "HST", google: "NASDAQ:HST", currency: "USD" },
				{ yahoo: "0J66.L", google: "LON:0J66", currency: "GBP" },
			],
		},
		{
			name: "HP Inc.",
			symbol: "HPQ",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Computers, Phones & Household Electronics",
				"Technology",
				"Technology Equipment",
				"Computer Hardware",
			],
			symbols: [
				{ yahoo: "7HP.F", google: "FRA:7HP", currency: "EUR" },
				{ yahoo: "HPQ", google: "NYSE:HPQ", currency: "USD" },
				{ yahoo: "0J2E.L", google: "LON:0J2E", currency: "GBP" },
			],
		},
		{
			name: "Humana Inc.",
			symbol: "HUM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Managed Health care",
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "HUM.F", google: "FRA:HUM", currency: "EUR" },
				{ yahoo: "HUM", google: "NYSE:HUM", currency: "USD" },
				{ yahoo: "0J6Z.L", google: "LON:0J6Z", currency: "GBP" },
			],
		},
		{
			name: "Huntington Bancshares Inc.",
			symbol: "HBAN",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "HU3.F", google: "FRA:HU3", currency: "EUR" },
				{ yahoo: "HBANN", google: "NASDAQ:HBANN", currency: "USD" },
				{ yahoo: "HBAN", google: "NASDAQ:HBAN", currency: "USD" },
				{ yahoo: "0J72.L", google: "LON:0J72", currency: "GBP" },
			],
		},
		{
			name: "Huntington Ingalls Industries Inc.",
			symbol: "HII",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
			symbols: [
				{ yahoo: "HI4.F", google: "FRA:HI4", currency: "EUR" },
				{ yahoo: "HII", google: "NYSE:HII", currency: "USD" },
				{ yahoo: "0J76.L", google: "LON:0J76", currency: "GBP" },
			],
		},
		{
			name: "IDEXX Laboratories Inc.",
			symbol: "IDXX",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "IX1.F", google: "FRA:IX1", currency: "EUR" },
				{ yahoo: "IDXX", google: "NASDAQ:IDXX", currency: "USD" },
				{ yahoo: "0J8P.L", google: "LON:0J8P", currency: "GBP" },
			],
		},
		{
			name: "Illinois Tool Works Inc.",
			symbol: "ITW",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Industrials", "Industrial Conglomerates"],
			symbols: [
				{ yahoo: "ILT.F", google: "FRA:ILT", currency: "EUR" },
				{ yahoo: "ITW", google: "NYSE:ITW", currency: "USD" },
				{ yahoo: "0J8W.L", google: "LON:0J8W", currency: "GBP" },
			],
		},
		{
			name: "Intercontinental Exchange Inc.",
			symbol: "ICE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Financial & Commodity Market Operators",
			],
			symbols: [
				{ yahoo: "IC2.F", google: "FRA:IC2", currency: "EUR" },
				{ yahoo: "ICE", google: "NYSE:ICE", currency: "USD" },
				{ yahoo: "0JC3.L", google: "LON:0JC3", currency: "GBP" },
			],
		},
		{
			name: "International Flavors & Fragrances Inc.",
			symbol: "IFF",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas Transportation Services",
				"Oil & Gas Related Equipment and Services",
			],
			symbols: [
				{ yahoo: "IFF.F", google: "FRA:IFF", currency: "EUR" },
				{ yahoo: "IFF", google: "NYSE:IFF", currency: "USD" },
				{ yahoo: "0RF3.L", google: "LON:0RF3", currency: "GBP" },
			],
		},
		{
			name: "International Paper Company",
			symbol: "IP",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas Transportation Services",
				"Oil & Gas Related Equipment and Services",
			],
			symbols: [
				{ yahoo: "INP.F", google: "FRA:INP", currency: "EUR" },
				{ yahoo: "IP", google: "NYSE:IP", currency: "USD" },
				{ yahoo: "0JCB.L", google: "LON:0JCB", currency: "GBP" },
				{ yahoo: "INPAP", google: "OTCMKTS:INPAP", currency: "USD" },
			],
		},
		{
			name: "The Interpublic Group of Companies Inc.",
			symbol: "IPG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Media & Publishing",
				"Advertising & Marketing",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "IPG.F", google: "FRA:IPG", currency: "EUR" },
				{ yahoo: "IPG", google: "NYSE:IPG", currency: "USD" },
				{ yahoo: "0JCK.L", google: "LON:0JCK", currency: "GBP" },
			],
		},
		{
			name: "Invesco Ltd.",
			symbol: "IVZ",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "3IW.F", google: "FRA:3IW", currency: "EUR" },
				{ yahoo: "IVZ", google: "NYSE:IVZ", currency: "USD" },
				{ yahoo: "0UAN.L", google: "LON:0UAN", currency: "GBP" },
			],
		},
		{
			name: "IQVIA Holdings Inc.",
			symbol: "IQV",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Biotechnology & Medical Research",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "QTS.F", google: "FRA:QTS", currency: "EUR" },
				{ yahoo: "IQV", google: "NYSE:IQV", currency: "USD" },
				{ yahoo: "0JDM.L", google: "LON:0JDM", currency: "GBP" },
			],
		},
		{
			name: "Iron Mountain Inc.",
			symbol: "IRM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "I5M.F", google: "FRA:I5M", currency: "EUR" },
				{ yahoo: "IRM", google: "NYSE:IRM", currency: "USD" },
			],
		},
		{
			name: "J.B. Hunt Transport Services Inc.",
			symbol: "JBHT",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Ground Freight & Logistics",
				"Industrials",
				"Freight & Logistics Services",
				"Transportation",
			],
			symbols: [
				{ yahoo: "JB1.F", google: "FRA:JB1", currency: "EUR" },
				{ yahoo: "JBHT", google: "NASDAQ:JBHT", currency: "USD" },
				{ yahoo: "0J71.L", google: "LON:0J71", currency: "GBP" },
			],
		},
		{
			name: "Jack Henry & Associates Inc.",
			symbol: "JKHY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "JHY.F", google: "FRA:JHY", currency: "EUR" },
				{ yahoo: "JKHY", google: "NASDAQ:JKHY", currency: "USD" },
				{ yahoo: "0A6D.L", google: "LON:0A6D", currency: "GBP" },
			],
		},
		{
			name: "Jacobs Engineering Group Inc.",
			symbol: "JEC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "JEG.F", google: "FRA:JEG", currency: "EUR" },
				{ yahoo: "J", google: "NYSE:J", currency: "USD" },
				{ yahoo: "0JOI.L", google: "LON:0JOI", currency: "GBP" },
				{ yahoo: "Z0Y.F", google: "FRA:Z0Y", currency: "EUR" },
			],
		},
		{
			name: "The J. M. Smucker Company",
			symbol: "SJM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "JM2.F", google: "FRA:JM2", currency: "EUR" },
				{ yahoo: "SJM", google: "NYSE:SJM", currency: "USD" },
				{ yahoo: "0L7F.L", google: "LON:0L7F", currency: "GBP" },
			],
		},
		{
			name: "Johnson Controls International PLC",
			symbol: "JCI",
			country: "Ireland",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Electrical Components & Equipment",
			],
			symbols: [
				{ yahoo: "TYIA.F", google: "FRA:TYIA", currency: "EUR" },
				{ yahoo: "JCI", google: "NYSE:JCI", currency: "USD" },
				{ yahoo: "0Y7S.L", google: "LON:0Y7S", currency: "GBP" },
			],
		},
		{
			name: "Juniper Networks Inc.",
			symbol: "JNPR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Communications & Networking",
			],
			symbols: [
				{ yahoo: "JNP.F", google: "FRA:JNP", currency: "EUR" },
				{ yahoo: "JNPR", google: "NYSE:JNPR", currency: "USD" },
				{ yahoo: "0JPH.L", google: "LON:0JPH", currency: "GBP" },
			],
		},
		{
			name: "Kellogg Company",
			symbol: "K",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "KEL.F", google: "FRA:KEL", currency: "EUR" },
				{ yahoo: "K", google: "NYSE:K", currency: "USD" },
				{ yahoo: "0R1R.L", google: "LON:0R1R", currency: "GBP" },
				{ yahoo: "K-WI", google: "NYSE:K-WI", currency: "USD" },
			],
		},
		{
			name: "Keysight Technologies Inc.",
			symbol: "KEYS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "1KT.F", google: "FRA:1KT", currency: "EUR" },
				{ yahoo: "KEYS", google: "NYSE:KEYS", currency: "USD" },
				{ yahoo: "0A7N.L", google: "LON:0A7N", currency: "GBP" },
			],
		},
		{
			name: "Kimberly-Clark Corporation",
			symbol: "KMB",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Personal Products",
				"Personal & Household Products & Services",
			],
			symbols: [
				{ yahoo: "KMY.F", google: "FRA:KMY", currency: "EUR" },
				{ yahoo: "KMB", google: "NYSE:KMB", currency: "USD" },
				{ yahoo: "0JQZ.L", google: "LON:0JQZ", currency: "GBP" },
			],
		},
		{
			name: "Kimco Realty Corporation",
			symbol: "KIM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "KIC.F", google: "FRA:KIC", currency: "EUR" },
				{ yahoo: "KIM-PI", google: "NYSE:KIM", currency: "USD" },
				{ yahoo: "KIM", google: "NYSE:KIM", currency: "USD" },
				{ yahoo: "0JR1.L", google: "LON:0JR1", currency: "GBP" },
			],
		},
		{
			name: "KLA-Tencor Corporation",
			symbol: "KLAC",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Semiconductor Equipment & Testing",
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
			],
			symbols: [
				{ yahoo: "KLA.F", google: "FRA:KLA", currency: "EUR" },
				{ yahoo: "KLAC", google: "NASDAQ:KLAC", currency: "USD" },
				{ yahoo: "0JPO.L", google: "LON:0JPO", currency: "GBP" },
			],
		},
		{
			name: "The Kroger Co.",
			symbol: "KR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Food Retail & Distribution",
			],
			symbols: [
				{ yahoo: "KOG.F", google: "FRA:KOG", currency: "EUR" },
				{ yahoo: "KR", google: "NYSE:KR", currency: "USD" },
				{ yahoo: "0JS2.L", google: "LON:0JS2", currency: "GBP" },
			],
		},
		{
			name: "Laboratory Corporation of America Holdings",
			symbol: "LH",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Facilities & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "LAB.F", google: "FRA:LAB", currency: "EUR" },
				{ yahoo: "LH", google: "NYSE:LH", currency: "USD" },
				{ yahoo: "0JSY.L", google: "LON:0JSY", currency: "GBP" },
			],
		},
		{
			name: "Lamb Weston Holdings Inc.",
			symbol: "LW",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Fishing & Farming",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "0L5.F", google: "FRA:0L5", currency: "EUR" },
				{ yahoo: "LW", google: "NYSE:LW", currency: "USD" },
			],
		},
		{
			name: "The Estée Lauder Companies Inc.",
			symbol: "EL",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Cosmetics"],
			symbols: [
				{ yahoo: "ELAA.F", google: "NYSE:ELAA", currency: "EUR" },
				{ yahoo: "EL", google: "NYSE:EL", currency: "USD" },
			],
		},
		{
			name: "Lennar Corporation",
			symbol: "LEN",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Homebuilding",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "LNN.F", google: "FRA:LNN", currency: "EUR" },
				{ yahoo: "LEN", google: "NYSE:LEN", currency: "USD" },
				{ yahoo: "0JU0.L", google: "LON:0JU0", currency: "GBP" },
				{ yahoo: "LNN0.F", google: "FRA:LNN0", currency: "EUR" },
			],
		},
		{
			name: "Lincoln National Corporation",
			symbol: "LNC",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance", "Financials", "Life & Health Insurance"],
			symbols: [
				{ yahoo: "LCO.F", google: "FRA:LCO", currency: "EUR" },
				{ yahoo: "LNC", google: "NYSE:LNC", currency: "USD" },
				{ yahoo: "0JV3.L", google: "LON:0JV3", currency: "GBP" },
			],
		},
		{
			name: "LKQ Corporation",
			symbol: "LKQ",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "LKQ1.F", google: "FRA:LKQ1", currency: "EUR" },
				{ yahoo: "LKQ", google: "NASDAQ:LKQ", currency: "USD" },
				{ yahoo: "0JSJ.L", google: "LON:0JSJ", currency: "GBP" },
			],
		},
		{
			name: "Loews Corporation",
			symbol: "L",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance", "Property & Casualty Insurance", "Financials"],
			symbols: [
				{ yahoo: "LTR.F", google: "FRA:LTR", currency: "EUR" },
				{ yahoo: "L", google: "NYSE:L", currency: "USD" },
				{ yahoo: "0JVI.L", google: "LON:0JVI", currency: "GBP" },
			],
		},
		{
			name: "LyondellBasell Industries N.V.",
			symbol: "LYB",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "DLY.F", google: "FRA:DLY", currency: "EUR" },
				{ yahoo: "LYB", google: "NYSE:LYB", currency: "USD" },
				{ yahoo: "0EDD.L", google: "LON:0EDD", currency: "GBP" },
			],
		},
		{
			name: "M&T Bank Corporation",
			symbol: "MTB",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "MTZ.F", google: "FRA:MTZ", currency: "EUR" },
				{ yahoo: "MTB", google: "NYSE:MTB", currency: "USD" },
				{ yahoo: "0JW2.L", google: "LON:0JW2", currency: "GBP" },
			],
		},
		{
			name: "Marathon Oil Corporation",
			symbol: "MRO",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas",
				"Oil & Gas Exploration and Production",
			],
			symbols: [
				{ yahoo: "USS.F", google: "FRA:USS", currency: "EUR" },
				{ yahoo: "MRO", google: "NYSE:MRO", currency: "USD" },
				{ yahoo: "0JY9.L", google: "LON:0JY9", currency: "GBP" },
			],
		},
		{
			name: "Marathon Petroleum Corporation",
			symbol: "MPC",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "MPN.F", google: "FRA:MPN", currency: "EUR" },
				{ yahoo: "MPC", google: "NYSE:MPC", currency: "USD" },
				{ yahoo: "0JYA.L", google: "LON:0JYA", currency: "GBP" },
			],
		},
		{
			name: "Marsh & McLennan Companies Inc.",
			symbol: "MMC",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "MSN.F", google: "FRA:MSN", currency: "EUR" },
				{ yahoo: "MMC", google: "NYSE:MMC", currency: "USD" },
				{ yahoo: "MHM.L", google: "LON:MHM", currency: "GBP" },
			],
		},
		{
			name: "Martin Marietta Materials Inc.",
			symbol: "MLM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Construction Materials",
				"Mineral Resources",
				"Basic Materials",
			],
			symbols: [
				{ yahoo: "MMX.F", google: "FRA:MMX", currency: "EUR" },
				{ yahoo: "MLM", google: "NYSE:MLM", currency: "USD" },
				{ yahoo: "0JZ0.L", google: "LON:0JZ0", currency: "GBP" },
			],
		},
		{
			name: "Masco Corporation",
			symbol: "MAS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Construction Supplies & Fixtures",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "MSQ.F", google: "FRA:MSQ", currency: "EUR" },
				{ yahoo: "MAS", google: "NYSE:MAS", currency: "USD" },
				{ yahoo: "0JZ1.L", google: "LON:0JZ1", currency: "GBP" },
			],
		},
		{
			name: "McCormick & Company Inc.",
			symbol: "MKC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "MCX.F", google: "FRA:MCX", currency: "EUR" },
				{ yahoo: "MKC", google: "NYSE:MKC", currency: "USD" },
				{ yahoo: "0JZS.L", google: "LON:0JZS", currency: "GBP" },
			],
		},
		{
			name: "McKesson Corporation",
			symbol: "MCK",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Drug Retailers",
			],
			symbols: [
				{ yahoo: "MCK.F", google: "FRA:MCK", currency: "EUR" },
				{ yahoo: "MCK", google: "NYSE:MCK", currency: "USD" },
				{ yahoo: "0JZU.L", google: "LON:0JZU", currency: "GBP" },
			],
		},
		{
			name: "Mettler-Toledo International Inc.",
			symbol: "MTD",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "MTO.F", google: "FRA:MTO", currency: "EUR" },
				{ yahoo: "MTD", google: "NYSE:MTD", currency: "USD" },
				{ yahoo: "0K10.L", google: "LON:0K10", currency: "GBP" },
			],
		},
		{
			name: "MGM Resorts International",
			symbol: "MGM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Casinos & Gaming",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "MGG.F", google: "FRA:MGG", currency: "EUR" },
				{ yahoo: "MGM", google: "NYSE:MGM", currency: "USD" },
				{ yahoo: "0JWC.L", google: "LON:0JWC", currency: "GBP" },
			],
		},
		{
			name: "Mid-America Apartment Communities Inc.",
			symbol: "MAA",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Real Estate",
				"Financials",
				"Residential REITs",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "M2K.F", google: "FRA:M2K", currency: "EUR" },
				{ yahoo: "MAA", google: "NYSE:MAA", currency: "USD" },
				{ yahoo: "0K1E.L", google: "LON:0K1E", currency: "GBP" },
			],
		},
		{
			name: "Mohawk Industries Inc.",
			symbol: "MHK",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Household Goods",
				"Home Furnishings",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "MWK.F", google: "FRA:MWK", currency: "EUR" },
				{ yahoo: "MHK", google: "NYSE:MHK", currency: "USD" },
				{ yahoo: "0K2F.L", google: "LON:0K2F", currency: "GBP" },
			],
		},
		{
			name: "Moody's Corporation",
			symbol: "MCO",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Professional Information Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "DUT.F", google: "FRA:DUT", currency: "EUR" },
				{ yahoo: "MCO", google: "NYSE:MCO", currency: "USD" },
				{ yahoo: "0K36.L", google: "LON:0K36", currency: "GBP" },
			],
		},
		{
			name: "The Mosaic Company",
			symbol: "MOS",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Agricultural Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "02M.F", google: "FRA:02M", currency: "EUR" },
				{ yahoo: "MOS", google: "NYSE:MOS", currency: "USD" },
				{ yahoo: "0K3B.L", google: "LON:0K3B", currency: "GBP" },
			],
		},
		{
			name: "Motorola Solutions Inc.",
			symbol: "MSI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Communications & Networking",
			],
			symbols: [
				{ yahoo: "MTLA.F", google: "FRA:MTLA", currency: "EUR" },
				{ yahoo: "MSI", google: "NYSE:MSI", currency: "USD" },
				{ yahoo: "0K3H.L", google: "LON:0K3H", currency: "GBP" },
			],
		},
		{
			name: "MSCI Inc.",
			symbol: "MSCI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Professional Information Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "3HM.F", google: "FRA:3HM", currency: "EUR" },
				{ yahoo: "MSCI", google: "NYSE:MSCI", currency: "USD" },
				{ yahoo: "0A8Y.L", google: "LON:0A8Y", currency: "GBP" },
			],
		},
		{
			name: "Nasdaq Inc.",
			wiki_name: "Nasdaq",
			symbol: "NDAQ",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Financial & Commodity Market Operators",
			],
			symbols: [
				{ yahoo: "NAQ.F", google: "FRA:NAQ", currency: "EUR" },
				{ yahoo: "NDAQ", google: "NASDAQ:NDAQ", currency: "USD" },
				{ yahoo: "0K4T.L", google: "LON:0K4T", currency: "GBP" },
			],
		},
		{
			name: "Newell Brands Inc.",
			symbol: "NWL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Appliances, Tools & Housewares",
				"Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "NWL.F", google: "FRA:NWL", currency: "EUR" },
				{ yahoo: "NWL", google: "NASDAQ:NWL", currency: "USD" },
				{ yahoo: "0K7J.L", google: "LON:0K7J", currency: "GBP" },
			],
		},
		{
			name: "Newmont Goldcorp Corporation",
			symbol: "NEM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Gold",
				"Basic Materials",
			],
			symbols: [
				{ yahoo: "NMM.F", google: "FRA:NMM", currency: "EUR" },
				{ yahoo: "NEM", google: "NYSE:NEM", currency: "USD" },
			],
		},
		{
			name: "News Corporation",
			symbol: "NWSA",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Media & Publishing",
				"Consumer Publishing",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "NC0B.F", google: "FRA:NC0B", currency: "EUR" },
				{ yahoo: "NWSA", google: "NASDAQ:NWSA", currency: "USD" },
				{ yahoo: "NC0.F", google: "FRA:NC0", currency: "EUR" },
				{ yahoo: "NWS", google: "NASDAQ:NWS", currency: "USD" },
				{ yahoo: "0K7U.L", google: "LON:0K7U", currency: "GBP" },
			],
		},
		{
			name: "NiSource Inc.",
			symbol: "NI",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Multiline Utilities", "Utilities"],
			symbols: [
				{ yahoo: "NOU.F", google: "FRA:NOU", currency: "EUR" },
				{ yahoo: "NI", google: "NYSE:NI", currency: "USD" },
				{ yahoo: "0K87.L", google: "LON:0K87", currency: "GBP" },
			],
		},
		{
			name: "Northern Trust Corporation",
			symbol: "NTRS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "NT4.F", google: "FRA:NT4", currency: "EUR" },
				{ yahoo: "NTRS", google: "NASDAQ:NTRS", currency: "USD" },
				{ yahoo: "0K91.L", google: "LON:0K91", currency: "GBP" },
			],
		},
		{
			name: "Norwegian Cruise Line Holdings Ltd.",
			symbol: "NCLH",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Cyclical Consumer Services",
				"Hotels, Motels & Cruise Lines",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "1NC.F", google: "FRA:1NC", currency: "EUR" },
				{ yahoo: "NCLH", google: "NYSE:NCLH", currency: "USD" },
				{ yahoo: "0UC3.L", google: "LON:0UC3", currency: "GBP" },
			],
		},
		{
			name: "NRG Energy Inc.",
			symbol: "NRG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "NRA.F", google: "FRA:NRA", currency: "EUR" },
				{ yahoo: "NRG", google: "NYSE:NRG", currency: "USD" },
			],
		},
		{
			name: "Nucor Corporation",
			symbol: "NUE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Basic Materials",
				"Steel",
			],
			symbols: [
				{ yahoo: "NUO.F", google: "FRA:NUO", currency: "EUR" },
				{ yahoo: "NUE", google: "NYSE:NUE", currency: "USD" },
				{ yahoo: "0K9L.L", google: "LON:0K9L", currency: "GBP" },
			],
		},
		{
			name: "Omnicom Group Inc.",
			symbol: "OMC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Media & Publishing",
				"Advertising & Marketing",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "OCN.F", google: "FRA:OCN", currency: "EUR" },
				{ yahoo: "OMC", google: "NYSE:OMC", currency: "USD" },
				{ yahoo: "0KBK.L", google: "LON:0KBK", currency: "GBP" },
			],
		},
		{
			name: "ONEOK Inc.",
			symbol: "OKE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas Transportation Services",
				"Oil & Gas Related Equipment and Services",
			],
			symbols: [
				{ yahoo: "ONK.F", google: "FRA:ONK", currency: "EUR" },
				{ yahoo: "OKE", google: "NYSE:OKE", currency: "USD" },
				{ yahoo: "0KCI.L", google: "LON:0KCI", currency: "GBP" },
			],
		},
		{
			name: "Packaging Corporation of America",
			symbol: "PKG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Paper Packaging",
				"Basic Materials",
				"Containers & Packaging",
				"Applied Resources",
			],
			symbols: [
				{ yahoo: "PKG", google: "NYSE:PKG", currency: "USD" },
				{ yahoo: "PKA.F", google: "FRA:PKA", currency: "EUR" },
				{ yahoo: "0KEZ.L", google: "LON:0KEZ", currency: "GBP" },
			],
		},
		{
			name: "Parker-Hannifin Corporation",
			symbol: "PH",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "PH.F", google: "FRA:PH", currency: "EUR" },
				{ yahoo: "PH", google: "NYSE:PH", currency: "USD" },
				{ yahoo: "PAR.F", google: "FRA:PAR", currency: "EUR" },
				{ yahoo: "0KFZ.L", google: "LON:0KFZ", currency: "GBP" },
			],
		},
		{
			name: "Pentair PLC",
			symbol: "PNR",
			country: "United Kingdom",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "PNT.F", google: "FRA:PNT", currency: "EUR" },
				{ yahoo: "PNR", google: "NYSE:PNR", currency: "USD" },
				{ yahoo: "0Y5X.L", google: "LON:0Y5X", currency: "GBP" },
			],
		},
		{
			name: "PerkinElmer Inc.",
			symbol: "PKI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "PKN.F", google: "FRA:PKN", currency: "EUR" },
				{ yahoo: "PKI", google: "NYSE:PKI", currency: "USD" },
				{ yahoo: "0KHE.L", google: "LON:0KHE", currency: "GBP" },
			],
		},
		{
			name: "Phillips 66",
			symbol: "PSX",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "R66.F", google: "FRA:R66", currency: "EUR" },
				{ yahoo: "PSX", google: "NYSE:PSX", currency: "USD" },
				{ yahoo: "0KHZ.L", google: "LON:0KHZ", currency: "GBP" },
			],
		},
		{
			name: "Pinnacle West Capital Corporation",
			symbol: "PNW",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "PWC.F", google: "FRA:PWC", currency: "EUR" },
				{ yahoo: "PNW", google: "NYSE:PNW", currency: "USD" },
				{ yahoo: "0KIT.L", google: "LON:0KIT", currency: "GBP" },
			],
		},
		{
			name: "Pioneer Natural Resources Company",
			symbol: "PXD",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas",
				"Oil & Gas Exploration and Production",
			],
			symbols: [
				{ yahoo: "PNK.F", google: "FRA:PNK", currency: "EUR" },
				{ yahoo: "PXD", google: "NYSE:PXD", currency: "USD" },
				{ yahoo: "0KIX.L", google: "LON:0KIX", currency: "GBP" },
			],
		},
		{
			name: "The PNC Financials Group Inc.",
			symbol: "PNC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "PNP.F", google: "FRA:PNP", currency: "EUR" },
				{ yahoo: "PNC", google: "NYSE:PNC", currency: "USD" },
			],
		},
		{
			name: "PPG Industries Inc.",
			symbol: "PPG",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "PPQ.F", google: "FRA:PPQ", currency: "EUR" },
				{ yahoo: "PPG", google: "NYSE:PPG", currency: "USD" },
				{ yahoo: "0KEI.L", google: "LON:0KEI", currency: "GBP" },
			],
		},
		{
			name: "PPL Corporation",
			symbol: "PPL",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Multiline Utilities", "Utilities"],
			symbols: [
				{ yahoo: "PP9.F", google: "FRA:PP9", currency: "EUR" },
				{ yahoo: "PPL", google: "NYSE:PPL", currency: "USD" },
				{ yahoo: "0KEJ.L", google: "LON:0KEJ", currency: "GBP" },
			],
		},
		{
			name: "The Progressive Corporation",
			symbol: "PGR",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance", "Property & Casualty Insurance", "Financials"],
			symbols: [
				{ yahoo: "PGV.F", google: "FRA:PGV", currency: "EUR" },
				{ yahoo: "PGR", google: "NYSE:PGR", currency: "USD" },
				{ yahoo: "0KOC.L", google: "LON:0KOC", currency: "GBP" },
			],
		},
		{
			name: "Prologis Inc.",
			symbol: "PLD",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "POJN.F", google: "FRA:POJN", currency: "EUR" },
				{ yahoo: "PLD", google: "NYSE:PLD", currency: "USD" },
				{ yahoo: "PLDGP", google: "OTCMKTS:PLDGP", currency: "USD" },
				{ yahoo: "0KOD.L", google: "LON:0KOD", currency: "GBP" },
			],
		},
		{
			name: "Prudential Financial Inc.",
			symbol: "PRU",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance", "Financials", "Life & Health Insurance"],
			symbols: [
				{ yahoo: "PLL.F", google: "FRA:PLL", currency: "EUR" },
				{ yahoo: "PRU", google: "NYSE:PRU", currency: "USD" },
				{ yahoo: "0KRX.L", google: "LON:0KRX", currency: "GBP" },
			],
		},
		{
			name: "Public Service Enterprise Group Inc.",
			symbol: "PEG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrials",
				"Real Estate",
				"Industrial & Commercial Services",
				"Environmental Services & Equipment",
				"Professional & Commercial Services",
				"Financials",
				"Residential & Commercial RETIs",
				"Specialized REITs",
			],
			symbols: [
				{ yahoo: "PSE.F", google: "FRA:PSE", currency: "EUR" },
				{ yahoo: "PEG", google: "NYSE:PEG", currency: "USD" },
				{ yahoo: "0KS2.L", google: "LON:0KS2", currency: "GBP" },
			],
		},
		{
			name: "Public Storage",
			symbol: "PSA",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Specialized REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "PUP.F", google: "FRA:PUP", currency: "EUR" },
				{ yahoo: "PSA", google: "NYSE:PSA", currency: "USD" },
				{ yahoo: "PBSTV", google: "OTCMKTS:PBSTV", currency: "USD" },
			],
		},
		{
			name: "PulteGroup Inc.",
			symbol: "PHM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Homebuilding",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "PU7.F", google: "FRA:PU7", currency: "EUR" },
				{ yahoo: "PHM", google: "NYSE:PHM", currency: "USD" },
				{ yahoo: "0KS6.L", google: "LON:0KS6", currency: "GBP" },
			],
		},
		{
			name: "Qorvo Inc.",
			symbol: "QRVO",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Semiconductor Equipment & Testing",
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
			],
			symbols: [
				{ yahoo: "2QO.F", google: "FRA:2QO", currency: "EUR" },
				{ yahoo: "QRVO", google: "NASDAQ:QRVO", currency: "USD" },
				{ yahoo: "0KSJ.L", google: "LON:0KSJ", currency: "GBP" },
			],
		},
		{
			name: "Quanta Services Inc.",
			symbol: "PWR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "QAA.F", google: "FRA:QAA", currency: "EUR" },
				{ yahoo: "PWR", google: "NYSE:PWR", currency: "USD" },
				{ yahoo: "0KSR.L", google: "LON:0KSR", currency: "GBP" },
			],
		},
		{
			name: "Quest Diagnostics Inc.",
			symbol: "DGX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Facilities & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "QDI.F", google: "FRA:QDI", currency: "EUR" },
				{ yahoo: "DGX", google: "NYSE:DGX", currency: "USD" },
			],
		},
		{
			name: "Ralph Lauren Corporation",
			symbol: "RL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Textiles & Apparel",
				"Apparel & Accessories",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "PRL.F", google: "FRA:PRL", currency: "EUR" },
				{ yahoo: "RL", google: "NYSE:RL", currency: "USD" },
				{ yahoo: "0KTS.L", google: "LON:0KTS", currency: "GBP" },
			],
		},
		{
			name: "Raymond James Financial Inc.",
			symbol: "RJF",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Banking & Brokerage Services",
			],
			symbols: [
				{ yahoo: "RJF.F", google: "FRA:RJF", currency: "EUR" },
				{ yahoo: "RJF", google: "NYSE:RJF", currency: "USD" },
				{ yahoo: "0KU1.L", google: "LON:0KU1", currency: "GBP" },
			],
		},
		{
			name: "Realty Income Corporation",
			symbol: "O",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "RY6.F", google: "FRA:RY6", currency: "EUR" },
				{ yahoo: "UHT", google: "NYSE:UHT", currency: "USD" },
				{ yahoo: "O", google: "NYSE:O", currency: "USD" },
				{ yahoo: "0KUE.L", google: "LON:0KUE", currency: "GBP" },
			],
		},
		{
			name: "Regions Financial Corporation",
			symbol: "RF",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "RN7.F", google: "FRA:RN7", currency: "EUR" },
				{ yahoo: "RF-PB", google: "NYSE:RF-PB", currency: "USD" },
				{ yahoo: "RF", google: "NYSE:RF", currency: "USD" },
				{ yahoo: "0KV3.L", google: "LON:0KV3", currency: "GBP" },
			],
		},
		{
			name: "Republic Services Inc.",
			symbol: "RSG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Environmental Services & Equipment",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "RPU.F", google: "FRA:RPU", currency: "EUR" },
				{ yahoo: "RSG", google: "NYSE:RSG", currency: "USD" },
				{ yahoo: "0KW1.L", google: "LON:0KW1", currency: "GBP" },
			],
		},
		{
			name: "ResMed Inc.",
			symbol: "RMD",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "RMEA.F", google: "FRA:RMEA", currency: "EUR" },
				{ yahoo: "RMD", google: "NYSE:RMD", currency: "USD" },
				{ yahoo: "0KW4.L", google: "LON:0KW4", currency: "GBP" },
				{ yahoo: "RME.F", google: "FRA:RME", currency: "EUR" },
				{ yahoo: "RSMDF", google: "OTCMKTS:RSMDF", currency: "USD" },
			],
		},
		{
			name: "Robert Half International Inc.",
			symbol: "RHI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrials",
				"Professional & Commercial Services",
				"Employment Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "RHJ.F", google: "FRA:RHJ", currency: "EUR" },
				{ yahoo: "RHI", google: "NYSE:RHI", currency: "USD" },
				{ yahoo: "0KX9.L", google: "LON:0KX9", currency: "GBP" },
			],
		},
		{
			name: "Rockwell Automation Inc.",
			symbol: "ROK",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Electrical Components & Equipment",
			],
			symbols: [
				{ yahoo: "RWL.F", google: "FRA:RWL", currency: "EUR" },
				{ yahoo: "ROK", google: "NYSE:ROK", currency: "USD" },
				{ yahoo: "0KXA.L", google: "LON:0KXA", currency: "GBP" },
			],
		},
		{
			name: "Rollins Inc.",
			symbol: "ROL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Business Support Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "RLS.F", google: "FRA:RLS", currency: "EUR" },
				{ yahoo: "ROL", google: "NYSE:ROL", currency: "USD" },
			],
		},
		{
			name: "Roper Technologies Inc.",
			symbol: "ROP",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "ROP.F", google: "FRA:ROP", currency: "EUR" },
				{ yahoo: "ROP", google: "NYSE:ROP", currency: "USD" },
				{ yahoo: "0KXM.L", google: "LON:0KXM", currency: "GBP" },
			],
		},
		{
			name: "Royal Caribbean Cruises Ltd.",
			symbol: "RCL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Cyclical Consumer Services",
				"Hotels, Motels & Cruise Lines",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "RC8.F", google: "FRA:RC8", currency: "EUR" },
				{ yahoo: "RCL", google: "NYSE:RCL", currency: "USD" },
				{ yahoo: "0I1W.L", google: "LON:0I1W", currency: "GBP" },
			],
		},
		{
			name: "S&P Global Inc.",
			symbol: "SPGI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Professional Information Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "MHL.F", google: "FRA:MHL", currency: "EUR" },
				{ yahoo: "SPGI", google: "NYSE:SPGI", currency: "USD" },
				{ yahoo: "0KYY.L", google: "LON:0KYY", currency: "GBP" },
			],
		},
		{
			name: "salesforce.com Inc.",
			symbol: "CRM",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "FOO.F", google: "FRA:FOO", currency: "EUR" },
				{ yahoo: "CRM", google: "NYSE:CRM", currency: "USD" },
				{ yahoo: "FOO0.F", google: "FRA:FOO0", currency: "EUR" },
				{ yahoo: "0QYJ.L", google: "LON:0QYJ", currency: "GBP" },
			],
		},
		{
			name: "SBA Communications Corporation",
			symbol: "SBAC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Specialized REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "4SB.F", google: "FRA:4SB", currency: "EUR" },
				{ yahoo: "SBAC", google: "NASDAQ:SBAC", currency: "USD" },
				{ yahoo: "0KYZ.L", google: "LON:0KYZ", currency: "GBP" },
			],
		},
		{
			name: "The Charles Schwab Corporation",
			symbol: "SCHW",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: ["Financials"],
			symbols: [
				{ yahoo: "SWG.F", google: "FRA:SWG", currency: "EUR" },
				{ yahoo: "SCHW", google: "NYSE:SCHW", currency: "USD" },
				{ yahoo: "0L3I.L", google: "LON:0L3I", currency: "GBP" },
				{ yahoo: "SWG0.F", google: "FRA:SWG0", currency: "EUR" },
			],
		},
		{
			name: "Sealed Air Corporation",
			symbol: "SEE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Non-Paper Containers & Packaging",
				"Basic Materials",
				"Containers & Packaging",
				"Applied Resources",
			],
			symbols: [
				{ yahoo: "SDA.F", google: "FRA:SDA", currency: "EUR" },
				{ yahoo: "SEE", google: "NYSE:SEE", currency: "USD" },
				{ yahoo: "0L4F.L", google: "LON:0L4F", currency: "GBP" },
			],
		},
		{
			name: "Sempra Energy",
			symbol: "SRE",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Multiline Utilities", "Utilities"],
			symbols: [
				{ yahoo: "SE4.F", google: "FRA:SE4", currency: "EUR" },
				{ yahoo: "SRE", google: "NYSE:SRE", currency: "USD" },
				{ yahoo: "0L5A.L", google: "LON:0L5A", currency: "GBP" },
			],
		},
		{
			name: "The Sherwin-Williams Company",
			symbol: "SHW",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "SJ3.F", google: "FRA:SJ3", currency: "EUR" },
				{ yahoo: "SHW", google: "NYSE:SHW", currency: "USD" },
				{ yahoo: "0L5V.L", google: "LON:0L5V", currency: "GBP" },
			],
		},
		{
			name: "Snap-on Inc.",
			symbol: "SNA",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "SPU.F", google: "FRA:SPU", currency: "EUR" },
				{ yahoo: "SNA", google: "NYSE:SNA", currency: "USD" },
			],
		},
		{
			name: "Southwest Airlines Co.",
			symbol: "LUV",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Passenger Transportation Services",
				"Industrials",
				"Airlines",
				"Transportation",
			],
			symbols: [
				{ yahoo: "SWN.F", google: "FRA:SWN", currency: "EUR" },
				{ yahoo: "LUV", google: "NYSE:LUV", currency: "USD" },
				{ yahoo: "0L8F.L", google: "LON:0L8F", currency: "GBP" },
			],
		},
		{
			name: "Stanley Black & Decker Inc.",
			symbol: "SWK",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "SWF.F", google: "FRA:SWF", currency: "EUR" },
				{ yahoo: "SWK", google: "NYSE:SWK", currency: "USD" },
				{ yahoo: "0L9E.L", google: "LON:0L9E", currency: "GBP" },
			],
		},
		{
			name: "State Street Corporation",
			symbol: "STT",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "ZYA.F", google: "FRA:ZYA", currency: "EUR" },
				{ yahoo: "STT", google: "NYSE:STT", currency: "USD" },
				{ yahoo: "0L9G.L", google: "LON:0L9G", currency: "GBP" },
			],
		},
		{
			name: "Stryker Corporation",
			symbol: "SYK",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "SYK.F", google: "FRA:SYK", currency: "EUR" },
				{ yahoo: "SYK", google: "NYSE:SYK", currency: "USD" },
				{ yahoo: "0R2S.L", google: "LON:0R2S", currency: "GBP" },
			],
		},
		{
			name: "SVB Financial Group",
			symbol: "SIVB",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "SV4.F", google: "FRA:SV4", currency: "EUR" },
				{ yahoo: "SIVB", google: "NASDAQ:SIVB", currency: "USD" },
			],
		},
		{
			name: "Synchrony Financial",
			symbol: "SYF",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Consumer Lending",
			],
			symbols: [
				{ yahoo: "SFE.F", google: "FRA:SFE", currency: "EUR" },
				{ yahoo: "SYF", google: "NYSE:SYF", currency: "USD" },
				{ yahoo: "0LC3.L", google: "LON:0LC3", currency: "GBP" },
			],
		},
		{
			name: "Synopsys Inc.",
			symbol: "SNPS",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: ["Software", "Technology", "Software & IT Services"],
			symbols: [
				{ yahoo: "SYP.F", google: "FRA:SYP", currency: "EUR" },
				{ yahoo: "SNPS", google: "NASDAQ:SNPS", currency: "USD" },
				{ yahoo: "0LBP.L", google: "LON:0LBP", currency: "GBP" },
			],
		},
		{
			name: "Sysco Corporation",
			symbol: "SYY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Food Retail & Distribution",
			],
			symbols: [
				{ yahoo: "SYY.F", google: "FRA:SYY", currency: "EUR" },
				{ yahoo: "SYY", google: "NYSE:SYY", currency: "USD" },
				{ yahoo: "0LC6.L", google: "LON:0LC6", currency: "GBP" },
			],
		},
		{
			name: "T. Rowe Price Group Inc.",
			symbol: "TROW",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "TR1.F", google: "FRA:TR1", currency: "EUR" },
				{ yahoo: "TROW", google: "NASDAQ:TROW", currency: "USD" },
				{ yahoo: "0KNY.L", google: "LON:0KNY", currency: "GBP" },
			],
		},
		{
			name: "Take-Two Interactive Software Inc.",
			symbol: "TTWO",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Leisure Products",
				"Toys & Juvenile Products",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "TKE.F", google: "FRA:TKE", currency: "EUR" },
				{ yahoo: "TTWO", google: "NASDAQ:TTWO", currency: "USD" },
				{ yahoo: "0LCX.L", google: "LON:0LCX", currency: "GBP" },
			],
		},
		{
			name: "Tapestry Inc.",
			symbol: "TPR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Retailers",
				"Apparel & Accessories Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "CO.F", google: "FRA:COY", currency: "EUR" },
				{ yahoo: "TPR", google: "NYSE:TPR", currency: "USD" },
				{ yahoo: "0LD5.L", google: "LON:0LD5", currency: "GBP" },
				{ yahoo: "COY.F", google: "FRA:COY", currency: "EUR" },
			],
		},
		{
			name: "TE Connectivity Ltd.",
			symbol: "TEL",
			country: "Switzerland",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Electrical Components & Equipment",
			],
			symbols: [
				{ yahoo: "9TC.F", google: "FRA:9TC", currency: "EUR" },
				{ yahoo: "TEL", google: "NYSE:TEL", currency: "USD" },
			],
		},
		{
			name: "Teleflex Inc.",
			symbol: "TFX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "TBH.F", google: "FRA:TBH", currency: "EUR" },
				{ yahoo: "TFX", google: "NYSE:TFX", currency: "USD" },
			],
		},
		{
			name: "Thermo Fisher Scientific Inc.",
			symbol: "TMO",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "TN8.F", google: "FRA:TN8", currency: "EUR" },
				{ yahoo: "TMO", google: "NYSE:TMO", currency: "USD" },
				{ yahoo: "0R0H.L", google: "LON:0R0H", currency: "GBP" },
			],
		},
		{
			name: "The TJX Companies Inc.",
			symbol: "TJX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Diversified Retail",
				"Retailers",
				"Discount Stores",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "TJX.F", google: "FRA:TJX", currency: "EUR" },
				{ yahoo: "TJX", google: "NYSE:TJX", currency: "USD" },
				{ yahoo: "0LCE.L", google: "LON:0LCE", currency: "GBP" },
			],
		},
		{
			name: "Twitter Inc.",
			symbol: "TWTR",
			country: "United States",
			indices: [],
			industries: ["Technology", "Software & IT Services", "Internet Services"],
			symbols: [
				{ yahoo: "TWR.F", google: "FRA:TWR", currency: "EUR" },
				{ yahoo: "TWTR", google: "NYSE:TWTR", currency: "USD" },
			],
		},
		{
			name: "Tyson Foods Inc.",
			symbol: "TSN",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "TF7A.F", google: "FRA:TF7A", currency: "EUR" },
				{ yahoo: "TSN", google: "NYSE:TSN", currency: "USD" },
				{ yahoo: "0LHR.L", google: "LON:0LHR", currency: "GBP" },
			],
		},
		{
			name: "UDR Inc.",
			symbol: "UDR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Real Estate",
				"Financials",
				"Residential REITs",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "UF0.F", google: "FRA:UF0", currency: "EUR" },
				{ yahoo: "UDR", google: "NYSE:UDR", currency: "USD" },
				{ yahoo: "0LHS.L", google: "LON:0LHS", currency: "GBP" },
			],
		},
		{
			name: "United Continental Holdings Inc.",
			symbol: "UAL",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
			symbols: [
				{ yahoo: "UAL1.F", google: "FRA:UAL1", currency: "EUR" },
				{ yahoo: "UAL", google: "NASDAQ:UAL", currency: "USD" },
				{ yahoo: "0LIU.L", google: "LON:0LIU", currency: "GBP" },
			],
		},
		{
			name: "United Rentals Inc.",
			symbol: "URI",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Business Support Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "UR3.F", google: "FRA:UR3", currency: "EUR" },
				{ yahoo: "URI", google: "NYSE:URI", currency: "USD" },
				{ yahoo: "0LIY.L", google: "LON:0LIY", currency: "GBP" },
			],
		},
		{
			name: "Universal Health Services Inc.",
			symbol: "UHS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Healthcare Providers & Services",
				"Healthcare Facilities & Services",
				"Healthcare Services",
			],
			symbols: [
				{ yahoo: "UHS.F", google: "FRA:UHS", currency: "EUR" },
				{ yahoo: "UHS", google: "NYSE:UHS", currency: "USD" },
				{ yahoo: "0LJL.L", google: "LON:0LJL", currency: "GBP" },
				{ yahoo: "UHID", google: "OTCMKTS:UHID", currency: "USD" },
			],
		},
		{
			name: "V.F. Corporation",
			symbol: "VFC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Textiles & Apparel",
				"Apparel & Accessories",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "VFP.F", google: "FRA:VFP", currency: "EUR" },
				{ yahoo: "VFC", google: "NYSE:VFC", currency: "USD" },
				{ yahoo: "0R30.L", google: "LON:0R30", currency: "GBP" },
			],
		},
		{
			name: "Valero Energy Corporation",
			symbol: "VLO",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "V1L.F", google: "FRA:V1L", currency: "EUR" },
				{ yahoo: "VLO", google: "NYSE:VLO", currency: "USD" },
				{ yahoo: "0LK6.L", google: "LON:0LK6", currency: "GBP" },
			],
		},
		{
			name: "Ventas Inc.",
			symbol: "VTR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Specialized REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "VEN.F", google: "FRA:VEN", currency: "EUR" },
				{ yahoo: "VTR", google: "NYSE:VTR", currency: "USD" },
				{ yahoo: "0LO4.L", google: "LON:0LO4", currency: "GBP" },
			],
		},
		{
			name: "VeriSign Inc.",
			symbol: "VRSN",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "VRS.F", google: "FRA:VRSN", currency: "EUR" },
				{ yahoo: "VRSN", google: "NASDAQ:VRSN", currency: "USD" },
				{ yahoo: "0LOZ.L", google: "LON:0LOZ", currency: "GBP" },
			],
		},
		{
			name: "Vornado Realty Trust",
			symbol: "VNO",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "VO7.F", google: "FRA:VO7", currency: "EUR" },
				{ yahoo: "VNO", google: "NYSE:VNO", currency: "USD" },
				{ yahoo: "0LR2.L", google: "LON:0LR2", currency: "GBP" },
				{ yahoo: "VNORP", google: "OTCMKTS:VNORP", currency: "USD" },
			],
		},
		{
			name: "Vulcan Materials Company",
			symbol: "VMC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Construction Materials",
				"Mineral Resources",
				"Basic Materials",
			],
			symbols: [
				{ yahoo: "VMC.F", google: "FRA:VMC", currency: "EUR" },
				{ yahoo: "VMC", google: "NYSE:VMC", currency: "USD" },
				{ yahoo: "0LRK.L", google: "LON:0LRK", currency: "GBP" },
			],
		},
		{
			name: "Wabtec Corporation",
			symbol: "WAB",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Heavy Machinery & Vehicles",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "WB2.F", google: "FRA:WB2", currency: "EUR" },
				{ yahoo: "WAB", google: "NYSE:WAB", currency: "USD" },
			],
		},
		{
			name: "Waste Management Inc.",
			symbol: "WM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Environmental Services & Equipment",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "UWS.F", google: "FRA:UWS", currency: "EUR" },
				{ yahoo: "WM", google: "NYSE:WM", currency: "USD" },
				{ yahoo: "0LTG.L", google: "LON:0LTG", currency: "GBP" },
			],
		},
		{
			name: "Waters Corporation",
			symbol: "WAT",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "WAZ.F", google: "FRA:WAZ", currency: "EUR" },
				{ yahoo: "WAT", google: "NYSE:WAT", currency: "USD" },
				{ yahoo: "0LTI.L", google: "LON:0LTI", currency: "GBP" },
			],
		},
		{
			name: "WEC Energy Group Inc.",
			symbol: "WEC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "WIC.F", google: "FRA:WIC", currency: "EUR" },
				{ yahoo: "WEC", google: "NYSE:WEC", currency: "USD" },
				{ yahoo: "0LSL.L", google: "LON:0LSL", currency: "GBP" },
			],
		},
		{
			name: "Welltower Inc.",
			symbol: "WELL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Specialized REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "HCW.F", google: "FRA:HCW", currency: "EUR" },
				{ yahoo: "WELL", google: "NYSE:WELL", currency: "USD" },
				{ yahoo: "0LUS.L", google: "LON:0LUS", currency: "GBP" },
			],
		},
		{
			name: "WestRock Company",
			symbol: "WRK",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Paper Packaging",
				"Basic Materials",
				"Containers & Packaging",
				"Applied Resources",
			],
			symbols: [
				{ yahoo: "WRK.F", google: "FRA:WRK", currency: "EUR" },
				{ yahoo: "WRK", google: "OTCMKTS:WRK", currency: "USD" },
				{ yahoo: "1WR.F", google: "FRA:1WR", currency: "EUR" },
				{ yahoo: "0LW9.L", google: "LON:0LW9", currency: "GBP" },
			],
		},
		{
			name: "Weyerhaeuser Company",
			symbol: "WY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Specialized REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "WHC.F", google: "FRA:WHC", currency: "EUR" },
				{ yahoo: "WY", google: "NYSE:WY", currency: "USD" },
				{ yahoo: "0LWG.L", google: "LON:0LWG", currency: "GBP" },
			],
		},
		{
			name: "Whirlpool Corporation",
			symbol: "WHR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Cyclical Consumer Products",
				"Appliances, Tools & Housewares",
				"Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "WHR.F", google: "FRA:WHR", currency: "EUR" },
				{ yahoo: "WHR", google: "NYSE:WHR", currency: "USD" },
				{ yahoo: "0LWH.L", google: "LON:0LWH", currency: "GBP" },
			],
		},
		{
			name: "The Williams Companies Inc.",
			symbol: "WMB",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Oil & Gas Transportation Services",
				"Oil & Gas Related Equipment and Services",
			],
			symbols: [
				{ yahoo: "WMB.F", google: "FRA:WMB", currency: "EUR" },
				{ yahoo: "WMB", google: "NYSE:WMB", currency: "USD" },
				{ yahoo: "0LXB.L", google: "LON:0LXB", currency: "GBP" },
			],
		},
		{
			name: "WTW Public Limited Company",
			symbol: "WLTW",
			country: "United Kingdom",
			indices: ["S&P 500"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "WTY.F", google: "FRA:WTY", currency: "EUR" },
				{ yahoo: "WLTW", google: "NASDAQ:WLTW", currency: "USD" },
				{ yahoo: "0Y4Q.L", google: "LON:0Y4Q", currency: "GBP" },
				{ yahoo: "WTW", google: "NASDAQ:WTW", currency: "USD" },
			],
		},
		{
			name: "Wynn Resorts, Limited",
			symbol: "WYNN",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Casinos & Gaming",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "WYR.F", google: "FRA:WYR", currency: "EUR" },
				{ yahoo: "WYNN", google: "NASDAQ:WYNN", currency: "USD" },
				{ yahoo: "0QYK.L", google: "LON:0QYK", currency: "GBP" },
			],
		},
		{
			name: "Xylem Inc.",
			symbol: "XYL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "XY6.F", google: "FRA:XY6", currency: "EUR" },
				{ yahoo: "XYL", google: "NYSE:XYL", currency: "USD" },
				{ yahoo: "0M29.L", google: "LON:0M29", currency: "GBP" },
			],
		},
		{
			name: "YUM! Brands Inc.",
			symbol: "YUM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Hotels & Entertainment Services",
				"Restaurants & Bars",
				"Cyclical Consumer Services",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "TGR.F", google: "FRA:TGR", currency: "EUR" },
				{ yahoo: "YUM", google: "NYSE:YUM", currency: "USD" },
				{ yahoo: "0QYD.L", google: "LON:0QYD", currency: "GBP" },
			],
		},
		{
			name: "Zimmer Biomet Holdings Inc.",
			symbol: "ZBH",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "ZIM.F", google: "FRA:ZIM", currency: "EUR" },
				{ yahoo: "ZBH", google: "NYSE:ZBH", currency: "USD" },
			],
		},
		{
			name: "Zions Bancorporation, National Association",
			symbol: "ZION",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "ZB1.F", google: "FRA:ZB1", currency: "EUR" },
				{ yahoo: "ZION", google: "NASDAQ:ZION", currency: "USD" },
				{ yahoo: "0M3L.L", google: "LON:0M3L", currency: "GBP" },
			],
		},
		{
			name: "Zoetis Inc.",
			symbol: "ZTS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "ZOE.F", google: "FRA:ZOE", currency: "EUR" },
				{ yahoo: "ZTS", google: "NYSE:ZTS", currency: "USD" },
				{ yahoo: "0M3Q.L", google: "LON:0M3Q", currency: "GBP" },
			],
		},
		{
			name: "Anheuser-Busch InBev SA/NV",
			symbol: "ABI",
			country: "Belgium",
			indices: ["BEL 20", "EURO STOXX 50"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Brewers",
				"Beverages",
			],
			symbols: [
				{ yahoo: "1NBA.F", google: "FRA:1NBA", currency: "EUR" },
				{ yahoo: "BUD", google: "NYSE:BUD", currency: "USD" },
				{ yahoo: "BUDFF", google: "OTCMKTS:BUDFF", currency: "USD" },
				{ yahoo: "ITKA.F", google: "FRA:ITKA", currency: "EUR" },
			],
		},
		{
			name: "Ackermans & van Haaren",
			wiki_name: "Ackermans & van Haaren",
			symbol: "XBRU",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [
				"Conglomerate",
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "B3K.F", google: "FRA:B3K", currency: "EUR" },
				{ yahoo: "AVHNF", google: "OTCMKTS:AVHNF", currency: "USD" },
				{ yahoo: "AVHNY", google: "OTCMKTS:AVHNY", currency: "USD" },
			],
			isins: ["BE0003764785"],
			metadata: { founded: 1880, employees: 21522 },
			akas: [],
		},
		{
			name: "Ageas SA/NV",
			symbol: "AGS",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "FO4N.F", google: "FRA:FO4N", currency: "EUR" },
				{ yahoo: "AGESF", google: "OTCMKTS:AGESF", currency: "USD" },
				{ yahoo: "AGESY", google: "OTCMKTS:AGESY", currency: "USD" },
				{ yahoo: "0Q99.L", google: "LON:0Q99", currency: "GBP" },
				{ yahoo: "FO4.F", google: "FRA:FO4", currency: "EUR" },
			],
		},
		{
			name: "Aperam SA",
			symbol: "APAM",
			country: "Luxembourg",
			indices: ["BEL 20", "CAC Mid 60"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Basic Materials",
				"Steel",
			],
			symbols: [
				{ yahoo: "7AA.F", google: "FRA:7AA", currency: "EUR" },
				{ yahoo: "APEMY", google: "OTCMKTS:APEMY", currency: "USD" },
				{ yahoo: "APAM.AS", google: "AMS:APAM", currency: "EUR" },
				{ yahoo: "APAM.MC", google: "BME:APAM", currency: "EUR" },
				{ yahoo: "0OLF.L", google: "LON:0OLF", currency: "GBP" },
				{ yahoo: "7AAN.F", google: "FRA:7AAN", currency: "EUR" },
			],
		},
		{
			name: "argenx SE",
			symbol: "ARGX",
			country: "Netherlands",
			indices: ["BEL 20"],
			industries: [
				"Biotechnology & Medical Research",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "1AE.F", google: "FRA:1AE", currency: "EUR" },
				{ yahoo: "ARGX", google: "NASDAQ:ARGX", currency: "USD" },
				{ yahoo: "ARGNF", google: "OTCMKTS:ARGNF", currency: "USD" },
				{ yahoo: "1AEA.F", google: "FRA:1AEA", currency: "EUR" },
			],
		},
		{
			name: "Cofinimmo SA",
			symbol: "COFB",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "COF.F", google: "FRA:COF", currency: "EUR" },
				{ yahoo: "0J3X.L", google: "LON:0J3X", currency: "GBP" },
				{ yahoo: "CFMOF", google: "OTCMKTS:CFMOF", currency: "USD" },
			],
		},
		{
			name: "Galapagos NV",
			symbol: "GLPG",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [
				"Biotechnology & Medical Research",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "GXE.F", google: "FRA:GXE", currency: "EUR" },
				{ yahoo: "GLPG", google: "NASDAQ:GLPG", currency: "USD" },
				{ yahoo: "GLPG.AS", google: "AMS:GLPG", currency: "EUR" },
				{ yahoo: "GLPGF", google: "OTCMKTS:GLPGF", currency: "USD" },
				{ yahoo: "GXEA.F", google: "FRA:GXEA", currency: "EUR" },
				{ yahoo: "0JXZ.L", google: "LON:0JXZ", currency: "GBP" },
			],
		},
		{
			name: "Groupe Bruxelles Lambert SA",
			symbol: "GBLB",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: ["Diversified Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "EAI.F", google: "FRA:EAI", currency: "EUR" },
				{ yahoo: "GBLBF", google: "OTCMKTS:GBLBF", currency: "USD" },
				{ yahoo: "GBLBY", google: "OTCMKTS:GBLBY", currency: "USD" },
				{ yahoo: "EAI0.F", google: "FRA:EAI0", currency: "EUR" },
				{ yahoo: "0IN2.L", google: "LON:0IN2", currency: "GBP" },
			],
		},
		{
			name: "ING Groep N.V.",
			symbol: "INGA",
			country: "Netherlands",
			indices: ["AEX", "EURO STOXX 50"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "INN1.F", google: "FRA:INN1", currency: "EUR" },
				{ yahoo: "ISG", google: "NYSE:ISG", currency: "USD" },
				{ yahoo: "ING", google: "NYSE:ING", currency: "USD" },
				{ yahoo: "INGVF", google: "OTCMKTS:INGVF", currency: "USD" },
				{ yahoo: "INGA.AS", google: "AMS:INGA", currency: "EUR" },
				{ yahoo: "INNA.F", google: "FRA:INNA", currency: "EUR" },
			],
		},
		{
			name: "KBC Group NV",
			symbol: "KBC",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "KDB.F", google: "FRA:KDB", currency: "EUR" },
				{ yahoo: "KBCSF", google: "OTCMKTS:KBCSF", currency: "USD" },
				{ yahoo: "KBCSY", google: "OTCMKTS:KBCSY", currency: "USD" },
				{ yahoo: "KDB0.F", google: "FRA:KDB0", currency: "EUR" },
				{ yahoo: "0EYG.L", google: "LON:0EYG", currency: "GBP" },
			],
		},
		{
			name: "Proximus PLC",
			symbol: "PROX",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "BX7.F", google: "FRA:BX7", currency: "EUR" },
				{ yahoo: "BGAOY", google: "OTCMKTS:BGAOY", currency: "USD" },
				{ yahoo: "BGAOF", google: "OTCMKTS:BGAOF", currency: "USD" },
				{ yahoo: "BX70.F", google: "FRA:BX70", currency: "EUR" },
				{ yahoo: "0DPU.L", google: "LON:0DPU", currency: "GBP" },
			],
		},
		{
			name: "Sofina S.A.",
			wiki_name: "Sofina S.A.",
			symbol: "SOF",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "8FS.F", google: "FRA:8FS", currency: "EUR" },
				{ yahoo: "SFNXF", google: "OTCMKTS:SFNXF", currency: "USD" },
			],
			isins: ["BE0003717312"],
			metadata: { founded: 1898, employees: "" },
			akas: [],
		},
		{
			name: "Solvay SA",
			symbol: "SOLB",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "SOL.F", google: "FRA:SOL", currency: "EUR" },
				{ yahoo: "SOBS", google: "OTCMKTS:SOBS", currency: "USD" },
				{ yahoo: "SOLVY", google: "OTCMKTS:SOLVY", currency: "USD" },
				{ yahoo: "SLVYY", google: "OTCMKTS:SLVYY", currency: "USD" },
				{ yahoo: "SVYSF", google: "OTCMKTS:SVYSF", currency: "USD" },
				{ yahoo: "SOL0.F", google: "FRA:SOL0", currency: "EUR" },
				{ yahoo: "0NZR.L", google: "LON:0NZR", currency: "GBP" },
			],
		},
		{
			name: "UCB S.A.",
			wiki_name: "UCB S.A.",
			symbol: "XBRU",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "UNC.F", google: "FRA:UNC", currency: "EUR" },
				{ yahoo: "UCBJY", google: "OTCMKTS:UCBJY", currency: "USD" },
				{ yahoo: "UCBJF", google: "OTCMKTS:UCBJF", currency: "USD" },
				{ yahoo: "UNC0.F", google: "FRA:UNC0", currency: "EUR" },
				{ yahoo: "0NZT.L", google: "LON:0NZT", currency: "GBP" },
			],
			isins: ["BE0003739530"],
			metadata: { founded: 1928, employees: 7489 },
			akas: [],
		},
		{
			name: "Acciona SA",
			symbol: "ANA",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "AJ3.F", google: "FRA:AJ3", currency: "EUR" },
				{ yahoo: "ACXIF", google: "OTCMKTS:ACXIF", currency: "USD" },
				{ yahoo: "ANA.MC", google: "BME:ANA", currency: "EUR" },
			],
		},
		{
			name: "Acerinox SA",
			symbol: "ACX",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Basic Materials",
				"Steel",
			],
			symbols: [
				{ yahoo: "ACE1.F", google: "FRA:ACE1", currency: "EUR" },
				{ yahoo: "ANIOY", google: "OTCMKTS:ANIOY", currency: "USD" },
				{ yahoo: "ACX.MC", google: "BME:ACX", currency: "EUR" },
				{ yahoo: "ACE.F", google: "FRA:ACE", currency: "EUR" },
			],
		},
		{
			name: "Actividades de Construcción y Servicios SA",
			symbol: "ACS",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: ["Industrials", "Engineering & Construction"],
			symbols: [
				{ yahoo: "OCI1.F", google: "FRA:OCI1", currency: "EUR" },
				{ yahoo: "ACSAF", google: "OTCMKTS:ACSAF", currency: "USD" },
				{ yahoo: "ACS.MC", google: "BME:ACS", currency: "EUR" },
			],
		},
		{
			name: "Aena SA",
			symbol: "AENA",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Industrials",
				"Transport Infrastructure",
				"Airport Services",
				"Transportation",
			],
			symbols: [
				{ yahoo: "A44.F", google: "FRA:A44", currency: "EUR" },
				{ yahoo: "ANNSF", google: "OTCMKTS:ANNSF", currency: "USD" },
			],
		},
		{
			name: "Amadeus IT Group SA",
			symbol: "AMS",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "AI3A.F", google: "FRA:AI3A", currency: "EUR" },
				{ yahoo: "AMADF", google: "OTCMKTS:AMADF", currency: "USD" },
				{ yahoo: "AMS.MC", google: "BME:AMS", currency: "EUR" },
				{ yahoo: "AMADY", google: "OTCMKTS:AMADY", currency: "USD" },
				{ yahoo: "AI3B.F", google: "FRA:AI3B", currency: "EUR" },
				{ yahoo: "0P2W.L", google: "LON:0P2W", currency: "GBP" },
			],
		},
		{
			name: "Banco Bilbao Vizcaya Argentaria SA",
			symbol: "BBVA",
			country: "United States",
			indices: ["EURO STOXX 50", "IBEX 35"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "BOY.F", google: "FRA:BOY", currency: "EUR" },
				{ yahoo: "BBVA", google: "NYSE:BBVA", currency: "USD" },
				{ yahoo: "BVA.L", google: "LON:BVA", currency: "GBP" },
				{ yahoo: "BBVXF", google: "OTCMKTS:BBVXF", currency: "USD" },
				{ yahoo: "BBVA.MC", google: "BME:BBVA", currency: "EUR" },
				{ yahoo: "BBVA.F", google: "FRA:BBVA", currency: "EUR" },
			],
		},
		{
			name: "Bankinter SA",
			symbol: "BKT",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "BAKA.F", google: "FRA:BAKA", currency: "EUR" },
				{ yahoo: "BKNIY", google: "OTCMKTS:BKNIY", currency: "USD" },
				{ yahoo: "BKT.MC", google: "BME:BKT", currency: "EUR" },
				{ yahoo: "BKIMF", google: "OTCMKTS:BKIMF", currency: "USD" },
				{ yahoo: "BAK.F", google: "FRA:BAK", currency: "EUR" },
			],
		},
		{
			name: "CaixaBank SA",
			symbol: "CABK",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "48CA.F", google: "FRA:48CA", currency: "EUR" },
				{ yahoo: "CAIXY", google: "OTCMKTS:CAIXY", currency: "USD" },
				{ yahoo: "CABK.MC", google: "BME:CABK", currency: "EUR" },
				{ yahoo: "FV9J.F", google: "FRA:FV9J", currency: "EUR" },
			],
		},
		{
			name: "Cellnex Telecom SA",
			symbol: "CLNX",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Telecommunications Services",
				"Wireless Telecommunications Services",
			],
			symbols: [
				{ yahoo: "472.F", google: "FRA:472", currency: "EUR" },
				{ yahoo: "CLNXF", google: "OTCMKTS:CLNXF", currency: "USD" },
				{ yahoo: "CLLNY", google: "OTCMKTS:CLLNY", currency: "USD" },
				{ yahoo: "CLNX.MC", google: "BME:CLNX", currency: "EUR" },
				{ yahoo: "4720.F", google: "FRA:4720", currency: "EUR" },
			],
		},
		{
			name: "Endesa SA",
			symbol: "ELE",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "ENA.F", google: "FRA:ENA", currency: "EUR" },
				{ yahoo: "ELEZF", google: "OTCMKTS:ELEZF", currency: "USD" },
				{ yahoo: "ELE.MC", google: "BME:ELE", currency: "EUR" },
				{ yahoo: "ELEZY", google: "OTCMKTS:ELEZY", currency: "USD" },
				{ yahoo: "ENAA.F", google: "FRA:ENAA", currency: "EUR" },
				{ yahoo: "0N9G.L", google: "LON:0N9G", currency: "GBP" },
			],
		},
		{
			name: "Ferrovial SA",
			symbol: "FER",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "UFG.F", google: "FRA:UFG", currency: "EUR" },
				{ yahoo: "FRRVY", google: "OTCMKTS:FRRVY", currency: "USD" },
				{ yahoo: "FER.MC", google: "BME:FER", currency: "EUR" },
				{ yahoo: "UFGD.F", google: "FRA:UFGD", currency: "EUR" },
				{ yahoo: "FRRVF", google: "OTCMKTS:FRRVF", currency: "USD" },
				{ yahoo: "0P2N.L", google: "LON:0P2N", currency: "GBP" },
				{ yahoo: "FER.AS", google: "AMS:FER", currency: "EUR" },
				{ yahoo: "FERVF", google: "OTCMKTS:FERVF", currency: "USD" },
				{ yahoo: "8ZQ.F", google: "FRA:8ZQ", currency: "EUR" },
			],
		},
		{
			name: "Grifols SA",
			symbol: "GRF",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "OZTA.F", google: "FRA:OZTA", currency: "EUR" },
				{ yahoo: "GIFLF", google: "OTCMKTS:GIFLF", currency: "USD" },
				{ yahoo: "GRF.MC", google: "BME:GRF", currency: "EUR" },
				{ yahoo: "GRFS", google: "NASDAQ:GRFS", currency: "USD" },
				{ yahoo: "GIKLY", google: "OTCMKTS:GIKLY", currency: "USD" },
				{ yahoo: "GIFOF", google: "OTCMKTS:GIFOF", currency: "USD" },
				{ yahoo: "G0FB.F", google: "FRA:G0FB", currency: "EUR" },
				{ yahoo: "0RDU.L", google: "LON:0RDU", currency: "GBP" },
			],
		},
		{
			name: "Iberdrola SA",
			symbol: "IBE",
			country: "Spain",
			indices: ["EURO STOXX 50", "IBEX 35"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "IBE1.F", google: "FRA:IBE1", currency: "EUR" },
				{ yahoo: "IBDRY", google: "OTCMKTS:IBDRY", currency: "USD" },
				{ yahoo: "IBE.MC", google: "BME:IBE", currency: "EUR" },
				{ yahoo: "IBE5.F", google: "FRA:IBE5", currency: "EUR" },
				{ yahoo: "0HIT.L", google: "LON:0HIT", currency: "GBP" },
			],
		},
		{
			name: "Industria de Diseño Textil SA",
			wiki_name: "Industria de Diseño Textil, S.A.",
			symbol: "ITX",
			country: "Spain",
			indices: ["EURO STOXX 50", "IBEX 35"],
			industries: [
				"Retailers",
				"Apparel & Accessories Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "IXD1.F", google: "FRA:IXD1", currency: "EUR" },
				{ yahoo: "IDEXF", google: "OTCMKTS:IDEXF", currency: "USD" },
			],
		},
		{
			name: "Indra Sistemas SA",
			symbol: "IDR",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Technology",
				"Software & IT Services",
				"IT Services & Consulting",
			],
			symbols: [
				{ yahoo: "IDA.F", google: "FRA:IDA", currency: "EUR" },
				{ yahoo: "ISMAY", google: "OTCMKTS:ISMAY", currency: "USD" },
				{ yahoo: "IDR.MC", google: "BME:IDR", currency: "EUR" },
				{ yahoo: "ISMAF", google: "OTCMKTS:ISMAF", currency: "USD" },
				{ yahoo: "IDA0.F", google: "FRA:IDA0", currency: "EUR" },
				{ yahoo: "0HA9.L", google: "LON:0HA9", currency: "GBP" },
			],
		},
		{
			name: "Inmobiliaria Colonial, SOCIMI SA",
			symbol: "COL",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Commercial REITs",
				"Real Estate",
				"Real Estate Development & Operations",
				"Real Estate Operations",
				"Financials",
				"Residential & Commercial RETIs",
			],
			symbols: [
				{ yahoo: "COL.MC", google: "BME:COL", currency: "USD" },
				{ yahoo: "IMQCF", google: "OTCMKTS:IMQCF", currency: "USD" },
				{ yahoo: "HSC2.F", google: "FRA:HSC2", currency: "EUR" },
			],
		},
		{
			name: "Meliá Hotels International",
			symbol: "MEL",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Hotels & Entertainment Services",
				"Cyclical Consumer Services",
				"Hotels, Motels & Cruise Lines",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "MEL.F", google: "FRA:MEL", currency: "EUR" },
				{ yahoo: "SMIZF", google: "OTCMKTS:SMIZF", currency: "USD" },
				{ yahoo: "MEL.MC", google: "BME:MEL", currency: "EUR" },
			],
		},
		{
			name: "MERLIN Properties SOCIMI SA",
			symbol: "MRL",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: ["Real Estate"],
			symbols: [
				{ yahoo: "MEQA.F", google: "FRA:MEQA", currency: "EUR" },
				{ yahoo: "MRPRF", google: "OTCMKTS:MRPRF", currency: "USD" },
				{ yahoo: "MRL.MC", google: "BME:MRL", currency: "EUR" },
				{ yahoo: "0QVM.L", google: "LON:0QVM", currency: "GBP" },
			],
		},
		{
			name: "Naturgy Energy Group SA",
			symbol: "NTGY",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: ["Regulated Gas"],
			symbols: [
				{ yahoo: "GAN.F", google: "FRA:GAN", currency: "EUR" },
				{ yahoo: "GASNF", google: "OTCMKTS:GASNF", currency: "USD" },
				{ yahoo: "GASNY", google: "OTCMKTS:GASNY", currency: "USD" },
				{ yahoo: "NTGY.MC", google: "BME:NTGY", currency: "EUR" },
				{ yahoo: "GANA.F", google: "FRA:GANA", currency: "EUR" },
			],
		},
		{
			name: "Red Eléctrica Corporación SA",
			symbol: "REE",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Electric Utilities",
				"Electric Utilities & IPPs",
				"Utilities",
			],
			symbols: [
				{ yahoo: "RE21.F", google: "FRA:RE21", currency: "EUR" },
				{ yahoo: "RDEIF", google: "OTCMKTS:RDEIF", currency: "USD" },
			],
		},
		{
			name: "Repsol SA",
			symbol: "REP",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "REP.F", google: "FRA:REP", currency: "EUR" },
				{ yahoo: "REPYF", google: "OTCMKTS:REPYF", currency: "USD" },
				{ yahoo: "REP.MC", google: "BME:REP", currency: "EUR" },
				{ yahoo: "REPYY", google: "OTCMKTS:REPYY", currency: "USD" },
				{ yahoo: "REPA.F", google: "FRA:REPA", currency: "EUR" },
			],
		},
		{
			name: "Siemens Gamesa Renewable Energy SA",
			symbol: "SGRE",
			country: "Germany",
			indices: ["IBEX 35"],
			industries: ["Industrials", "Industrial Conglomerates"],
			symbols: [
				{ yahoo: "GTQ1.F", google: "FRA:GTQ1", currency: "EUR" },
				{ yahoo: "GCTAF", google: "OTCMKTS:GCTAF", currency: "USD" },
				{ yahoo: "SGRE.MC", google: "BME:SGRE", currency: "EUR" },
				{ yahoo: "GCTAY", google: "OTCMKTS:GCTAY", currency: "USD" },
				{ yahoo: "GTQB.F", google: "FRA:GTQB", currency: "EUR" },
			],
		},
		{
			name: "Telefónica",
			symbol: "TEF",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "TNE5.F", google: "FRA:TNE5", currency: "EUR" },
				{ yahoo: "TEF", google: "NYSE:TEF", currency: "USD" },
			],
			isins: ["ES0178430E18"],
			metadata: { founded: 1924, employees: 113182 },
			akas: [],
		},
		{
			name: "Adyen N.V.",
			symbol: "ADYEN",
			country: "Netherlands",
			indices: ["AEX", "EURO STOXX 50"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Financial & Commodity Market Operators",
			],
			symbols: [
				{ yahoo: "1N8.F", google: "FRA:1N8", currency: "EUR" },
				{ yahoo: "ADYYF", google: "OTCMKTS:ADYYF", currency: "USD" },
				{ yahoo: "ADYEY", google: "OTCMKTS:ADYEY", currency: "USD" },
				{ yahoo: "ADYEN.AS", google: "AMS:ADYEN", currency: "EUR" },
				{ yahoo: "1N8U.F", google: "FRA:1N8U", currency: "EUR" },
				{ yahoo: "0YP5.L", google: "LON:0YP5", currency: "GBP" },
			],
		},
		{
			name: "Aegon N.V.",
			symbol: "AGN",
			country: "United States",
			indices: ["AEX"],
			industries: [
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "AENF.F", google: "FRA:AENF", currency: "EUR" },
				{ yahoo: "AEGOF", google: "OTCMKTS:AEGOF", currency: "USD" },
				{ yahoo: "AEG", google: "NYSE:AEG", currency: "USD" },
				{ yahoo: "AGN.AS", google: "AMS:AGN", currency: "EUR" },
				{ yahoo: "AEND.F", google: "FRA:AEND", currency: "EUR" },
				{ yahoo: "0Q0Y.L", google: "LON:0Q0Y", currency: "GBP" },
			],
		},
		{
			name: "Koninklijke Ahold Delhaize N.V.",
			symbol: "AD",
			country: "Netherlands",
			indices: ["AEX", "EURO STOXX 50"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Food Retail & Distribution",
			],
			symbols: [
				{ yahoo: "AHOD.F", google: "FRA:AHOD", currency: "EUR" },
				{ yahoo: "AHODF", google: "OTCMKTS:AHODF", currency: "USD" },
				{ yahoo: "ADRNY", google: "OTCMKTS:ADRNY", currency: "USD" },
				{ yahoo: "AD.AS", google: "AMS:AD", currency: "EUR" },
				{ yahoo: "AHOG.F", google: "FRA:AHOG", currency: "EUR" },
			],
		},
		{
			name: "Akzo Nobel N.V.",
			symbol: "AKZA",
			country: "Netherlands",
			indices: ["AEX"],
			industries: ["Commodity Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "AKUP.F", google: "FRA:AKUP", currency: "EUR" },
				{ yahoo: "AKZOF", google: "OTCMKTS:AKZOF", currency: "USD" },
				{ yahoo: "AKU1.F", google: "FRA:AKU1", currency: "EUR" },
				{ yahoo: "AKZA.AS", google: "AMS:AKZA", currency: "EUR" },
				{ yahoo: "AKZOY", google: "OTCMKTS:AKZOY", currency: "USD" },
				{ yahoo: "0A00.L", google: "LON:0A00", currency: "GBP" },
			],
		},
		{
			name: "ASML Holding N.V.",
			symbol: "ASML",
			country: "Netherlands",
			indices: ["AEX", "EURO STOXX 50", "NASDAQ 100"],
			industries: [
				"Semiconductor Equipment & Testing",
				"Technology",
				"Technology Equipment",
				"Semiconductors & Semiconductor Equipment",
			],
			symbols: [
				{ yahoo: "ASME.F", google: "FRA:ASME", currency: "EUR" },
				{ yahoo: "ASMLF", google: "OTCMKTS:ASMLF", currency: "USD" },
				{ yahoo: "ASML", google: "NASDAQ:ASML", currency: "USD" },
				{ yahoo: "ASML.AS", google: "AMS:ASML", currency: "EUR" },
				{ yahoo: "ASMF.F", google: "FRA:ASMF", currency: "EUR" },
			],
		},
		{
			name: "Koninklijke DSM N.V.",
			symbol: "DSM",
			country: "Netherlands",
			indices: ["AEX"],
			industries: [
				"Basic Materials",
				"Chemicals",
				"Cyclical Consumer Products",
				"Diversified Chemicals",
				"Textiles & Apparel",
				"Apparel & Accessories",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "DSM2.F", google: "FRA:DSM2", currency: "EUR" },
				{ yahoo: "KDSKF", google: "OTCMKTS:KDSKF", currency: "USD" },
				{ yahoo: "DSM.AS", google: "AMS:DSM", currency: "EUR" },
				{ yahoo: "RDSMY", google: "OTCMKTS:RDSMY", currency: "USD" },
				{ yahoo: "DSM.F", google: "FRA:DSM", currency: "EUR" },
			],
		},
		{
			name: "Heineken N.V.",
			symbol: "HEIA",
			country: "Netherlands",
			indices: ["AEX"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Brewers",
				"Beverages",
			],
			symbols: [
				{ yahoo: "4H5.F", google: "FRA:4H5", currency: "EUR" },
				{ yahoo: "HINKF", google: "OTCMKTS:HINKF", currency: "USD" },
				{ yahoo: "HEIA.AS", google: "AMS:HEIA", currency: "EUR" },
				{ yahoo: "HNK1.F", google: "FRA:HNK1", currency: "EUR" },
				{ yahoo: "HEINY", google: "OTCMKTS:HEINY", currency: "USD" },
				{ yahoo: "HNK2.F", google: "FRA:HNK2", currency: "EUR" },
				{ yahoo: "0O26.L", google: "LON:0O26", currency: "GBP" },
			],
		},
		{
			name: "IMCD N.V.",
			symbol: "IMCD",
			country: "Netherlands",
			indices: ["AEX"],
			industries: ["Chemicals"],
			symbols: [
				{ yahoo: "INX.F", google: "FRA:INX", currency: "EUR" },
				{ yahoo: "IMDZF", google: "OTCMKTS:IMDZF", currency: "USD" },
				{ yahoo: "IMCD.AS", google: "AMS:IMCD", currency: "EUR" },
				{ yahoo: "IMCDY", google: "OTCMKTS:IMCDY", currency: "USD" },
			],
		},
		{
			name: "Koninklijke KPN N.V.",
			symbol: "KPN",
			country: "Netherlands",
			indices: ["AEX"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "KPN.F", google: "FRA:KPN", currency: "EUR" },
				{ yahoo: "KKPNF", google: "OTCMKTS:KKPNF", currency: "USD" },
				{ yahoo: "KPN.AS", google: "AMS:KPN", currency: "EUR" },
				{ yahoo: "KKPNY", google: "OTCMKTS:KKPNY", currency: "USD" },
				{ yahoo: "KPNB.F", google: "FRA:KPNB", currency: "EUR" },
				{ yahoo: "0O8F.L", google: "LON:0O8F", currency: "GBP" },
			],
		},
		{
			name: "NN Group N.V.",
			symbol: "NN",
			country: "Netherlands",
			indices: ["AEX"],
			industries: ["Insurance", "Financials", "Life & Health Insurance"],
			symbols: [
				{ yahoo: "2NN.F", google: "FRA:2NN", currency: "EUR" },
				{ yahoo: "NNGPF", google: "OTCMKTS:NNGPF", currency: "USD" },
				{ yahoo: "NNGRY", google: "OTCMKTS:NNGRY", currency: "USD" },
				{ yahoo: "NN.AS", google: "AMS:NN", currency: "EUR" },
				{ yahoo: "2NN0.F", google: "FRA:2NN0", currency: "EUR" },
			],
		},
		{
			name: "Koninklijke Philips N.V.",
			symbol: "PHIA",
			country: "Netherlands",
			indices: ["AEX", "EURO STOXX 50"],
			industries: [
				"Healthcare",
				"Advanced Medical Equipment & Technology",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "PHIA.F", google: "FRA:PHIA", currency: "EUR" },
				{ yahoo: "PHG", google: "NYSE:PHG", currency: "USD" },
				{ yahoo: "RYLPF", google: "OTCMKTS:RYLPF", currency: "USD" },
				{ yahoo: "PHIA.AS", google: "AMS:PHIA", currency: "EUR" },
				{ yahoo: "PHI1.F", google: "FRA:PHI1", currency: "EUR" },
			],
		},
		{
			name: "Randstad N.V.",
			symbol: "RAND",
			country: "Netherlands",
			indices: ["AEX"],
			industries: [
				"Industrials",
				"Professional & Commercial Services",
				"Employment Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "RSH.F", google: "FRA:RSH", currency: "EUR" },
				{ yahoo: "RANJF", google: "OTCMKTS:RANJF", currency: "USD" },
				{ yahoo: "RAND.AS", google: "AMS:RAND", currency: "EUR" },
				{ yahoo: "RANJY", google: "OTCMKTS:RANJY", currency: "USD" },
				{ yahoo: "0NW2.L", google: "LON:0NW2", currency: "GBP" },
				{ yahoo: "RSH0.F", google: "FRA:RSH0", currency: "EUR" },
			],
		},
		{
			name: "Wolters Kluwer N.V.",
			symbol: "WKL",
			country: "Netherlands",
			indices: ["AEX"],
			industries: [
				"Professional Information Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "WOSB.F", google: "FRA:WOSB", currency: "EUR" },
				{ yahoo: "WTKWY", google: "OTCMKTS:WTKWY", currency: "USD" },
				{ yahoo: "WKL.AS", google: "AMS:WKL", currency: "EUR" },
				{ yahoo: "WOS.F", google: "FRA:WOS", currency: "EUR" },
			],
		},
		{
			name: "ABB Ltd",
			symbol: "ABB",
			country: "Switzerland",
			indices: ["CAC Mid 60", "OMX Stockholm 30", "Switzerland 20"],
			industries: [
				"Heavy Electrical Equipment",
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
			],
			symbols: [
				{ yahoo: "ABJ.F", google: "FRA:ABJ", currency: "EUR" },
				{ yahoo: "ABB", google: "NYSE:ABB", currency: "USD" },
				{ yahoo: "ABJA.F", google: "FRA:ABJA", currency: "EUR" },
				{ yahoo: "ABLZF", google: "OTCMKTS:ABLZF", currency: "USD" },
				{ yahoo: "0A6W.L", google: "LON:0A6W", currency: "GBP" },
				{ yahoo: "0NX2.L", google: "LON:0NX2", currency: "GBP" },
				{ yahoo: "ABBNY", google: "OTCMKTS:ABBNY", currency: "USD" },
			],
		},
		{
			name: "Alfa Laval AB",
			symbol: "ALFA",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "AA9.F", google: "FRA:AA9", currency: "EUR" },
				{ yahoo: "ALFVY", google: "OTCMKTS:ALFVY", currency: "USD" },
				{ yahoo: "ALFVF", google: "OTCMKTS:ALFVF", currency: "USD" },
			],
		},
		{
			name: "ASSA ABLOY AB",
			symbol: "ASSA B",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Cyclical Consumer Products",
				"Construction Supplies & Fixtures",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "ALZC.F", google: "FRA:ALZC", currency: "EUR" },
				{ yahoo: "ASAZY", google: "OTCMKTS:ASAZY", currency: "USD" },
				{ yahoo: "ASAZF", google: "OTCMKTS:ASAZF", currency: "USD" },
			],
		},
		{
			name: "Atlas Copco AB",
			symbol: "ATCO A",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: ["Industrials"],
			symbols: [
				{ yahoo: "ACO2.F", google: "FRA:ACO2", currency: "EUR" },
				{ yahoo: "ATLKY", google: "OTCMKTS:ATLKY", currency: "USD" },
				{ yahoo: "ACO1.F", google: "FRA:ACO1", currency: "EUR" },
				{ yahoo: "ACO.F", google: "FRA:ACO", currency: "EUR" },
				{ yahoo: "ATLCY", google: "OTCMKTS:ATLCY", currency: "USD" },
				{ yahoo: "ACO5.F", google: "FRA:ACO5", currency: "EUR" },
				{ yahoo: "ACO4.F", google: "FRA:ACO4", currency: "EUR" },
				{ yahoo: "ATLPF", google: "OTCMKTS:ATLPF", currency: "USD" },
			],
		},
		{
			name: "Autoliv Inc.",
			symbol: "ALIV SDB",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "LIVS.F", google: "FRA:LIVS", currency: "EUR" },
				{ yahoo: "ALV", google: "NYSE:ALV", currency: "USD" },
				{ yahoo: "0HJH.L", google: "LON:0HJH", currency: "GBP" },
				{ yahoo: "LIV.F", google: "FRA:LIV", currency: "EUR" },
				{ yahoo: "0MI0.L", google: "LON:0MI0", currency: "GBP" },
			],
		},
		{
			name: "Boliden AB",
			symbol: "BOL",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Specialty Mining & Metals",
				"Basic Materials",
			],
			symbols: [
				{ yahoo: "BWJQ.F", google: "FRA:BWJQ", currency: "EUR" },
				{ yahoo: "BDNNY", google: "OTCMKTS:BDNNY", currency: "USD" },
				{ yahoo: "BWJ.F", google: "FRA:BWJ", currency: "EUR" },
				{ yahoo: "BOLIF", google: "OTCMKTS:BOLIF", currency: "USD" },
				{ yahoo: "BWJ1.F", google: "FRA:BWJ1", currency: "EUR" },
				{ yahoo: "BWJ0.F", google: "FRA:BWJ0", currency: "EUR" },
			],
		},
		{
			name: "Telefonaktiebolaget LM Ericsson",
			symbol: "ERICB",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: ["Telecommunications equipment", "Networking equipment"],
			symbols: [
				{ yahoo: "ERCB.F", google: "FRA:ERCB", currency: "EUR" },
				{ yahoo: "ERIXF", google: "OTCMKTS:ERIXF", currency: "USD" },
				{ yahoo: "ERIC", google: "NASDAQ:ERIC", currency: "USD" },
				{ yahoo: "0IID.L", google: "LON:0IID", currency: "GBP" },
				{ yahoo: "0O86.L", google: "LON:0O86", currency: "GBP" },
				{ yahoo: "ERCA.F", google: "FRA:ERCA", currency: "EUR" },
				{ yahoo: "ERCG.F", google: "FRA:ERCG", currency: "EUR" },
			],
		},
		{
			name: "Essity AB",
			symbol: "ESSITY B",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Consumer Non-Cyclicals",
				"Personal Products",
				"Personal & Household Products & Services",
			],
			symbols: [
				{ yahoo: "ESW.F", google: "FRA:ESW", currency: "EUR" },
				{ yahoo: "ESSYY", google: "OTCMKTS:ESSYY", currency: "USD" },
				{ yahoo: "ETTYF", google: "OTCMKTS:ETTYF", currency: "USD" },
				{ yahoo: "ESWB.F", google: "FRA:ESWB", currency: "EUR" },
			],
		},
		{
			name: "Getinge AB",
			symbol: "GETI B",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Medical Equipment, Supplies & Distribution",
				"Healthcare",
				"Healthcare Services",
				"Healthcare Equipment & Supplies",
			],
			symbols: [
				{ yahoo: "GTN.F", google: "FRA:GTN", currency: "EUR" },
				{ yahoo: "GNGBY", google: "OTCMKTS:GNGBY", currency: "USD" },
				{ yahoo: "GNGBF", google: "OTCMKTS:GNGBF", currency: "USD" },
			],
		},
		{
			name: "H & M Hennes & Mauritz AB",
			wiki_name: "H & M Hennes & Mauritz AB",
			symbol: "HM B",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Retailers",
				"Apparel & Accessories Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "HMSB.F", google: "FRA:HMSB", currency: "EUR" },
				{ yahoo: "HNNMY", google: "OTCMKTS:HNNMY", currency: "USD" },
				{ yahoo: "HMRZF", google: "OTCMKTS:HMRZF", currency: "USD" },
				{ yahoo: "HMSA.F", google: "FRA:HMSA", currency: "EUR" },
				{ yahoo: "0HBP.L", google: "LON:0HBP", currency: "GBP" },
			],
			isins: ["SE0000106270"],
			metadata: { founded: 1947, employees: 179000 },
			akas: [],
		},
		{
			name: "Hexagon AB",
			symbol: "HEXA B",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Technology",
				"Technology Equipment",
				"Electronic Equipments & Parts",
			],
			symbols: [
				{ yahoo: "HXGC.F", google: "FRA:HXGC", currency: "EUR" },
				{ yahoo: "HXGBF", google: "OTCMKTS:HXGBF", currency: "USD" },
				{
					yahoo: "US4282631070.F",
					google: "FRA:US4282631070",
					currency: "EUR",
				},
				{ yahoo: "HXGBY", google: "OTCMKTS:HXGBY", currency: "USD" },
				{ yahoo: "0GRX.L", google: "LON:0GRX", currency: "GBP" },
				{ yahoo: "HXG.F", google: "FRA:HXG", currency: "EUR" },
			],
		},
		{
			name: "Investor AB",
			symbol: "INVEB",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: ["Holding Companies", "Financials"],
			symbols: [
				{ yahoo: "IVSA.F", google: "FRA:IVSA", currency: "EUR" },
				{ yahoo: "IVSXF", google: "OTCMKTS:IVSXF", currency: "USD" },
				{ yahoo: "IVSBF", google: "OTCMKTS:IVSBF", currency: "USD" },
				{ yahoo: "IVSD.F", google: "FRA:IVSD", currency: "EUR" },
				{ yahoo: "IVS.F", google: "FRA:IVS", currency: "EUR" },
			],
		},
		{
			name: "Kinnevik AB",
			symbol: "KINV B",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: ["Holding Companies", "Financials"],
			symbols: [
				{ yahoo: "IV6C.F", google: "FRA:IV6C", currency: "EUR" },
				{ yahoo: "KNVKF", google: "OTCMKTS:KNVKF", currency: "USD" },
				{ yahoo: "IV60.F", google: "FRA:IV60", currency: "EUR" },
				{ yahoo: "0RH1.L", google: "LON:0RH1", currency: "GBP" },
				{ yahoo: "KNKBF", google: "OTCMKTS:KNKBF", currency: "USD" },
				{ yahoo: "IV6.F", google: "FRA:IV6", currency: "EUR" },
			],
		},
		{
			name: "Sandvik AB",
			symbol: "SANDVIK A",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Industrial Goods",
				"Industrials",
				"Machinery, Equipment & Components",
				"Industrial Machinery & Equipment",
			],
			symbols: [
				{ yahoo: "SVKB.F", google: "FRA:SVKB", currency: "EUR" },
				{ yahoo: "SDVKY", google: "OTCMKTS:SDVKY", currency: "USD" },
				{ yahoo: "SDVKF", google: "OTCMKTS:SDVKF", currency: "USD" },
			],
		},
		{
			name: "Securitas AB",
			symbol: "SECU B",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Business Support Services",
				"Industrials",
				"Professional & Commercial Services",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "S7MB.F", google: "FRA:S7MB", currency: "EUR" },
				{ yahoo: "SCTBF", google: "OTCMKTS:SCTBF", currency: "USD" },
				{ yahoo: "SCTBY", google: "OTCMKTS:SCTBY", currency: "USD" },
			],
		},
		{
			name: "Skandinaviska Enskilda Banken AB",
			symbol: "SEBA",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "SEBA.F", google: "FRA:SEBA", currency: "EUR" },
				{ yahoo: "SVKEF", google: "OTCMKTS:SVKEF", currency: "USD" },
				{ yahoo: "0GUX.L", google: "LON:0GUX", currency: "GBP" },
				{ yahoo: "SEBC.F", google: "FRA:SEBC", currency: "EUR" },
			],
		},
		{
			name: "Skanska AB",
			symbol: "SKANSKA B",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "SKNB.F", google: "FRA:SKNB", currency: "EUR" },
				{ yahoo: "SKSBF", google: "OTCMKTS:SKSBF", currency: "USD" },
				{ yahoo: "SKBSY", google: "OTCMKTS:SKBSY", currency: "USD" },
			],
		},
		{
			name: "AB SKF",
			symbol: "SKF B",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: ["Manufacturing"],
			symbols: [
				{ yahoo: "SKFA.F", google: "FRA:SKFA", currency: "EUR" },
				{ yahoo: "SKUFF", google: "OTCMKTS:SKUFF", currency: "USD" },
				{ yahoo: "0NWW.L", google: "LON:0NWW", currency: "GBP" },
			],
		},
		{
			name: "Svenska Handelsbanken AB",
			symbol: "SHB A",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "SVHH.F", google: "FRA:SVHH", currency: "EUR" },
				{ yahoo: "SVNLY", google: "OTCMKTS:SVNLY", currency: "USD" },
				{ yahoo: "0R7S.L", google: "LON:0R7S", currency: "GBP" },
				{ yahoo: "SVHG.F", google: "FRA:SVHG", currency: "EUR" },
				{ yahoo: "SVHI.F", google: "FRA:SVHI", currency: "EUR" },
				{ yahoo: "SVNLF", google: "OTCMKTS:SVNLF", currency: "USD" },
			],
		},
		{
			name: "Swedbank AB",
			symbol: "SWED A",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "FRYA.F", google: "FRA:FRYA", currency: "EUR" },
				{ yahoo: "SWDBY", google: "OTCMKTS:SWDBY", currency: "USD" },
				{ yahoo: "SWDBF", google: "OTCMKTS:SWDBF", currency: "USD" },
			],
		},
		{
			name: "Swedish Match AB",
			symbol: "SWMA",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Tobacco",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "SWM.F", google: "FRA:SWM", currency: "EUR" },
				{ yahoo: "SWMAF", google: "OTCMKTS:SWMAF", currency: "USD" },
				{ yahoo: "SWMC.F", google: "FRA:SWMC", currency: "EUR" },
				{ yahoo: "SWMAY", google: "OTCMKTS:SWMAY", currency: "USD" },
				{ yahoo: "SWM0.F", google: "FRA:SWM0", currency: "EUR" },
				{ yahoo: "0GO4.L", google: "LON:0GO4", currency: "GBP" },
			],
		},
		{
			name: "Tele2 AB",
			symbol: "TEL2 B",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [
				"Telecommunications Services",
				"Wireless Telecommunications Services",
			],
			symbols: [
				{ yahoo: "NCYD.F", google: "FRA:NCYD", currency: "EUR" },
				{ yahoo: "TLTZY", google: "OTCMKTS:TLTZY", currency: "USD" },
				{ yahoo: "TLTZF", google: "OTCMKTS:TLTZF", currency: "USD" },
			],
		},
		{
			name: "Alcon Inc.",
			symbol: "ALC",
			country: "United States",
			indices: ["Switzerland 20"],
			industries: [
				"Industrials",
				"Marine Freight & Logistics",
				"Freight & Logistics Services",
				"Transportation",
			],
			symbols: [
				{ yahoo: "2U3.F", google: "FRA:2U3", currency: "EUR" },
				{ yahoo: "ALC", google: "NYSE:ALC", currency: "USD" },
				{ yahoo: "0A0D.L", google: "LON:0A0D", currency: "GBP" },
			],
		},
		{
			name: "Compagnie financiere Richemont SA",
			symbol: "CFR",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: [
				"Cyclical Consumer Products",
				"Textiles & Apparel",
				"Apparel & Accessories",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "RITN.F", google: "FRA:RITN", currency: "EUR" },
				{ yahoo: "CFRHF", google: "OTCMKTS:CFRHF", currency: "USD" },
				{ yahoo: "CFRUY", google: "OTCMKTS:CFRUY", currency: "USD" },
				{ yahoo: "0QMU.L", google: "LON:0QMU", currency: "GBP" },
				{ yahoo: "RIT1.F", google: "FRA:RIT1", currency: "EUR" },
			],
		},
		{
			name: "Credit Suisse Group AG",
			symbol: "CSGN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: [
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "CSX.F", google: "FRA:CSX", currency: "EUR" },
				{ yahoo: "CS", google: "NYSE:CS", currency: "USD" },
				{ yahoo: "CSX1.F", google: "FRA:CSX1", currency: "EUR" },
				{ yahoo: "CSGKF", google: "OTCMKTS:CSGKF", currency: "USD" },
				{ yahoo: "0I4P.L", google: "LON:0I4P", currency: "GBP" },
				{ yahoo: "0QP5.L", google: "LON:0QP5", currency: "GBP" },
			],
		},
		{
			name: "Geberit AG",
			symbol: "GEBN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: [
				"Cyclical Consumer Products",
				"Construction Supplies & Fixtures",
				"Homebuilding / Household Goods",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "GBRA.F", google: "FRA:GBRA", currency: "EUR" },
				{ yahoo: "GBERY", google: "OTCMKTS:GBERY", currency: "USD" },
				{ yahoo: "GBRF.F", google: "FRA:GBRF", currency: "EUR" },
				{ yahoo: "0QQ2.L", google: "LON:0QQ2", currency: "GBP" },
				{ yahoo: "GBERF", google: "OTCMKTS:GBERF", currency: "USD" },
			],
		},
		{
			name: "Givaudan SA",
			symbol: "GIVN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: ["Specialty Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "GIN.F", google: "FRA:GIN", currency: "EUR" },
				{ yahoo: "GVDNY", google: "OTCMKTS:GVDNY", currency: "USD" },
				{ yahoo: "GIN1.F", google: "FRA:GIN1", currency: "EUR" },
				{ yahoo: "GVDBF", google: "OTCMKTS:GVDBF", currency: "USD" },
				{ yahoo: "0QPS.L", google: "LON:0QPS", currency: "GBP" },
			],
		},
		{
			name: "Lonza Group Ltd",
			symbol: "LONN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: [
				"Biotechnology & Medical Research",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "LO3.F", google: "FRA:LO3", currency: "EUR" },
				{ yahoo: "LZAGF", google: "OTCMKTS:LZAGF", currency: "USD" },
				{ yahoo: "LZAGY", google: "OTCMKTS:LZAGY", currency: "USD" },
				{ yahoo: "0QNO.L", google: "LON:0QNO", currency: "GBP" },
				{ yahoo: "LO3A.F", google: "FRA:LO3A", currency: "EUR" },
			],
		},
		{
			name: "Nestlé SA",
			symbol: "NESN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
				"Food & Tobacco",
			],
			symbols: [
				{ yahoo: "NESN.F", google: "FRA:NESN", currency: "EUR" },
				{ yahoo: "NESN", google: "OTCMKTS:NESN", currency: "USD" },
				{ yahoo: "NESM.F", google: "FRA:NESM", currency: "EUR" },
				{ yahoo: "NSRGY", google: "OTCMKTS:NSRGY", currency: "USD" },
			],
		},
		{
			name: "Novartis AG",
			symbol: "NOVN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "NOT.F", google: "FRA:NOT", currency: "EUR" },
				{ yahoo: "NVS", google: "NYSE:NVS", currency: "USD" },
				{ yahoo: "NOTA.F", google: "FRA:NOTA", currency: "EUR" },
				{ yahoo: "NVSEF", google: "OTCMKTS:NVSEF", currency: "USD" },
				{ yahoo: "0K9E.L", google: "LON:0K9E", currency: "GBP" },
				{ yahoo: "0QLR.L", google: "LON:0QLR", currency: "GBP" },
			],
		},
		{
			name: "Roche Holding AG",
			symbol: "ROG",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: [
				"Pharmaceuticals",
				"Pharmaceuticals & Medical Research",
				"Healthcare",
			],
			symbols: [
				{ yahoo: "RHO.F", google: "FRA:RHO", currency: "EUR" },
				{ yahoo: "RHHVF", google: "OTCMKTS:RHHVF", currency: "USD" },
				{ yahoo: "RHHBY", google: "OTCMKTS:RHHBY", currency: "USD" },
				{ yahoo: "RHO6.F", google: "FRA:RHO6", currency: "EUR" },
				{ yahoo: "0TDF.L", google: "LON:0TDF", currency: "GBP" },
				{ yahoo: "0QQ6.L", google: "LON:0QQ6", currency: "GBP" },
			],
		},
		{
			name: "Sika AG",
			symbol: "SIKA",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: ["Specialty Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "SIKA.F", google: "FRA:SIKA", currency: "EUR" },
				{ yahoo: "SKFOF", google: "OTCMKTS:SKFOF", currency: "USD" },
				{ yahoo: "SXYAY", google: "OTCMKTS:SXYAY", currency: "USD" },
				{ yahoo: "0Z4C.L", google: "LON:0Z4C", currency: "GBP" },
				{ yahoo: "SIKB.F", google: "FRA:SIKB", currency: "EUR" },
			],
		},
		{
			name: "Swiss Life Holding AG",
			symbol: "SLHN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: ["Insurance", "Financials", "Life & Health Insurance"],
			symbols: [
				{ yahoo: "SLW.F", google: "FRA:SLW", currency: "EUR" },
				{ yahoo: "SWSDF", google: "OTCMKTS:SWSDF", currency: "USD" },
				{ yahoo: "SZLMY", google: "OTCMKTS:SZLMY", currency: "USD" },
				{ yahoo: "0QMG.L", google: "LON:0QMG", currency: "GBP" },
				{ yahoo: "SLW1.F", google: "FRA:SLW1", currency: "EUR" },
			],
		},
		{
			name: "Swisscom AG",
			symbol: "SCMN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "SWJA.F", google: "FRA:SWJA", currency: "EUR" },
				{ yahoo: "SWZCF", google: "OTCMKTS:SWZCF", currency: "USD" },
				{ yahoo: "SCMWY", google: "OTCMKTS:SCMWY", currency: "USD" },
				{ yahoo: "0QKI.L", google: "LON:0QKI", currency: "GBP" },
			],
		},
		{
			name: "UBS Group AG",
			symbol: "UBSG",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: [
				"Banking & Investment Services",
				"Investment Banking & Investment Services",
				"Financials",
				"Investment Management & Fund Operators",
			],
			symbols: [
				{ yahoo: "0UB.F", google: "FRA:0UB", currency: "EUR" },
				{ yahoo: "UBS", google: "NYSE:UBS", currency: "USD" },
				{ yahoo: "0R3T.L", google: "LON:0R3T", currency: "GBP" },
			],
		},
		{
			name: "Zurich Insurance Group AG",
			symbol: "ZURN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "ZFI1.F", google: "FRA:ZFI1", currency: "EUR" },
				{ yahoo: "ZFSVF", google: "OTCMKTS:ZFSVF", currency: "USD" },
				{ yahoo: "ZURVY", google: "OTCMKTS:ZURVY", currency: "USD" },
				{ yahoo: "0QP2.L", google: "LON:0QP2", currency: "GBP" },
			],
		},
		{
			name: "Corteva Inc.",
			symbol: "CTVA",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Basic Materials", "Agricultural Inputs"],
			symbols: [
				{ yahoo: "2X0.F", google: "FRA:2X0", currency: "EUR" },
				{ yahoo: "CTVA", google: "NYSE:CTVA", currency: "USD" },
				{ yahoo: "0A1W.L", google: "LON:0A1W", currency: "GBP" },
			],
		},
		{
			name: "Aeroflot",
			symbol: "AFLT",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [
				"Passenger Transportation Services",
				"Industrials",
				"Airlines",
				"Transportation",
			],
			symbols: [
				{ yahoo: "AETG.F", google: "FRA:AETG", currency: "EUR" },
				{ yahoo: "AFLT.ME", google: "MCX:AFLT", currency: "RUB" },
			],
		},
		{
			name: "Alrosa",
			symbol: "ALRS",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Diamonds"],
			symbols: [{ yahoo: "ALRS.ME", google: "MCX:ALRS", currency: "RUB" }],
		},
		{
			name: "PAO Detsky Mir",
			symbol: "DSKY",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [
				"Cyclical Consumer Products",
				"Leisure Products",
				"Toys & Juvenile Products",
				"Consumer Cyclicals",
			],
			symbols: [{ yahoo: "DSKY.ME", google: "MCX:DSKY", currency: "RUB" }],
		},
		{
			name: "Federal Grid Company of Unified Energy System JSC",
			symbol: "FEES",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Energy", "Multiline Utilities", "Utilities"],
			symbols: [{ yahoo: "FEES.ME", google: "MCX:FEES", currency: "RUB" }],
		},
		{
			name: "Gazprom",
			symbol: "GAZP",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "GAZ.F", google: "FRA:GAZ", currency: "EUR" },
				{ yahoo: "OGZPY", google: "OTCMKTS:OGZPY", currency: "USD" },
				{ yahoo: "GAZP.ME", google: "MCX:GAZP", currency: "RUB" },
			],
		},
		{
			name: "Inter RAO UES",
			symbol: "IRAO",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Energy"],
			symbols: [{ yahoo: "IRAO.ME", google: "MCX:IRAO", currency: "RUB" }],
		},
		{
			name: "Lukoil",
			symbol: "LKOH",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Energy"],
			symbols: [
				{ yahoo: "LUK.F", google: "FRA:LUK", currency: "EUR" },
				{ yahoo: "LUKOY", google: "OTCMKTS:LUKOY", currency: "USD" },
				{ yahoo: "LKOH.ME", google: "MCX:LKOH", currency: "RUB" },
			],
		},
		{
			name: "Magnit",
			symbol: "MGNT",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Food Processing",
			],
			symbols: [
				{ yahoo: "5M71.F", google: "FRA:5M71", currency: "EUR" },
				{ yahoo: "MGNT.ME", google: "MCX:MGNT", currency: "RUB" },
				{ yahoo: "MGNI", google: "NASDAQ:MGNI", currency: "USD" },
				{ yahoo: "15R.F", google: "FRA:15R", currency: "EUR" },
				{ yahoo: "0A99.L", google: "LON:0A99", currency: "GBP" },
			],
		},
		{
			name: "Mobile TeleSystems",
			symbol: "MTSS",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "MKY.F", google: "FRA:MKY", currency: "EUR" },
				{ yahoo: "MBT", google: "NYSE:MBT", currency: "USD" },
				{ yahoo: "MTSS.ME", google: "MCX:MTSS", currency: "RUB" },
			],
		},
		{
			name: "Novolipetsk Steel",
			symbol: "NLMK",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Steel"],
			symbols: [
				{ yahoo: "N7MG.F", google: "FRA:N7MG", currency: "EUR" },
				{ yahoo: "NLMK.ME", google: "MCX:NLMK", currency: "RUB" },
			],
		},
		{
			name: "Novatek",
			symbol: "NVTK",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Energy", "Fossil Fuels", "Gas"],
			symbols: [
				{ yahoo: "N1O.F", google: "FRA:N1O", currency: "EUR" },
				{ yahoo: "NOVKY", google: "OTCMKTS:NOVKY", currency: "USD" },
				{ yahoo: "NVTK.ME", google: "MCX:NVTK", currency: "RUB" },
			],
		},
		{
			name: "JSC PhosAgro",
			symbol: "PHOR",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Diversified Chemicals", "Basic Materials", "Chemicals"],
			symbols: [
				{ yahoo: "P6SG.F", google: "FRA:P6SG", currency: "EUR" },
				{ yahoo: "PHOJY", google: "OTCMKTS:PHOJY", currency: "USD" },
				{ yahoo: "PHOR.ME", google: "MCX:PHOR", currency: "RUB" },
			],
		},
		{
			name: "PIK Group",
			symbol: "PIKK",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Real estate", "Construction"],
			symbols: [{ yahoo: "PIKK.ME", google: "MCX:PIKK", currency: "RUB" }],
		},
		{
			name: "Polymetal International",
			symbol: "POLY",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [
				"Metals & Mining",
				"Mineral Resources",
				"Gold",
				"Basic Materials",
			],
			symbols: [
				{ yahoo: "PM6.F", google: "FRA:PM6", currency: "EUR" },
				{ yahoo: "AUCOY", google: "OTCMKTS:AUCOY", currency: "USD" },
				{ yahoo: "POLY.ME", google: "MCX:POLY", currency: "RUB" },
				{ yahoo: "PM6A.F", google: "FRA:PM6A", currency: "EUR" },
				{ yahoo: "POLY.L", google: "LON:POLY", currency: "GBP" },
				{ yahoo: "POYYF", google: "OTCMKTS:POYYF", currency: "USD" },
			],
		},
		{
			name: "Rostelecom",
			symbol: "RTKM",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [
				"Telecommunications Services",
				"Integrated Telecommunications Services",
			],
			symbols: [
				{ yahoo: "RTL.F", google: "FRA:RTL", currency: "EUR" },
				{ yahoo: "ROSYY", google: "OTCMKTS:ROSYY", currency: "USD" },
				{ yahoo: "RTKM.ME", google: "MCX:RTKM", currency: "RUB" },
			],
		},
		{
			name: "United Company RUSAL",
			symbol: "RUAL",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Aluminium"],
			symbols: [
				{ yahoo: "R6L.F", google: "FRA:R6L", currency: "EUR" },
				{ yahoo: "RUAL.ME", google: "MCX:RUAL", currency: "RUB" },
				{ yahoo: "UNRIF", google: "OTCMKTS:UNRIF", currency: "USD" },
			],
		},
		{
			name: "RusHydro",
			symbol: "HYDR",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Electric utility", "Hydroelectrical power"],
			symbols: [
				{ yahoo: "RG2A.F", google: "FRA:RG2A", currency: "EUR" },
				{ yahoo: "RSHYY", google: "OTCMKTS:RSHYY", currency: "USD" },
				{ yahoo: "HYDR.ME", google: "MCX:HYDR", currency: "RUB" },
			],
		},
		{
			name: "Sberbank of Russia",
			symbol: "SBER",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Banking"],
			symbols: [
				{ yahoo: "SBNC.F", google: "FRA:SBNC", currency: "EUR" },
				{ yahoo: "SBRCY", google: "OTCMKTS:SBRCY", currency: "USD" },
				{ yahoo: "SBER.ME", google: "MCX:SBER", currency: "RUB" },
			],
		},
		{
			name: "Severstal",
			symbol: "CHMF",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Steel", "Mining"],
			symbols: [
				{ yahoo: "RTS2.F", google: "FRA:RTS2", currency: "EUR" },
				{ yahoo: "SVJTY", google: "OTCMKTS:SVJTY", currency: "USD" },
				{ yahoo: "CHMF.ME", google: "MCX:CHMF", currency: "RUB" },
				{ yahoo: "0SJQ.L", google: "LON:0SJQ", currency: "GBP" },
			],
		},
		{
			name: "Sistema",
			symbol: "AFKS",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Banking", "Real Estate", "Media", "Tourism"],
			symbols: [{ yahoo: "AFKS.ME", google: "MCX:AFKS", currency: "RUB" }],
		},
		{
			name: "Surgutneftegas",
			symbol: "SNGS",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "SGNV.F", google: "FRA:SGNV", currency: "EUR" },
				{ yahoo: "SGTPY", google: "OTCMKTS:SGTPY", currency: "USD" },
				{ yahoo: "SNGS.ME", google: "MCX:SNGS", currency: "RUB" },
				{ yahoo: "SNGSP.ME", google: "MCX:SNGSP", currency: "RUB" },
			],
		},
		{
			name: "Tatneft",
			symbol: "TATN",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [
				{ yahoo: "TTFB.F", google: "FRA:TTFB", currency: "EUR" },
				{ yahoo: "OAOFY", google: "OTCMKTS:OAOFY", currency: "USD" },
				{ yahoo: "TATN.ME", google: "MCX:TATN", currency: "RUB" },
				{ yahoo: "TATNP.ME", google: "MCX:TATNP", currency: "RUB" },
			],
		},
		{
			name: "Transneft",
			symbol: "TRNFP",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Energy", "Fossil Fuels", "Oil & Gas"],
			symbols: [{ yahoo: "TRNFP.ME", google: "MCX:TRNFP", currency: "RUB" }],
		},
		{
			name: "X5 Retail Group",
			symbol: "FIVE",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["retail"],
			symbols: [
				{ yahoo: "PJP.F", google: "FRA:PJP", currency: "EUR" },
				{ yahoo: "FIVE.ME", google: "MCX:FIVE", currency: "RUB" },
				{ yahoo: "FIVE.L", google: "LON:FIVE", currency: "GBP" },
			],
		},
		{
			name: "Yandex N.V.",
			symbol: "NASDAQ:YNDX",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Internet", "Search engine"],
			symbols: [
				{ yahoo: "YDX.F", google: "FRA:YDX", currency: "EUR" },
				{ yahoo: "YNDX", google: "NYSE:YNDX", currency: "USD" },
				{ yahoo: "YNDX.ME", google: "MCX:YNDX", currency: "RUB" },
			],
		},
		{
			name: "AVEVA Group plc",
			wiki_name: "AVEVA Group plc",
			symbol: "AVV",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [
				"Software",
				"Enterprise Asset Management",
				"Manufacturing Execution System",
				"Enterprise Solutions",
			],
			symbols: [
				{ yahoo: "AVV.L", google: "LON:AVV", currency: "GBP" },
				{ yahoo: "AVEVF", google: "OTCMKTS:AVEVF", currency: "USD" },
				{ yahoo: "4A91.F", google: "FRA:4A91", currency: "EUR" },
				{ yahoo: "AVVYY", google: "OTCMKTS:AVVYY", currency: "USD" },
			],
			isins: ["GB00BBG9VN75"],
			metadata: { founded: 1967, employees: 1317 },
			akas: [],
		},
		{
			name: "B&M S.A.",
			wiki_name: "B&M S.A.",
			symbol: "BME",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Retail"],
			symbols: [
				{ yahoo: "BME.L", google: "LON:BME", currency: "GBP" },
				{ yahoo: "BMRRY", google: "OTCMKTS:BMRRY", currency: "USD" },
				{ yahoo: "BMRPF", google: "OTCMKTS:BMRPF", currency: "USD" },
				{ yahoo: "BMNU.F", google: "FRA:BMNU", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1978, employees: 32000 },
			akas: [],
		},
		{
			name: "Entain plc",
			wiki_name: "Entain plc",
			symbol: "ENT",
			country: "Isle of Man",
			indices: ["FTSE 100"],
			industries: ["Online gambling"],
			symbols: [
				{ yahoo: "ENT.L", google: "LON:ENT", currency: "GBP" },
				{ yahoo: "6GI.F", google: "FRA:6GI", currency: "EUR" },
				{ yahoo: "GMVHF", google: "OTCMKTS:GMVHF", currency: "USD" },
				{ yahoo: "GMVHY", google: "OTCMKTS:GMVHY", currency: "USD" },
				{ yahoo: "6GI0.F", google: "FRA:6GI0", currency: "EUR" },
			],
			isins: ["IM00B5VQMV65", "US2936031069"],
			metadata: { founded: 2004, employees: "" },
			akas: [],
		},
		{
			name: "Intermediate Capital Group plc",
			wiki_name: "Intermediate Capital Group plc",
			symbol: "ICP",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Financial services", "asset management"],
			symbols: [
				{ yahoo: "I2X2.F", google: "FRA:I2X2", currency: "EUR" },
				{ yahoo: "ICGUF", google: "OTCMKTS:ICGUF", currency: "USD" },
				{ yahoo: "ICP.L", google: "LON:ICP", currency: "GBP" },
			],
			isins: ["GB00BYT1DJ19"],
			metadata: { founded: 1989, employees: "" },
			akas: [],
		},
		{
			name: "JD Sports Fashion plc",
			wiki_name: "JD Sports Fashion plc",
			symbol: "JD",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Retail"],
			symbols: [
				{ yahoo: "JD.L", google: "LON:JD", currency: "GBP" },
				{ yahoo: "9JD2.F", google: "FRA:9JD2", currency: "EUR" },
				{ yahoo: "JDSPY", google: "OTCMKTS:JDSPY", currency: "USD" },
				{ yahoo: "JDDSF", google: "OTCMKTS:JDDSF", currency: "USD" },
				{ yahoo: "9JD.F", google: "FRA:9JD", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1981, employees: 34885 },
			akas: [],
		},
		{
			name: "Just Eat Takeaway.com NV",
			wiki_name: "Just Eat Takeaway.com NV",
			symbol: "JET",
			country: "Netherlands",
			indices: ["AEX"],
			industries: ["Online food ordering"],
			symbols: [
				{ yahoo: "GRUB", google: "NASDAQ:GRUB", currency: "USD" },
				{ yahoo: "T5W.F", google: "FRA:T5W", currency: "EUR" },
			],
			isins: ["NL0012015705"],
			metadata: { founded: 2000, employees: 5423 },
			akas: [],
		},
		{
			name: "Landsec",
			wiki_name: "Landsec",
			symbol: "LAND",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Property"],
			symbols: [],
			isins: ["GB0031809436"],
			metadata: { founded: 1944, employees: 578 },
			akas: [],
		},
		{
			name: "M&G",
			wiki_name: "M&G",
			symbol: "MNG",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Financial services"],
			symbols: [
				{ yahoo: "MGPUF", google: "OTCMKTS:MGPUF", currency: "USD" },
				{ yahoo: "MNG.L", google: "LON:MNG", currency: "GBP" },
			],
			isins: ["GB00BKFB1C65"],
			metadata: { founded: 1931, employees: 6000 },
			akas: [],
		},
		{
			name: "Rio Tinto Group Rio Tinto plc & Rio Tinto Limited",
			wiki_name: "Rio Tinto Group Rio Tinto plc & Rio Tinto Limited",
			symbol: "RIO",
			country: "Australia",
			indices: ["FTSE 100"],
			industries: ["Metal", "Mining"],
			symbols: [{ yahoo: "RIO", google: "NYSE:RIO", currency: "USD" }],
			isins: ["AU000000RIO1", "GB0007188757"],
			metadata: { founded: 1873, employees: 45000 },
			akas: [],
		},
		{
			name: "SSE plc",
			wiki_name: "SSE plc",
			symbol: "SSE",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [],
			symbols: [
				{ yahoo: "SSE.L", google: "LON:SSE", currency: "GBP" },
				{ yahoo: "SCT.F", google: "FRA:SCT", currency: "EUR" },
				{ yahoo: "SSEZY", google: "OTCMKTS:SSEZY", currency: "USD" },
				{ yahoo: "SCTA.F", google: "FRA:SCTA", currency: "EUR" },
			],
			isins: ["GB0007908733"],
			metadata: { founded: 1998, employees: 11720 },
			akas: [],
		},
		{
			name: "Alliant Energy Corporation",
			wiki_name: "Alliant Energy Corporation",
			symbol: "LNT",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["utilities"],
			symbols: [
				{ yahoo: "0HCT.L", google: "LON:0HCT", currency: "GBP" },
				{ yahoo: "LNT", google: "NASDAQ:LNT", currency: "USD" },
				{ yahoo: "AY1.F", google: "FRA:AY1", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1917, employees: 3989 },
			akas: [],
		},
		{
			name: "Amcor plc",
			wiki_name: "Amcor plc",
			symbol: "AMCR",
			country: "Switzerland",
			indices: ["S&P 500"],
			industries: ["Packaging"],
			symbols: [
				{ yahoo: "AMCR", google: "NYSE:AMCR", currency: "USD" },
				{ yahoo: "AXZA.F", google: "FRA:AXZA", currency: "EUR" },
				{ yahoo: "485B.F", google: "FRA:485B", currency: "EUR" },
				{ yahoo: "AMCCF", google: "OTCMKTS:AMCCF", currency: "USD" },
				{ yahoo: "485.F", google: "FRA:485", currency: "EUR" },
			],
			isins: ["JE00BJ1F3079"],
			metadata: { founded: 1860, employees: 35000 },
			akas: [],
		},
		{
			name: "Bio-Rad Laboratories, Inc.",
			wiki_name: "Bio-Rad Laboratories, Inc.",
			symbol: "BIO",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "BIO", google: "NYSE:BIO", currency: "USD" },
				{ yahoo: "BUWA.F", google: "FRA:BUWA", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1952, employees: 8120 },
			akas: [],
		},
		{
			name: "Carrier Global Corporation",
			wiki_name: "Carrier Global Corporation",
			symbol: "CARR",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Heating", "Ventilating", "Air Conditioning", "HVAC"],
			symbols: [
				{ yahoo: "CARR", google: "NYSE:CARR", currency: "USD" },
				{ yahoo: "4PN.F", google: "FRA:4PN", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1915, employees: 53000 },
			akas: [],
		},
		{
			name: "Catalent, Inc.",
			wiki_name: "Catalent, Inc.",
			symbol: "CTLT",
			country: "United Kingdom",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "CTLT", google: "NYSE:CTLT", currency: "USD" },
				{ yahoo: "0C8.F", google: "FRA:0C8", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 2007, employees: 14000 },
			akas: [],
		},
		{
			name: "Cboe Global Markets, Inc.",
			wiki_name: "Cboe Global Markets, Inc.",
			symbol: "CBOE",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "0HQN.L", google: "LON:0HQN", currency: "GBP" },
				{ yahoo: "C67.F", google: "FRA:C67", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1973, employees: 823 },
			akas: [],
		},
		{
			name: "CDW Corporation",
			wiki_name: "CDW Corporation",
			symbol: "CDW",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["IT"],
			symbols: [
				{ yahoo: "CDW", google: "NASDAQ:CDW", currency: "USD" },
				{ yahoo: "CDW.F", google: "FRA:CDW", currency: "EUR" },
			],
			isins: ["US12514G1085"],
			metadata: { founded: 1984, employees: 9019 },
			akas: [],
		},
		{
			name: "DexCom, Inc.",
			wiki_name: "DexCom, Inc.",
			symbol: "DXCM",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "DXCM", google: "NASDAQ:DXCM", currency: "USD" },
				{ yahoo: "DC4.F", google: "FRA:DC4", currency: "EUR" },
				{ yahoo: "0A4M.L", google: "LON:0A4M", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 1999, employees: 3900 },
			akas: [],
		},
		{
			name: "Domino's Pizza, Inc.",
			wiki_name: "Domino's Pizza, Inc.",
			symbol: "DPZ",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Food delivery", "Franchising", "Restaurants"],
			symbols: [
				{ yahoo: "DPZ", google: "NYSE:DPZ", currency: "USD" },
				{ yahoo: "DOM.L", google: "LON:DOM", currency: "GBP" },
				{ yahoo: "DPUKY", google: "OTCMKTS:DPUKY", currency: "USD" },
				{ yahoo: "EZV.F", google: "FRA:EZV", currency: "EUR" },
				{ yahoo: "DKOB.F", google: "FRA:DKOB", currency: "EUR" },
			],
			isins: ["US25754A2015"],
			metadata: { founded: 1960, employees: 14400 },
			akas: [],
		},
		{
			name: "Dow Inc.",
			wiki_name: "Dow Inc.",
			symbol: "DOW",
			country: "United States",
			indices: ["DOW JONES", "S&P 100", "S&P 500"],
			industries: ["Chemicals"],
			symbols: [
				{ yahoo: "2OY.F", google: "FRA:2OY", currency: "EUR" },
				{ yahoo: "DOW", google: "NYSE:DOW", currency: "USD" },
				{ yahoo: "0A1S.L", google: "LON:0A1S", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 2019, employees: 36500 },
			akas: [],
		},
		{
			name: "Etsy, Inc.",
			wiki_name: "Etsy, Inc.",
			symbol: "ETSY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "ETSY", google: "NASDAQ:ETSY", currency: "USD" },
				{ yahoo: "0IIW.L", google: "LON:0IIW", currency: "GBP" },
				{ yahoo: "3E2.F", google: "FRA:3E2", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 2005, employees: 1400 },
			akas: [],
		},
		{
			name: "General Dynamics Corporation",
			wiki_name: "General Dynamics Corporation",
			symbol: "GD",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: ["Aerospace", "Defense"],
			symbols: [
				{ yahoo: "GD", google: "NYSE:GD", currency: "USD" },
				{ yahoo: "0IUC.L", google: "LON:0IUC", currency: "GBP" },
				{ yahoo: "GDX.F", google: "FRA:GDX", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1899, employees: 104000 },
			akas: [],
		},
		{
			name: "Howmet Aerospace Inc.",
			wiki_name: "Howmet Aerospace Inc.",
			symbol: "HWM",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "HWM", google: "NYSE:HWM", currency: "USD" },
				{ yahoo: "48Z.F", google: "FRA:48Z", currency: "EUR" },
				{ yahoo: "48Z0.F", google: "FRA:48Z0", currency: "EUR" },
				{ yahoo: "0TCU.L", google: "LON:0TCU", currency: "GBP" },
			],
			isins: ["US4432011082"],
			metadata: { founded: 1888, employees: 19700 },
			akas: [],
		},
		{
			name: "IDEX Corporation",
			wiki_name: "IDEX Corporation",
			symbol: "IEX",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Manufacturing"],
			symbols: [
				{ yahoo: "IEX", google: "NYSE:IEX", currency: "USD" },
				{ yahoo: "ID7.F", google: "FRA:ID7", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1988, employees: 7075 },
			akas: [],
		},
		{
			name: "KeyBank",
			wiki_name: "KeyBank",
			symbol: "KEY",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Financial services"],
			symbols: [{ yahoo: "KEY", google: "NYSE:KEY", currency: "USD" }],
			isins: [],
			metadata: { founded: 1825, employees: 17999 },
			akas: [],
		},
		{
			name: "L3Harris Technologies, Inc.",
			wiki_name: "L3Harris Technologies, Inc.",
			symbol: "LHX",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Aerospace", "Defense"],
			symbols: [
				{ yahoo: "LHX", google: "NYSE:LHX", currency: "USD" },
				{ yahoo: "HRS.F", google: "FRA:HRS", currency: "EUR" },
				{ yahoo: "0L3H.L", google: "LON:0L3H", currency: "GBP" },
				{ yahoo: "HRS", google: "NYSE:HRS", currency: "USD" },
			],
			isins: ["US5024311095"],
			metadata: { founded: 2019, employees: 50000 },
			akas: [],
		},
		{
			name: "Las Vegas Sands Corporation",
			wiki_name: "Las Vegas Sands Corporation",
			symbol: "LVS",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Hospitality", "Tourism"],
			symbols: [{ yahoo: "LVS", google: "NYSE:LVS", currency: "USD" }],
			isins: [],
			metadata: { founded: 1988, employees: 46000 },
			akas: [],
		},
		{
			name: "Leidos Holdings, Inc.",
			wiki_name: "Leidos Holdings, Inc.",
			symbol: "LDOS",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["defense", "healthcare"],
			symbols: [
				{ yahoo: "LDOS", google: "NYSE:LDOS", currency: "USD" },
				{ yahoo: "S6IA.F", google: "FRA:S6IA", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1969, employees: 32000 },
			akas: [],
		},
		{
			name: "Live Nation Entertainment, Inc.",
			wiki_name: "Live Nation Entertainment, Inc.",
			symbol: "LYV",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Entertainment"],
			symbols: [
				{ yahoo: "LYV", google: "NYSE:LYV", currency: "USD" },
				{ yahoo: "3LN.F", google: "FRA:3LN", currency: "EUR" },
				{ yahoo: "0JVD.L", google: "LON:0JVD", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 2010, employees: 10500 },
			akas: [],
		},
		{
			name: "Lockheed Martin Corporation",
			wiki_name: "Lockheed Martin Corporation",
			symbol: "LMT",
			country: "United States",
			indices: ["S&P 100", "S&P 500"],
			industries: ["Advanced Technologies", "Aerospace", "Defense"],
			symbols: [
				{ yahoo: "LMT", google: "NYSE:LMT", currency: "USD" },
				{ yahoo: "LOM.F", google: "FRA:LOM", currency: "EUR" },
				{ yahoo: "0R3E.L", google: "LON:0R3E", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 1995, employees: 110000 },
			akas: [],
		},
		{
			name: "Lumen Technologies, Inc.",
			wiki_name: "Lumen Technologies, Inc.",
			symbol: "LUMN",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Telecommunication"],
			symbols: [
				{ yahoo: "LUMN", google: "NYSE:LUMN", currency: "USD" },
				{ yahoo: "CYTH.F", google: "FRA:CYTH", currency: "EUR" },
				{ yahoo: "0HVP.L", google: "LON:0HVP", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 1930, employees: 42500 },
			akas: [],
		},
		{
			name: "MarketAxess Holdings Inc.",
			wiki_name: "MarketAxess Holdings Inc.",
			symbol: "MKTX",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "MKTX", google: "NASDAQ:MKTX", currency: "USD" },
				{ yahoo: "MWI.F", google: "FRA:MWI", currency: "EUR" },
			],
			isins: ["US57060D1081"],
			metadata: { founded: 2000, employees: 527 },
			akas: [],
		},
		{
			name: "Molson Coors Beverage Company",
			wiki_name: "Molson Coors Beverage Company",
			symbol: "TAP",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Food & Beverages",
				"Consumer Non-Cyclicals",
				"Brewers",
				"Beverages",
			],
			symbols: [
				{ yahoo: "TAP", google: "NYSE:TAP", currency: "USD" },
				{ yahoo: "TAP-A", google: "NYSE:TAP-A", currency: "USD" },
				{ yahoo: "NY7.F", google: "FRA:NY7", currency: "EUR" },
				{ yahoo: "0K2K.L", google: "LON:0K2K", currency: "GBP" },
				{ yahoo: "NY70.F", google: "FRA:NY70", currency: "EUR" },
			],
			isins: ["US60871R2094"],
			metadata: { founded: 1786, employees: 17700 },
			akas: [],
		},
		{
			name: "Northrop Grumman Corporation",
			wiki_name: "Northrop Grumman Corporation",
			symbol: "NOC",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Defense industry"],
			symbols: [
				{ yahoo: "NOC", google: "NYSE:NOC", currency: "USD" },
				{ yahoo: "0K92.L", google: "LON:0K92", currency: "GBP" },
				{ yahoo: "NTH.F", google: "FRA:NTH", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1994, employees: 97000 },
			akas: [],
		},
		{
			name: "NortonLifeLock Inc.",
			wiki_name: "NortonLifeLock Inc.",
			symbol: "NLOK",
			country: "United States",
			indices: [],
			industries: [
				"Computer security",
				"Software",
				"Technology",
				"Software & IT Services",
			],
			symbols: [
				{ yahoo: "NLOK", google: "NASDAQ:NLOK", currency: "USD" },
				{ yahoo: "SYM.F", google: "FRA:SYM", currency: "EUR" },
			],
			isins: ["US6687711084"],
			metadata: { founded: 1982, employees: 3659 },
			akas: [],
		},
		{
			name: "NVR, Inc.",
			wiki_name: "NVR, Inc.",
			symbol: "NVR",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Home construction"],
			symbols: [
				{ yahoo: "NVR", google: "NYSE:NVR", currency: "USD" },
				{ yahoo: "NVE.F", google: "FRA:NVE", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1980, employees: 5600 },
			akas: [],
		},
		{
			name: "Old Dominion Freight Line, Inc.",
			wiki_name: "Old Dominion Freight Line, Inc.",
			symbol: "ODFL",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "ODFL", google: "NASDAQ:ODFL", currency: "USD" },
				{ yahoo: "0A7P.L", google: "LON:0A7P", currency: "GBP" },
				{ yahoo: "ODF.F", google: "FRA:ODF", currency: "EUR" },
			],
			isins: ["US6795801009"],
			metadata: { founded: 1934, employees: 21279 },
			akas: [],
		},
		{
			name: "Otis Worldwide Corporation",
			wiki_name: "Otis Worldwide Corporation",
			symbol: "OTIS",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "OTIS", google: "NYSE:OTIS", currency: "USD" },
				{ yahoo: "4PG.F", google: "FRA:4PG", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1853, employees: 69000 },
			akas: [],
		},
		{
			name: "Paycom Software, Inc.",
			wiki_name: "Paycom Software, Inc.",
			symbol: "PAYC",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["HCM"],
			symbols: [
				{ yahoo: "PAYC", google: "NYSE:PAYC", currency: "USD" },
				{ yahoo: "0KGH.L", google: "LON:0KGH", currency: "GBP" },
				{ yahoo: "0PY.F", google: "FRA:0PY", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1998, employees: 4200 },
			akas: [],
		},
		{
			name: "PepsiCo, Inc.",
			wiki_name: "PepsiCo, Inc.",
			symbol: "PEP",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: ["Beverages"],
			symbols: [
				{ yahoo: "PEP", google: "NASDAQ:PEP", currency: "USD" },
				{ yahoo: "PEP.F", google: "FRA:PEP", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1898, employees: 267000 },
			akas: [],
		},
		{
			name: "Pool Corporation",
			wiki_name: "Pool Corporation",
			symbol: "POOL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "POOL", google: "NASDAQ:POOL", currency: "USD" },
				{ yahoo: "SP1.F", google: "FRA:SP1", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Principal Financial Group, Inc.",
			wiki_name: "Principal Financial Group, Inc.",
			symbol: "PFG",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "PFG", google: "NASDAQ:PFG", currency: "USD" },
				{ yahoo: "PG4.F", google: "FRA:PG4", currency: "EUR" },
				{ yahoo: "0KO5.L", google: "LON:0KO5", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 1879, employees: 17600 },
			akas: [],
		},
		{
			name: "Regency Centers Corporation",
			wiki_name: "Regency Centers Corporation",
			symbol: "REG",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Real estate investment trust"],
			symbols: [
				{ yahoo: "REG", google: "NASDAQ:REG", currency: "USD" },
				{ yahoo: "RRC.F", google: "FRA:RRC", currency: "EUR" },
				{ yahoo: "0KUT.L", google: "LON:0KUT", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 1963, employees: 450 },
			akas: [],
		},
		{
			name: "ServiceNow, Inc.",
			wiki_name: "ServiceNow, Inc.",
			symbol: "NOW",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Enterprise software"],
			symbols: [
				{ yahoo: "NOW", google: "NYSE:NOW", currency: "USD" },
				{ yahoo: "0L5N.L", google: "LON:0L5N", currency: "GBP" },
				{ yahoo: "4S0.F", google: "FRA:4S0", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 2003, employees: 12500 },
			akas: [],
		},
		{
			name: "Teledyne Technologies Incorporated",
			wiki_name: "Teledyne Technologies Incorporated",
			symbol: "TDY",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Conglomerate"],
			symbols: [
				{ yahoo: "TDY", google: "NYSE:TDY", currency: "USD" },
				{ yahoo: "TYZ.F", google: "FRA:TYZ", currency: "EUR" },
				{ yahoo: "0ACF.L", google: "LON:0ACF", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 1960, employees: 11790 },
			akas: [],
		},
		{
			name: "Teradyne, Inc.",
			wiki_name: "Teradyne, Inc.",
			symbol: "TER",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "TER", google: "NASDAQ:TER", currency: "USD" },
				{ yahoo: "0LEF.L", google: "LON:0LEF", currency: "GBP" },
				{ yahoo: "TEY.F", google: "FRA:TEY", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1960, employees: 5400 },
			akas: [],
		},
		{
			name: "Textron Inc.",
			wiki_name: "Textron Inc.",
			symbol: "TXT",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Aerospace industry",
				"Automotive industry",
				"Defence industry",
			],
			symbols: [
				{ yahoo: "TXT", google: "NYSE:TXT", currency: "USD" },
				{ yahoo: "0LF0.L", google: "LON:0LF0", currency: "GBP" },
				{ yahoo: "TXT.F", google: "FRA:TXT", currency: "EUR" },
			],
			isins: ["US8832031012"],
			metadata: { founded: 1923, employees: 35000 },
			akas: [],
		},
		{
			name: "Trane Technologies plc",
			wiki_name: "Trane Technologies plc",
			symbol: "TT",
			country: "Ireland",
			indices: ["S&P 500"],
			industries: ["Conglomerate"],
			symbols: [
				{ yahoo: "TT", google: "NYSE:TT", currency: "USD" },
				{ yahoo: "2IS.F", google: "FRA:2IS", currency: "EUR" },
			],
			isins: ["IE00B6330302"],
			metadata: { founded: 1871, employees: 50000 },
			akas: [],
		},
		{
			name: "TransDigm Group",
			wiki_name: "TransDigm Group",
			symbol: "TDG",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Industrial Goods", "Industrials", "Aerospace & Defense"],
			symbols: [
				{ yahoo: "T7D.F", google: "FRA:T7D", currency: "EUR" },
				{ yahoo: "TDG", google: "NYSE:TDG", currency: "USD" },
				{ yahoo: "0REK.L", google: "LON:0REK", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 1993, employees: 14200 },
			akas: [],
		},
		{
			name: "Truist Financial Corporation",
			wiki_name: "Truist Financial Corporation",
			symbol: "TFC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Financial services",
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "TFC", google: "NYSE:TFC", currency: "USD" },
				{ yahoo: "BBK.F", google: "FRA:BBK", currency: "EUR" },
			],
			isins: ["US89832Q1094"],
			metadata: { founded: 1872, employees: 54982 },
			akas: [],
		},
		{
			name: "Tyler Technologies, Inc.",
			wiki_name: "Tyler Technologies, Inc.",
			symbol: "TYL",
			country: "Canada",
			indices: ["S&P 500"],
			industries: ["Software"],
			symbols: [
				{ yahoo: "TYL", google: "NYSE:TYL", currency: "USD" },
				{ yahoo: "TYP.F", google: "FRA:TYP", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1966, employees: 5368 },
			akas: [],
		},
		{
			name: "Viatris Inc.",
			wiki_name: "Viatris Inc.",
			symbol: "VTRS",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Pharmaceuticals"],
			symbols: [
				{ yahoo: "VTRS", google: "NASDAQ:VTRS", currency: "USD" },
				{ yahoo: "VIA.F", google: "FRA:VIA", currency: "EUR" },
			],
			isins: ["US92556V1061"],
			metadata: { founded: 2020, employees: 45000 },
			akas: [],
		},
		{
			name: "W. R. Berkley Corporation",
			wiki_name: "W. R. Berkley Corporation",
			symbol: "WRB",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Insurance"],
			symbols: [
				{ yahoo: "WRB", google: "NYSE:WRB", currency: "USD" },
				{ yahoo: "0HMZ.L", google: "LON:0HMZ", currency: "GBP" },
				{ yahoo: "WR1.F", google: "FRA:WR1", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1967, employees: 7448 },
			akas: [],
		},
		{
			name: "West Pharmaceutical Services, Inc.",
			wiki_name: "West Pharmaceutical Services, Inc.",
			symbol: "WST",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Pharmaceuticals"],
			symbols: [
				{ yahoo: "WST", google: "NYSE:WST", currency: "USD" },
				{ yahoo: "WPS.F", google: "FRA:WPS", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1923, employees: 8200 },
			akas: [],
		},
		{
			name: "Xcel Energy Inc.",
			wiki_name: "Xcel Energy Inc.",
			symbol: "XEL",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "XEL", google: "NASDAQ:XEL", currency: "USD" },
				{ yahoo: "NRN.F", google: "FRA:NRN", currency: "EUR" },
				{ yahoo: "0M1R.L", google: "LON:0M1R", currency: "GBP" },
			],
			isins: ["US98389B1008"],
			metadata: { founded: 1909, employees: 11075 },
			akas: [],
		},
		{
			name: "Zebra Technologies Corporation",
			wiki_name: "Zebra Technologies Corporation",
			symbol: "ZBRA",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Computer hardware"],
			symbols: [
				{ yahoo: "ZBRA", google: "NASDAQ:ZBRA", currency: "USD" },
				{ yahoo: "ZT1A.F", google: "FRA:ZT1A", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1969, employees: 7400 },
			akas: [],
		},
		{
			name: "Knorr-Bremse Aktiengesellschaft",
			wiki_name: "Knorr-Bremse Aktiengesellschaft",
			symbol: "KBX",
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Manufacturing"],
			symbols: [
				{ yahoo: "KBX.F", google: "FRA:KBX", currency: "EUR" },
				{ yahoo: "0KBI.L", google: "LON:0KBI", currency: "GBP" },
				{ yahoo: "KNRRY", google: "OTCMKTS:KNRRY", currency: "USD" },
				{ yahoo: "KNBHF", google: "OTCMKTS:KNBHF", currency: "USD" },
				{ yahoo: "KBXA.F", google: "FRA:KBXA", currency: "EUR" },
			],
			isins: ["DE000KBX1006"],
			metadata: { founded: 1905, employees: 28905 },
			akas: [],
		},
		{
			name: "Shop Apotheke Europe",
			wiki_name: "Shop Apotheke Europe",
			symbol: "SAE",
			country: "Netherlands",
			indices: ["SDAX"],
			industries: [
				"Consumer Non-Cyclicals",
				"Food & Drug Retailing",
				"Drug Retailers",
			],
			symbols: [
				{ yahoo: "SAE.F", google: "FRA:SAE", currency: "EUR" },
				{ yahoo: "SAEYY", google: "OTCMKTS:SAEYY", currency: "USD" },
				{ yahoo: "SHPPF", google: "OTCMKTS:SHPPF", currency: "USD" },
				{ yahoo: "SAE1.F", google: "FRA:SAE1", currency: "EUR" },
			],
			isins: ["NL0012044747"],
			metadata: { founded: 2001, employees: 1175 },
			akas: [],
		},
		{
			name: "Siemens Energy AG",
			wiki_name: "Siemens Energy AG",
			symbol: "ENR",
			country: "Germany",
			indices: ["DAX"],
			industries: ["Energy technology"],
			symbols: [
				{ yahoo: "ENR.F", google: "FRA:ENR", currency: "EUR" },
				{ yahoo: "SMEGF", google: "OTCMKTS:SMEGF", currency: "USD" },
				{ yahoo: "SMNEY", google: "OTCMKTS:SMNEY", currency: "USD" },
				{ yahoo: "ENR0.F", google: "FRA:ENR0", currency: "EUR" },
				{ yahoo: "0SEA.L", google: "LON:0SEA", currency: "GBP" },
			],
			isins: ["DE000ENER6Y0"],
			metadata: { founded: 2020, employees: 92000 },
			akas: [],
		},
		{
			name: "Strör SE & Co. KGaA",
			wiki_name: "Strör SE & Co. KGaA",
			symbol: null,
			country: "Germany",
			indices: ["MDAX"],
			industries: ["Mass media"],
			symbols: [],
			isins: ["DE0007493991"],
			metadata: { founded: 1990, employees: 12210 },
			akas: [],
		},
		{
			name: "TeamViewer AG",
			wiki_name: "TeamViewer AG",
			symbol: null,
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: [],
			symbols: [
				{ yahoo: "TMV.F", google: "FRA:TMV", currency: "EUR" },
				{ yahoo: "TMVWY", google: "OTCMKTS:TMVWY", currency: "USD" },
				{
					yahoo: "DE000A2YN900.F",
					google: "FRA:DE000A2YN900",
					currency: "EUR",
				},
				{ yahoo: "TMV1.F", google: "FRA:TMV1", currency: "EUR" },
			],
			isins: ["DE000A2YN900"],
			metadata: { founded: 2005, employees: 1256 },
			akas: [],
		},
		{
			name: "VARTA AG",
			wiki_name: "VARTA AG",
			symbol: "VAR1",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: ["Electrical equipment"],
			symbols: [
				{ yahoo: "VAR1.F", google: "FRA:VAR1", currency: "EUR" },
				{ yahoo: "VARGF", google: "OTCMKTS:VARGF", currency: "USD" },
				{ yahoo: "VARTY", google: "OTCMKTS:VARTY", currency: "USD" },
				{ yahoo: "VAR.F", google: "FRA:VAR", currency: "EUR" },
			],
			isins: ["DE000A0TGJ55"],
			metadata: { founded: 1887, employees: 4601 },
			akas: [],
		},
		{
			name: "Prosus N.V.",
			wiki_name: "Prosus N.V.",
			symbol: "PRX",
			country: "Netherlands",
			indices: ["AEX", "EURO STOXX 50"],
			industries: [],
			symbols: [
				{ yahoo: "PROSY", google: "OTCMKTS:PROSY", currency: "USD" },
				{ yahoo: "PRX.AS", google: "AMS:PRX", currency: "EUR" },
				{
					yahoo: "NL0013654783.F",
					google: "FRA:NL0013654783",
					currency: "EUR",
				},
				{ yahoo: "1TY.F", google: "FRA:1TY", currency: "EUR" },
				{ yahoo: "1YL.F", google: "FRA:1YL", currency: "EUR" },
			],
			isins: ["NL0013654783"],
			metadata: { founded: 2019, employees: 15677 },
			akas: [],
		},
		{
			name: "Air France-KLM Group SA",
			wiki_name: "Air France-KLM Group SA",
			symbol: "XPAR",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Aviation"],
			symbols: [],
			isins: ["FR0000031122"],
			metadata: { founded: 2004, employees: 88800 },
			akas: [],
		},
		{
			name: "ALD Automotive",
			wiki_name: "ALD Automotive",
			symbol: "ALD",
			country: "Germany",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [],
			isins: [],
			metadata: { founded: 1968, employees: 6500 },
			akas: [],
		},
		{
			name: "ALTEN S.A.",
			wiki_name: "ALTEN S.A.",
			symbol: "AN3",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "AN3.F", google: "FRA:AN3", currency: "EUR" },
				{ yahoo: "0O1S.L", google: "LON:0O1S", currency: "GBP" },
			],
			isins: ["FR0000071946"],
			metadata: { founded: 1988, employees: 37200 },
			akas: [],
		},
		{
			name: "Amundi",
			wiki_name: "Amundi",
			symbol: "AMUN",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Investment management"],
			symbols: [{ yahoo: "AMDUF", google: "OTCMKTS:AMDUF", currency: "USD" }],
			isins: ["FR0004125920"],
			metadata: { founded: 2010, employees: 4500 },
			akas: [],
		},
		{
			name: "BIC S.A.",
			wiki_name: "BIC S.A.",
			symbol: "BB",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Manufacturing"],
			symbols: [{ yahoo: "BIF.F", google: "FRA:BIF", currency: "EUR" }],
			isins: ["FR0000120966"],
			metadata: { founded: 1945, employees: 14934 },
			akas: [],
		},
		{
			name: "Bolloré",
			wiki_name: "Bolloré",
			symbol: "BOIVF",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Transport"],
			symbols: [
				{ yahoo: "BOIVF", google: "OTCMKTS:BOIVF", currency: "USD" },
				{ yahoo: "BOP.F", google: "FRA:BOP", currency: "EUR" },
			],
			isins: ["FR0000039299"],
			metadata: { founded: 1822, employees: 37886 },
			akas: [],
		},
		{
			name: "CGG (Unternehmen)",
			wiki_name: "CGG (Unternehmen)",
			symbol: "CGGYY",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Oil services"],
			symbols: [
				{ yahoo: "CGGYY", google: "OTCMKTS:CGGYY", currency: "USD" },
				{ yahoo: "GDGE.F", google: "FRA:GDGE", currency: "EUR" },
				{ yahoo: "CGG", google: "NYSE:CGG", currency: "USD" },
				{ yahoo: "GDGF.F", google: "FRA:GDGF", currency: "EUR" },
			],
			isins: ["FR0013181864"],
			metadata: { founded: 1931, employees: 5400 },
			akas: [],
		},
		{
			name: "Covivio",
			wiki_name: "Covivio",
			symbol: "GSEFF",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Commercial Real Estate"],
			symbols: [
				{ yahoo: "GSEFF", google: "OTCMKTS:GSEFF", currency: "USD" },
				{ yahoo: "F5D.F", google: "FRA:F5D", currency: "EUR" },
				{ yahoo: "FNCDY", google: "OTCMKTS:FNCDY", currency: "USD" },
				{ yahoo: "F5D0.F", google: "FRA:F5D0", currency: "EUR" },
			],
			isins: ["FR0000064578"],
			metadata: { founded: 2000, employees: 922 },
			akas: [],
		},
		{
			name: "Dassault Aviation S.A.",
			wiki_name: "Dassault Aviation S.A.",
			symbol: "DAU",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Aerospace", "Defense"],
			symbols: [
				{ yahoo: "DAU.F", google: "FRA:DAU", currency: "EUR" },
				{ yahoo: "DUAVF", google: "OTCMKTS:DUAVF", currency: "USD" },
				{ yahoo: "DAU0.F", google: "FRA:DAU0", currency: "EUR" },
			],
			isins: ["FR0000121725", "FR0014004L86"],
			metadata: { founded: 1929, employees: 11398 },
			akas: [],
		},
		{
			name: "Elior Group",
			wiki_name: "Elior Group",
			symbol: "ELIOR",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Foodservice"],
			symbols: [
				{ yahoo: "21E.F", google: "FRA:21E", currency: "EUR" },
				{ yahoo: "ELROF", google: "OTCMKTS:ELROF", currency: "USD" },
				{ yahoo: "ELORY", google: "OTCMKTS:ELORY", currency: "USD" },
			],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Eramet",
			wiki_name: "Eramet",
			symbol: "ERMAY",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "ERMAY", google: "OTCMKTS:ERMAY", currency: "USD" },
				{ yahoo: "ER7.F", google: "FRA:ER7", currency: "EUR" },
				{ yahoo: "ER70.F", google: "FRA:ER70", currency: "EUR" },
			],
			isins: ["FR0000131757"],
			metadata: { founded: 1880, employees: 13938 },
			akas: [],
		},
		{
			name: "Eurazeo",
			wiki_name: "Eurazeo",
			symbol: "EUQ",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "EUQ.F", google: "FRA:EUQ", currency: "EUR" },
				{ yahoo: "EUZOF", google: "OTCMKTS:EUZOF", currency: "USD" },
			],
			isins: ["FR0000121121"],
			metadata: { founded: 2001, employees: 270 },
			akas: [],
		},
		{
			name: "Eurofins Scientific SE",
			wiki_name: "Eurofins Scientific SE",
			symbol: "ESF0",
			country: "Luxembourg",
			indices: ["CAC 40"],
			industries: ["Laboratory", "analysis"],
			symbols: [
				{ yahoo: "ESF0.F", google: "FRA:ESF0", currency: "EUR" },
				{ yahoo: "ERFSF", google: "OTCMKTS:ERFSF", currency: "USD" },
			],
			isins: ["FR0014000MR3"],
			metadata: { founded: 1987, employees: 43320 },
			akas: [],
		},
		{
			name: "Eutelsat Communications",
			wiki_name: "Eutelsat Communications",
			symbol: "EUTLF",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Satellite communication"],
			symbols: [
				{ yahoo: "EUTLF", google: "OTCMKTS:EUTLF", currency: "USD" },
				{ yahoo: "E3B.F", google: "FRA:E3B", currency: "EUR" },
				{ yahoo: "ETCMY", google: "OTCMKTS:ETCMY", currency: "USD" },
				{ yahoo: "E3B0.F", google: "FRA:E3B0", currency: "EUR" },
				{ yahoo: "ETL.L", google: "LON:ETL", currency: "GBP" },
			],
			isins: ["FR0010221234"],
			metadata: { founded: 1982, employees: 1000 },
			akas: [],
		},
		{
			name: "La Française des Jeux",
			wiki_name: "La Française des Jeux",
			symbol: null,
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Lottery"],
			symbols: [],
			isins: ["FR0013451333"],
			metadata: { founded: 1976, employees: 2200 },
			akas: [],
		},
		{
			name: "Casino Guichard-Perrachon S.A.",
			wiki_name: "Casino Guichard-Perrachon S.A.",
			symbol: "CGUSY",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "CGUSY", google: "OTCMKTS:CGUSY", currency: "USD" },
				{ yahoo: "CAJ.F", google: "FRA:CAJ", currency: "EUR" },
				{ yahoo: "CGUIF", google: "OTCMKTS:CGUIF", currency: "USD" },
				{ yahoo: "CAJ0.F", google: "FRA:CAJ0", currency: "EUR" },
			],
			isins: ["FR0000125585"],
			metadata: { founded: 1898, employees: 220000 },
			akas: [],
		},
		{
			name: "Fnac Darty SA",
			wiki_name: "Fnac Darty SA",
			symbol: "GRUPF",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "GRUPF", google: "OTCMKTS:GRUPF", currency: "USD" },
				{ yahoo: "1FN.F", google: "FRA:1FN", currency: "EUR" },
				{ yahoo: "0QSH.L", google: "LON:0QSH", currency: "GBP" },
			],
			isins: ["FR0011476928"],
			metadata: { founded: 1954, employees: 14500 },
			akas: [],
		},
		{
			name: "Gaztransport & Technigaz",
			wiki_name: "Gaztransport & Technigaz",
			symbol: "9TG",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "9TG.F", google: "FRA:9TG", currency: "EUR" },
				{ yahoo: "GZPZY", google: "OTCMKTS:GZPZY", currency: "USD" },
				{ yahoo: "GZPZF", google: "OTCMKTS:GZPZF", currency: "USD" },
				{ yahoo: "9TG0.F", google: "FRA:9TG0", currency: "EUR" },
			],
			isins: ["FR0011726835"],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Icade",
			wiki_name: "Icade",
			symbol: "3IC",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "3IC.F", google: "FRA:3IC", currency: "EUR" },
				{ yahoo: "CDMGF", google: "OTCMKTS:CDMGF", currency: "USD" },
				{ yahoo: "0K4O.L", google: "LON:0K4O", currency: "GBP" },
			],
			isins: ["FR0000035081"],
			metadata: { founded: 1954, employees: 1127 },
			akas: [],
		},
		{
			name: "Imerys",
			wiki_name: "Imerys",
			symbol: "IY4",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "IY4.F", google: "FRA:IY4", currency: "EUR" },
				{ yahoo: "IMYSF", google: "OTCMKTS:IMYSF", currency: "USD" },
				{ yahoo: "IMYSY", google: "OTCMKTS:IMYSY", currency: "USD" },
			],
			isins: ["FR0000120859"],
			metadata: { founded: 1880, employees: 16300 },
			akas: [],
		},
		{
			name: "Ipsen Group",
			wiki_name: "Ipsen Group",
			symbol: "IPN",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Pharmaceutical"],
			symbols: [
				{ yahoo: "IPSEY", google: "OTCMKTS:IPSEY", currency: "USD" },
				{ yahoo: "I7G.F", google: "FRA:I7G", currency: "EUR" },
			],
			isins: ["FR0010259150"],
			metadata: { founded: 1929, employees: 5700 },
			akas: [],
		},
		{
			name: "Ipsos SA",
			wiki_name: "Ipsos SA",
			symbol: "IPZ",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Public opinion research"],
			symbols: [
				{ yahoo: "IPZ.F", google: "FRA:IPZ", currency: "EUR" },
				{ yahoo: "IPSOF", google: "OTCMKTS:IPSOF", currency: "USD" },
			],
			isins: ["FR0000073298"],
			metadata: { founded: 1975, employees: 16664 },
			akas: [],
		},
		{
			name: "JCDecaux",
			wiki_name: "JCDecaux",
			symbol: "JCDXF",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "JCDXF", google: "OTCMKTS:JCDXF", currency: "USD" },
				{ yahoo: "DCS.F", google: "FRA:DCS", currency: "EUR" },
				{ yahoo: "JCDXY", google: "OTCMKTS:JCDXY", currency: "USD" },
				{ yahoo: "DCS0.F", google: "FRA:DCS0", currency: "EUR" },
			],
			isins: ["FR0000077919"],
			metadata: { founded: 1964, employees: 13030 },
			akas: [],
		},
		{
			name: "Korian",
			wiki_name: "Korian",
			symbol: "KO2",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Caregiving"],
			symbols: [
				{ yahoo: "KO2.F", google: "FRA:KO2", currency: "EUR" },
				{ yahoo: "0OPS.L", google: "LON:0OPS", currency: "GBP" },
			],
			isins: ["FR0010386334"],
			metadata: { founded: 2003, employees: 56000 },
			akas: [],
		},
		{
			name: "Mercialys",
			wiki_name: "Mercialys",
			symbol: "MEIYF",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "MEIYF", google: "OTCMKTS:MEIYF", currency: "USD" },
				{ yahoo: "M8Y.F", google: "FRA:M8Y", currency: "EUR" },
			],
			isins: ["FR0010241638"],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Nexans S.A.",
			wiki_name: "Nexans S.A.",
			symbol: "NXPRF",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Manufacturing", "technology"],
			symbols: [
				{ yahoo: "NXPRF", google: "OTCMKTS:NXPRF", currency: "USD" },
				{ yahoo: "NXS.F", google: "FRA:NXS", currency: "EUR" },
				{ yahoo: "NEXNY", google: "OTCMKTS:NEXNY", currency: "USD" },
				{ yahoo: "NXS0.F", google: "FRA:NXS0", currency: "EUR" },
			],
			isins: ["FR0000044448"],
			metadata: { founded: 1897, employees: 25843 },
			akas: [],
		},
		{
			name: "Nexity S.A.",
			wiki_name: "Nexity S.A.",
			symbol: "NQ9",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Real estate development", "Real estate services"],
			symbols: [
				{ yahoo: "NQ9.F", google: "FRA:NQ9", currency: "EUR" },
				{ yahoo: "NNXXY", google: "OTCMKTS:NNXXY", currency: "USD" },
			],
			isins: ["FR0010112524"],
			metadata: { founded: 2000, employees: 10000 },
			akas: [],
		},
		{
			name: "Compagnie Plastic Omnium",
			wiki_name: "Compagnie Plastic Omnium",
			symbol: "EZM",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Automobile"],
			symbols: [
				{ yahoo: "EZM.F", google: "FRA:EZM", currency: "EUR" },
				{ yahoo: "0NW1.L", google: "LON:0NW1", currency: "GBP" },
				{ yahoo: "PASTF", google: "OTCMKTS:PASTF", currency: "USD" },
			],
			isins: ["FR0000124570"],
			metadata: { founded: 1946, employees: 25000 },
			akas: [],
		},
		{
			name: "Quadient",
			wiki_name: "Quadient",
			symbol: "NPACY",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Technology"],
			symbols: [
				{ yahoo: "NPACY", google: "OTCMKTS:NPACY", currency: "USD" },
				{ yahoo: "NEQ.F", google: "FRA:NEQ", currency: "EUR" },
				{ yahoo: "NPACF", google: "OTCMKTS:NPACF", currency: "USD" },
				{ yahoo: "NEQ0.F", google: "FRA:NEQ0", currency: "EUR" },
			],
			isins: ["FR0000120560"],
			metadata: { founded: 1924, employees: 5500 },
			akas: [],
		},
		{
			name: "Rubis SCA",
			wiki_name: "Rubis SCA",
			symbol: "RBSFY",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "RBSFY", google: "OTCMKTS:RBSFY", currency: "USD" },
				{ yahoo: "RUBSF", google: "OTCMKTS:RUBSF", currency: "USD" },
				{ yahoo: "BYNN.F", google: "FRA:BYNN", currency: "EUR" },
				{ yahoo: "BYN.F", google: "FRA:BYN", currency: "EUR" },
			],
			isins: ["FR0013269123"],
			metadata: { founded: 1990, employees: 3508 },
			akas: [],
		},
		{
			name: "Soitec",
			wiki_name: "Soitec",
			symbol: "SLOIF",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "SLOIF", google: "OTCMKTS:SLOIF", currency: "USD" },
				{ yahoo: "SOH1.F", google: "FRA:SOH1", currency: "EUR" },
				{ yahoo: "SLOIY", google: "OTCMKTS:SLOIY", currency: "USD" },
				{ yahoo: "SOH.F", google: "FRA:SOH", currency: "EUR" },
			],
			isins: ["FR0013227113"],
			metadata: { founded: 1992, employees: 1500 },
			akas: [],
		},
		{
			name: "Sopra Steria Group SA",
			wiki_name: "Sopra Steria Group SA",
			symbol: "OR8",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["consulting", "services"],
			symbols: [
				{ yahoo: "OR8.F", google: "FRA:OR8", currency: "EUR" },
				{ yahoo: "SPSAF", google: "OTCMKTS:SPSAF", currency: "USD" },
			],
			isins: ["FR0000050809"],
			metadata: { founded: 1968, employees: 44114 },
			akas: [],
		},
		{
			name: "SPIE SA",
			wiki_name: "SPIE SA",
			symbol: "4SP",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "4SP.F", google: "FRA:4SP", currency: "EUR" },
				{ yahoo: "0R8M.L", google: "LON:0R8M", currency: "GBP" },
			],
			isins: ["FR0012757854"],
			metadata: { founded: 1900, employees: 47200 },
			akas: [],
		},
		{
			name: "TF1 Group",
			wiki_name: "TF1 Group",
			symbol: "FSE",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Media"],
			symbols: [{ yahoo: "FSE.F", google: "FRA:FSE", currency: "EUR" }],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Trigano S.A.",
			wiki_name: "Trigano S.A.",
			symbol: "TGO",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "TGO.F", google: "FRA:TGO", currency: "EUR" },
				{ yahoo: "TGNOF", google: "OTCMKTS:TGNOF", currency: "USD" },
			],
			isins: ["FR0005691656"],
			metadata: { founded: 1935, employees: 8735 },
			akas: [],
		},
		{
			name: "Vallourec S.A",
			wiki_name: "Vallourec S.A",
			symbol: "VACD",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Manufacturing"],
			symbols: [
				{ yahoo: "VACD.F", google: "FRA:VACD", currency: "EUR" },
				{ yahoo: "VLOWY", google: "OTCMKTS:VLOWY", currency: "USD" },
				{ yahoo: "VACE.F", google: "FRA:VACE", currency: "EUR" },
				{ yahoo: "VLOUF", google: "OTCMKTS:VLOUF", currency: "USD" },
			],
			isins: ["FR0000120354", "FR0013506730"],
			metadata: { founded: 1957, employees: 20 },
			akas: [],
		},
		{
			name: "Virbac",
			wiki_name: "Virbac",
			symbol: "VRBCF",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Animal health care"],
			symbols: [
				{ yahoo: "VRBCF", google: "OTCMKTS:VRBCF", currency: "USD" },
				{ yahoo: "V16.F", google: "FRA:V16", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Wendel SE",
			wiki_name: "Wendel SE",
			symbol: "WNDLF",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Investment"],
			symbols: [
				{ yahoo: "WNDLF", google: "OTCMKTS:WNDLF", currency: "USD" },
				{ yahoo: "WIS.F", google: "FRA:WIS", currency: "EUR" },
				{ yahoo: "0HZD.L", google: "LON:0HZD", currency: "GBP" },
			],
			isins: ["FR0000121204"],
			metadata: { founded: 2002, employees: 124118 },
			akas: [],
		},
		{
			name: "Thales S.A.",
			wiki_name: "Thales S.A.",
			symbol: "HO",
			country: "France",
			indices: ["CAC 40"],
			industries: ["Defence"],
			symbols: [
				{ yahoo: "THLLY", google: "OTCMKTS:THLLY", currency: "USD" },
				{ yahoo: "CSF.F", google: "FRA:CSF", currency: "EUR" },
				{ yahoo: "THLEF", google: "OTCMKTS:THLEF", currency: "USD" },
				{ yahoo: "CSF0.F", google: "FRA:CSF0", currency: "EUR" },
			],
			isins: ["FR0000121329"],
			metadata: { founded: 2000, employees: 80000 },
			akas: [],
		},
		{
			name: "Partners Group",
			wiki_name: "Partners Group",
			symbol: "PGHN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: [
				"Private equity",
				"private debt",
				"real estate",
				"infrastructure",
			],
			symbols: [
				{ yahoo: "PGPHF", google: "OTCMKTS:PGPHF", currency: "USD" },
				{ yahoo: "APG.MC", google: "BME:APG", currency: "EUR" },
				{ yahoo: "XE1.F", google: "FRA:XE1", currency: "EUR" },
			],
			isins: ["CH0024608827"],
			metadata: { founded: 1996, employees: 1500 },
			akas: [],
		},
		{
			name: "Swiss Reinsurance Company Ltd",
			wiki_name: "Swiss Reinsurance Company Ltd",
			symbol: "SREN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: ["Financial services"],
			symbols: [],
			isins: ["CH0126881561"],
			metadata: { founded: 1863, employees: 15401 },
			akas: [],
		},
		{
			name: "Aedifica",
			wiki_name: "Aedifica",
			symbol: "AOO",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [],
			symbols: [
				{ yahoo: "AOO.F", google: "FRA:AOO", currency: "EUR" },
				{ yahoo: "AEDFF", google: "OTCMKTS:AEDFF", currency: "USD" },
				{ yahoo: "0DKX.L", google: "LON:0DKX", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Colruyt Group",
			wiki_name: "Colruyt Group",
			symbol: "XBRU",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: ["Retail"],
			symbols: [
				{ yahoo: "EFC1.F", google: "FRA:EFC1", currency: "EUR" },
				{ yahoo: "EFC.F", google: "FRA:EFC", currency: "EUR" },
				{ yahoo: "CUYTY", google: "OTCMKTS:CUYTY", currency: "USD" },
			],
			isins: ["BE0974256852"],
			metadata: { founded: 1925, employees: 20300 },
			akas: [],
		},
		{
			name: "UMICORE N.V./S.A.",
			wiki_name: "UMICORE N.V./S.A.",
			symbol: "XBRU",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: ["Metals", "Mining"],
			symbols: [],
			isins: ["BE0974320526"],
			metadata: { founded: 1989, employees: 10000 },
			akas: [],
		},
		{
			name: "Aktiebolaget Electrolux",
			wiki_name: "Aktiebolaget Electrolux",
			symbol: "ELUX B",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: ["Home appliance"],
			symbols: [],
			isins: ["SE0000103814"],
			metadata: { founded: 1919, employees: 58265 },
			akas: [],
		},
		{
			name: "Svenska Cellulosa Aktiebolaget",
			wiki_name: "Svenska Cellulosa Aktiebolaget",
			symbol: "SCA A",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: ["Forestry", "paper"],
			symbols: [
				{ yahoo: "0MCJ.L", google: "LON:0MCJ", currency: "GBP" },
				{ yahoo: "SVCBF", google: "OTCMKTS:SVCBF", currency: "USD" },
				{ yahoo: "SCA.F", google: "FRA:SCA", currency: "EUR" },
				{ yahoo: "SCA1.F", google: "FRA:SCA1", currency: "EUR" },
				{ yahoo: "SCABY", google: "OTCMKTS:SCABY", currency: "USD" },
				{ yahoo: "0MCK.L", google: "LON:0MCK", currency: "GBP" },
			],
			isins: ["SE0000112724", "SE0000171886"],
			metadata: { founded: 1929, employees: 4031 },
			akas: [],
		},
		{
			name: "SSAB Svenskt Stål AB",
			wiki_name: "SSAB Svenskt Stål AB",
			symbol: "SSAB A",
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: ["Steel"],
			symbols: [],
			isins: ["SE0000171100"],
			metadata: { founded: 1978, employees: 14514 },
			akas: [],
		},
		{
			name: "Kemira Oyj",
			wiki_name: "Kemira Oyj",
			symbol: "KRA1V",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: ["Chemicals"],
			symbols: [
				{ yahoo: "KEM.F", google: "FRA:KEM", currency: "EUR" },
				{ yahoo: "KOYJF", google: "OTCMKTS:KOYJF", currency: "USD" },
				{ yahoo: "0MGG.L", google: "LON:0MGG", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 1920, employees: 5062 },
			akas: [],
		},
		{
			name: "Kojamo",
			wiki_name: "Kojamo",
			symbol: "KOJAMO",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [],
			symbols: [
				{ yahoo: "V4OC.F", google: "FRA:V4OC", currency: "EUR" },
				{ yahoo: "KOJAF", google: "OTCMKTS:KOJAF", currency: "USD" },
			],
			isins: ["FI4000312251"],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Neles Oyj",
			wiki_name: "Neles Oyj",
			symbol: "NELES",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: ["process industries"],
			symbols: [
				{ yahoo: "52R.F", google: "FRA:52R", currency: "EUR" },
				{ yahoo: "MXTOF", google: "OTCMKTS:MXTOF", currency: "USD" },
			],
			isins: ["FI4000440664"],
			metadata: { founded: 2020, employees: 2850 },
			akas: [],
		},
		{
			name: "TietoEVRY Oyj",
			wiki_name: "TietoEVRY Oyj",
			symbol: "TIETO",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: ["IT services", "IT consulting"],
			symbols: [
				{ yahoo: "TTEB.F", google: "FRA:TTEB", currency: "EUR" },
				{ yahoo: "0KG0.L", google: "LON:0KG0", currency: "GBP" },
			],
			isins: ["FI0009000277"],
			metadata: { founded: 1968, employees: 23632 },
			akas: [],
		},
		{
			name: "Eckert & Ziegler Strahlen- und Medizintechnik AG",
			wiki_name: "Eckert & Ziegler Strahlen- und Medizintechnik AG",
			symbol: "EUZ",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "EUZ.F", google: "FRA:EUZ", currency: "EUR" },
				{ yahoo: "0NZY.L", google: "LON:0NZY", currency: "GBP" },
			],
			isins: ["DE0005659700"],
			metadata: { founded: 1997, employees: 828 },
			akas: [],
		},
		{
			name: "Banco de Sabadell, S.A.",
			wiki_name: "Banco de Sabadell, S.A.",
			symbol: "SAB",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: ["Financial services"],
			symbols: [
				{ yahoo: "BDSB.F", google: "FRA:BDSB", currency: "EUR" },
				{ yahoo: "BNDSY", google: "OTCMKTS:BNDSY", currency: "USD" },
				{ yahoo: "SAB.MC", google: "BME:SAB", currency: "EUR" },
				{ yahoo: "BNDSF", google: "OTCMKTS:BNDSF", currency: "USD" },
				{ yahoo: "0H00.L", google: "LON:0H00", currency: "GBP" },
				{ yahoo: "BDS.F", google: "FRA:BDS", currency: "EUR" },
			],
			isins: ["ES0113860A34"],
			metadata: { founded: 1881, employees: 26181 },
			akas: [],
		},
		{
			name: "Banco Santander, S.A.",
			wiki_name: "Banco Santander, S.A.",
			symbol: "SAN",
			country: "Spain",
			indices: ["EURO STOXX 50", "IBEX 35"],
			industries: ["Financial services"],
			symbols: [
				{ yahoo: "SAN.MC", google: "BME:SAN", currency: "EUR" },
				{ yahoo: "SAN", google: "NYSE:SAN", currency: "USD" },
				{ yahoo: "BNC.L", google: "LON:BNC", currency: "GBP" },
				{ yahoo: "BCDRF", google: "OTCMKTS:BCDRF", currency: "USD" },
				{ yahoo: "BSDK.F", google: "FRA:BSDK", currency: "EUR" },
				{ yahoo: "BSD2.F", google: "FRA:BSD2", currency: "EUR" },
			],
			isins: ["ES0113900J37"],
			metadata: { founded: 1857, employees: 191189 },
			akas: [],
		},
		{
			name: "Enagás, S.A.",
			wiki_name: "Enagás, S.A.",
			symbol: "ENG",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: ["Energy industry"],
			symbols: [],
			isins: ["ES0130960018"],
			metadata: { founded: 1972, employees: 1439 },
			akas: [],
		},
		{
			name: "Enel S.p.A.",
			wiki_name: "Enel S.p.A.",
			symbol: "ENEL",
			country: "Italy",
			indices: ["EURO STOXX 50"],
			industries: ["Electric utility"],
			symbols: [
				{ yahoo: "ENLA.F", google: "FRA:ENLA", currency: "EUR" },
				{ yahoo: "ENL.F", google: "FRA:ENL", currency: "EUR" },
			],
			isins: ["IT0003128367"],
			metadata: { founded: 1962, employees: 66717 },
			akas: [],
		},
		{
			name: "Eni S.p.A.",
			wiki_name: "Eni S.p.A.",
			symbol: "ENI",
			country: "Italy",
			indices: ["EURO STOXX 50"],
			industries: ["Oil and gas"],
			symbols: [
				{ yahoo: "E", google: "NYSE:E", currency: "USD" },
				{ yahoo: "EIPAF", google: "OTCMKTS:EIPAF", currency: "USD" },
				{ yahoo: "ENI.F", google: "FRA:ENI", currency: "EUR" },
				{ yahoo: "ENI1.F", google: "FRA:ENI1", currency: "EUR" },
			],
			isins: ["IT0003132476"],
			metadata: { founded: 1953, employees: 31495 },
			akas: [],
		},
		{
			name: "Intesa Sanpaolo S.p.A.",
			wiki_name: "Intesa Sanpaolo S.p.A.",
			symbol: "ISP",
			country: "Italy",
			indices: ["EURO STOXX 50"],
			industries: ["Financial services"],
			symbols: [
				{ yahoo: "IES.F", google: "FRA:IES", currency: "EUR" },
				{ yahoo: "ISNPY", google: "OTCMKTS:ISNPY", currency: "USD" },
				{ yahoo: "0HBC.L", google: "LON:0HBC", currency: "GBP" },
				{ yahoo: "IESJ.F", google: "FRA:IESJ", currency: "EUR" },
			],
			isins: ["IT0000072618"],
			metadata: { founded: 1583, employees: 96892 },
			akas: [],
		},
		{
			name: "LVMH Moët Hennessy - Louis Vuitton, Société Européenne",
			wiki_name: "LVMH Moët Hennessy Louis Vuitton",
			symbol: "MC",
			country: "France",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: [
				"Luxury goods",
				"Cyclical Consumer Products",
				"Textiles & Apparel",
				"Apparel & Accessories",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "MOH.F", google: "FRA:MOH", currency: "EUR" },
				{ yahoo: "LVMHF", google: "OTCMKTS:LVMHF", currency: "USD" },
			],
			isins: ["FR0000121014"],
			metadata: { founded: 1987, employees: 163309 },
			akas: [],
		},
		{
			name: "Société Générale SA",
			wiki_name: "Société Générale S.A.",
			symbol: "GLE",
			country: "France",
			indices: ["CAC 40"],
			industries: [
				"Financial services",
				"Banking & Investment Services",
				"Banking Services",
				"Financials",
				"Banks",
			],
			symbols: [
				{ yahoo: "SGE.F", google: "FRA:SGE", currency: "EUR" },
				{ yahoo: "SCGLF", google: "OTCMKTS:SCGLF", currency: "USD" },
			],
			isins: ["FR0000130809"],
			metadata: { founded: 1864, employees: 147125 },
			akas: [],
		},
		{
			name: "Amadeus FiRe AG",
			wiki_name: "Amadeus FiRe AG",
			symbol: "AAD",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "AAD.F", google: "FRA:AAD", currency: "EUR" },
				{ yahoo: "0DK9.L", google: "LON:0DK9", currency: "GBP" },
			],
			isins: ["DE0005093108"],
			metadata: { founded: 2003, employees: 3502 },
			akas: [],
		},
		{
			name: "Abengoa S.A.",
			wiki_name: "Abengoa S.A.",
			symbol: "AGOAF",
			country: "Spain",
			indices: ["MDAX"],
			industries: [],
			symbols: [
				{ yahoo: "AGOAF", google: "OTCMKTS:AGOAF", currency: "USD" },
				{ yahoo: "AYO0.F", google: "FRA:AYO0", currency: "EUR" },
				{ yahoo: "ABGOF", google: "OTCMKTS:ABGOF", currency: "USD" },
				{ yahoo: "ABGOY", google: "OTCMKTS:ABGOY", currency: "USD" },
				{ yahoo: "AYO.F", google: "FRA:AYO", currency: "EUR" },
			],
			isins: ["ES0105200416"],
			metadata: { founded: 1941, employees: 23104 },
			akas: [],
		},
		{
			name: "Ceconomy AG",
			wiki_name: "Ceconomy AG",
			symbol: "CEC1",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Computer & Electronics Retailers",
				"Retailers",
				"Specialty Retailers",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "CEC1.F", google: "FRA:CEC1", currency: "EUR" },
				{ yahoo: "CEC.F", google: "FRA:CEC", currency: "EUR" },
				{ yahoo: "MEOD.F", google: "FRA:MEOD", currency: "EUR" },
				{ yahoo: "MTTRY", google: "OTCMKTS:MTTRY", currency: "USD" },
				{ yahoo: "MTAGF", google: "OTCMKTS:MTAGF", currency: "USD" },
			],
			isins: ["DE0007257503", "DE0007257537"],
			metadata: { founded: 1996, employees: 53954 },
			akas: [],
		},
		{
			name: "CropEnergies AG",
			wiki_name: "CropEnergies AG",
			symbol: "CE2",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "CE2.F", google: "FRA:CE2", currency: "EUR" },
				{ yahoo: "0DXG.L", google: "LON:0DXG", currency: "GBP" },
			],
			isins: ["DE000A0LAUP1"],
			metadata: { founded: 2006, employees: 450 },
			akas: [],
		},
		{
			name: "Dermapharm Holding SE",
			wiki_name: "Dermapharm Holding SE",
			symbol: "DMP",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "DMP.F", google: "FRA:DMP", currency: "EUR" },
				{ yahoo: "0A5J.L", google: "LON:0A5J", currency: "GBP" },
				{ yahoo: "DMPHF", google: "OTCMKTS:DMPHF", currency: "USD" },
			],
			isins: ["DE000A2GS5D8"],
			metadata: { founded: 1991, employees: 2311 },
			akas: [],
		},
		{
			name: "Flatexdegiro AG",
			wiki_name: "Flatexdegiro AG",
			symbol: "FTK",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "FTK.F", google: "FRA:FTK", currency: "EUR" },
				{ yahoo: "FNNTF", google: "OTCMKTS:FNNTF", currency: "USD" },
			],
			isins: ["DE000FTG1111"],
			metadata: { founded: 1999, employees: 971 },
			akas: [],
		},
		{
			name: "Hensoldt AG",
			wiki_name: "Hensoldt AG",
			symbol: "HAG",
			country: "Germany",
			indices: ["TECDAX"],
			industries: ["Defence"],
			symbols: [
				{ yahoo: "HAG.F", google: "FRA:HAG", currency: "EUR" },
				{ yahoo: "0A5S.L", google: "LON:0A5S", currency: "GBP" },
				{ yahoo: "HNSDF", google: "OTCMKTS:HNSDF", currency: "USD" },
				{ yahoo: "HAGHY", google: "OTCMKTS:HAGHY", currency: "USD" },
				{ yahoo: "HAG0.F", google: "FRA:HAG0", currency: "EUR" },
			],
			isins: ["DE000HAG0005"],
			metadata: { founded: 2017, employees: 5600 },
			akas: [],
		},
		{
			name: "Instone Real Estate Group AG",
			wiki_name: "Instone Real Estate Group AG",
			symbol: "INS",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "INS.F", google: "FRA:INS", currency: "EUR" },
				{ yahoo: "0ZQ7.L", google: "LON:0ZQ7", currency: "GBP" },
			],
			isins: ["DE000A2NBX80"],
			metadata: { founded: 2017, employees: 375 },
			akas: [],
		},
		{
			name: "STRATEC SE",
			wiki_name: "STRATEC SE",
			symbol: "SBS",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "SBS.F", google: "FRA:SBS", currency: "EUR" },
				{ yahoo: "0RAR.L", google: "LON:0RAR", currency: "GBP" },
			],
			isins: ["DE000STRA555"],
			metadata: { founded: 1979, employees: 1302 },
			akas: [],
		},
		{
			name: "Traton Group",
			wiki_name: "Traton Group",
			symbol: "8TRA",
			country: "Germany",
			indices: ["SDAX"],
			industries: ["Automotive"],
			symbols: [{ yahoo: "8TRA.F", google: "FRA:8TRA", currency: "EUR" }],
			isins: ["DE000TRAT0N7"],
			metadata: { founded: 2018, employees: 82600 },
			akas: [],
		},
		{
			name: "VERBIO Vereinigte BioEnergie AG",
			wiki_name: "VERBIO Vereinigte BioEnergie AG",
			symbol: "VBVBF",
			country: "Germany",
			indices: ["SDAX", "TECDAX"],
			industries: ["Biofuel"],
			symbols: [
				{ yahoo: "VBVBF", google: "OTCMKTS:VBVBF", currency: "USD" },
				{ yahoo: "VBK.F", google: "FRA:VBK", currency: "EUR" },
				{ yahoo: "0NLY.L", google: "LON:0NLY", currency: "GBP" },
			],
			isins: ["DE000A0JL9W6"],
			metadata: { founded: 2006, employees: 725 },
			akas: [],
		},
		{
			name: "ZEAL Network SE",
			wiki_name: "ZEAL Network SE",
			symbol: "TIMA",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "TIMA.F", google: "FRA:TIMA", currency: "EUR" },
				{ yahoo: "0QJQ.L", google: "LON:0QJQ", currency: "GBP" },
			],
			isins: ["DE000ZEAL241"],
			metadata: { founded: 1999, employees: 165 },
			akas: [],
		},
		{
			name: "MICEX-RTS",
			wiki_name: "MICEX-RTS",
			symbol: null,
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [],
			symbols: [],
			isins: ["RU000A0JR4A1"],
			metadata: { founded: 2011, employees: "" },
			akas: [],
		},
		{
			name: "Bank of Moscow",
			wiki_name: "Bank of Moscow",
			symbol: null,
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [],
			symbols: [],
			isins: ["RU0006571916"],
			metadata: { founded: 1995, employees: 10504 },
			akas: [],
		},
		{
			name: "Polyus Gold",
			wiki_name: "Polyus Gold",
			symbol: "PLZL",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Gold mining"],
			symbols: [],
			isins: ["US73181P1021"],
			metadata: { founded: 1980, employees: "" },
			akas: [],
		},
		{
			name: "JSC VTB Bank",
			wiki_name: "JSC VTB Bank",
			symbol: "VTBR",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Banking"],
			symbols: [],
			isins: [],
			metadata: { founded: 1990, employees: "" },
			akas: [],
		},
		{
			name: "Atlassian Corporation Plc",
			wiki_name: "Atlassian Corporation Plc",
			symbol: "TEAM",
			country: "Australia",
			indices: ["NASDAQ 100"],
			industries: ["Software"],
			symbols: [
				{ yahoo: "TEAM", google: "NASDAQ:TEAM", currency: "USD" },
				{ yahoo: "59A.F", google: "FRA:59A", currency: "EUR" },
			],
			isins: ["GB00BZ09BD16"],
			metadata: { founded: 2002, employees: 6117 },
			akas: [],
		},
		{
			name: "DocuSign, Inc.",
			wiki_name: "DocuSign, Inc.",
			symbol: "DOCU",
			country: "United States",
			indices: ["NASDAQ 100"],
			industries: [],
			symbols: [
				{ yahoo: "DOCU", google: "NASDAQ:DOCU", currency: "USD" },
				{ yahoo: "DS3.F", google: "FRA:DS3", currency: "EUR" },
				{ yahoo: "0XNH.L", google: "LON:0XNH", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 2003, employees: 6200 },
			akas: [],
		},
		{
			name: "Keurig Dr Pepper Inc.",
			wiki_name: "Keurig Dr Pepper Inc.",
			symbol: "KDP",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: ["Beverage"],
			symbols: [
				{ yahoo: "DP5.F", google: "FRA:DP5", currency: "EUR" },
				{ yahoo: "KDP", google: "NASDAQ:KDP", currency: "USD" },
				{ yahoo: "0Z62.L", google: "LON:0Z62", currency: "GBP" },
			],
			isins: ["US49271VAP58"],
			metadata: { founded: 1981, employees: 25500 },
			akas: [],
		},
		{
			name: "lululemon athletica inc.",
			wiki_name: "lululemon athletica inc.",
			symbol: "LULU",
			country: "Canada",
			indices: ["NASDAQ 100"],
			industries: ["Retail"],
			symbols: [
				{ yahoo: "LULU", google: "NASDAQ:LULU", currency: "USD" },
				{ yahoo: "33L.F", google: "FRA:33L", currency: "EUR" },
				{ yahoo: "0JVT.L", google: "LON:0JVT", currency: "GBP" },
			],
			isins: ["US5500211090"],
			metadata: { founded: 1998, employees: 19000 },
			akas: [],
		},
		{
			name: "Marvell Technology Group Ltd.",
			wiki_name: "Marvell Technology Group Ltd.",
			symbol: "MRVL",
			country: "United States",
			indices: ["NASDAQ 100"],
			industries: ["Semiconductor"],
			symbols: [
				{ yahoo: "MVL.F", google: "FRA:MVL", currency: "EUR" },
				{ yahoo: "MRVL", google: "NASDAQ:MRVL", currency: "USD" },
				{ yahoo: "0UBI.L", google: "LON:0UBI", currency: "GBP" },
				{ yahoo: "9MW.F", google: "FRA:9MW", currency: "EUR" },
			],
			isins: ["US5738741041"],
			metadata: { founded: 1995, employees: 5200 },
			akas: [],
		},
		{
			name: "Match Group, Inc.",
			wiki_name: "Match Group, Inc.",
			symbol: "MTCH",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: ["Online dating service"],
			symbols: [
				{ yahoo: "MTCH", google: "NASDAQ:MTCH", currency: "USD" },
				{ yahoo: "4MGN.F", google: "FRA:4MGN", currency: "EUR" },
				{ yahoo: "0JZ7.L", google: "LON:0JZ7", currency: "GBP" },
			],
			isins: ["US57667L1070"],
			metadata: { founded: "", employees: 1700 },
			akas: [],
		},
		{
			name: "Mercado Libre, Inc.",
			wiki_name: "Mercado Libre, Inc.",
			symbol: "MELI",
			country: "Argentina",
			indices: ["NASDAQ 100"],
			industries: [],
			symbols: [{ yahoo: "MELI", google: "NASDAQ:MELI", currency: "USD" }],
			isins: [],
			metadata: { founded: 1999, employees: 15500 },
			akas: [],
		},
		{
			name: "Moderna, Inc.",
			wiki_name: "Moderna, Inc.",
			symbol: "MRNA",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 500"],
			industries: ["Biotechnology"],
			symbols: [
				{ yahoo: "MRNA", google: "NASDAQ:MRNA", currency: "USD" },
				{ yahoo: "0QF.F", google: "FRA:0QF", currency: "EUR" },
			],
			isins: ["US60770K1079"],
			metadata: { founded: 2010, employees: 1500 },
			akas: [],
		},
		{
			name: "Okta, Inc.",
			wiki_name: "Okta, Inc.",
			symbol: "OKTA",
			country: "United States",
			indices: ["NASDAQ 100"],
			industries: ["Software"],
			symbols: [
				{ yahoo: "0KB7.L", google: "LON:0KB7", currency: "GBP" },
				{ yahoo: "0OK.F", google: "FRA:0OK", currency: "EUR" },
				{ yahoo: "OKTA", google: "NASDAQ:OKTA", currency: "USD" },
			],
			isins: [],
			metadata: { founded: 2009, employees: 2379 },
			akas: [],
		},
		{
			name: "Pinduoduo Inc.",
			wiki_name: "Pinduoduo Inc.",
			symbol: "PDD",
			country: "China",
			indices: ["NASDAQ 100"],
			industries: ["Internet"],
			symbols: [
				{ yahoo: "9PDA.F", google: "FRA:9PDA", currency: "EUR" },
				{ yahoo: "PDD", google: "NASDAQ:PDD", currency: "USD" },
			],
			isins: [],
			metadata: { founded: "", employees: "" },
			akas: [],
		},
		// {
		// 	name: "Seagen Inc.",
		// 	wiki_name: "Seagen Inc.",
		// 	symbol: "SGEN",
		// 	country: "United States",
		// 	indices: ["NASDAQ 100"],
		// 	industries: ["Biotechnology", "pharmaceutical"],
		// 	symbols: [
		// 		{ yahoo: "SGT.F", google: "FRA:SGT", currency: "EUR" },
		// 		{ yahoo: "SGEN", google: "NASDAQ:SGEN", currency: "USD" },
		// 		{ yahoo: "0A8R.L", google: "LON:0A8R", currency: "GBP" },
		// 	],
		// 	isins: [],
		// 	metadata: { founded: 1997, employees: 1605 },
		// 	akas: [],
		// },
		// {
		// 	name: "Splunk Inc.",
		// 	wiki_name: "Splunk Inc.",
		// 	symbol: "SPLK",
		// 	country: "United States",
		// 	indices: ["NASDAQ 100"],
		// 	industries: [],
		// 	symbols: [
		// 		{ yahoo: "S0U.F", google: "FRA:S0U", currency: "EUR" },
		// 		{ yahoo: "SPLK", google: "NASDAQ:SPLK", currency: "USD" },
		// 		{ yahoo: "0R09.L", google: "LON:0R09", currency: "GBP" },
		// 	],
		// 	isins: [],
		// 	metadata: { founded: 2003, employees: 5800 },
		// 	akas: [],
		// },
		{
			name: "Workday, Inc.",
			wiki_name: "Workday, Inc.",
			symbol: "WDAY",
			country: "United States",
			indices: ["NASDAQ 100"],
			industries: ["Cloud computing", "Software"],
			symbols: [
				{ yahoo: "WDAY", google: "NASDAQ:WDAY", currency: "USD" },
				{ yahoo: "0M18.L", google: "LON:0M18", currency: "GBP" },
				{ yahoo: "W7D.F", google: "FRA:W7D", currency: "EUR" },
			],
			isins: ["US98138H1014"],
			metadata: { founded: 2005, employees: 12500 },
			akas: [],
		},
		{
			name: "Zoom Video Communications, Inc.",
			wiki_name: "Zoom Video Communications, Inc.",
			symbol: "ZM",
			country: "United States",
			indices: ["NASDAQ 100"],
			industries: [],
			symbols: [
				{ yahoo: "5ZM.F", google: "FRA:5ZM", currency: "EUR" },
				{ yahoo: "ZM", google: "NASDAQ:ZM", currency: "USD" },
				{ yahoo: "0A1O.L", google: "LON:0A1O", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 2011, employees: 2532 },
			akas: [],
		},
		{
			name: "Caesars Entertainment, Inc.",
			wiki_name: "Caesars Entertainment, Inc.",
			symbol: "CZR",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Gaming", "hospitality"],
			symbols: [
				{ yahoo: "CZR", google: "NASDAQ:CZR", currency: "USD" },
				{ yahoo: "CZR.F", google: "FRA:CZR", currency: "EUR" },
				{ yahoo: "0A78.L", google: "LON:0A78", currency: "GBP" },
				{ yahoo: "2ER.F", google: "FRA:2ER", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1996, employees: 54000 },
			akas: [],
		},
		{
			name: "Charles River Laboratories International, Inc.",
			wiki_name: "Charles River Laboratories International, Inc.",
			symbol: "CRL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [
				"Pharmaceuticals",
				"Biotechnology",
				"Gene Therapy",
				"Cell Therapy",
				"Medical devices",
			],
			symbols: [
				{ yahoo: "CRL", google: "NYSE:CRL", currency: "USD" },
				{ yahoo: "RV6.F", google: "FRA:RV6", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1947, employees: 18400 },
			akas: [],
		},
		{
			name: "Enphase Energy, Inc.",
			wiki_name: "Enphase Energy, Inc.",
			symbol: "ENPH",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Renewable Energy", "Solar Energy", "High tech"],
			symbols: [
				{ yahoo: "ENPH", google: "NASDAQ:ENPH", currency: "USD" },
				{ yahoo: "0QYE.L", google: "LON:0QYE", currency: "GBP" },
				{ yahoo: "E0P.F", google: "FRA:E0P", currency: "EUR" },
			],
			isins: ["US29355A1079"],
			metadata: { founded: 2006, employees: 1000 },
			akas: [],
		},
		{
			name: "Generac Holdings Inc.",
			wiki_name: "Generac Holdings Inc.",
			symbol: "GNRC",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Manufacturing"],
			symbols: [
				{ yahoo: "GNRC", google: "NYSE:GNRC", currency: "USD" },
				{ yahoo: "G84.F", google: "FRA:G84", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1959, employees: 6500 },
			akas: [],
		},
		{
			name: "Monolithic Power Systems, Inc.",
			wiki_name: "Monolithic Power Systems, Inc.",
			symbol: "MPWR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "MPWR", google: "NASDAQ:MPWR", currency: "USD" },
				{ yahoo: "NQG.F", google: "FRA:NQG", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1997, employees: 2300 },
			akas: [],
		},
		{
			name: "PTC Inc.",
			wiki_name: "PTC Inc.",
			symbol: "PTC",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["PLM", "IOT"],
			symbols: [
				{ yahoo: "PTC", google: "NASDAQ:PTC", currency: "USD" },
				{ yahoo: "PMTA.F", google: "FRA:PMTA", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1985, employees: 6055 },
			akas: [],
		},
		{
			name: "Trimble Inc.",
			wiki_name: "Trimble Inc.",
			symbol: "TRMB",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["RFID"],
			symbols: [
				{ yahoo: "TRMB", google: "NASDAQ:TRMB", currency: "USD" },
				{ yahoo: "TB5.F", google: "FRA:TB5", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1978, employees: 11500 },
			akas: [],
		},
		{
			name: "Porsche Automobil Holding SE",
			wiki_name: "Porsche Automobil Holding SE",
			symbol: "PAH3",
			country: "Germany",
			indices: ["DAX"],
			industries: ["Holding company"],
			symbols: [
				{ yahoo: "PAH3.F", google: "FRA:PAH3", currency: "EUR" },
				{ yahoo: "PAHA.F", google: "FRA:PAHA", currency: "EUR" },
				{ yahoo: "POAHY", google: "OTCMKTS:POAHY", currency: "USD" },
			],
			isins: ["DE000PAH0038"],
			metadata: { founded: 2007, employees: 951 },
			akas: [],
		},
		{
			name: "Fluidra",
			wiki_name: "Fluidra",
			symbol: "FDR",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: ["wellness equipment"],
			symbols: [
				{ yahoo: "4F1.F", google: "FRA:4F1", currency: "EUR" },
				{ yahoo: "FLUIF", google: "OTCMKTS:FLUIF", currency: "USD" },
				{ yahoo: "FDR.MC", google: "BME:FDR", currency: "EUR" },
				{ yahoo: "FLDAY", google: "OTCMKTS:FLDAY", currency: "USD" },
				{ yahoo: "4F10.F", google: "FRA:4F10", currency: "EUR" },
			],
			isins: ["ES0137650018"],
			metadata: { founded: 1969, employees: 5500 },
			akas: [],
		},
		{
			name: "Mapfre, S.A.",
			wiki_name: "Mapfre, S.A.",
			symbol: "MAP",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: ["Insurance", "Financials", "Multiline Insurance & Brokers"],
			symbols: [
				{ yahoo: "CMAB.F", google: "FRA:CMAB", currency: "EUR" },
				{ yahoo: "MPFRF", google: "OTCMKTS:MPFRF", currency: "USD" },
				{ yahoo: "MPFRY", google: "OTCMKTS:MPFRY", currency: "USD" },
				{ yahoo: "MAP.MC", google: "BME:MAP", currency: "EUR" },
			],
			isins: ["ES0124244E34"],
			metadata: { founded: 1933, employees: 33730 },
			akas: [],
		},
		{
			name: "Pharma Mar S.A.",
			wiki_name: "Pharma Mar S.A.",
			symbol: "PHM",
			country: "Spain",
			indices: ["IBEX 35"],
			industries: ["Pharmaceutical"],
			symbols: [
				{ yahoo: "PHMMF", google: "OTCMKTS:PHMMF", currency: "USD" },
				{ yahoo: "PMRA.F", google: "FRA:PMRA", currency: "EUR" },
				{ yahoo: "PHM.MC", google: "BME:PHM", currency: "EUR" },
				{ yahoo: "0RC6.L", google: "LON:0RC6", currency: "GBP" },
			],
			isins: ["ES0169501022"],
			metadata: { founded: 1986, employees: 600 },
			akas: [],
		},
		{
			name: "AUTO1 Group SE",
			wiki_name: "AUTO1 Group SE",
			symbol: "AG1",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "AG1.F", google: "FRA:AG1", currency: "EUR" },
				{ yahoo: "0A9L.L", google: "LON:0A9L", currency: "GBP" },
				{ yahoo: "ATOGF", google: "OTCMKTS:ATOGF", currency: "USD" },
			],
			isins: ["DE000A2LQ884"],
			metadata: { founded: 2012, employees: 4418 },
			akas: [],
		},
		{
			name: "Holcim Ltd.",
			wiki_name: "Holcim Ltd.",
			symbol: "HOLN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: [
				"Building material",
				"Construction Materials",
				"Mineral Resources",
				"Basic Materials",
			],
			symbols: [
				{ yahoo: "HCMLF", google: "OTCMKTS:HCMLF", currency: "USD" },
				{ yahoo: "HLB.F", google: "FRA:HLB", currency: "EUR" },
				{ yahoo: "HLBB.F", google: "FRA:HLBB", currency: "EUR" },
				{ yahoo: "HCMLY", google: "OTCMKTS:HCMLY", currency: "USD" },
				{ yahoo: "0QKY.L", google: "LON:0QKY", currency: "GBP" },
			],
			isins: ["CH0012214059"],
			metadata: { founded: 1912, employees: 71000 },
			akas: [],
		},
		{
			name: "Stellantis N.V.",
			wiki_name: "Stellantis N.V.",
			symbol: "STLA",
			country: "Netherlands",
			indices: ["CAC 40", "EURO STOXX 50"],
			industries: ["Automotive"],
			symbols: [
				{ yahoo: "STLA", google: "NYSE:STLA", currency: "USD" },
				{ yahoo: "STLA.F", google: "FRA:STLA", currency: "EUR" },
				{
					yahoo: "NL00150001Q9.F",
					google: "FRA:NL00150001Q9",
					currency: "EUR",
				},
				{ yahoo: "8TI.F", google: "FRA:8TI", currency: "EUR" },
			],
			isins: ["NL00150001Q9"],
			metadata: { founded: 2021, employees: 300000 },
			akas: [],
		},
		{
			name: "Teleperformance",
			wiki_name: "Teleperformance",
			symbol: "TEP",
			country: "France",
			indices: ["CAC 40"],
			industries: ["Business process outsourcing"],
			symbols: [
				{ yahoo: "RCF.F", google: "FRA:RCF", currency: "EUR" },
				{ yahoo: "TLPFY", google: "OTCMKTS:TLPFY", currency: "USD" },
				{ yahoo: "RCF0.F", google: "FRA:RCF0", currency: "EUR" },
				{ yahoo: "TLPFF", google: "OTCMKTS:TLPFF", currency: "USD" },
			],
			isins: ["FR0000051807"],
			metadata: { founded: 1978, employees: 383000 },
			akas: [],
		},
		{
			name: "Elis S.A.",
			wiki_name: "Elis S.A.",
			symbol: "ELIS",
			country: "France",
			indices: [],
			industries: ["Textilservice"],
			symbols: [{ yahoo: "7EL.F", google: "FRA:7EL", currency: "EUR" }],
			isins: ["FR0012435121"],
			metadata: { founded: 1883, employees: 47779 },
			akas: [],
		},
		{
			name: "Orpea S.A.",
			wiki_name: "Orpea S.A.",
			symbol: "ORP",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["healthcare"],
			symbols: [
				{ yahoo: "OPA.F", google: "FRA:OPA", currency: "EUR" },
				{ yahoo: "ORRRY", google: "OTCMKTS:ORRRY", currency: "USD" },
				{ yahoo: "ORPEF", google: "OTCMKTS:ORPEF", currency: "USD" },
			],
			isins: ["FR0000184798"],
			metadata: { founded: 1989, employees: 49185 },
			akas: [],
		},
		{
			name: "Verallia Deutschland AG",
			wiki_name: "Verallia Deutschland AG",
			symbol: null,
			country: "Germany",
			indices: ["CAC Mid 60", "CDAX"],
			industries: ["glass container"],
			symbols: [{ yahoo: "OLG.F", google: "FRA:OLG", currency: "EUR" }],
			isins: ["DE0006851603"],
			metadata: { founded: 1946, employees: 3000 },
			akas: [],
		},
		{
			name: "Magnitogorski metallurgitscheski kombinat",
			wiki_name: "Magnitogorski metallurgitscheski kombinat",
			symbol: "MAGN",
			country: "Togo",
			indices: ["MOEX"],
			industries: [],
			symbols: [],
			isins: ["RU0009084396", "US5591892048"],
			metadata: { founded: 1932, employees: "" },
			akas: [],
		},
		{
			name: "Metso Outotec Oyj",
			wiki_name: "Metso Outotec Oyj",
			symbol: "MOCORP",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: [
				"Industrial machinery",
				"Industrials",
				"Construction & Engineering",
				"Industrial & Commercial Services",
			],
			symbols: [
				{ yahoo: "M6Q.F", google: "FRA:M6Q", currency: "EUR" },
				{ yahoo: "OUKPY", google: "OTCMKTS:OUKPY", currency: "USD" },
				{ yahoo: "OUKPF", google: "OTCMKTS:OUKPF", currency: "USD" },
				{ yahoo: "M6QB.F", google: "FRA:M6QB", currency: "EUR" },
			],
			isins: ["FI0009014575"],
			metadata: { founded: 2020, employees: 15600 },
			akas: [],
		},
		{
			name: "ASM International N.V.",
			wiki_name: "ASM International N.V.",
			symbol: "ASM",
			country: "Netherlands",
			indices: ["AEX"],
			industries: ["Semiconductor industry"],
			symbols: [
				{ yahoo: "AVSN.F", google: "FRA:AVSN", currency: "EUR" },
				{ yahoo: "AVS.F", google: "FRA:AVS", currency: "EUR" },
				{ yahoo: "ASM.AS", google: "AMS:ASM", currency: "EUR" },
			],
			isins: ["NL0000334118"],
			metadata: { founded: 1968, employees: 2337 },
			akas: [],
		},
		{
			name: "Organon & Co.",
			wiki_name: "Organon & Co.",
			symbol: "OGN",
			country: "United Kingdom",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "OGN", google: "NYSE:OGN", currency: "USD" },
				{ yahoo: "7XP.F", google: "FRA:7XP", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 2020, employees: 9000 },
			akas: [],
		},
		{
			name: "Metropole Télévision SA",
			wiki_name: "Metropole Télévision SA",
			symbol: "XPAR",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Media"],
			symbols: [{ yahoo: "MMT.F", google: "FRA:MMT", currency: "EUR" }],
			isins: [],
			metadata: { founded: 1987, employees: "" },
			akas: [],
		},
		{
			name: "ATOSS Software AG",
			wiki_name: "ATOSS Software AG",
			symbol: "AOF",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "AOF.F", google: "FRA:AOF", currency: "EUR" },
				{ yahoo: "0N66.L", google: "LON:0N66", currency: "GBP" },
			],
			isins: ["DE0005104400"],
			metadata: { founded: 1987, employees: 560 },
			akas: [],
		},
		{
			name: "Nagarro SE",
			wiki_name: "Nagarro SE",
			symbol: "NA9",
			country: "Germany",
			indices: ["SDAX", "TECDAX"],
			industries: [],
			symbols: [
				{ yahoo: "NA9.F", google: "FRA:NA9", currency: "EUR" },
				{ yahoo: "NGRRF", google: "OTCMKTS:NGRRF", currency: "USD" },
				{ yahoo: "0AA3.L", google: "LON:0AA3", currency: "GBP" },
			],
			isins: ["DE000A3H2200"],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Vantage Towers AG",
			wiki_name: "Vantage Towers AG",
			symbol: "VTWR",
			country: "Germany",
			indices: ["MDAX", "TECDAX"],
			industries: [],
			symbols: [
				{ yahoo: "VTWR.F", google: "FRA:VTWR", currency: "EUR" },
				{ yahoo: "VTAGY", google: "OTCMKTS:VTAGY", currency: "USD" },
				{ yahoo: "VTWRF", google: "OTCMKTS:VTWRF", currency: "USD" },
				{ yahoo: "0A9Y.L", google: "LON:0A9Y", currency: "GBP" },
				{ yahoo: "VTW.F", google: "FRA:VTW", currency: "EUR" },
			],
			isins: ["DE000A3H3LL2"],
			metadata: { founded: 2020, employees: "" },
			akas: [],
		},
		{
			name: "Volvo Car Corporation",
			wiki_name: "Volvo Car Corporation",
			symbol: null,
			country: "Sweden",
			indices: ["OMX Stockholm 30"],
			industries: [],
			symbols: [],
			isins: ["SE0016844831"],
			metadata: { founded: 1927, employees: 40131 },
			akas: [],
		},
		{
			name: "Bath & Body Works, Inc.",
			wiki_name: "Bath & Body Works, Inc.",
			symbol: "BBWI",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Retailing"],
			symbols: [
				{ yahoo: "BBWI", google: "NYSE:BBWI", currency: "USD" },
				{ yahoo: "0JSC.L", google: "LON:0JSC", currency: "GBP" },
				{ yahoo: "LTD0.F", google: "FRA:LTD0", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1963, employees: 59950 },
			akas: [],
		},
		{
			name: "BE Semiconductor Industries N.V.",
			wiki_name: "BE Semiconductor Industries N.V.",
			symbol: "BESI",
			country: "Netherlands",
			indices: ["AEX"],
			industries: [],
			symbols: [
				{ yahoo: "BESI.AS", google: "AMS:BESI", currency: "EUR" },
				{ yahoo: "BSI.F", google: "FRA:BSI", currency: "EUR" },
				{ yahoo: "BESIY", google: "OTCMKTS:BESIY", currency: "USD" },
				{ yahoo: "BSIA.F", google: "FRA:BSIA", currency: "EUR" },
				{ yahoo: "BESVF", google: "OTCMKTS:BESVF", currency: "USD" },
			],
			isins: ["NL0012866412"],
			metadata: { founded: 1995, employees: 2040 },
			akas: [],
		},
		{
			name: "Signify N.V.",
			wiki_name: "Signify N.V.",
			symbol: "LIGHT",
			country: "Netherlands",
			indices: ["AEX"],
			industries: ["Lighting"],
			symbols: [
				{ yahoo: "LIGHT.AS", google: "AMS:LIGHT", currency: "EUR" },
				{ yahoo: "SFFYF", google: "OTCMKTS:SFFYF", currency: "USD" },
				{ yahoo: "G14.F", google: "FRA:G14", currency: "EUR" },
				{ yahoo: "PHPPY", google: "OTCMKTS:PHPPY", currency: "USD" },
			],
			isins: ["NL0011821392"],
			metadata: { founded: 2016, employees: 32005 },
			akas: [],
		},
		{
			name: "McPhy Energy",
			wiki_name: "McPhy Energy",
			symbol: "MCPHY",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["hydrogen production", "distribution equipment"],
			symbols: [
				{ yahoo: "M6P.F", google: "FRA:M6P", currency: "EUR" },
				{ yahoo: "0QTF.L", google: "LON:0QTF", currency: "GBP" },
				{ yahoo: "MPHYF", google: "OTCMKTS:MPHYF", currency: "USD" },
			],
			isins: ["FR86502205917"],
			metadata: { founded: 2008, employees: 110 },
			akas: [],
		},
		{
			name: "Neoen",
			wiki_name: "Neoen",
			symbol: "NEOEN",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["renewable energy"],
			symbols: [{ yahoo: "N1N.F", google: "FRA:N1N", currency: "EUR" }],
			isins: ["FR0011675362"],
			metadata: { founded: 2008, employees: 130 },
			akas: [],
		},
		{
			name: "SCOR SE",
			wiki_name: "SCOR SE",
			symbol: "SCR",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Financial services"],
			symbols: [
				{ yahoo: "SDRC.F", google: "FRA:SDRC", currency: "EUR" },
				{ yahoo: "SCRYY", google: "OTCMKTS:SCRYY", currency: "USD" },
				{ yahoo: "SDRB.F", google: "FRA:SDRB", currency: "EUR" },
				{ yahoo: "0IT3.L", google: "LON:0IT3", currency: "GBP" },
			],
			isins: ["FR0010411983"],
			metadata: { founded: 1970, employees: 3028 },
			akas: [],
		},
		{
			name: "Solutions 30",
			wiki_name: "Solutions 30",
			symbol: null,
			country: "Luxembourg",
			indices: ["CAC Mid 60"],
			industries: ["it services"],
			symbols: [
				{ yahoo: "30L3.F", google: "FRA:30L3", currency: "EUR" },
				{ yahoo: "SLUNF", google: "OTCMKTS:SLUNF", currency: "USD" },
			],
			isins: ["FR0013379484"],
			metadata: { founded: 2003, employees: 5061 },
			akas: [],
		},
		{
			name: "Technip Energies N.V.",
			wiki_name: "Technip Energies N.V.",
			symbol: "TE",
			country: "Netherlands",
			indices: ["CAC Mid 60"],
			industries: ["plant engineering"],
			symbols: [
				{ yahoo: "68F.F", google: "FRA:68F", currency: "EUR" },
				{ yahoo: "0A8A.L", google: "LON:0A8A", currency: "GBP" },
				{ yahoo: "THNPY", google: "OTCMKTS:THNPY", currency: "USD" },
				{ yahoo: "THNPF", google: "OTCMKTS:THNPF", currency: "USD" },
				{ yahoo: "68F0.F", google: "FRA:68F0", currency: "EUR" },
			],
			isins: ["NL0014559478"],
			metadata: { founded: 2021, employees: 15000 },
			akas: [],
		},
		{
			name: "Valneva SE",
			wiki_name: "Valneva SE",
			symbol: "VALN",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Biotechnology"],
			symbols: [
				{ yahoo: "VALN", google: "NASDAQ:VALN", currency: "USD" },
				{ yahoo: "AYJ.F", google: "FRA:AYJ", currency: "EUR" },
				{ yahoo: "INRLF", google: "OTCMKTS:INRLF", currency: "USD" },
				{ yahoo: "0OB3.L", google: "LON:0OB3", currency: "GBP" },
				{ yahoo: "AYJ0.F", google: "FRA:AYJ0", currency: "EUR" },
			],
			isins: ["FR0004056851"],
			metadata: { founded: 2013, employees: 500 },
			akas: [],
		},
		{
			name: "Logitech International S.A.",
			wiki_name: "Logitech International S.A.",
			symbol: "LOGN",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: ["Technology"],
			symbols: [
				{ yahoo: "LOGI", google: "NASDAQ:LOGI", currency: "USD" },
				{ yahoo: "0QK6.L", google: "LON:0QK6", currency: "GBP" },
			],
			isins: ["CH0025751329"],
			metadata: { founded: 1981, employees: 7000 },
			akas: [],
		},
		{
			name: "CrowdStrike Holdings, Inc.",
			wiki_name: "CrowdStrike Holdings, Inc.",
			symbol: "CRWD",
			country: "United States",
			indices: ["NASDAQ 100"],
			industries: ["Information security"],
			symbols: [
				{ yahoo: "CRWD", google: "NASDAQ:CRWD", currency: "USD" },
				{ yahoo: "45C.F", google: "FRA:45C", currency: "EUR" },
				{ yahoo: "0A3N.L", google: "LON:0A3N", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 2011, employees: 3163 },
			akas: [],
		},
		{
			name: "Bio-Techne Corporation",
			wiki_name: "Bio-Techne Corporation",
			symbol: "TECH",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Biotechnology"],
			symbols: [
				{ yahoo: "TECH", google: "NASDAQ:TECH", currency: "USD" },
				{ yahoo: "TE1.F", google: "FRA:TE1", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1976, employees: 2300 },
			akas: [],
		},
		{
			name: "Brown & Brown, Inc.",
			wiki_name: "Brown & Brown, Inc.",
			symbol: "BRO",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "BRO", google: "NYSE:BRO", currency: "USD" },
				{ yahoo: "BTW.F", google: "FRA:BTW", currency: "EUR" },
			],
			isins: ["US1152361010"],
			metadata: { founded: 1939, employees: 11136 },
			akas: [],
		},
		{
			name: "Ceridian HCM Holding Inc.",
			wiki_name: "Ceridian HCM Holding Inc.",
			symbol: "CDAY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "CDAY", google: "NYSE:CDAY", currency: "USD" },
				{ yahoo: "CQL.F", google: "FRA:CQL", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1992, employees: 5974 },
			akas: [],
		},
		{
			name: "Ingersoll Rand Inc.",
			wiki_name: "Ingersoll Rand Inc.",
			symbol: "IR",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "IR", google: "NYSE:IR", currency: "USD" },
				{ yahoo: "5GD.F", google: "FRA:5GD", currency: "EUR" },
			],
			isins: ["US45687V1061"],
			metadata: { founded: 1859, employees: 15900 },
			akas: [],
		},
		{
			name: "Elia",
			wiki_name: "Elia",
			symbol: "XBRU",
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [],
			symbols: [],
			isins: ["BE0003822393"],
			metadata: { founded: 2001, employees: 1229 },
			akas: [],
		},
		{
			name: "Melexis",
			wiki_name: "Melexis",
			symbol: null,
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [],
			symbols: [
				{ yahoo: "MEX.F", google: "FRA:MEX", currency: "EUR" },
				{ yahoo: "MLXSF", google: "OTCMKTS:MLXSF", currency: "USD" },
			],
			isins: ["BE0165385973"],
			metadata: { founded: 1988, employees: 1200 },
			akas: [],
		},
		{
			name: "Universal Music Group N.V.",
			wiki_name: "Universal Music Group N.V.",
			symbol: "UMG",
			country: "United States",
			indices: ["AEX", "EURO STOXX 50"],
			industries: ["Music"],
			symbols: [
				{ yahoo: "UMGNF", google: "OTCMKTS:UMGNF", currency: "USD" },
				{ yahoo: "UMG.AS", google: "AMS:UMG", currency: "EUR" },
				{ yahoo: "0UMG.L", google: "LON:0UMG", currency: "GBP" },
				{ yahoo: "0VD.F", google: "FRA:0VD", currency: "EUR" },
				{ yahoo: "0VD0.F", google: "FRA:0VD0", currency: "EUR" },
				{ yahoo: "UNVGY", google: "OTCMKTS:UNVGY", currency: "USD" },
			],
			isins: ["NL0015000IY2"],
			metadata: { founded: 1934, employees: 8319 },
			akas: [],
		},
		{
			name: "ABOUT YOU AG & Co. KG",
			wiki_name: "ABOUT YOU AG & Co. KG",
			symbol: null,
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [],
			isins: ["DE000A3CNK42"],
			metadata: { founded: 2014, employees: 1000 },
			akas: [],
		},
		{
			name: "Basler AG",
			wiki_name: "Basler AG",
			symbol: "BSL",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "BSL.F", google: "FRA:BSL", currency: "EUR" },
				{ yahoo: "0DUI.L", google: "LON:0DUI", currency: "GBP" },
			],
			isins: ["DE0005102008"],
			metadata: { founded: 1988, employees: 857 },
			akas: [],
		},
		{
			name: "PVA TePla AG",
			wiki_name: "PVA TePla AG",
			symbol: "TPE",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "TPE.F", google: "FRA:TPE", currency: "EUR" },
				{ yahoo: "TPLKF", google: "OTCMKTS:TPLKF", currency: "USD" },
				{ yahoo: "0NL1.L", google: "LON:0NL1", currency: "GBP" },
			],
			isins: ["DE0007461006"],
			metadata: { founded: 2002, employees: 553 },
			akas: [],
		},
		{
			name: "secunet Security Networks AG",
			wiki_name: "secunet Security Networks AG",
			symbol: "0NWC",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "0NWC.L", google: "LON:0NWC", currency: "GBP" },
				{ yahoo: "YSN.F", google: "FRA:YSN", currency: "EUR" },
			],
			isins: ["DE0007276503"],
			metadata: { founded: 1997, employees: 740 },
			akas: [],
		},
		{
			name: "STO SE & Co. KGaA",
			wiki_name: "STO SE & Co. KGaA",
			symbol: "STO3",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "STO3.F", google: "FRA:STO3", currency: "EUR" },
				{ yahoo: "0G5B.L", google: "LON:0G5B", currency: "GBP" },
			],
			isins: ["DE0007274136"],
			metadata: { founded: 1955, employees: 5333 },
			akas: [],
		},
		{
			name: "SUSE S.A.",
			wiki_name: "SUSE S.A.",
			symbol: "SUSE",
			country: "Germany",
			indices: ["SDAX", "TECDAX"],
			industries: ["Computer software"],
			symbols: [{ yahoo: "SUSE.F", google: "FRA:SUSE", currency: "EUR" }],
			isins: ["LU2333210958"],
			metadata: { founded: 1992, employees: 1750 },
			akas: [],
		},
		{
			name: "SYNLAB",
			wiki_name: "SYNLAB",
			symbol: "SYAB",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "SYAB.F", google: "FRA:SYAB", currency: "EUR" },
				{ yahoo: "0A9B.L", google: "LON:0A9B", currency: "GBP" },
			],
			isins: ["DE000A2TSL71"],
			metadata: { founded: 1998, employees: 20000 },
			akas: [],
		},
		{
			name: "Coterra Energy Inc.",
			wiki_name: "Coterra Energy Inc.",
			symbol: "CTRA",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Natural gas", "Oil"],
			symbols: [
				{ yahoo: "CTRA", google: "NYSE:CTRA", currency: "USD" },
				{ yahoo: "XCQ.F", google: "FRA:XCQ", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1989, employees: 503 },
			akas: [],
		},
		{
			name: "Globe Life Field",
			wiki_name: "Globe Life Field",
			symbol: "GL",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [],
			isins: [],
			metadata: { founded: "", employees: "" },
			akas: [],
		},
		{
			name: "Norfolk Southern Railway",
			wiki_name: "Norfolk Southern Railway",
			symbol: "NSC",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Solar power in Spain",
			wiki_name: "Solar power in Spain",
			symbol: "SLR",
			country: "Finland",
			indices: ["IBEX 35"],
			industries: [],
			symbols: [],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Darktrace plc",
			wiki_name: "Darktrace plc",
			symbol: "DARK",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [],
			symbols: [
				{ yahoo: "7TJ.F", google: "FRA:7TJ", currency: "EUR" },
				{ yahoo: "DRKTF", google: "OTCMKTS:DRKTF", currency: "USD" },
				{ yahoo: "DARK.L", google: "LON:DARK", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 2013, employees: 1200 },
			akas: [],
		},
		{
			name: "Hypoport SE",
			wiki_name: "Hypoport SE",
			symbol: null,
			country: "Germany",
			indices: ["MDAX"],
			industries: [],
			symbols: [
				{ yahoo: "HYQ.F", google: "FRA:HYQ", currency: "EUR" },
				{ yahoo: "HYPOF", google: "OTCMKTS:HYPOF", currency: "USD" },
			],
			isins: ["DE0005493365"],
			metadata: { founded: 1999, employees: 2131 },
			akas: [],
		},
		{
			name: "Laboratorios Rovi",
			wiki_name: "Laboratorios Rovi",
			symbol: "ROVI",
			country: "Finland",
			indices: ["IBEX 35"],
			industries: [],
			symbols: [],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Daimler Truck Holding AG",
			wiki_name: "Daimler Truck Holding AG",
			symbol: "DTG",
			country: "Germany",
			indices: ["DAX"],
			industries: ["Automotive"],
			symbols: [
				{ yahoo: "DTG.F", google: "FRA:DTG", currency: "EUR" },
				{ yahoo: "DTRUY", google: "OTCMKTS:DTRUY", currency: "USD" },
				{ yahoo: "DTG1.F", google: "FRA:DTG1", currency: "EUR" },
			],
			isins: ["DE000DTR0CK8"],
			metadata: { founded: 2021, employees: 99640 },
			akas: [],
		},
		{
			name: "Mercedes-Benz Group AG",
			wiki_name: "Mercedes-Benz Group AG",
			akas: ["Mercedes-Benz Group"],
			symbol: "MBG",
			country: "Germany",
			indices: ["DAX", "EURO STOXX 50"],
			industries: ["Automotive"],
			symbols: [
				{ yahoo: "MBG.F", google: "FRA:MBG", currency: "EUR" },
				{ yahoo: "MBGAF", google: "OTCMKTS:MBGAF", currency: "USD" },
				{ yahoo: "DAII.F", google: "FRA:DAII", currency: "EUR" },
			],
			isins: ["DE0007100000"],
			metadata: { founded: 1998, employees: 172000 },
		},
		{
			name: "Airbnb, Inc.",
			wiki_name: "Airbnb, Inc.",
			symbol: "ABNB",
			country: "United States",
			indices: ["NASDAQ 100"],
			industries: ["Lodging"],
			symbols: [
				{ yahoo: "ABNB", google: "NASDAQ:ABNB", currency: "USD" },
				{ yahoo: "6Z1.F", google: "FRA:6Z1", currency: "EUR" },
				{ yahoo: "0A8C.L", google: "LON:0A8C", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 2008, employees: 6132 },
			akas: [],
		},
		{
			name: "Datadog, Inc.",
			wiki_name: "Datadog, Inc.",
			symbol: "DDOG",
			country: "United States",
			indices: ["NASDAQ 100"],
			industries: ["System Monitoring"],
			symbols: [
				{ yahoo: "DDOG", google: "NASDAQ:DDOG", currency: "USD" },
				{ yahoo: "0A3O.L", google: "LON:0A3O", currency: "GBP" },
				{ yahoo: "3QD.F", google: "FRA:3QD", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 2010, employees: 3200 },
			akas: [],
		},
		{
			name: "Palo Alto Networks, Inc.",
			wiki_name: "Palo Alto Networks, Inc.",
			symbol: "PANW",
			country: "United States",
			indices: ["NASDAQ 100"],
			industries: ["Cybersecurity"],
			symbols: [
				{ yahoo: "PANW", google: "NASDAQ:PANW", currency: "USD" },
				{ yahoo: "5AP.F", google: "FRA:5AP", currency: "EUR" },
				{ yahoo: "0KF5.L", google: "LON:0KF5", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 2005, employees: 11098 },
			akas: [],
		},
		{
			name: "Zscaler, Inc.",
			wiki_name: "Zscaler, Inc.",
			symbol: "ZS",
			country: "United States",
			indices: ["NASDAQ 100"],
			industries: [
				"Network security",
				"computer security",
				"Internet security",
			],
			symbols: [
				{ yahoo: "ZS", google: "NASDAQ:ZS", currency: "USD" },
				{ yahoo: "0ZC.F", google: "FRA:0ZC", currency: "EUR" },
				{ yahoo: "0XVU.L", google: "LON:0XVU", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 2007, employees: 4000 },
			akas: [],
		},
		{
			name: "En+ Group plc",
			wiki_name: "En+ Group plc",
			symbol: "ENPG",
			country: "United Kingdom",
			indices: ["MOEX"],
			industries: [],
			symbols: [],
			isins: [],
			metadata: { founded: 2002, employees: 100000 },
			akas: [],
		},
		{
			name: "Fix Price Group",
			wiki_name: "Fix Price Group",
			symbol: "FIXP",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [],
			symbols: [{ yahoo: "FIXP.ME", google: "MCX:FIXP", currency: "RUB" }],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Globaltrans",
			wiki_name: "Globaltrans",
			symbol: null,
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [],
			symbols: [],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "HeadHunter Group",
			wiki_name: "HeadHunter Group",
			symbol: "HHRU",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [],
			symbols: [
				{ yahoo: "HHRU.ME", google: "MCX:HHRU", currency: "RUB" },
				{ yahoo: "HHR", google: "NASDAQ:HHR", currency: "USD" },
			],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Nornickel",
			wiki_name: "Nornickel",
			symbol: "GMKN",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Mining", "Metals"],
			symbols: [],
			isins: ["RU0007288411"],
			metadata: { founded: 1935, employees: 72 },
			akas: [],
		},
		{
			name: "Ozon",
			wiki_name: "Ozon",
			symbol: "OZON",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["technology"],
			symbols: [{ yahoo: "OZON", google: "NASDAQ:OZON", currency: "USD" }],
			isins: ["US69269L1044"],
			metadata: { founded: 1998, employees: 12000 },
			akas: [],
		},
		{
			name: "Petropavlovsk PLC",
			wiki_name: "Petropavlovsk PLC",
			symbol: "POGR",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: ["Gold mining"],
			symbols: [
				{ yahoo: "POGR.ME", google: "MCX:POGR", currency: "RUB" },
				{ yahoo: "PHZ.F", google: "FRA:PHZ", currency: "EUR" },
				{ yahoo: "POG.L", google: "LON:POG", currency: "GBP" },
			],
			isins: ["GB0031544546"],
			metadata: { founded: 1994, employees: 8499 },
			akas: [],
		},
		{
			name: "Tinkoff Bank",
			wiki_name: "Tinkoff Bank",
			symbol: "TCS",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [],
			symbols: [],
			isins: ["US87238U2033"],
			metadata: { founded: 2006, employees: 25970 },
			akas: [],
		},
		{
			name: "Mail.Ru Group",
			wiki_name: "Mail.Ru Group",
			symbol: "VKCO",
			country: "Russian Federation",
			indices: ["MOEX"],
			industries: [],
			symbols: [],
			isins: [],
			metadata: { founded: 2005, employees: 3122 },
			akas: [],
		},
		{
			name: "Camden Property Trust",
			wiki_name: "Camden Property Trust",
			symbol: "CPT",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Real estate investment trust"],
			symbols: [
				{ yahoo: "CPT", google: "NYSE:CPT", currency: "USD" },
				{ yahoo: "CAL.F", google: "FRA:CAL", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1981, employees: 1700 },
			akas: [],
		},
		{
			name: "EPAM Systems, Inc.",
			wiki_name: "EPAM Systems, Inc.",
			symbol: "EPAM",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Software engineering"],
			symbols: [
				{ yahoo: "EPAM", google: "NYSE:EPAM", currency: "USD" },
				{ yahoo: "E3M.F", google: "FRA:E3M", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1993, employees: 58824 },
			akas: [],
		},
		{
			name: "FactSet Research Systems Inc.",
			wiki_name: "FactSet Research Systems Inc.",
			symbol: "FDS",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Financial services", "Technology"],
			symbols: [
				{ yahoo: "FDS", google: "NYSE:FDS", currency: "USD" },
				{ yahoo: "FA1.F", google: "FRA:FA1", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1978, employees: 10892 },
			akas: [],
		},
		{
			name: "Molina Healthcare, Inc.",
			wiki_name: "Molina Healthcare, Inc.",
			symbol: "MOH",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Healthcare"],
			symbols: [{ yahoo: "MOH", google: "NYSE:MOH", currency: "USD" }],
			isins: [],
			metadata: { founded: 1980, employees: 2021 },
			akas: [],
		},
		{
			name: "Nordson Corporation",
			wiki_name: "Nordson Corporation",
			symbol: "NDSN",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "NDSN", google: "NASDAQ:NDSN", currency: "USD" },
				{ yahoo: "ND1.F", google: "FRA:ND1", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1935, employees: 6813 },
			akas: [],
		},
		{
			name: "Paramount Global",
			wiki_name: "Paramount Global",
			symbol: "PARA",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Media", "Entertainment"],
			symbols: [
				{ yahoo: "PARAA", google: "NASDAQ:PARAA", currency: "USD" },
				{ yahoo: "PARA", google: "NASDAQ:PARA", currency: "USD" },
			],
			isins: ["US92556H2067"],
			metadata: { founded: 2019, employees: 22965 },
			akas: [],
		},
		{
			name: "Signature Bank",
			wiki_name: "Signature Bank",
			symbol: "SBNY",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [{ yahoo: "SBNY", google: "NASDAQ:SBNY", currency: "USD" }],
			isins: [],
			metadata: { founded: 2001, employees: 1854 },
			akas: [],
		},
		{
			name: "SolarEdge Technologies, Inc.",
			wiki_name: "SolarEdge Technologies, Inc.",
			symbol: "SEDG",
			country: "Israel",
			indices: ["S&P 500"],
			industries: ["Photovoltaics"],
			symbols: [
				{ yahoo: "SEDG", google: "NASDAQ:SEDG", currency: "USD" },
				{ yahoo: "2ED.F", google: "FRA:2ED", currency: "EUR" },
				{ yahoo: "0L7S.L", google: "LON:0L7S", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 2006, employees: 3174 },
			akas: [],
		},
		{
			name: "Warner Bros. Discovery, Inc.",
			wiki_name: "Warner Bros. Discovery, Inc.",
			symbol: "WBD",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Media", "Entertainment"],
			symbols: [{ yahoo: "WBD", google: "NASDAQ:WBD", currency: "USD" }],
			isins: ["US9344231041"],
			metadata: { founded: 2022, employees: "" },
			akas: [],
		},
		{
			name: "Royal Dutch Shell PLC",
			symbol: "RDSA",
			country: "Netherlands",
			indices: ["FTSE 100"],
			industries: [
				"Energy",
				"Fossil Fuels",
				"Integrated Oil & Gas",
				"Oil & Gas",
			],
			symbols: [
				{ yahoo: "R6C.F", google: "FRA:R6C", currency: "EUR" },
				{ yahoo: "RDS-A", google: "NYSE:RDS-A", currency: "USD" },
				{ yahoo: "R6C3", google: "FRA:R6C3", currency: "USD" },
				{ yahoo: "RDS-B", google: "NYSE:RDS-B", currency: "USD" },
				{ yahoo: "RDSB.L", google: "LON:RDSB", currency: "GBP" },
				{ yahoo: "RDSA.AS", google: "AMS:RDSA", currency: "EUR" },
				{ yahoo: "R6C3.F", google: "FRA:R6C3", currency: "EUR" },
			],
		},
		{
			name: "Airtel Africa plc",
			wiki_name: "Airtel Africa plc",
			symbol: "AAF",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Telecommunication"],
			symbols: [
				{ yahoo: "AAFRF", google: "OTCMKTS:AAFRF", currency: "USD" },
				{ yahoo: "AAF.L", google: "LON:AAF", currency: "GBP" },
				{ yahoo: "9AA.F", google: "FRA:9AA", currency: "EUR" },
				{ yahoo: "9AA0.F", google: "FRA:9AA0", currency: "EUR" },
				{ yahoo: "AARTY", google: "OTCMKTS:AARTY", currency: "USD" },
			],
			isins: [],
			metadata: { founded: 2010, employees: 25000 },
			akas: [],
		},
		{
			name: "Endeavour Mining",
			wiki_name: "Endeavour Mining",
			symbol: "EDV",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Mining"],
			symbols: [
				{ yahoo: "EDV.L", google: "LON:EDV", currency: "GBP" },
				{ yahoo: "EDVMF", google: "OTCMKTS:EDVMF", currency: "USD" },
				{ yahoo: "6E2.F", google: "FRA:6E2", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1988, employees: "" },
			akas: [],
		},
		{
			name: "Howden Joinery Group plc",
			wiki_name: "Howden Joinery Group plc",
			symbol: "HWDN",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Furniture"],
			symbols: [
				{ yahoo: "10J.F", google: "FRA:10J", currency: "EUR" },
				{ yahoo: "HWDJF", google: "OTCMKTS:HWDJF", currency: "USD" },
				{ yahoo: "HWDN.L", google: "LON:HWDN", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 1995, employees: "" },
			akas: [],
		},
		{
			name: "RS Group plc",
			wiki_name: "RS Group plc",
			symbol: "RS",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [],
			symbols: [
				{ yahoo: "ECN.F", google: "FRA:ECN", currency: "EUR" },
				{ yahoo: "EENEF", google: "OTCMKTS:EENEF", currency: "USD" },
				{ yahoo: "RS1.L", google: "LON:RS1", currency: "GBP" },
			],
			isins: ["GB0003096442"],
			metadata: { founded: 1937, employees: 6806 },
			akas: [],
		},
		{
			name: "About You Holding SE",
			wiki_name: "About You Holding SE",
			symbol: "0A9O",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "0A9O.L", google: "LON:0A9O", currency: "GBP" },
				{ yahoo: "YOU.F", google: "FRA:YOU", currency: "EUR" },
			],
			isins: ["DE000A3CNK42"],
			metadata: { founded: 2014, employees: 1000 },
			akas: [],
		},
		{
			name: "adesso SE",
			wiki_name: "adesso SE",
			symbol: "0N5I",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "0N5I.L", google: "LON:0N5I", currency: "GBP" },
				{ yahoo: "ADN1.F", google: "FRA:ADN1", currency: "EUR" },
			],
			isins: ["DE000A0Z23Q5"],
			metadata: { founded: 1997, employees: 5814 },
			akas: [],
		},
		{
			name: "GFT Technologies SE",
			wiki_name: "GFT Technologies SE",
			symbol: "0O2W",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "0O2W.L", google: "LON:0O2W", currency: "GBP" },
				{ yahoo: "GFT.F", google: "FRA:GFT", currency: "EUR" },
			],
			isins: ["DE0005800601"],
			metadata: { founded: 1987, employees: 7718 },
			akas: [],
		},
		{
			name: "Vitesco Technologies Group AG",
			wiki_name: "Vitesco Technologies Group AG",
			symbol: "VTS",
			country: "Germany",
			indices: ["SDAX"],
			industries: [
				"Auto, Truck & Motorcycle Parts",
				"Automobiles & Auto Parts",
				"Consumer Cyclicals",
			],
			symbols: [
				{ yahoo: "VTS.F", google: "FRA:VTS", currency: "EUR" },
				{ yahoo: "VTSCY", google: "OTCMKTS:VTSCY", currency: "USD" },
				{ yahoo: "0AAF.L", google: "LON:0AAF", currency: "GBP" },
				{ yahoo: "VTSC.F", google: "FRA:VTSC", currency: "EUR" },
			],
			isins: ["DE000VTSC017"],
			metadata: { founded: 2019, employees: 37000 },
			akas: [],
		},
		{
			name: "1&1 Aktiengesellschaft",
			wiki_name: "1&1 Aktiengesellschaft",
			symbol: "1U1",
			country: "Germany",
			indices: ["TECDAX"],
			industries: [],
			symbols: [{ yahoo: "1U1.F", google: "FRA:1U1", currency: "EUR" }],
			isins: ["DE0005545503"],
			metadata: { founded: 1988, employees: 3167 },
			akas: [],
		},
		{
			name: "Sonova Group",
			wiki_name: "Sonova Group",
			symbol: "SOON",
			country: "Switzerland",
			indices: ["Switzerland 20"],
			industries: ["Medical device"],
			symbols: [],
			isins: ["CH0012549785", "CH1012549785"],
			metadata: { founded: 1947, employees: 14242 },
			akas: [],
		},
		{
			name: "Shell plc",
			wiki_name: "Shell plc",
			symbol: "SHELL",
			country: "United Kingdom",
			indices: ["AEX"],
			industries: ["Energy", "Petroleum"],
			symbols: [
				{ yahoo: "SHEL", google: "NYSE:SHEL", currency: "USD" },
				{ yahoo: "SHELL.AS", google: "AMS:SHELL", currency: "EUR" },
				{ yahoo: "R6C0.F", google: "FRA:R6C0", currency: "EUR" },
				{ yahoo: "RYDAF", google: "OTCMKTS:RYDAF", currency: "USD" },
				{ yahoo: "SHEL.L", google: "LON:SHEL", currency: "GBP" },
				{ yahoo: "L3H.F", google: "FRA:L3H", currency: "EUR" },
			],
			isins: ["GB00BP6MXD84"],
			metadata: { founded: 1907, employees: 86000 },
			akas: [],
		},
		{
			name: "EnergieKontor AG",
			wiki_name: "EnergieKontor AG",
			symbol: "0E9V",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [
				{ yahoo: "0E9V.L", google: "LON:0E9V", currency: "GBP" },
				{ yahoo: "EKT.F", google: "FRA:EKT", currency: "EUR" },
			],
			isins: ["DE0005313506"],
			metadata: { founded: 1990, employees: 178 },
			akas: [],
		},
		{
			name: "Kontron S&T AG",
			wiki_name: "Kontron S&T AG",
			symbol: "KBC",
			country: "Germany",
			indices: ["SDAX"],
			industries: ["Computer hardware"],
			symbols: [{ yahoo: "KBC.F", google: "FRA:KBC", currency: "EUR" }],
			isins: [],
			metadata: { founded: 1959, employees: 1286 },
			akas: [],
		},
		{
			name: "PNE AG",
			wiki_name: "PNE AG",
			symbol: "PNE3",
			country: "Germany",
			indices: ["SDAX"],
			industries: [],
			symbols: [{ yahoo: "PNE3.F", google: "FRA:PNE3", currency: "EUR" }],
			isins: ["DE000A0JBPG2"],
			metadata: { founded: 1994, employees: 369 },
			akas: [],
		},
		{
			name: "ADTRAN, Inc.",
			wiki_name: "ADTRAN, Inc.",
			akas: ["Adtran, Inc."],
			symbol: "ADTN",
			country: "United States",
			indices: ["S&P 600"],
			industries: [],
			symbols: [{ yahoo: "ADTN", google: "NASDAQ:ADTN", currency: "USD" }],
			isins: ["US00738A1060"],
			metadata: { founded: 1985, employees: 2045 },
		},
		{
			name: "D’Ieteren S.A.",
			wiki_name: "D’Ieteren S.A.",
			symbol: null,
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [],
			symbols: [{ yahoo: "DJDA.F", google: "FRA:DJDA", currency: "EUR" }],
			isins: ["BE0974259880"],
			metadata: { founded: 1805, employees: 32951 },
			akas: [],
		},
		{
			name: "VGP NV",
			wiki_name: "VGP NV",
			symbol: null,
			country: "Belgium",
			indices: ["BEL 20"],
			industries: [],
			symbols: [
				{ yahoo: "0HM0.L", google: "LON:0HM0", currency: "GBP" },
				{ yahoo: "VGPBF", google: "OTCMKTS:VGPBF", currency: "USD" },
				{ yahoo: "4V1.F", google: "FRA:4V1", currency: "EUR" },
			],
			isins: ["BE0003878957"],
			metadata: { founded: 1998, employees: 220 },
			akas: [],
		},
		{
			name: "CoStar Group, Inc.",
			wiki_name: "CoStar Group, Inc.",
			symbol: "CSGP",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Technology", "Commercial property"],
			symbols: [
				{ yahoo: "CSGP", google: "NASDAQ:CSGP", currency: "USD" },
				{ yahoo: "RLG.F", google: "FRA:RLG", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1987, employees: 4742 },
			akas: [],
		},
		{
			name: "Elevance Health, Inc.",
			wiki_name: "Elevance Health, Inc.",
			symbol: "ELV",
			country: "India",
			indices: ["S&P 500"],
			industries: ["Managed healthcare"],
			symbols: [
				{ yahoo: "ELV", google: "NYSE:ELV", currency: "USD" },
				{ yahoo: "0HG8.L", google: "LON:0HG8", currency: "GBP" },
				{ yahoo: "A58.F", google: "FRA:A58", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 2004, employees: 98200 },
			akas: [],
		},
		{
			name: "EQT Corporation",
			wiki_name: "EQT Corporation",
			symbol: "EQT",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Petroleum industry"],
			symbols: [
				{ yahoo: "EQT", google: "NYSE:EQT", currency: "USD" },
				{ yahoo: "EQ6.F", google: "FRA:EQ6", currency: "EUR" },
				{ yahoo: "0IDU.L", google: "LON:0IDU", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 1888, employees: 693 },
			akas: [],
		},
		{
			name: "Invitation Homes Inc.",
			wiki_name: "Invitation Homes Inc.",
			symbol: "INVH",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Real estate investment trust"],
			symbols: [
				{ yahoo: "INVH", google: "NYSE:INVH", currency: "USD" },
				{ yahoo: "4IV.F", google: "FRA:4IV", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 2012, employees: 1240 },
			akas: [],
		},
		{
			name: "ON Semiconductor Corporation",
			wiki_name: "ON Semiconductor Corporation",
			symbol: "ON",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Semiconductors"],
			symbols: [
				{ yahoo: "ON", google: "NASDAQ:ON", currency: "USD" },
				{ yahoo: "0KC4.L", google: "LON:0KC4", currency: "GBP" },
				{ yahoo: "XS4.F", google: "FRA:XS4", currency: "EUR" },
			],
			isins: ["US6821891057"],
			metadata: { founded: 1999, employees: 30000 },
			akas: [],
		},
		{
			name: "PG&E Corporation",
			wiki_name: "PG&E Corporation",
			symbol: "PCG",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Electricity", "Natural gas"],
			symbols: [
				{ yahoo: "PCG", google: "NYSE:PCG", currency: "USD" },
				{ yahoo: "0QR3.L", google: "LON:0QR3", currency: "GBP" },
				{ yahoo: "PCG.F", google: "FRA:PCG", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1905, employees: 26000 },
			akas: [],
		},
		{
			name: "Targa Resources Corp.",
			wiki_name: "Targa Resources Corp.",
			symbol: "TRGP",
			country: "United States",
			indices: ["S&P 500"],
			industries: [],
			symbols: [
				{ yahoo: "TRGP", google: "NYSE:TRGP", currency: "USD" },
				{ yahoo: "0LD9.L", google: "LON:0LD9", currency: "GBP" },
				{ yahoo: "TAR.F", google: "FRA:TAR", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 2005, employees: 2430 },
			akas: [],
		},
		{
			name: "Vici Properties Inc.",
			wiki_name: "Vici Properties Inc.",
			symbol: "VICI",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Real estate investment trust"],
			symbols: [
				{ yahoo: "VICI", google: "NYSE:VICI", currency: "USD" },
				{ yahoo: "1KN.F", google: "FRA:1KN", currency: "EUR" },
			],
			isins: ["US9256521090"],
			metadata: { founded: 2017, employees: 147 },
			akas: [],
		},
		{
			name: "Altarea SCA",
			wiki_name: "Altarea SCA",
			akas: ["Altarea"],
			symbol: "ALTA",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "0IRK.L", google: "LON:0IRK", currency: "GBP" },
				{ yahoo: "XAL.F", google: "FRA:XAL", currency: "EUR" },
				{ yahoo: "ATRRF", google: "OTCMKTS:ATRRF", currency: "USD" },
			],
			isins: [],
			metadata: { founded: 1994, employees: 1742 },
		},
		{
			name: "Antin Infrastructure Partners",
			wiki_name: "Antin Infrastructure Partners",
			symbol: null,
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [{ yahoo: "8ZU.F", google: "FRA:8ZU", currency: "EUR" }],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Carmila SAS",
			wiki_name: "Carmila SAS",
			symbol: "CARM",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [{ yahoo: "CUX1.F", google: "FRA:CUX1", currency: "EUR" }],
			isins: ["FR0010828137"],
			metadata: { founded: 2014, employees: 180 },
			akas: [],
		},
		{
			name: "Compagnie Française d'Assurance pour le Commerce Extérieur (COFACE)",
			wiki_name:
				"Compagnie Française d'Assurance pour le Commerce Extérieur (COFACE)",
			symbol: null,
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["Credit Insurance"],
			symbols: [],
			isins: ["FR0010667147"],
			metadata: { founded: 1946, employees: 4250 },
			akas: [],
		},
		{
			name: "Derichebourg",
			wiki_name: "Derichebourg",
			symbol: null,
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [{ yahoo: "PNU.F", google: "FRA:PNU", currency: "EUR" }],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Euroapi",
			wiki_name: "Euroapi",
			symbol: null,
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "940.F", google: "FRA:940", currency: "EUR" },
				{ yahoo: "EAPIF", google: "OTCMKTS:EAPIF", currency: "USD" },
			],
			isins: [],
			metadata: { founded: 0, employees: 0 },
			akas: [],
		},
		{
			name: "Interparfums SA",
			wiki_name: "Interparfums SA",
			symbol: "ITP",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [{ yahoo: "I8P.F", google: "FRA:I8P", currency: "EUR" }],
			isins: ["FR0004024222", "US4583341098"],
			metadata: { founded: 1982, employees: 298 },
			akas: [],
		},
		{
			name: "OVH SAS",
			wiki_name: "OVH SAS",
			symbol: "OVH",
			country: "France",
			indices: ["CAC Mid 60"],
			industries: ["hosting"],
			symbols: [],
			isins: [],
			metadata: { founded: 1999, employees: 1400 },
			akas: [],
		},
		{
			name: "Somfy",
			wiki_name: "Somfy",
			symbol: null,
			country: "France",
			indices: ["CAC Mid 60"],
			industries: [],
			symbols: [
				{ yahoo: "YSM.F", google: "FRA:YSM", currency: "EUR" },
				{ yahoo: "0RR3.L", google: "LON:0RR3", currency: "GBP" },
			],
			isins: [],
			metadata: { founded: 1969, employees: 6500 },
			akas: [],
		},
		{
			name: "Wärtsilä Corporation",
			wiki_name: "Wärtsilä Corporation",
			symbol: "WRT1V",
			country: "Finland",
			indices: ["OMX Helsinki 25"],
			industries: ["Manufacturing"],
			symbols: [],
			isins: ["FI0009003727"],
			metadata: { founded: 1834, employees: 18 },
			akas: [],
		},
		{
			name: "Convatec Group plc",
			wiki_name: "Convatec Group plc",
			symbol: "CTEC",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [],
			symbols: [
				{ yahoo: "CTEC.L", google: "LON:CTEC", currency: "GBP" },
				{ yahoo: "2CV.F", google: "FRA:2CV", currency: "EUR" },
				{ yahoo: "CNVVY", google: "OTCMKTS:CNVVY", currency: "USD" },
				{ yahoo: "2CVU.F", google: "FRA:2CVU", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1978, employees: 9900 },
			akas: [],
		},
		{
			name: "Dechra Pharmaceuticals plc",
			wiki_name: "Dechra Pharmaceuticals plc",
			symbol: "DPH",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Pharmaceuticals"],
			symbols: [
				{ yahoo: "1PK0.F", google: "FRA:1PK0", currency: "EUR" },
				{ yahoo: "DPHAY", google: "OTCMKTS:DPHAY", currency: "USD" },
				{ yahoo: "DPH.L", google: "LON:DPH", currency: "GBP" },
				{ yahoo: "1PK.F", google: "FRA:1PK", currency: "EUR" },
				{ yahoo: "DCHPF", google: "OTCMKTS:DCHPF", currency: "USD" },
			],
			isins: ["GB0009633180"],
			metadata: { founded: 1997, employees: 1700 },
			akas: [],
		},
		{
			name: "F&C Investment Trust plc",
			wiki_name: "F&C Investment Trust plc",
			symbol: "FCIT",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Investment management"],
			symbols: [
				{ yahoo: "FCIT.L", google: "LON:FCIT", currency: "GBP" },
				{ yahoo: "FLIVF", google: "OTCMKTS:FLIVF", currency: "USD" },
			],
			isins: [],
			metadata: { founded: 1868, employees: "" },
			akas: [],
		},
		{
			name: "Frasers Group plc",
			wiki_name: "Frasers Group plc",
			symbol: "FRAS",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["textile", "cosmetics", "electronics"],
			symbols: [
				{ yahoo: "FRAS.L", google: "LON:FRAS", currency: "GBP" },
				{ yahoo: "ZVX.F", google: "FRA:ZVX", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1982, employees: 26496 },
			akas: [],
		},
		{
			name: "Haleon plc",
			wiki_name: "Haleon plc",
			symbol: "HLN",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Healthcare"],
			symbols: [
				{ yahoo: "HLNCF", google: "OTCMKTS:HLNCF", currency: "USD" },
				{ yahoo: "HLN", google: "NYSE:HLN", currency: "USD" },
				{ yahoo: "H6D.F", google: "FRA:H6D", currency: "EUR" },
				{ yahoo: "HLN.L", google: "LON:HLN", currency: "GBP" },
				{ yahoo: "H6D0.F", google: "FRA:H6D0", currency: "EUR" },
			],
			isins: ["GB00BMX86B70"],
			metadata: { founded: 2022, employees: 22000 },
			akas: [],
		},
		{
			name: "Harbour Energy plc",
			wiki_name: "Harbour Energy plc",
			symbol: "HBR",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: ["Oil"],
			symbols: [
				{ yahoo: "PQQ0.F", google: "FRA:PQQ0", currency: "EUR" },
				{ yahoo: "PMOIF", google: "OTCMKTS:PMOIF", currency: "USD" },
				{ yahoo: "HBR.L", google: "LON:HBR", currency: "GBP" },
				{ yahoo: "PQQ2.F", google: "FRA:PQQ2", currency: "EUR" },
				{ yahoo: "HBRIY", google: "OTCMKTS:HBRIY", currency: "USD" },
			],
			isins: [],
			metadata: { founded: 2014, employees: "" },
			akas: [],
		},
		{
			name: "HomeServe plc",
			wiki_name: "HomeServe plc",
			symbol: "HSV",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [],
			symbols: [
				{ yahoo: "HSV.L", google: "LON:HSV", currency: "GBP" },
				{ yahoo: "XHSB.F", google: "FRA:XHSB", currency: "EUR" },
				{ yahoo: "HMSVF", google: "OTCMKTS:HMSVF", currency: "USD" },
			],
			isins: [],
			metadata: { founded: 1993, employees: "" },
			akas: [],
		},
		{
			name: "Pershing Square Holdings Ltd.",
			wiki_name: "Pershing Square Holdings Ltd.",
			symbol: "PSH",
			country: "Guernsey",
			indices: ["FTSE 100"],
			industries: [],
			symbols: [
				{ yahoo: "PSH.L", google: "LON:PSH", currency: "GBP" },
				{ yahoo: "7P2.F", google: "FRA:7P2", currency: "EUR" },
				{ yahoo: "PSH.AS", google: "AMS:PSH", currency: "EUR" },
				{ yahoo: "PSHZF", google: "OTCMKTS:PSHZF", currency: "USD" },
			],
			isins: [],
			metadata: { founded: "", employees: "" },
			akas: [],
		},
		{
			name: "The Unite Group plc",
			wiki_name: "The Unite Group plc",
			symbol: "UTG",
			country: "United Kingdom",
			indices: ["FTSE 100"],
			industries: [],
			symbols: [],
			isins: [],
			metadata: { founded: 1991, employees: 1900 },
			akas: [],
		},
		{
			name: "Arch Capital Group Ltd.",
			wiki_name: "Arch Capital Group Ltd.",
			symbol: "ACGL",
			country: "Bermuda",
			indices: ["S&P 500"],
			industries: ["Insurance", "Finance"],
			symbols: [
				{ yahoo: "ACGL", google: "NASDAQ:ACGL", currency: "USD" },
				{ yahoo: "RSK.F", google: "FRA:RSK", currency: "EUR" },
			],
			isins: [],
			metadata: { founded: 1995, employees: 5200 },
			akas: [],
		},
		{
			name: "Meta Platforms, Inc.",
			wiki_name: "Meta Platforms, Inc.",
			symbol: "META",
			country: "United States",
			indices: ["NASDAQ 100", "S&P 100", "S&P 500"],
			industries: [
				"Social media",
				"Social network advertising",
				"Consumer electronics",
				"Virtual reality",
			],
			symbols: [
				{ yahoo: "META", google: "NASDAQ:META", currency: "USD" },
				{ yahoo: "FB2A.F", google: "FRA:FB2A", currency: "EUR" },
				{ yahoo: "MWRK", google: "OTCMKTS:MWRK", currency: "USD" },
			],
			isins: [],
			metadata: { founded: 2004, employees: 83553 },
			akas: [],
		},
		{
			name: "Gen Digital Inc.",
			wiki_name: "Gen Digital Inc.",
			symbol: "GEN",
			country: "United States",
			indices: ["S&P 500"],
			industries: ["Computer software"],
			symbols: [{ yahoo: "GEN", google: "NASDAQ:GEN", currency: "USD" }],
			isins: [],
			metadata: { founded: 1982, employees: 2700 },
			akas: [],
		},
		{
			name: "Lucid Motors",
			wiki_name: "Lucid Motors",
			akas: ["Lucid Group, Inc."],
			symbol: "LCID",
			country: "United States",
			indices: ["NASDAQ 100"],
			// industries: ["Automobiles"],
			// symbols: [{ yahoo: "LCID", google: "NASDAQ:LCID", currency: "USD" }],
			// isins: ["US5494981039"],
			// metadata: { founded: 2007, employees: 3900 },
		},
	],
};
