import { Link, LinkProps, useNavigate } from "react-router-dom";
import * as React from "react";

import { docsConfig } from "@/config/docs";
// import { siteConfig } from "@/config/site";
import { cn } from "@/lib/utils";
import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";

export function MobileNav() {
	const [open, setOpen] = React.useState(false);

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger asChild>
				<Button
					variant="ghost"
					className="mr-2 px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden"
				>
					<svg
						strokeWidth="1.5"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="h-5 w-5"
					>
						<path
							d="M3 5H11"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						></path>
						<path
							d="M3 12H16"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						></path>
						<path
							d="M3 19H21"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						></path>
					</svg>
					<span className="sr-only">Toggle Menu</span>
				</Button>
			</SheetTrigger>

			<SheetContent side="left" className="pr-0">
				<MobileLink to="/" className="flex items-center" onOpenChange={setOpen}>
					<Icons.logo className="mr-2 h-4 w-0" />
					{/* <span className="font-bold">{siteConfig.name}</span> */}
				</MobileLink>
				<ScrollArea className="my-4 h-[calc(100vh-8rem)] pb-10 pl-6">
					<div className="flex flex-col space-y-3">
						{docsConfig.mainNav?.map(
							(item) =>
								item.href && (
									<MobileLink
										key={item.href}
										to={item.href}
										onOpenChange={setOpen}
									>
										{item.title}
									</MobileLink>
								)
						)}
					</div>
					<div className="flex flex-col space-y-2">
						{docsConfig.sidebarNav.map((item, index) => (
							<div key={index} className="flex flex-col space-y-3 pt-6">
								<h4 className="font-medium">{item.title}</h4>
								{item?.items?.length &&
									item.items.map((item) => (
										<React.Fragment key={item.href}>
											{!item.disabled &&
												(item.href ? (
													<MobileLink
														to={item.href}
														onOpenChange={setOpen}
														className="text-muted-foreground"
													>
														{item.title}
														{item.label && (
															<span className="ml-2 rounded-md bg-[#adfa1d] px-1.5 py-0.5 text-xs leading-none text-[#000000] no-underline group-hover:no-underline">
																{item.label}
															</span>
														)}
													</MobileLink>
												) : (
													item.title
												))}
										</React.Fragment>
									))}
							</div>
						))}
					</div>
				</ScrollArea>
			</SheetContent>
		</Sheet>
	);
}

interface MobileLinkProps extends LinkProps {
	onOpenChange?: (open: boolean) => void;
	children: React.ReactNode;
	className?: string;
}

function MobileLink({
	to,
	onOpenChange,
	className,
	children,
	...props
}: MobileLinkProps) {
	const router = useNavigate();
	return (
		<Link
			to={to}
			onClick={() => {
				router(to);
				onOpenChange?.(false);
			}}
			className={cn(className)}
			{...props}
		>
			{children}
		</Link>
	);
}
